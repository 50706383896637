// import { useNavigate } from "react-router-dom";
import * as actionTypes from "../actionTypes";

export const fetchChannelIdDataStart = () => {
  // console.log("userDetails", userDetails);
  return {
    type: actionTypes.FETCH_CHANNEL_ID_DATA_START,
    // payload: userDetails,
  };
};
export const fetchChannelIdDataSuccess = (data) => {
  // console.log("userDetails", data);
  return {
    type: actionTypes.FETCH_CHANNEL_ID_DATA_SUCCESS,
    payload: data,
  };
};
export const fetchChannelIdDataFailed = (error) => {
  console.log("userDetails", error);
  // const navigate = useNavigate()
  // if(error==="YouTube Channel is not Present")
  // navigate("/connect")
  return {
    type: actionTypes.FETCH_CHANNEL_ID_DATA_FAILED,
    payload: error,
  };
};