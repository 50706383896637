import React from 'react'
import { useNavigate } from 'react-router-dom'

const Congrate = () => {
    const navigate = useNavigate()

    const login = () => {
navigate("/login")
    }
  return (
    <div
      className="container-fluid cf-pricing-hero-section"
      style={{ height: "100vh" }}
    >
      <div className="d-flex justify-content-center">
        <div
          className="cf-pricing-box-cards green-pricing-box"
          style={{ minHeight: "0px" }}
        >

          <div className="cf-pricing-plan-content-wrapper-2" style={{marginBottom:"0px"}}>
            
            <div className="cf-pricing-plan-text-2">Congratulations! Your account has been verified.</div>
              <div className="cf-pricing-plan-content-text-2" style={{fontSize:"16px"}}>
                Click here to <span style={{color:"#7ff3de",cursor:"pointer"}} onClick={login}>Login</span>
              </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Congrate