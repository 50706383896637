import React,{useState} from 'react'

const Thumbnail = ({file,width,height,borderRadius,className,setImageUploadBtn}) => {
    const [preview,setPreview] = useState(null)
    console.log('borderRadius', file)
    const reader = new FileReader();
    try {
        reader.readAsDataURL(file);
        
      } catch (error) {
        console.log("show",error);
      }
    // reader.readAsDataURL(file);
    console.log('reader.result', reader)
    reader.onload = () => {
        setPreview(reader.result)
    }
  return (
    <img
    src={preview}
    width={width}
    height={height}
    className={className}
    style={{borderRadius:`${borderRadius}`}}
    alt="Thumbnail"
                          />
  )
}

export default Thumbnail