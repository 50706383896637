import React from "react";
// import { Modal, Button } from 'rsuite';
// import PrepoPie from './PrepoPie';
import Modal from "react-bootstrap/Modal";
import PieMenu from "../Private/PieMenu";
// import classes from "./TermsService.module.css";

const ChartModal = ({ open, handleClose, locate2, chartPrepo,headers }) => {
  // console.log("open", locate2);
  // console.log("chartPrepo",chartPrepo)
  return (
    <>
      <Modal
        dialogClassName="modal-100w"
        show={open}
        // style={{ maxWidth: "100%" }}
        size="lg"
        // fullscreen
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{headers}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h4>Key Indicators</h4>
            <p><span className="indicator"></span>Highly Searched</p>
            <p><span className="indicator"></span>Avg. Searched</p>
            <p><span className="indicator"></span>Lowest Searched</p>
          </div>
          <PieMenu data={chartPrepo} locate={locate2} />
          {/* <button onClick={handleDownload}>download</button> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChartModal;
