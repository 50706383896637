import React, { useState } from "react";
import { country } from "../Pages/Country";
import { Form, Formik } from "formik";
// import { askYoutube } from "../Pages/AskMap";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";
import { ProgressBar } from "react-loader-spinner";
import { Tooltip, Whisper } from "rsuite";
import InfoOutlineIcon from "@rsuite/icons/InfoOutline";
import { tooltips } from "../Pages/StringStore";
import Preposition from "../Pages/Preposition";
import Question from "../Pages/Question";
import Comparision from "../Pages/Comparision";
import Alphabet from "../Pages/Alphabet";
// import PieMenu from "./PieMenu";

const AskYtubeBooster = () => {
  const [load, setLoad] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [prePo, setPrePo] = useState([]);
  const [comparing, setComparing] = useState([]);
  const [chartCompare, setChartCompare] = useState(null);
  const [chartPrepo, setChartPrepo] = useState(null);
  const [aplha, setAlpha] = useState([]);
  const [chartComparing,setChartComparing] = useState(null)
  const [chartAlphabet,setChartAlphabet] = useState(null)

  const languages = [
    { id: "1", name: "English", code: "en" },
    { id: "2", name: "Français", code: "fr" },
    { id: "2", name: "Español", code: "es" },
    { id: "3", name: "Deutsch", code: "de" },
    { id: "4", name: "Português", code: "pt" },
    { id: "5", name: "Italiano", code: "it" },
    { id: "6", name: "Nederlands", code: "nl" },
    { id: "7", name: "Polski", code: "pl" },
    { id: "8", name: "Dansk", code: "da" },
    { id: "9", name: "Svenska", code: "sv" },
    { id: "10", name: "Norsk", code: "no" },
    { id: "11", name: "Pусский", code: "ru" },
    { id: "12", name: "Slovenčina", code: "el" },
    { id: "13", name: "Suomi", code: "fi" },
    { id: "14", name: "Čeština", code: "cs" },
    { id: "15", name: "Hindi", code: "hi" },
  ];

  const initialValues = {
    keyword: "",
    country_code: "US",
    language: "en",
  };

  // const datalol = [
  //   { label: "Slice", value: 25, color: 'yellow' },
  //   { label: 'Slice 2', value: 25, color: 'blue' },
  //   { label: 'Slice 5', value: 25, color: 'green' },
  //   { label: 'Slice 6', value: 25, color: 'red' },
  // ];

  const generateAnswer = async (values) => {
    // e.preventDefault();
    // console.log("e", values);
    // console.log("askYoutube", askYoutube);
    try {
      setLoad(true);
      const data = await privateRequest.post(
        `/api/youtube/ask-ytubebooster/`,
        values
      );
      // console.log("askdata", data.data);
      setLoad(false);
      // console.log("firstDataforcompare", data.data.chart.question);
      setChartCompare(data.data.chart.question);
      setChartPrepo(data.data.chart.preposition);
      setChartComparing(data.data.chart.comparison)
      setChartAlphabet(data.data.chart.alpha)
      // for Question
      const forResponce = [];
      Object.keys(data.data.question).forEach((key) =>
        forResponce.push({
          name: key,
          rating: data.data.question[key],
        })
      );
      // console.log("forResponse",forResponce)
      setQuestions(forResponce);
      // for preposition
      const forPrePo = [];
      Object.keys(data.data.preposition).forEach((key) =>
        forPrePo.push({
          name: key,
          rating: data.data.preposition[key],
        })
      );
      setPrePo(forPrePo);
      // for comparision
      const forCompa = [];
      Object.keys(data.data.comparison).forEach((key) =>
        forCompa.push({
          name: key,
          rating: data.data.comparison[key],
        })
      );
      setComparing(forCompa);
      //  for Alphabet
      const forAlpha = [];
      Object.keys(data.data.alpha).forEach((key) =>
        forAlpha.push({
          name: key,
          rating: data.data.alpha[key],
        })
      );
      setAlpha(forAlpha);
    } catch (err) {
      console.log("askerr", err);
    }
  };

  // console.log("question", questions);

  // useEffect(() => {
  //   rerendering_pie();
  // }, [locate]);

  return (
    <>
      <div>
        <h1 className="ais" style={{ marginBottom: "0px" }}>
          Trending Topic Research
          <span className="information ms-1">
            <Whisper
              placement="bottomEnd"
              controlId="control-id-click"
              trigger="hover"
              speaker={<Tooltip>{tooltips.askytb}</Tooltip>}
            >
              <InfoOutlineIcon style={{ marginBottom: "12px" }} />
            </Whisper>
          </span>
        </h1>
      </div>
      <div
        className="containers-custom"
        style={{ paddingLeft: "30px", paddingTop: "16px" }}
      >
        <div className="f-tab-heading">
          Utilize your keywords to find out what your target audience is
          searching for. Expand the research to include countries and languages.
        </div>
        <div className="container-fluid">
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={generateAnswer}
          >
            {(formik) => {
              return (
                <Form>
                  <div className="row mt-2">
                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12">
                      <div className="brix---position-relative-2">
                        <input
                          type="text"
                          className="brix---input-large-button-inside w-input"
                          autoFocus={true}
                          style={{ border: "none" }}
                          maxLength="256"
                          data-name="BRIX - Footer Email - V16"
                          placeholder="Enter a topic, brand or product. Use 1-2 words for best results"
                          id="BRIX-Footer-Email-V16"
                          name="keyword"
                          value={formik.values.keyword}
                          onChange={formik.handleChange}
                          // value={askyt}
                          //           onChange={(e) => setAskYt(e.target.value)}
                        />
                        {formik.values.keyword.length === 0 || load === true ? (
                          ""
                        ) : (
                          <input
                            type="submit"
                            data-wait="Please wait..."
                            value="Generate"
                            style={{ border: "none" }}
                            className="brix---btn-primary-inside-input w-button"
                          />
                        )}{" "}
                      </div>
                    </div>
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 d-flex gap-1">
                      <select
                        name="country_code"
                        value={formik.values.country_code}
                        onChange={formik.handleChange}
                        //  onChange={(e) => cntryCode(e)}
                        style={{
                          height: "49px",
                          borderRadius: "10px",
                          border: "1px solid #fff",
                          width: "auto",
                        }}
                        className="form-select"
                        aria-label="Default select example"
                      >
                        {country.map((res, index) => (
                          <option key={index} value={res.code}>
                            {res.name}
                          </option>
                        ))}
                      </select>
                      <select
                        name="language"
                        value={formik.values.language}
                        onChange={formik.handleChange}
                        // onChange={(e) => langsCode(e)}
                        style={{
                          height: "49px",
                          borderRadius: "10px",
                          border: "1px solid #fff",
                          width: "auto",
                        }}
                        className="form-select"
                        aria-label="Default select example"
                      >
                        {languages.map((res, index) => (
                          <option key={index} value={res.code}>
                            {res.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <div className="container-fluid">
        {/* {questions} */}

        {load === true ? (
          <div className="d-flex justify-content-center align-items-center mt-5">
            LOADING &nbsp;
            <ProgressBar
              height="80"
              width="80"
              ariaLabel="progress-bar-loading"
              // wrapperStyle={{}}
              wrapperClass="progress-bar-wrapper"
              borderColor="#53389e"
              barColor="#53389e"
            />
          </div>
        ) : (
          <div
            className="mt-2 main-row"
            style={{ fontSize: "14px", textAlign: "start" }}
          >
            <Question chartCompare={chartCompare} questions={questions} />

            <Preposition chartPrepo={chartPrepo} prePo={prePo} />

            <Comparision comparing={comparing} chartComparing={chartComparing} />
            
            <Alphabet aplha={aplha} chartAlphabet={chartAlphabet}/>
          </div>
        )}
      </div>
    </>
  );
};

export default AskYtubeBooster;
