import React, { useEffect, useState } from "react";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";
import { Button, Modal, Tag } from "rsuite";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubscriptionDetailsStart } from "../../../redux/actions/subscriptionDetailsActions";

const TransitionInvoice = () => {
  const [tranceData, setTranceData] = useState([]);
  const [invData, setInvData] = useState([]);
  const [open, setOpen] = useState(false);
  const [reasons, setReasons] = useState(false);
  const [acceptReason, setAcceptReason] = useState("");
  const [cancelledData,setCancelledData] = useState([])
  // const [subscriptionDetails,setSubscriptionDetails] = useState([])
  const { subscribeData } = useSelector((state) => state.subscribe);
  // console.log("subscribedataa",subscribeData.unused_credits)

  const dispatch = useDispatch();

  const handleOpen = (data) => {
    setOpen(true);
    // console.log("modalData", data);
    // setMData(data);
  };
  const handleClose = () => {
    setReasons(false)
    setOpen(false)
  };

  const ApplyCancellation = async () => {
    const payload = {
      reason: acceptReason
    }
    // console.log('accept reacons',payload)
    try {
      privateRequest.post(`/api/payments/subscription-cancel/`, payload)
    } catch (err) {
      console.log("subscription cancell", err)
    }
    getCancellationDetails();
    handleClose();
  }

  const getCancellationDetails = async () => {
    try {
      
      const data = await privateRequest.get(`/api/payments/list-cancel-subscription/`);
      // console.log("don't tell your",data)
      setCancelledData(data.data)
    } catch (err) {
      console.log("err",err)
    }
  }

  // const getSubscriptionInfo = async () => {
  // const data = await privateRequest.get(`/api/payments/user/subscription/`);
  // console.log("supinfo",data.data)
  // setSubscriptionDetails(data.data)
  // }

  const gettransdetails = async () => {
    const data = await privateRequest.get("/api/payments/transactions/");
    // console.log("trancedata", data.data.transaction_data);
    setTranceData(data.data);
  };

  // console.log("subscrebers", subscribeData);

  const getInvoice = async () => {
    const inv = await privateRequest.get("/api/payments/invoices/");
    // console.log("inv", inv.data.invoices_data);
    setInvData(inv.data);
  };
  useEffect(() => {
    // getSubscriptionInfo();
    dispatch(fetchSubscriptionDetailsStart());
    getCancellationDetails();
    gettransdetails();
    getInvoice();
  }, []);

  return (
    <>
      <h2>Subscription Data</h2>
      <div className="section-3-view col-lg-10">
        <div className="row">
          <div className="table-responsive">
            <h4>Subscription Details :</h4>
            <table className="table" style={{ border: "#fff" }}>
              <thead>
                <tr
                  className="tr-shadow"
                  style={{
                    backgroundColor: "#fff",
                    fontSize: "14px",
                    color: "#3e3a3a",
                  }}
                >
                  <th scope="col">GATEWAY NAME</th>
                  <th scope="col">BILLING PERIOD</th>
                  <th scope="col">START AT</th>
                  <th scope="col">NEXT BILLING DATE</th>
                  <th scope="col">TRAIL EXPIRY DATE</th>
                  <th scope="col">STATUS</th>
                  <th scope="col">UPDATE TYPE</th>
                  <th scope="col">Unused Credits</th>
                  <th scope="col">Subscription Count</th>
                  <th scope="col">Current Plan</th>
                </tr>
              </thead>
              <tbody style={{ backgroundColor: "aliceblue" }}>
                <tr>
                  <td>
                    <div style={{ textTransform: "capitalize" }}>
                      {" "}
                      <p>{subscribeData.gateway_name}</p>
                      {/* <Tag color="green">{res.gateway_name}</Tag> */}
                      {/* <p className="sub-leting" style={{ marginTop: "6px" }}>
                          {moment(res.created_at).format(
                            " MMMM DD,YYYY h:mm a"
                          )}
                        </p> */}
                    </div>
                  </td>

                  <td>
                    <p>{subscribeData.billing_period}</p>
                  </td>
                  <td>
                    <p style={{ color: "blue" }}>
                      {moment(subscribeData.starts_at).format(
                        " MMMM DD,YYYY h:mm a"
                      )}
                    </p>
                    {/* <p className="sub-leting">Gateway - {res.gateway_type}</p>
                      <p
                        className="sub-leting"
                        style={{ letterSpacing: "2px" }}
                      >
                        Card-{res["card-last_four_digits"]}
                      </p> */}
                  </td>
                  <td>
                    {moment(subscribeData.next_billing_date).format(
                      " MMMM DD,YYYY h:mm a"
                    )}
                  </td>
                  <td>
                    {subscribeData.trial_expiry_date === null
                      ? "-"
                      : moment(subscribeData.trial_expiry_date).format(
                        " MMMM DD,YYYY h:mm a"
                      )}
                  </td>
                  <td>{subscribeData.status}</td>
                  <td>{subscribeData.update_type === null ? "-" : subscribeData.update_type}</td>
                  <td>{subscribeData.unused_credits === undefined ? "" : subscribeData.unused_credits.remaining_amount}</td>
                  <td>{subscribeData.unused_credits === undefined ? "" : subscribeData.unused_credits.subscription_count}</td>
                  <td>{subscribeData.plan_name}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

       {/* cancellation data */}
       {cancelledData.length===0?null:<div className="section-3-view col-lg-10">
        <div className="row">
          <div className="table-responsive">
            <h4>Subscription Details :</h4>
            <table className="table" style={{ border: "#fff" }}>
              <thead>
                <tr
                  className="tr-shadow"
                  style={{
                    backgroundColor: "#fff",
                    fontSize: "14px",
                    color: "#3e3a3a",
                  }}
                >
                  <th scope="col">User Id</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">Cancellation Date</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody style={{ backgroundColor: "aliceblue" }}>
                {cancelledData.map((res) => (<tr key={res.user_id}>
                  <td>{res.user_id}</td>
                  <td>{res.starts_at}</td>
                  <td>{res.cancellation_date}</td>
                  <td>{res.status}</td>
                </tr>))}
              </tbody>
              </table>
            </div>
            </div>
            </div>}

      {/* transaction details */}
      <div className="section-3-view col-lg-9">
        <div className="row">
          <div className="table-responsive">
            <h4>Transactions Details :</h4>
            <table className="table" style={{ border: "#fff" }}>
              <thead>
                <tr
                  className="tr-shadow"
                  style={{
                    backgroundColor: "#fff",
                    fontSize: "14px",
                    color: "#3e3a3a",
                  }}
                >
                  <th scope="col">DATE/STATUS</th>
                  <th scope="col">TRANSACTION</th>
                  <th scope="col">TRANSACTION ID</th>
                  <th scope="col">AMOUNT</th>
                </tr>
              </thead>
              <tbody style={{ backgroundColor: "aliceblue" }}>
                {tranceData.transaction_data === undefined ? "" : tranceData.transaction_data.map((res) => (
                  <tr key={res.invoice_id}>
                    <td>
                      <div style={{ textTransform: "capitalize" }}>
                        {" "}
                        <Tag color="green">{res.status}</Tag>
                        <p className="sub-leting" style={{ marginTop: "6px" }}>
                          {moment(res.created_at).format(
                            " MMMM DD,YYYY h:mm a"
                          )}
                        </p>
                      </div>
                    </td>

                    <td>
                      <p style={{ color: "blue" }}>{res.invoice_id}</p>
                    </td>
                    <td>
                      <p style={{ color: "blue" }}>{res.transaction_id}</p>
                      <p className="sub-leting">Gateway - {res.gateway_type}</p>
                      <p
                        className="sub-leting"
                        style={{ letterSpacing: "2px" }}
                      >
                        Card-{res["card-last_four_digits"]}
                      </p>
                    </td>
                    <td style={{ color: "green" }}>${res.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* invoice Details */}
      <div className="section-3-view col-lg-9 mb-5">
        <div className="row">
          <div className="table-responsive">
            <h4>Invoices Details:</h4>
            <table className="table">
              <thead>
                <tr
                  className="tr-shadow"
                  style={{
                    backgroundColor: "#fff",
                    fontSize: "14px",
                    color: "#3e3a3a",
                  }}
                >
                  <th scope="col">DATE/STATUS</th>
                  <th scope="col">INVOICE ID</th>
                  <th scope="col">AMOUNT</th>
                  {/* <th scope="col">created at</th>
                <th scope="col">invoice Link</th> */}
                </tr>
              </thead>
              <tbody>
                {invData.invoices_data === undefined ? "" : invData.invoices_data.map((res) => (
                  <tr key={res.invoice_id}>
                    <td>
                      <div style={{ textTransform: "capitalize" }}>
                        {" "}
                        <Tag color="green">{res.status}</Tag>
                        <p className="sub-leting" style={{ marginTop: "6px" }}>
                          {moment(res.created_at).format(
                            " MMMM DD,YYYY h:mm a"
                          )}
                        </p>
                      </div>
                    </td>
                    <td style={{ color: "blue" }}>{res.invoice_id}</td>
                    <td style={{ color: "green" }}>
                      <p>
                        ${res.amount} &nbsp;
                        <span style={{ color: "grey", cursor: "pointer" }}>
                          <a href={res.invoice_link} target="_blank" rel="noopener noreferrer">
                            <i
                              className="fa-sharp fa-solid fa-square-arrow-up-right"
                              style={{
                                padding: "2px",
                                border: "1px solid grey",
                                color: "grey",
                              }}
                            ></i>
                          </a>
                        </span>
                      </p>
                      <p></p>
                    </td>
                    {/* <td>{res.createdAt}</td>
                <td><a href={res.invoice_link} target="_blank">Get Link</a></td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <hr />
      <h2>Credits Data</h2>
      {/* credits details */}
      <div className="section-3-view col-lg-9">
        <div className="row">
          <div className="table-responsive">
            <h4>Transactions Details :</h4>
            <table className="table" style={{ border: "#fff" }}>
              <thead>
                <tr
                  className="tr-shadow"
                  style={{
                    backgroundColor: "#fff",
                    fontSize: "14px",
                    color: "#3e3a3a",
                  }}
                >
                  <th scope="col">DATE/STATUS</th>
                  <th scope="col">TRANSACTION</th>
                  <th scope="col">TRANSACTION ID</th>
                  <th scope="col">AMOUNT</th>
                </tr>
              </thead>
              <tbody style={{ backgroundColor: "aliceblue" }}>
                {tranceData.transaction_credits_data === undefined ? "" : tranceData.transaction_credits_data.map((res) => (
                  <tr key={res.invoice_id}>
                    <td>
                      <div style={{ textTransform: "capitalize" }}>
                        {" "}
                        <Tag color="green">{res.status}</Tag>
                        <p className="sub-leting" style={{ marginTop: "6px" }}>
                          {moment(res.created_at).format(
                            " MMMM DD,YYYY h:mm a"
                          )}
                        </p>
                      </div>
                    </td>

                    <td>
                      <p style={{ color: "blue" }}>{res.invoice_id}</p>
                    </td>
                    <td>
                      <p style={{ color: "blue" }}>{res.transaction_id}</p>
                      <p className="sub-leting">Gateway - {res.gateway_type}</p>
                      <p
                        className="sub-leting"
                        style={{ letterSpacing: "2px" }}
                      >
                        Card-{res["card-last_four_digits"]}
                      </p>
                    </td>
                    <td style={{ color: "green" }}>${res.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="section-3-view col-lg-9 mb-5">
        <div className="row">
          <div className="table-responsive">
            <h4>Invoices Details:</h4>
            <table className="table">
              <thead>
                <tr
                  className="tr-shadow"
                  style={{
                    backgroundColor: "#fff",
                    fontSize: "14px",
                    color: "#3e3a3a",
                  }}
                >
                  <th scope="col">DATE/STATUS</th>
                  <th scope="col">INVOICE ID</th>
                  <th scope="col">AMOUNT</th>
                  {/* <th scope="col">created at</th>
                <th scope="col">invoice Link</th> */}
                </tr>
              </thead>
              <tbody>
                {invData.invoice_credits_data === undefined ? "" : invData.invoice_credits_data.map((res) => (
                  <tr key={res.invoice_id}>
                    <td>
                      <div style={{ textTransform: "capitalize" }}>
                        {" "}
                        <Tag color="green">{res.status}</Tag>
                        <p className="sub-leting" style={{ marginTop: "6px" }}>
                          {moment(res.created_at).format(
                            " MMMM DD,YYYY h:mm a"
                          )}
                        </p>
                      </div>
                    </td>
                    <td style={{ color: "blue" }}>{res.invoice_id}</td>
                    <td style={{ color: "green" }}>
                      <p>
                        ${res.amount} &nbsp;
                        <span style={{ color: "grey", cursor: "pointer" }}>
                          <a href={res.invoice_link} target="_blank" rel="noopener noreferrer">
                            <i
                              className="fa-sharp fa-solid fa-square-arrow-up-right"
                              style={{
                                padding: "2px",
                                border: "1px solid grey",
                                color: "grey",
                              }}
                            ></i>
                          </a>
                        </span>
                      </p>
                      <p></p>
                    </td>
                    {/* <td>{res.createdAt}</td>
                <td><a href={res.invoice_link} target="_blank">Get Link</a></td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* cancel subs */}
      <div className="section-3-view col-lg-9 mb-5">
        <p onClick={handleOpen} style={{ color: 'red', cursor: "pointer" }}>Cancel Subscription</p>
      </div>
      <Modal size="sm" open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 14 14"
              fill="#2196f3"
              aria-hidden="true"
              focusable="false"
              className="rs-icon"
              aria-label="info"
              datacategory="legacy"
            >
              <path d="M7 14A7 7 0 117 0a7 7 0 010 14zm0-9.333a1.167 1.167 0 100-2.334 1.167 1.167 0 000 2.334zm0 1.166c-.644 0-1.167.522-1.167 1.167v3.5a1.167 1.167 0 002.334 0V7c0-.644-.522-1.167-1.167-1.167z"></path>
            </svg>{" "}
            Information
          </Modal.Title>
        </Modal.Header>
        {reasons === true ? <>
          <Modal.Body>
            <p>Reason for cancellation</p>
            <textarea
              className="form-control"
              type="text"
              placeholder="enter your reason"
              value={acceptReason}
              onChange={(e) => setAcceptReason(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              No Change My Mind
            </Button>
            <Button
              onClick={ApplyCancellation}
              color="red"
              appearance="primary"
            >
              Cancel My Subscription
            </Button>
          </Modal.Footer>

        </> : <><Modal.Body>
          <p>Are your sure that you want to cancel the subscription</p>
        </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              No
            </Button>
            <Button
              onClick={(e) => setReasons(true)}
              color="red"
              appearance="primary"
            >
              Yes
            </Button>
          </Modal.Footer></>}
      </Modal>
    </>
  );
};

export default TransitionInvoice;
