import { takeEvery, all, put, call } from "redux-saga/effects";
import { useNavigate } from "react-router-dom";
// put, call

// actionTypes
import * as actionTypes from "../redux/actionTypes";
// import { Link } from "react-router-dom";

// actions
// loginActionFaild
import {
  loginActionFaild,
  loginActionSuccess,
} from "../redux/actions/loginActions";
import { loginUser } from "../api/service/login";



// services
// import { loginUser } from '../api/services/loginService';
// Worker saga
function* loadUser(action) {
  try {
    const data = yield call(loginUser, action.payload);
    // const dispatch = useDispatch();
    console.log("datare", data);
    if (data.error === "Invalid credentials, try again") {
      console.log("data.errorii", data.error);
      // yield put(loginActionFaild(data?.error))
      yield put(
        loginActionFaild(
          "Your email id and/or password seems to be invalid. Please check and try again. If you have forgotten your password, click on Forgot Password to reset your password."
        )
      );
    } else if (data.error) {
      yield put(loginActionFaild(data.error));
    } else if (data.tokens) {
      yield localStorage.setItem(
        "authUser",
        JSON.stringify({ ...data.tokens })
      );
      // yield localStorage.setItem("superUser", data?.superuser);
      yield put(loginActionSuccess(data.email));
    } else if (data.message === "redirected to forget password") {
      yield put(
        loginActionFaild(
          "We have recently updated our security policies, so please update/reset your password to access your new refreshed Dashboard!"
        )
      );
    }
  } catch (error) {
    // const txts = error.config.data;
    if (error.response.data.error === "no-access") {
      yield put(
        loginActionFaild(
          "Sorry! we are unable to locate the account registered with this email id. Please check the email and try again."
        )
      );
    } else if(error.response.data.error === "Invalid credentials, try again"){
      yield put(
        loginActionFaild(
          "Your email id and/or password seems to be invalid. Please check and try again. If you have forgotten your password, click on Forgot Password to reset your password."
        )
      );
    }
     else {
      console.log("catcherror",error.response.data.error)
      const navigate = useNavigate()
      
      yield put(loginActionFaild(error.response.data.error));
      navigate("/login")
    }
  }
}

// watcher saga
function* watchLogin() {
  yield takeEvery(actionTypes.LOGIN_START, loadUser);
}

export function* loginSaga() {
  yield all([watchLogin()]);
}
