import { takeEvery, all, put, call } from "redux-saga/effects";

import * as actionTypes from "../redux/actionTypes";

// actions
// import{
//     youtubeConnDataFetchSuccess,youtubeConnDataFetchFailed
// } from '../redux/actions/youtubeAction';
// import { fetchYoutube } from '../api/service/connect';
import { fetchChannelid } from "../api/service/channelid";
import {
  fetchChennalFailed,
  fetchChennalFirst,
  fetchChennalSuccess,
} from "../redux/actions/channelAction";
import { logoutAction } from "../redux/actions/loginActions";

// services

// import { fetchYoutube} from '../api/services/connect';

// Worker saga
// ***************************** Youtube START *****************************
function* loadChannel(action) {
  try {
    // console.log('action', action.payload);
    const { data } = yield call(fetchChannelid, action.payload);
    // console.log('datafetch', data)
    // localStorage.setItem("fav",data[0].multichannel)
    yield put(fetchChennalFirst(data[0].multichannel));
    yield put(fetchChennalSuccess(data));
    // yield put(youtubeConnDataFetchSuccess(data['Please go to this URL: ']));
  } catch (error) {
    // console.log("error",error)
    if (
      error.response.data.detail === "Given token not valid for any token type"
    ) {
      yield put(logoutAction());
      // yield put(fetchChennalFailed(error.response.data.detail));
    } else {
      yield put(fetchChennalFailed(error.response));
    }
  }
}

// watcher saga
function* watchChannel() {
  yield takeEvery(actionTypes.FETCH_CHANNEL_START, loadChannel);
}

export function* channelidSaga() {
  yield all([watchChannel()]);
}
