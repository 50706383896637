import React from 'react';
import style from "./VideoContentGenerator.module.css";

const AIheader = ({title,description,lasttext}) => {
  return (
    <div className={`${style["div-block-42"]}`}>
    <h1 className={style["heading-5"]}>{title}</h1>
    <h4 className={style["heading-17"]}>
      {description}
    </h4>
    <p className={style.para}>
        {lasttext}
      {/* Unlock your <strong>content creation </strong>potential with our{" "}
      <strong>innovative</strong> tool. While it's an invaluable companion
      in your creative journey, remember that true brilliance stems from
      your own unique ideas. Combine your creative genius with our AI
      assistance for a winning content formula. Rely on your creativity;
      our tool is just a helping hand in your content creation arsenal. */}
    </p>
  </div>
  )
}

export default AIheader