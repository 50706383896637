import React, { useState } from "react";
import { Drawer, Dropdown } from "rsuite";
import { Tab, Nav } from "react-bootstrap";

const DrawerPage = ({
  show,
  handleClose,
  isCheck,
  removeTag,
  handleKeyDown,
  keywordTail,
  savedTail,
  longTail,
  current,
  selectedss,
  locate2,
  visibled,
}) => {
  const [savedDataList, setSavedList] = useState([]);
  const getMap = (res) => {
    // console.log("savedMaped",res.keywords.split(","))
    setSavedList(res.keywords.split(","));
  };
  // console.log('savedssss',savedDataList)
  return (
    <>
      <Drawer size="md" backdrop="static" open={show} onClose={handleClose}>
        <Drawer.Header>
          <Drawer.Title>Keyword Suggestion</Drawer.Title>
          {/* <Drawer.Actions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleClose} appearance="primary">
              Confirm
            </Button>
          </Drawer.Actions> */}
        </Drawer.Header>
        <Drawer.Body>
          <div className="row mt-3">
            <div className="col-12">
              <div>
                <div className="original-video-tags background-color-indigo600">
                  <div className="current-head">Current Video Tags</div>
                  <div style={{ opacity: "0.6", fontSize: "11px" }}>
                    Press "Enter" key in your window or press "Return" key in
                    your mac
                  </div>
                  <div className="uui-testimonial18_content-wrapper">
                    <div className="uui-text-size-medium original-video-tag-color">
                      {isCheck.map((tag, index) => (
                        // <div className="tags-item" key={index}>
                        //   <span className="text">{tag}</span>
                        //   <span
                        //     onClick={() => removeTag(index)}
                        //     className="close"
                        //   >
                        //     &times;
                        //   </span>
                        // </div>
                        <div key={index} className="original-video-tag-badge">
                          <span>{tag}</span>{" "}
                          <span
                            className="close"
                            onClick={() => removeTag(index)}
                          >
                            ×
                          </span>
                        </div>
                      ))}
                      <input
                        type="text"
                        name="tagi"
                        // value={isCheck}
                        // onChange={handleKeyDown}
                        onKeyDown={(e) => handleKeyDown(e)}
                        className="tags-inputs"
                        placeholder="Type a tag"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="upnav">
                <Tab.Container defaultActiveKey="long">
                  <Nav
                    variant="pills"
                    style={{
                      paddingTop: "12px",
                      paddingBottom: "12px",
                      paddingLeft: "12px",
                      borderRadius: "10px",
                      fontSize: "14px",
                      backgroundColor: "rgb(239, 240, 246)",
                      marginBottom: "12px",
                    }}
                    className="d-flex gap-3 pd-5"
                  >
                    <Nav.Item
                      style={{
                        borderBottomColor: "#642eff",
                        borderRadius: "50px",
                      }}
                    >
                      <Nav.Link
                        onClick={(e) => selectedss(e, "long")}
                        style={
                          locate2 === "long"
                            ? {
                                borderBottomColor: "#642eff",
                                backgroundColor: "#fff",
                                color: "#160042",
                                fontWeight: "500",
                                padding: "12px 15px",
                              }
                            : {
                                backgroundColor: "#eff0f6",
                                color: "#160042",
                                padding: "12px 15px",
                              }
                        }
                        eventKey="long"
                      >
                        Long Tail Keywords
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      style={{
                        borderBottomColor: "#642eff",
                        borderRadius: "50px",
                      }}
                    >
                      <Nav.Link
                        onClick={(e) => selectedss(e, "keywords")}
                        style={
                          locate2 === "keywords"
                            ? {
                                borderBottomColor: "#642eff",
                                backgroundColor: "#fff",
                                color: "#160042",
                                fontWeight: "500",
                                padding: "12px 15px",
                              }
                            : {
                                backgroundColor: "#eff0f6",
                                color: "#160042",
                                padding: "12px 15px",
                              }
                        }
                        eventKey="keywords"
                      >
                       Recommended Keywords
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item
                      style={{
                        borderBottomColor: "#642eff",
                        borderRadius: "50px",
                      }}
                    >
                      <Nav.Link
                        onClick={(e) => selectedss(e, "suggest")}
                        style={
                          locate2 === "suggest"
                            ? {
                                borderBottomColor: "#642eff",
                                backgroundColor: "#fff",
                                color: "#160042",
                                fontWeight: "500",
                                padding: "12px 15px",
                              }
                            : {
                                backgroundColor: "#eff0f6",
                                color: "#160042",
                                padding: "12px 15px",
                              }
                        }
                        eventKey="suggest"
                      >
                        Suggestion
                      </Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item
                      style={{
                        borderBottomColor: "#642eff",
                        borderRadius: "50px",
                      }}
                    >
                      <Nav.Link
                        onClick={(e) => selectedss(e, "saved")}
                        style={
                          locate2 === "saved"
                            ? {
                                borderBottomColor: "#642eff",
                                backgroundColor: "#fff",
                                color: "#160042",
                                fontWeight: "500",
                                padding: "12px 15px",
                              }
                            : {
                                backgroundColor: "#eff0f6",
                                color: "#160042",
                                padding: "12px 15px",
                              }
                        }
                        eventKey="saved"
                      >
                        Saved Keyword List
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="long">
                      <div className="f-tab-pane-6">
                        <div className="uui-testimonial18_content background-color-indigo600">
                          <div className="uui-testimonial18_content-wrapper">
                            <div className="uui-heading-small text-color-white">
                              Long Tail Keyword
                            </div>
                            <div className="uui-space-xsmall"></div>
                            <div>
                              <div
                                className="uui-text-size-medium original-video-tag-color"
                                style={{
                                  backgroundColor: "rgba(20, 20, 43, 0.87)",
                                }}
                              >
                                {longTail.length === 0 ? (
                                  <p>Unable to found the long tail keyword</p>
                                ) : (
                                  longTail.map(({ kw, vol }, index) => (
                                    <label
                                      key={index}
                                      className="original-video-tag-badge"
                                      style={
                                        isCheck.includes(kw)
                                          ? {
                                              backgroundColor:
                                                "hsla(0, 0%, 100%, 0.11)",
                                              cursor: "pointer",
                                            }
                                          : { cursor: "pointer" }
                                      }
                                    >
                                      <input
                                        type="checkbox"
                                        value={kw}
                                        name={kw}
                                        style={{ display: "none" }}
                                        onChange={(e) => visibled(e)}
                                        checked={isCheck.includes(kw)}
                                      />
                                      <span>{kw}</span>
                                      <span className="volumes">{vol}</span>
                                    </label>
                                  ))
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                  <Tab.Content>
                    <Tab.Pane eventKey="keywords">
                      <div className="f-tab-pane-6">
                        <div className="uui-testimonial18_content background-color-indigo600">
                          <div className="uui-testimonial18_content-wrapper">
                            <div className="uui-heading-small text-color-white">
                             Recommended Keywords
                            </div>
                            <div className="uui-space-xsmall"></div>
                            <div>
                              <div
                                className="uui-text-size-medium original-video-tag-color"
                                style={{
                                  backgroundColor: "rgba(20, 20, 43, 0.87)",
                                }}
                              >
                                {keywordTail.length === 0 ? (
                                  <p>Unable to find the keywords</p>
                                ) : (
                                  keywordTail.map(({ kw, vol }, index) => (
                                    <label
                                      key={index}
                                      className="original-video-tag-badge"
                                      style={
                                        isCheck.includes(kw)
                                          ? {
                                              backgroundColor:
                                                "hsla(0, 0%, 100%, 0.11)",
                                              cursor: "pointer",
                                            }
                                          : { cursor: "pointer" }
                                      }
                                    >
                                      <input
                                        type="checkbox"
                                        value={kw}
                                        name={kw}
                                        style={{ display: "none" }}
                                        onChange={(e) => visibled(e)}
                                        checked={isCheck.includes(kw)}
                                      />
                                      <span>{kw}</span>
                                    </label>
                                  ))
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                  {/* <Tab.Content>
                    <Tab.Pane eventKey="suggest">
                      <div className="f-tab-pane-6">
                        <div className="uui-testimonial18_content background-color-indigo600">
                          <div className="uui-testimonial18_content-wrapper">
                            <div className="uui-heading-small text-color-white">
                              Suggested Keyword
                            </div>
                            <div className="uui-space-xsmall"></div>
                            <div>
                              <div
                                className="uui-text-size-medium original-video-tag-color"
                                style={{
                                  backgroundColor: "rgba(20, 20, 43, 0.87)",
                                }}
                              >
                                {current.map((kw, index) => (
                                  <label
                                    key={index}
                                    className="original-video-tag-badge"
                                    style={
                                      isCheck.includes(kw)
                                        ? {
                                            backgroundColor:
                                              "hsla(0, 0%, 100%, 0.11)",
                                            cursor: "pointer",
                                          }
                                        : { cursor: "pointer" }
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      value={kw}
                                      name={kw}
                                      style={{ display: "none" }}
                                      onChange={(e) => visibled(e)}
                                      checked={isCheck.includes(kw)}
                                    />
                                    <span>{kw}</span>
                                  </label>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content> */}
                  <Tab.Content>
                    <Tab.Pane eventKey="saved">
                      <div className="f-tab-pane-6">
                        <div className="uui-testimonial18_content background-color-indigo600">
                          <div className="uui-testimonial18_content-wrapper">
                            <div className="uui-heading-small text-color-white">
                              <Dropdown
                                style={{ color: "black" }}
                                title="Saved Keyword List"
                              >
                                {savedTail.map((res) => (
                                  <Dropdown.Item
                                    onClick={() => getMap(res)}
                                    key={res.id}
                                  >
                                    {res.name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown>
                            </div>
                            <div className="uui-space-xsmall"></div>
                            <div>
                              {savedDataList.length===0?"":<div
                                className="uui-text-size-medium original-video-tag-color"
                                style={{
                                  backgroundColor: "rgba(20, 20, 43, 0.87)",
                                }}
                              >
                                {savedDataList.map((kw, index) => (
                                  <label
                                    key={index}
                                    className="original-video-tag-badge"
                                    style={
                                      isCheck.includes(kw)
                                        ? {
                                            backgroundColor:
                                              "hsla(0, 0%, 100%, 0.11)",
                                            cursor: "pointer",
                                          }
                                        : { cursor: "pointer" }
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      value={kw}
                                      name={kw}
                                      style={{ display: "none" }}
                                      onChange={(e) => visibled(e)}
                                      checked={isCheck.includes(kw)}
                                    />
                                    <span>{kw}</span>
                                  </label>
                                ))}
                              </div>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default DrawerPage;
