import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Drawer, Pagination } from "rsuite";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";
const VideoDrawer = ({
  leftOpen,
  handleClosed,
  inputNumber,
  handleFirst,
  handleSecond,
  handleThird,
  handleForth,
  handlePlaylistFirst,
  handlePlaylistSecond,
  handlePlaylistThird,
  handlePlaylistForth,
  videoOrPlay,
  formik,
}) => {
  const [doubleapi, setDoubleApi] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const {channelid} = useSelector((state) => state.channels);
  const [pageTotal, setPageTotal] = useState();
  const {fetchId} = useSelector((state) => state.channelIdData)

  const videoOrPlaylist = async (page) => {
    setActivePage(page);
    if (videoOrPlay === false) {
      try {
        const video = await privateRequest.get(
          `/api/youtube/optimize/video-links/${channelid===null?fetchId:channelid}/?page=${page}&data_count=5`
        );
        // console.log("video", video.data.results);
        // setVideoApi(video.data.results)
        setPageTotal(video.data.count);
        setDoubleApi(video.data.results);
      } catch (err) {
        console.log("videoError", err);
      }
    } else {
      const video = await privateRequest.get(
        `/api/youtube/optimize/video-playlist/${channelid===null?fetchId:channelid}/`
      );
      console.log("video", video.data.Response);
      setDoubleApi(video.data.Response);
    }
  };

  useEffect(() => {
    // getVideoData();
    videoOrPlaylist(1);
    // getPlaylistData();
  }, [videoOrPlay,fetchId]);

  return (
    <>
      <Drawer
        backdrop="static"
        size="md"
        placement="left"
        open={leftOpen}
        onClose={() => handleClosed()}
      >
        <Drawer.Header>
          <Drawer.Title>
            {videoOrPlay === false ? "Video List" : "Playlist"}
          </Drawer.Title>
          {/* <Drawer.Actions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button onClick={() => setOpen(false)} appearance="primary">
              Confirm
            </Button>
          </Drawer.Actions> */}
        </Drawer.Header>
        <Drawer.Body>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Image</th>
                <th scope="col">Title</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {videoOrPlay === false
                ? doubleapi.map((res, index) => (
                    <tr key={index}>
                      <th scope="row">
                        <img
                          className="playvid"
                          alt="thumbnail"
                          src={res.video_thumbnail_url}
                        />
                      </th>
                      <td>{res.video_title}</td>
                      <td>
                      
                        <button
                          className="uk-button uk-button-secondary uk-border-rounded"
                          onClick={
                            inputNumber === 1
                              ? () => handleFirst(res, formik)
                              : inputNumber === 2
                              ? () => handleSecond(res, formik)
                              : inputNumber === 3
                              ? () => handleThird(res, formik)
                              : inputNumber === 4
                              ? () => handleForth(res, formik)
                              : () => handleFirst(res, formik)
                          }
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                  ))
                : doubleapi.map((res, index) => (
                    <tr>
                      <th scope="row">
                        <img className="playvid" alt="thumbnail" src={res.thumbnail} />
                      </th>
                      <td>{res.title}</td>
                      <td>
                        <button
                          className="uk-button uk-button-secondary uk-border-rounded"
                          onClick={
                            inputNumber === 5
                              ? () => handlePlaylistFirst(res, formik)
                              : inputNumber === 6
                              ? () => handlePlaylistSecond(res, formik)
                              : inputNumber === 7
                              ? () => handlePlaylistThird(res, formik)
                              : inputNumber === 8
                              ? () => handlePlaylistForth(res, formik)
                              : () => handlePlaylistFirst(res, formik)
                          }
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
          {videoOrPlay === false?(<Pagination
              prev
              next
              first
              last
              size="md"
              maxButtons={10}
              limit={5}
              total={pageTotal}
              activePage={activePage}
              onChangePage={videoOrPlaylist}
            />):""}
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default VideoDrawer;
