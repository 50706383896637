import React, { useState, useEffect, useRef } from "react";
import AnyChart from "anychart-react";
import anychart from "anychart";
import html2canvas from "html2canvas";

const PieMenu = ({ locate, data }) => {
  const [chart, setChart] = useState(null);
  const chartRef = useRef(null);

  const handleDownload = () => {
    if (chartRef.current) {
      // Capture a screenshot of the chart using html2canvas
      html2canvas(chartRef.current, {
        useCORS: true, // Enable CORS if needed (especially if chart data is loaded from a different origin)
      }).then((canvas) => {
        // Convert the canvas to a data URL (PNG format)
        const pngDataUrl = canvas.toDataURL("image/png");

        // Create a download link
        const downloadLink = document.createElement("a");
        downloadLink.href = pngDataUrl;
        downloadLink.download = "chart.png";

        // Trigger the download
        downloadLink.click();
      });
    }
  };

  useEffect(() => {
    const dataTree = anychart.data.tree(data, "as-table");
    const chart = anychart.sunburst(dataTree);

    // Configure the chart here
    chart.labels().format("{%Name}");
    chart.labels().fontColor("black");
    chart.leaves().thickness("80%");
    chart.tooltip().format("{%Id}");
    chart.tooltip().fontColor("black");

    chart.fill(function () {
      return anychart.color.lighten(this.parentColor, 0.25);
    });

    // Set container id for the chart
    chart.container("pie");

    // Draw the chart
    chart.draw();

    setChart(chart);
  }, [data]);

  return (
    <div id="pie">
      <div ref={chartRef}>
        {chart && (
          <AnyChart instance={chart} style={{ height: "600px" }} title="" />
        )}
      </div>
      <button onClick={handleDownload}>Download as PNG</button>
    </div>
  );
};

export default PieMenu;
