import React, { useState } from "react";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Button, Modal, useToaster } from "rsuite";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../Notification";

const GlobalAffliateLink = () => {
  const [linkTitle, setLinkTitle] = useState("");
  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [link3, setLink3] = useState("");
  // const [mdata, setMData] = useState("");
  const [open, setOpen] = useState(false);
  const [getAffliateLink, setAffliateLink] = useState([]);
  const [updateIcons, setUpdateIcons] = useState(false);
  const [getids, setGetids] = useState(null);
  const { channelid } = useSelector((state) => state.channels);
  const { fetchId } = useSelector((state) => state.channelIdData);

  const toaster = useToaster();

  const handleOpen = (e, data) => {
    setOpen(true);
    // console.log("modalData", data);
    setGetids(data);
    // setMData(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    // console.log("delete data");
    setOpen(false);
    try {
      // eslint-disable-next-line
      const deleteData = await privateRequest.delete(
        `/api/youtube/affiliate-links-manager/update-delete/${getids}/`
      );
      // console.log("deleteDat", deleteData);
      toaster.push(
        showSuccessNotification("You have successfully deleted the item")
      );
      //
    } catch (err) {
      console.log("ERR", err);
    }
    getAffliateLinkData();
  };

  const newText = () => {
    setLinkTitle("");
    setLink1("");
    setLink2("");
    setLink3("");
    setUpdateIcons(false);
    setGetids(null);
  };

  const editLink = (data) => {
    // console.log("provided", data);
    setGetids(data);
    setUpdateIcons(true);
    setLinkTitle(data.list_name);
    setLink1(data.link1);
    setLink2(data.link2);
    setLink3(data.link3);
  };

  const postAffliateLinkData = async () => {
    // console.log("link2", link2.length);
    // console.log("link3", link3.length);

    const linked = {
      multichannel: channelid === null ? fetchId : channelid,
      list_name: linkTitle,
      link1: link1,
      link2: link2,
      link3: link3,
    };

    if ((linked.link2.length === 0) & (linked.link3.length === 0)) {
      console.log("this is if section");
      const linked2 = {
        multichannel: channelid === null ? fetchId : channelid,
        list_name: linkTitle,
        link1: link1,
      };
      try {
        await privateRequest.post(
          `/api/youtube/affiliate-links-manager/`,
          linked2
        );
        toaster.push(showSuccessNotification("Added Successfully"));
        newText();
      } catch (err) {
        console.log("error", err);
        if (
          err.response.data.link1 === undefined ? null : "Enter a valid URL."
        ) {
          toaster.push(showErrorNotification("Please enter a valid URL."));
          // console.log("Invalid url");
        } else if (
          err.response.data.err === undefined ? null : "duplicate link"
        ) {
          // console.log("dublicate Link");
          toaster.push(showErrorNotification("duplicate link"));
        } else {
          console.log(err);
        }
      }
    } else if (linked.link1.length === 0) {
      toaster.push(showErrorNotification("Affliate link1 should not be empty"));
    } else {
      // console.log("this is else section");
      try {
        await privateRequest.post(
          `/api/youtube/affiliate-links-manager/`,
          linked
        );
        toaster.push(showSuccessNotification("Added Successfully"));
        newText();
        // console.log("datalinkdf", data);
      } catch (err) {
        console.log("error", err);
        if (
          err.response.data.link1 === undefined ? null : "Enter a valid URL."
        ) {
          toaster.push(showErrorNotification("Please enter a valid URL."));
          console.log("Invalid url");
        } else if (
          err.response.data.err === undefined ? null : "duplicate link"
        ) {
          // console.log("dublicate Link");
          toaster.push(showErrorNotification("duplicate link"));
        } else {
          console.log(err);
        }
      }
    }
    getAffliateLinkData();
  };

  const editAffliateLinkData = async () => {
    // console.log("edtings", getids);
    const linked = {
      multichannel: channelid === null ? fetchId : channelid,
      list_name: linkTitle,
      link1: link1,
      link2: link2,
      link3: link3,
    };

    try {
      // eslint-disable-next-line
      const edit = await privateRequest.put(
        `/api/youtube/affiliate-links-manager/update-delete/${getids.id}/`,
        linked
      );
      toaster.push(showSuccessNotification("Changes saved successfully"));
      // console.log("editsfor", edit);
      getAffliateLinkData();
      newText();
    } catch (err) {
      console.log(err);
    }
  };

  const getAffliateLinkData = async () => {
    try {
      const getAffliate = await privateRequest.get(
        `/api/youtube/affiliate-links-manager/${
          channelid === null ? fetchId : channelid
        }/`
      );
      // console.log("getAffliate", getAffliate.data);
      setAffliateLink(getAffliate.data);
    } catch (err) {
      console.log("erraffliate", err);
    }
    // postAffliateLinkData()
  };

  useEffect(() => {
    getAffliateLinkData();
  }, [fetchId]);
  return (
    <div className="uk-card uk-card-default uk-card-hover uk-card-body uk-width-2-2@m uk-margin-top">
      <h3 className="heading-14">AFFILIATE LINKS MANAGER</h3>
      <div className="row">
        <div className="global-affliat-disclaimer-left col-lg-6">
          <div className="d-flex justify-content-between">
            {/* <div> */}
            <input
              className="form-control"
              type="text"
              value={linkTitle}
              onChange={(e) => setLinkTitle(e.target.value)}
              placeholder="Add the Affliate Link Group name here"
              style={{ width: "83%" }}
            />
            {/* </div> */}
            <div className="d-flex gap-2">
              {getAffliateLink.length>=6?null:<svg
                xmlns="http://www.w3.org/2000/svg"
                onClick={newText}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-plus"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>}
              {updateIcons === false ? (
                getAffliateLink.length>=6?null:<svg
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={postAffliateLinkData}
                  style={{ cursor: "pointer" }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-clipboard"
                >
                  <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                  <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={editAffliateLinkData}
                  style={{ cursor: "pointer" }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-clipboard"
                >
                  <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                  <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                </svg>
              )}
            </div>
          </div>
          <div className="uk-margin-top">
            <p className="global-sub-text mb-1">
              Enter your Affiliate Links below
            </p>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                @
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Affliate Link 1"
                aria-label="Username"
                value={link1}
                onChange={(e) => setLink1(e.target.value)}
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                @
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Affliate Link 2"
                aria-label="Username"
                value={link2}
                onChange={(e) => setLink2(e.target.value)}
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                @
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Affliate Link 3"
                aria-label="Username"
                value={link3}
                onChange={(e) => setLink3(e.target.value)}
                aria-describedby="basic-addon1"
              />
            </div>
          </div>
        </div>
        <div className="global-affliat-disclaimer-right col-lg-6">
          <p className="global-sub-text uk-margin-bottom">
            Affiliate Link Groups (save upto 6 link groups)
          </p>
          <ul className="list-group">
            {getAffliateLink.map((res) => (
              <li className="list-group-item" key={res.id}>
                <div className="d-flex justify-content-between">
                  <p className="global-sub-text">{res.list_name}</p>
                  <div className="d-flex gap-2">
                    <svg
                      onClick={() => editLink(res)}
                      style={{ cursor: "pointer" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-edit-2 global-icon-setting"
                    >
                      <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={(e) => handleOpen(e, res.id)}
                      style={{ cursor: "pointer" }}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-trash-2 global-icon-setting"
                    >
                      <polyline points="3 6 5 6 21 6"></polyline>
                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                      <line x1="10" y1="11" x2="10" y2="17"></line>
                      <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Modal backdrop="static" size="sm" open={open} onClose={handleClose}>
        <Modal.Header>
          {/* <Modal.Title>
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 14 14"
              fill="#2196f3"
              aria-hidden="true"
              focusable="false"
              className="rs-icon"
              aria-label="info"
              data-category="legacy"
            >
              <path d="M7 14A7 7 0 117 0a7 7 0 010 14zm0-9.333a1.167 1.167 0 100-2.334 1.167 1.167 0 000 2.334zm0 1.166c-.644 0-1.167.522-1.167 1.167v3.5a1.167 1.167 0 002.334 0V7c0-.644-.522-1.167-1.167-1.167z"></path>
            </svg>{" "}
            Information
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to delete this item?</h5>
          <p>This action cannot be undone.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="primary">
            No, I changed my mind
          </Button>
          <Button onClick={handleDelete} color="red" appearance="primary">
            Yes, I want to delete anyway
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GlobalAffliateLink;
