import React from 'react'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'
import styles from './Sidebar.module.css'

const Sidebar = () => {
    const navigate = useNavigate()
    const logedout = () => {
        localStorage.clear();
        navigate("/admin")
    }
  return (
    <div className={styles.navflex}>
      <div className={styles.navigation}>
        <div className={styles.profile}>
          {" "}
          <img src="/images/ytb-vector.png" alt="profile" />
          <h4 className={styles.profilename}>Admin</h4>
        </div>
        <ul>
          <li>
            <NavLink
            className={styles.navhowe}
              to="/admin_dashboard"
              style={({ isActive }) => ({
                color: isActive ? "#fff" : undefined,
                // background: isActive ? '#7600dc' : undefined,
              })}
            >
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink
            className={styles.navhowe}
              style={({ isActive }) => ({
                color: isActive ? "#fff" : undefined,
                // background: isActive ? '#7600dc' : undefined,
              })}
              to="/admin-users"
            >
              Users
            </NavLink>
          </li>
           <li>
            <NavLink
            className={styles.navhowe}
              style={({ isActive }) => ({
                color: isActive ? "#fff" : undefined,
                // background: isActive ? '#7600dc' : undefined,
              })}
              to="/admin-coupons"
            >
              Coupons
            </NavLink>
          </li>
           <li>
            <NavLink
            className={styles.navhowe}
              style={({ isActive }) => ({
                color: isActive ? "#fff" : undefined,
                // background: isActive ? '#7600dc' : undefined,
              })}
              to="/admin-ai"
            >
              Ai Prompt
            </NavLink>
          </li>
           <li>
            <NavLink
            className={styles.navhowe}
              style={({ isActive }) => ({
                color: isActive ? "#fff" : undefined,
                // background: isActive ? '#7600dc' : undefined,
              })}
              to="/admin-appsumo-users"
            >
              Appsumo Users
            </NavLink>
          </li>
           <li>
            <NavLink
            className={styles.navhowe}
              style={({ isActive }) => ({
                color: isActive ? "#fff" : undefined,
                // background: isActive ? '#7600dc' : undefined,
              })}
              to="/admin-cancel-subscription"
            >
              Cancel Subscriber
            </NavLink>
          </li>
          {/* <li>
            <NavLink
            className={styles.navhowe}
              style={({ isActive }) => ({
                color: isActive ? "#fff" : undefined,
                // background: isActive ? '#7600dc' : undefined,
              })}
              to="/admin-credits-coupons"
            >
              Credits Coupons
            </NavLink>
          </li>
          <li>
            <NavLink
            className={styles.navhowe}
              style={({ isActive }) => ({
                color: isActive ? "#fff" : undefined,
                // background: isActive ? '#7600dc' : undefined,
              })}
              to="/admin-revoked"
            >
              Revoke Coupon
            </NavLink>
          </li>
          <li>
            <NavLink
            className={styles.navhowe}
              style={({ isActive }) => ({
                color: isActive ? "#fff" : undefined,
                // background: isActive ? '#7600dc' : undefined,
              })}
              to="/admin-login-record"
            >
              Login Records
            </NavLink>
          </li> */}
          <li
            className="warning"
            style={{ color: "#ffc107", fontWeight: "600", cursor: "pointer" }}
            onClick={logedout}
          >
            Logout
          </li> 
        </ul>
      </div>
      <div className={styles['main-content']}>
        <Outlet />
      </div>
    </div>
  )
}

export default Sidebar