import React, { useEffect, useRef, useState } from "react";
// import GoogleButton from "react-google-button";
import Helmet from "react-helmet";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useToaster } from "rsuite";
// import { showSuccessNotification } from "../../../Notification";
import { getPricing } from "../../../redux/actions/pricingActions";
import { priceTag } from "../Pages/StringStore";
import "./normalize.css";
import "./webflow.css";
import "./ytubebooster.webflow.css";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../Notification";
import PlaneDetails from "../Pages/PlaneDetails";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import AccordianGroup from "./AccordianGroup";

const LandingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const listRef = useRef();
  const toaster = useToaster();

  const movetopricings = () => {
    // console.log("locations",location.hash)
    if (location.hash === "#pricings") {
      // console.log("listRef",listRef)
      listRef.current.scrollIntoView();
    }
  };

  const login = () => {
    navigate("/login");
  };

  const [togged, setTogged] = useState(true);
  const [tab1Heading, setTab1Heading] = useState("w--current");
  const [tab1Content, setTab1Content] = useState("w--tab-active");
  const [tab2Heading, setTab2Heading] = useState("");
  const [tab2Content, setTab2Content] = useState("");
  const [tab3Heading, setTab3Heading] = useState("");
  const [tab3Content, setTab3Content] = useState("");
  const [tab4Heading, setTab4Heading] = useState("");
  const [tab4Content, setTab4Content] = useState("");
  const [tab5Heading, setTab5Heading] = useState("");
  const [tab5Content, setTab5Content] = useState("");

  // contact page adding
  const recaptchaRef = React.createRef();
  const [naam, setNaam] = useState("");
  const [inputs, setInputs] = useState({
    email: "",
    subject: "",
    message: "",
  });
  // handlechange for contaact us
  const handleOnChange = (e) => {
    // console.log("e.handlechanes", e);
    e.persist();
    setInputs((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const addContact = (e) => {
    e.preventDefault();
    // console.log("e", inputs);
    axios({
      method: "POST",
      url: "https://formbold.com/s/9R0Mo",
      // data: inputs,
      data: {
        ...inputs,
        "g-recaptcha-response": recaptchaRef.current.getValue(),
      },
    })
      .then((r) => {
        // console.log("hello", r);
        toaster.push(
          showSuccessNotification(
            "Thank you for submitting your query/feedback. We will get back to you within 24 - 48 hours"
          )
        );
      })
      .catch((r) => {
        // console.log("error", r.response.data.message);
        toaster.push(showErrorNotification(r.response.data.message));
      });
    setNaam("");
    setInputs({
      email: "",
      subject: "",
      message: "",
    });
  };

  const tabChange1 = () => {
    setTab1Heading("w--current");
    setTab1Content("w--tab-active");
    setTab2Heading("");
    setTab2Content("");
    setTab3Heading("");
    setTab3Content("");
    setTab4Heading("");
    setTab4Content("");
    setTab5Heading("");
    setTab5Content("");
  };
  const tabChange2 = () => {
    setTab2Heading("w--current");
    setTab2Content("w--tab-active");
    setTab1Heading("");
    setTab1Content("");
    setTab3Heading("");
    setTab3Content("");
    setTab4Heading("");
    setTab4Content("");
    setTab5Heading("");
    setTab5Content("");
  };

  const tabChange3 = () => {
    setTab3Heading("w--current");
    setTab3Content("w--tab-active");
    setTab1Heading("");
    setTab1Content("");
    setTab2Heading("");
    setTab2Content("");
    setTab4Heading("");
    setTab4Content("");
    setTab5Heading("");
    setTab5Content("");
  };
  const tabChange4 = () => {
    setTab4Heading("w--current");
    setTab4Content("w--tab-active");
    setTab1Heading("");
    setTab1Content("");
    setTab2Heading("");
    setTab2Content("");
    setTab3Heading("");
    setTab3Content("");
    setTab5Heading("");
    setTab5Content("");
  };
  const tabChange5 = () => {
    setTab5Heading("w--current");
    setTab5Content("w--tab-active");
    setTab1Heading("");
    setTab1Content("");
    setTab2Heading("");
    setTab2Content("");
    setTab3Heading("");
    setTab3Content("");
    setTab4Heading("");
    setTab4Content("");
  };

  const takeToggle = (data) => {
    if (togged === false) {
      setTogged(true);
    } else {
      setTogged(false);
    }
  };

  // const toast = useToaster();

  const handlePricing = (e, data) => {
    e.preventDefault();
    localStorage.setItem("prices", data);
    dispatch(getPricing(data));
    navigate("/register");
  };
  useEffect(() => {
    movetopricings();
  }, []);

  return (
    <>
      <Helmet>
        <title>YTubeBooster</title>
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta content="Webflow" name="generator" />
        <link href="https://fonts.googleapis.com" rel="preconnect" />
        <link
          href="https://fonts.gstatic.com"
          rel="preconnect"
          crossOrigin="anonymous"
        />
        {/* <link href="/images/favicon.ico" rel="shortcut icon" type="image/x-icon" /> */}
        <link href="/images/webclip.png" rel="apple-touch-icon" />
        {/* <script src="./webflow.js" type="text/javascript" /> */}
      </Helmet>
      <div className="body-2">
        {/* <div className="f-banner">
          <div className="f-banner-container-center">
            <div className="f-banner-center">
              <Message
                style={{ backgroundColor: "transparent", color: "#fff" }}
                closable
                type="info"
              >
                <div
                  className="f-banner-text-wrapper"
                  style={{ color: "#fff" }}
                >
                  <div className="f-paragraph-regular">
                    The banner is to inform the visitors with an{" "}
                    <span className="f-banner-text-span">
                      important message
                    </span>{" "}
                    here!
                  </div>
                </div>
                <div className="f-paragraph-detail" style={{ color: "#fff" }}>
                  Lorem ipsum dolor.
                </div>
              </Message>
            </div>
           
          </div>
        </div> */}
        <div className="navbar-no-shadow wf-section">
          <div
            data-animation="default"
            data-collapse="medium"
            data-duration={400}
            data-easing="ease"
            data-easing2="ease"
            role="banner"
            style={{ padding: "0px" }}
            className="navbar-no-shadow-container w-nav"
          >
            <div className="container-regular">
              {/* <div className="navbar-wrapper"> */}
              {/* <p
                  className="navbar-brand w-nav-brand"
                  style={{
                    backgroundColor: "#fff",
                    padding: "5px",
                    borderRadius: "4px",
                  }}
                >
                  <img
                    src="/images/ytb_beta_logo.svg"
                    loading="lazy"
                    width="200px"
                    alt="pro"
                  />
                </p> */}

              <Navbar expand="lg" className="bg-body-tertiary">
                <Container fluid>
                  <Navbar.Brand href="/landing#home">
                    <p
                      className="navbar-brand w-nav-brand"
                      style={{
                        backgroundColor: "#fff",
                        padding: "5px",
                        borderRadius: "4px",
                      }}
                    >
                      <img
                        src="/images/ytb_beta_logo.svg"
                        loading="lazy"
                        width="200px"
                        alt="pro"
                      />
                    </p>
                  </Navbar.Brand>
                  {/* <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xxl`} /> */}
                  <Navbar.Toggle aria-controls="basic-navbar-nav">
                    <div className="w-icon-nav-menu" />{" "}
                  </Navbar.Toggle>
                  <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-md`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                    placement="end"
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                        <p
                          className="navbar-brand w-nav-brand"
                          style={{
                            backgroundColor: "#fff",
                            padding: "5px",
                            borderRadius: "4px",
                          }}
                        >
                          <img
                            src="/images/ytb_beta_logo.svg"
                            loading="lazy"
                            width="200px"
                            alt="pro"
                          />
                        </p>
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Nav
                        className="justify-content-end flex-grow-1 mb-3"
                        style={{ textAlign: "center" }}
                      >
                        <Nav.Link href="#goto-top">Home</Nav.Link>
                        <Nav.Link href="#feature-comp">Features</Nav.Link>
                        <Nav.Link href="#pricings">Pricing</Nav.Link>
                        <Nav.Link href="#faq">Faq</Nav.Link>
                        <Nav.Link href="#contact-us">Contact Us</Nav.Link>
                        <Nav.Link
                          href="https://blog.ytubebooster.app/"
                          target="_blank"
                        >
                          Blogs
                        </Nav.Link>
                      </Nav>
                      <div className="nav-button-wrapper">
                        <p onClick={login} className="button-primary w-buttons">
                          Login
                        </p>
                      </div>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                </Container>
              </Navbar>
              {/* <nav role="navigation" className="nav-menu-wrapper w-nav-menu">
                  {
                    // eslint-disable-next-line
                  }
                  <ul className="nav-menu w-list-unstyled">
                    <li>
                      <a href="#goto-top" className="nav-link nav-link-header">
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="#feature-comp" className="nav-link nav-link-header">
                        Features
                      </a>
                    </li>
                    <li>
                      <a href="#pricings" className="nav-link nav-link-header">
                        Pricing
                      </a>
                    </li>
                    <li>
                      <a href="#contact-us" className="nav-link nav-link-header">
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://blog.ytubebooster.app/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="nav-link nav-link-header"
                      >
                        Blogs
                      </a>
                    </li>
                    <li className="mobile-margin-top-10">
                      <div className="nav-button-wrapper">
                        <p onClick={login} className="button-primary w-buttons">
                          Login
                        </p>
                      </div>
                    </li>
                  </ul>
                </nav> */}
              {/* <div className="menu-button w-nav-button">
                  <div className="w-icon-nav-menu" />
                </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
        <section className="section-2 wf-section" id="goto-top">
          <div className="container-3">
            <div className="hero-wrapper-2">
              <div className="hero-split-2">
                <h1 className="heading-6 sohead">
                  More{" "}
                  <span className="text-span-2">
                    <strong>Views</strong>
                  </span>
                  <br />
                  Mean More
                  <span className="text-span">
                    <strong className="bold-text-2"> Revenues</strong>
                  </span>
                </h1>
                <div className="divider" />
                <p className="paragraph-3" style={{ fontSize: "18px" }}>
                  Get higher watch time, subscribers and engagement without
                  breaking a sweat. Our all-in-one and easy-to-use-tool
                  optimizes your YouTube Channel{" "}
                  <strong>within one platform.</strong>
                </p>
                {/* <div className="hero-form-2 w-form">
                  <form
                    id="wf-form-Hero-Form"
                    name="wf-form-Hero-Form"
                    data-name="Hero Form"
                    method="get"
                    className="hero-form-container-2"
                  >
                    <input
                      type="text"
                      className="hero-form-input-2 w-inputs"
                      maxLength={256}
                      name="Hero-Email-2"
                      data-name="Hero Email 2"
                      aria-label="Enter your email"
                      placeholder="Enter your email"
                      id="Hero-Email-2"
                      required=""
                    />
                    <input
                      type="submit"
                      defaultValue="Get Started"
                      data-wait="Please wait..."
                      className="button-primary-2 w-buttons"
                    />
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div>
                </div> */}
                {/* <a href="#pricings" className="w-inline-block">
                  <GoogleButton /> */}
                {/* <div className="text-block"> &nbsp;Sign-up with Google</div> */}
                {/* <div className="arrow-embed-2 w-embed">
                  <svg
                    width={17}
                    height={17}
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.13 8.5L6.87296 4.24291L5.87067 5.24379L9.129 8.5L5.87067 11.7555L6.87225 12.7571L11.13 8.5Z"
                      fill="currentColor"
                    />
                  </svg>
                </div> */}
                {/* </a> */}
              </div>
              <div className="hero-split-2">
                <img
                  src="/images/hero-devices.png"
                  loading="lazy"
                  width={508}
                  sizes="(max-width: 767px) 96vw, (max-width: 991px) 63vw, (max-width: 1279px) 45vw, (max-width: 1919px) 449.46331787109375px, 23vw"
                  srcSet="/images/hero-devices-p-500.png 500w, /images/hero-devices.png 880w"
                  alt=""
                  className="shadow-two-2"
                />
              </div>
            </div>
          </div>
          <div className="container-4" />
        </section>
        <div className="f-section-large-2">
          <div className="f-container-regular">
            <div className="f-header-title-wrapper-center">
              <div className="f-margin-bottom-08">
                <div
                  className="f-heading-detail-small white-text"
                  style={{ color: "#fff" }}
                >
                  Introducing, YtubeBooster Pro!
                  {/* YTubeBooster PRO&nbsp;is here!! */}
                </div>
              </div>
              <div className="f-margin-bottom-24">
                <h1 className="f-h1-heading cf-text-opacity-70-italic lines">
                  {/* Save hours and grow your channel. */}
                  Grow Faster, Faster!
                </h1>
              </div>
              <div className="f-margin-bottom-40">
                {/* <p className="f-paragraph-large">
                  Our software uses a proprietary algorithm that analyzes
                  various aspects of your video, including the title,
                  description, tags, and metadata, to identify opportunities for
                  optimization. You can then make changes to your video based on
                  the software's recommendations and see the impact on your
                  video's performance in real-time.
                </p> */}
                <p className="f-paragraph-large">
                  Our state-of-the-art software identifies areas for
                  optimization, so you can refine your content with the smallest
                  of changes.
                </p>
                <p className="f-paragraph-large">
                  You can even let it make changes for you! Using proprietary
                  algorithm, YtubeBooster Pro analyses everything from the
                  video’s tags to the metadata so you can be sure that it’s
                  recommendations for impactful growth are in-depth and
                  on-point.
                </p>
              </div>
              <div className="f-header-button-middle">
                <a href="#pricings" className="f-button-neutral w-inline-block">
                  <div>Get Started</div>
                </a>
                {/* <a href="#price" className="f-button-secondary w-inline-block">
                  <div>Learn More</div>
                </a> */}
              </div>
            </div>
          </div>
        </div>
        <section className="features-metrics-2 wf-section">
          <div className="container-6">
            <div className="features-wrapper-2">
              <div className="features-block-2">
                <div className="features-title-2">
                  87<span className="features-title-small-2">%</span>
                </div>
                <div>Higher Sub Count</div>
              </div>
              <div className="features-block-2">
                <div className="features-title-2">
                  87<span className="features-title-small-2">%</span>
                </div>
                <div>Metric description</div>
              </div>
              <div className="features-block-2">
                <div className="features-title-2">
                  260+
                  <span className="features-title-small-2" />
                </div>
                <div>Channels Signed Up</div>
              </div>

              {/* <div className="features-block-2">
                <div className="features-title-2">$15,000</div>
                <div>Sales in last 6 months</div>
              </div> */}
            </div>
          </div>
        </section>
        <div className="cf-features-section-4 wf-section">
          <div
            data-w-id="bc034005-b42a-0e36-97b4-54ac8a219100"
            // style={{ opacity: 0 }}
            className="cf-features-4-tabs-wrapper"
          >
            <div className="cf-tabs-header">
              <h2
                className="cf-features-4-tabs-heading cf-text-opacity-70-italic"
                style={{ marginLeft: "186px" }}
              >
                One Tool Does It All
              </h2>
              {/* <h2 className="cf-features-4-tabs-heading cf-text-opacity-70-italic">
                What Our Software Can Do for You
                <br />
              </h2> */}
              <div className="cf-tabs-header-text">
                With our powerful SEO software, growing your audience has never
                been easier or faster. Best of all, it’s tailored for you!
              </div>
              {/* <div className="cf-tabs-header-text">
                Look no further than our powerful SEO software for YouTube. Our
                tool is designed to help content creators like you optimize your
                videos, get more views and engagement, and build your audience
                faster.
              </div> */}
            </div>
            <div className="cf-tabs-contain">
              <div
                data-current="Tab 1"
                data-easing="ease"
                data-duration-in={300}
                data-duration-out={100}
                className="cf-home-tabs w-tabs"
              >
                <div className="cf-tabs-menu w-tab-menu">
                  <div
                    data-w-tab="Tab 1"
                    className={`cf-home-tab-link w-inline-blocksw-tab-link ${tab1Heading}`}
                    onClick={tabChange1}
                  >
                    <div className="cur">Optimise</div>
                  </div>
                  <div
                    data-w-tab="Tab 2"
                    className={`cf-home-tab-link w-inline-blocksw-tab-link ${tab2Heading}`}
                    onClick={tabChange2}
                  >
                    <div className="cur">Titles</div>
                  </div>
                  <div
                    data-w-tab="Tab 3"
                    className={`cf-home-tab-link w-inline-blocksw-tab-link ${tab3Heading}`}
                    onClick={tabChange3}
                  >
                    <div className="cur">Descriptions </div>
                  </div>
                  <div
                    data-w-tab="Tab 4"
                    className={`cf-home-tab-link w-inline-blocksw-tab-link ${tab4Heading}`}
                    onClick={tabChange4}
                  >
                    <div className="cur">AI</div>
                  </div>
                  <div
                    data-w-tab="Tab 5"
                    className={`cf-home-tab-link w-inline-blocksw-tab-link ${tab5Heading}`}
                    onClick={tabChange5}
                  >
                    <div className="cur">Ask YtubeBooster</div>
                  </div>
                </div>
                <div className="cf-tabs-content w-tab-content">
                  <div
                    data-w-tab="Tab 1"
                    className={`cf-email w-tab-pane ${tab1Content}`}
                  >
                    <div className="cf-tabs-content-contain">
                      <div className="cf-tabs-left-wrap">
                        <h5 className="cf-tabs-sub-heading">
                          Channel Optimize
                        </h5>
                        <h4 className="cf-tabs-content-heading">
                          Insight For Growth
                        </h4>
                        {/* <h4 className="cf-tabs-content-heading">
                          Grow your YouTube channel exponentially
                        </h4> */}
                        <div className="cf-tabs-content-tesxt">
                          Let our SEO Manager research and implement the
                          keywords to help you stand out from the crowd. It will
                          refine tags, create engaging metadata and even polish
                          your thumbnails.
                        </div>
                        {/* <div className="cf-tabs-content-text">
                          Your video SEO manager is here: Research keywords to
                          improve visibility and reach of your video. Analyze
                          search volume, competition, and trends to optimize
                          your video content. Make your video title and
                          description compelling to capture your audience and
                          rank higher on search engines. Create engaging
                          metadata by leveraging our SEO manager's suggestions.
                          Optimizing tags maximizes your video's
                          discoverability. Tag recommendations are provided by
                          our SEO manager based on industry trends. Optimize
                          thumbnails for search results so they stand out and
                          encourage viewers to click. Get insight into audience
                          engagement, viewer retention, and traffic sources with
                          detailed analytics. Improve your video optimization
                          strategies with this data.
                        </div> */}
                        {/* <a href="#learn" className="cf-black-small-button w-buttons">
                          Learn More
                        </a> */}
                        {/* <img
                          loading="lazy"
                          src="/images/Vector.svg"
                          alt=""
                          className="cf-tabs-element"
                        /> */}
                        <img
                          loading="lazy"
                          src="/images/62.svg"
                          alt="image1"
                          className="cf-tabs-element _2"
                        />
                      </div>
                      <div className="cf-tabs-right-tab">
                      <img
                          loading="lazy"
                          src="/images/discription2.png"
                          alt="image2"
                          className="cf-tab-image tab-1"
                        />
                        {/* <img
                          loading="lazy"
                          src="/images/Group-1000000963.svg"
                          alt="image2"
                          className="cf-tab-image tab-1"
                        />
                        <img
                          loading="lazy"
                          src="/images/Card.svg"
                          alt="image3"
                          className="cf-tabs-image absolute tab-1"
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div
                    data-w-tab="Tab 2"
                    className={`cf-sms w-tab-pane ${tab2Content}`}
                  >
                    <div className="cf-tabs-content-contain">
                      <div className="cf-tabs-left-wrap">
                        <h5 className="cf-tabs-sub-heading">
                          Create Compelling Title
                        </h5>
                        <h4 className="cf-tabs-content-heading">
                          Higher Click Through Rate
                        </h4>
                        {/* <div className="cf-tabs-content-text">
                          The video title creation feature of a YouTube SEO
                          manager offers a range of powerful tools to optimize
                          your video titles for maximum visibility and
                          engagement. With this feature, you can: Generate
                          compelling and attention-grabbing titles using
                          advanced algorithms that consider popular keywords,
                          trending topics, and audience preferences. Analyze the
                          performance of your existing video titles, gain
                          insights into what people are looking for. Access AI
                          generated Youtube SEO friendly successful video titles
                          in your niche, providing inspiration and helping you
                          stay ahead of the competition. Get real-time SEO
                          suggestions and optimization tips for your video
                          title, ensuring your content is discovered and ranks
                          higher. With video title creation, you can enhance
                          your channel's visibility, attract more viewers, and
                          ultimately drive higher engagement and success.
                        </div> */}
                        <div className="cf-tabs-content-text">
                          Our range of powerful tools give you everything from
                          in-depth data into viewer retention, to trending
                          topics in your niche, and even complimentary AI to get
                          real-time SEO suggestions.741/++
                        </div>
                        {/* <a href="#learn" className="cf-black-small-button w-buttons">
                          Learn More
                        </a> */}
                        {/* <img
                          loading="lazy"
                          src="/images/Vector.svg"
                          alt=""
                          className="cf-tabs-element"
                        /> */}
                        <img
                          loading="lazy"
                          src="/images/62.svg"
                          alt="image4"
                          className="cf-tabs-element _2"
                        />
                      </div>
                      <div className="cf-tabs-right-tab">
                      <img
                          loading="lazy"
                          src="/images/title2.png"
                          alt="image5"
                          className="cf-tab-image  tab-1"
                        />
                        {/* <img
                          loading="lazy"
                          src="/images/Group-237486.svg"
                          alt="image5"
                          className="cf-tabs-image tab-2"
                        />
                        <img
                          loading="lazy"
                          src="/images/Graph.png"
                          alt=""
                          className="cf-tabs-image absolute tab-2"
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div
                    data-w-tab="Tab 3"
                    className={`cf-automation w-tab-pane ${tab3Content}`}
                  >
                    <div className="cf-tabs-content-contain">
                      <div className="cf-tabs-left-wrap">
                        <h5 className="cf-tabs-sub-heading">
                          Generate Eye-Catchy Description
                        </h5>
                        <h4 className="cf-tabs-content-heading">
                          Boost Discoverability
                        </h4>
                        {/* <h4 className="cf-tabs-content-heading">
                          Tools built to grow your channels
                        </h4> */}
                        {/* <div className="cf-tabs-content-text">
                          Introducing YouTube SEO Manager Video Description
                          Creation features: We provide AI-generated video
                          descriptions to help you create compelling video
                          descriptions. Optimize your video content for search
                          engines with keyword-rich descriptions and engaging
                          summaries. Integrate keyword research into your video
                          and boost its discoverability. Our software suggests
                          keywords based on your video's content, audience
                          preferences, and search trends. By incorporating these
                          keywords into your video description, you can improve
                          its visibility and attract more viewers. Advanced
                          Analytics Insights: Gain valuable insights into video
                          descriptions' performance with our comprehensive
                          analytics.
                        </div> */}
                        <div className="cf-tabs-content-text">
                          Our software scans your video to generate unique
                          descriptions specific to your content. The result are
                          the perfect keywords to utilize trends, audience and
                          preferences to get your video to the top.
                        </div>
                        {/* <a
                          href="#learn"
                          className="cf-black-small-button w-buttons"
                        >
                          Learn More
                        </a> */}
                        {/* <img
                          loading="lazy"
                          src="/images/Vector.svg"
                          alt=""
                          className="cf-tabs-element"
                        /> */}
                        <img
                          loading="lazy"
                          src="/images/62.svg"
                          alt="image6"
                          className="cf-tabs-element _2"
                        />
                      </div>
                      <div className="cf-tabs-right-tab">
                      <img
                      // style={{width:"60%"}}
                          loading="lazy"
                          src="/images/description4.png"
                          alt="image7"
                          className="cf-tab-image tab-3"
                        />
                        {/* <img
                          loading="lazy"
                          src="/images/JHV.svg"
                          alt="image7"
                          className="cf-tab-image tab-3"
                        />
                        <img
                          loading="lazy"
                          src="/images/KGHC.svg"
                          alt="image8"
                          className="cf-tabs-image absolute card tab-3"
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div
                    data-w-tab="Tab 4"
                    className={`cf-intelligent w-tab-pane ${tab4Content}`}
                  >
                    <div className="cf-tabs-content-contain">
                      <div className="cf-tabs-left-wrap">
                        <h5 className="cf-tabs-sub-heading">
                          INTELLIGENT AI GENERATION
                        </h5>
                        <h4 className="cf-tabs-content-heading">
                          Modern Solutions For Modern Problems
                        </h4>
                        {/* <h4 className="cf-tabs-content-heading">
                          Personalize Your Videos with AI.
                        </h4> */}
                        {/* <div className="cf-tabs-content-text">
                          Video AI tools for title, description, blogs, and
                          social media posts from a YouTube SEO manager include:
                          You can maximize views and engagement with our video
                          AI tool that analyzes your video's content and target
                          audience. Our tool helps you create compelling
                          descriptions that provide valuable insights,
                          incorporate relevant keywords, and effectively
                          communicate your video's content, giving it an edge in
                          search. Our AI-powered tool also helps you generate
                          engaging blog posts based on your YouTube content. You
                          can use it to write informative articles that drive
                          traffic to your channel. Using our video AI tool, you
                          can promote your YouTube videos on social media. Save
                          time and effort by automatically creating social media
                          posts tailored to different platforms. This tool
                          empowers content creators to optimize title,
                          description, blog posts, and social media posts,
                          ultimately driving organic growth and increasing
                          audience engagement.
                        </div> */}
                        <div className="cf-tabs-content-text">
                          Our unique AI tool integration can even help you with
                          social media posts, blogs and much more! Other
                          software developers keep you trapped on YouTube but we
                          know that a channel’s growth also comes from off-site
                          engagement, which is why YtubeBooster Pro is designed
                          for real content creators.
                        </div>
                        {/* <a
                          href="#learn"
                          className="cf-black-small-button w-buttons"
                        >
                          Learn More
                        </a> */}
                        {/* <img
                          loading="lazy"
                          src="/images/Vector.svg"
                          alt=""
                          className="cf-tabs-element"
                        /> */}
                        <img
                          loading="lazy"
                          src="/images/62.svg"
                          alt="image9"
                          className="cf-tabs-element _2"
                        />
                      </div>
                      <div className="cf-tabs-right-tab">
                        <img
                        // style={{width:"60%"}}
                          loading="lazy"
                          src="/images/ai3.png"
                          alt="image10"
                          className="cf-tab-image tab-4"
                        />
                        {/* <img
                          loading="lazy"
                          src="/images/Group-1000000965.svg"
                          alt="image10"
                          className="cf-tab-image tab-4"
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div
                    data-w-tab="Tab 5"
                    className={`cf-segmentation w-tab-pane ${tab5Content}`}
                  >
                    <div className="cf-tabs-content-contain">
                      <div className="cf-tabs-left-wrap">
                        <h5 className="cf-tabs-sub-heading">
                          ASK YTUBEBOOSTER
                        </h5>
                        <h4 className="cf-tabs-content-heading">
                          Personal Assistant 24/7
                        </h4>
                        {/* <h4 className="cf-tabs-content-heading">
                          A treasure trove of keywords and phrases.
                        </h4> */}
                        {/* <div className="cf-tabs-content-text">
                          Introducing AskYtubeBooster, our YouTube SEO manager's
                          powerful tool to optimize YouTube videos. The
                          following key features are available with this tool:
                          Keyword Research: Discover a treasure trove of
                          keywords and phrases relevant to your video niche.
                          Using advanced algorithms, we provide you with
                          comprehensive insights that enable you to optimize
                          your video and boost its visibility. Real-time trend
                          analysis keeps you ahead of the curve. Using our tool,
                          you can create timely and highly engaging video
                          content that captivates your audience. YouTube Content
                          Ideation: Never run out of ideas. Based on popular
                          search terms, our tool generates a plethora of content
                          suggestions that ensure that your video content is
                          relevant and compelling.
                        </div> */}
                        <div className="cf-tabs-content-text">
                          It’s one thing to boost your content but what if
                          you’re struggling to crack the subject for your next
                          video? Say hello to ‘AskYtubeBooster’! It’s your
                          on-hand management tool to generate endless ideas,
                          suggestions and fine-tuning.
                        </div>
                        {/* <a
                          href="#learn"
                          className="cf-black-small-button w-buttons"
                        >
                          Learn More
                        </a> */}
                        {/* <img
                          loading="lazy"
                          src="/images/Vector.svg"
                          alt=""
                          className="cf-tabs-element"
                        /> */}
                        <img
                          loading="lazy"
                          src="/images/62.svg"
                          alt=""
                          className="cf-tabs-element _2"
                        />
                      </div>
                      <div className="cf-tabs-right-tab">
                      <img
                      // style={{width:"80%"}}
                          loading="lazy"
                          src="/images/askytubebooster2.png"
                          alt=""
                          className="cf-tab-image tab-5"
                        />
                        {/* <img
                          loading="lazy"
                          src="/images/Visual-2.svg"
                          alt=""
                          className="cf-tab-image tab-5"
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="f-section-large">
          <div className="f-container-regular">
            <div className="f-margin-bottom-64">
              <div className="f-title-wrapper-center">
                <div className="f-margin-bottom-08">
                  <div className="f-heading-detail-small">
                    <span className="f-heading-detail-small">
                      Optimize Your YouTube Videos with Our
                    </span>
                  </div>
                </div>
                <div className="f-margin-bottom-16">
                  <h3 className="f-h3-heading">
                    <span
                      className="cf-text-opacity-70-italic"
                      style={{ lineHeight: "70px" }}
                    >
                      Powerful Tools – Easy-To-Use
                    </span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="f-feature-card-row">
              <div className="cards-width">
                <div className="f-feature-icon-wrapper">
                  <img src="/images/perfects.png" loading="lazy" alt="" />
                </div>
                <div className="f-margin-bottom-08">
                  <div className="f-sub-heading-large">Perfect Keywords</div>
                </div>
                <p className="f-paragraph-small">
                  Generate unique suggestions that are guaranteed to generate
                  growth.
                </p>
              </div>
              <div className="f-feature-card-divider" />
              <div className="cards-width">
                <div className="f-feature-icon-wrapper">
                  <img src="/images/Optimizes.png" loading="lazy" alt="" />
                </div>
                <div className="f-margin-bottom-08">
                  <div className="f-sub-heading-large">Optimized Titles</div>
                </div>
                <p className="f-paragraph-small">
                  Get the most out of your title by making each character count
                </p>
              </div>
              <div className="f-feature-card-divider" />
              <div className="cards-width">
                <div className="f-feature-icon-wrapper">
                  <img src="/images/meta_data.png" loading="lazy" alt="" />
                </div>
                <div className="f-margin-bottom-08">
                  <div className="f-sub-heading-large">Meta Data Magic</div>
                </div>
                <p className="f-paragraph-small">
                  Raise your ranking with relevant tags.
                </p>
              </div>
              <div className="f-feature-card-divider" />
              <div className="cards-width">
                <div className="f-feature-icon-wrapper">
                  <img src="/images/in-depth.png" loading="lazy" alt="" />
                </div>
                <div className="f-margin-bottom-08">
                  <div className="f-sub-heading-large">In-Depth Analytics</div>
                </div>
                <p className="f-paragraph-small">
                  Get real-time insight into video performance and progress to
                  make changes easily.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="combine-padding-global">
          <div className="combine-container-large">
            <div className="combine-padding-section-medium">
              <div className="combine-feature8_component">
                <div className="combine-feature8_content">
                  <h2
                    className="f-h3-heading cf-text-opacity-70-italic"
                    style={{ lineHeight: "70px" }}
                  >
                    Start Your Channel Growth Process
                  </h2>
                  <div className="combine-text-size-regular">
                    With our YouTube SEO manager, you can manage your YouTube
                    channel's search engine optimization (SEO) effectively. To
                    optimize your video content, start with comprehensive
                    keyword research. With our robust analytics, you can track
                    your channel's performance, monitor audience engagement, and
                    make data-driven decisions.
                  </div>
                  {/* <a
                    href="#learn"
                    className="combine-button-icon w-inline-block"
                  >
                    <div className="combine-button-content">
                      <div>Learn More</div>
                      <div className="combine-chevron-right_icon w-embed">
                        <svg
                          width="100%"
                          // height="currentHeight"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.83301 13L11.833 8L6.83301 3"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </a> */}
                </div>
                <div className="combine-feature8_content">
                  <div className="combine-feature8_wrapper">
                    <div className="combine-feature8_item">
                      <div className="combine-feature8_number">1.</div>
                      <h3 className="combine-heading-style-h6">
                        Keyword Research Wizard
                      </h3>
                      <div className="combine-text-size-regular">
                        Discover top-performing keywords and trending topics
                        relevant to your YouTube channel
                      </div>
                    </div>
                    <div className="combine-feature8_item">
                      <div className="combine-feature8_number">2.</div>
                      <h3 className="combine-heading-style-h6">
                        Performance Insights Dashboard
                      </h3>
                      <div className="combine-text-size-regular">
                        Understand your channel's performance with our
                        Performance Insights Dashboard.
                      </div>
                    </div>
                  </div>
                  <div className="combine-feature8_wrapper">
                    <div className="combine-feature8_item">
                      <div className="combine-feature8_number">3.</div>
                      <h3 className="combine-heading-style-h6">
                        Advanced Analytics Dashboard
                      </h3>
                      <div className="combine-text-size-regular">
                        Gather information using our Advanced Analytics
                        Dashboard.
                      </div>
                    </div>
                    {/* <div className="combine-feature8_item">
                      <div className="combine-feature8_number">4.</div>
                      <h3 className="combine-heading-style-h6">
                        Content Optimization Assistant
                      </h3>
                      <div className="combine-text-size-regular">
                        Content Optimization Assistant Streamline video content
                        optimization
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-4 wf-section">
          <div className="w-container">
            <div className="columns w-row">
              <div className="w-col w-col-4">
                <h1 className="heading-10">What are you waiting for?</h1>
              </div>
              <div className="column-2 w-col w-col-8">
                <p className="paragraph-5">
                  Join the hundreds of early-birds content creators who are
                  already improving their YouTube presence thanks to YtubeBooser
                  Pro!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="cf-pricing-hero-section">
          <div className="cf-wrapper-1200px-2">
            <div className="cf-pricing-hero-wrapper">
              <div className="cf-pricing-hero-head-container">
                <div className="cf-white-top-heading">Pricing plans</div>
                <h1 className="cf-pricing-hero-heading">
                  Boost your Subscription to <br />
                  <span className="cf-colourful-text-2">Boost Your value</span>
                </h1>
                <p className="cf-pricing-hero-para">
                  We’ve listened to our users to create the best custom, curated
                  prices to suit your needs. Any questions? Get in touch!
                </p>
              </div>
            </div>
            <div
              ref={listRef}
              className="cf-pricing-toggle-header-wrapper-2 pricing-header-toggle"
              id="pricings"
            >
              <div className="cf-toggle-row-2">
                <div className="cf-toggle-text-2">Monthly</div>
                {togged === false ? (
                  <div
                    data-w-id="36d9533a-055b-4e2a-3186-0d13e50c9fe3"
                    className="cf-toggle-contain-2 white-toggle"
                    onClick={takeToggle}
                  >
                    <div
                      style={{
                        WebkitTransform:
                          "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        MozTransform:
                          "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        msTransform:
                          "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        transform:
                          "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      }}
                      className="cf-toggle-dot-2 green-toggle-dot"
                    />
                  </div>
                ) : (
                  <div
                    data-w-id="36d9533a-055b-4e2a-3186-0d13e50c9fe3"
                    className="cf-toggle-contain-2 white-toggle"
                    onClick={takeToggle}
                  >
                    <div
                      style={{
                        WebkitTransform:
                          "translate3d(30px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        MozTransform:
                          "translate3d(30px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        msTransform:
                          "translate3d(30px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        transform:
                          "translate3d(30px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      }}
                      className="cf-toggle-dot-2 green-toggle-dot"
                    />
                  </div>
                )}
                <div className="cf-toggle-text-2">Yearly</div>
                <div className="cf-toggle-save-container-2">
                  <div className="cf-toggle-save-text-2">Save Up To 17%</div>
                  <img
                    src="images/Arrow-6.svg"
                    loading="lazy"
                    alt=""
                    className="cf-toggle-arrow-icon-2"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="cf-pricing-box-section">
            <div className="cf-wrapper-1200px-2">
              <div className="cf-pricing-box-wrapper-2 pricing-1-box">
                {togged === false ? (
                  <div
                    data-w-id="36d9533a-055b-4e2a-3186-0d13e50c9fee"
                    style={{
                      WebkitTransform:
                        "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: 1,
                      display: "display",
                    }}
                    className="cf-pricing-box-row-2 pricing-box-1"
                  >
                    <div
                      style={{ marginTop: "67px" }}
                      className="cf-pricing-box-2 pricing-1-box"
                    >
                      <div style={{ padding: "27px" }}>
                        <div className="cf-pricing-box-plan-wrapper-2">
                          <div className="cf-pricing-plan-text-2 pricing-1-plan-text">
                            Acorn
                          </div>
                          <p style={{ marginBottom: "32px" }}>
                            For new YouTube Creators who want access to
                            accelerated growth-oriented tools.
                          </p>
                          <div className="cf-pricing-plan-price-2">
                            $9.99
                            <span className="cf-pricing-plan-small-text-2">
                              /month
                            </span>
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-wrapper-2">
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src="images/1.svg"
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Free Trial not available. 07 days full refund
                              policy. No-quibble Cancellation policy.
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src="images/1.svg"
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              1 YouTube channel
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src="images/1.svg"
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Standard Channel analytics dashboard
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src="images/1.svg"
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Credits can be purchased to access AI powered
                              features
                            </div>
                          </div>
                        </div>
                        {/* <p className="cf-secondary-button-2 pricing-button w-buttons">
                        Register
                      </p> */}
                        <p
                          className="cf-secondary-button-2 pricing-button w-buttons"
                          onClick={(e) => handlePricing(e, priceTag.acornM)}
                        >
                          Register Now
                        </p>
                      </div>
                    </div>
                    <div
                      style={{ marginTop: "67px" }}
                      className="cf-pricing-box-2 green-pricing-box"
                    >
                      <div style={{ padding: "27px" }}>
                        <div className="cf-pricing-box-plan-wrapper-2 white-border">
                          <div className="cf-pricing-plan-text-2">Palm</div>
                          <p style={{ marginBottom: "32px" }}>
                            For budding and growing YouTube Creators seeking to
                            make informed data-driven decisions with rapid
                            channel growth potential
                          </p>
                          <div className="cf-pricing-plan-price-2">
                            $29.99
                            <span className="cf-pricing-plan-small-text-2">
                              /month
                            </span>
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-wrapper-2">
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src="images/2.svg"
                              loading="lazy"
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              All of Acorn features, plus Advanced Channel
                              Analytics, AI features with subscribed credits etc
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src="images/2.svg"
                              loading="lazy"
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Free 7-day Trial available with no-quibble
                              cancellation policy.
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src="images/2.svg"
                              loading="lazy"
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Add up to 3 YouTube channels you own or manage.
                            </div>
                          </div>
                        </div>
                        {/* <p className="cf-primary-button-2 pricing-button w-buttons">
                        Start Free Trial
                      </p> */}
                        <p
                          className="cf-primary-button-2 pricing-button w-buttons"
                          onClick={(e) => handlePricing(e, priceTag.palmM)}
                        >
                          Start Free 7-days Trial
                        </p>
                      </div>
                    </div>
                    <div
                      className="cf-pricing-box-2 pricing-1-box"
                      style={{ backgroundColor: "#ceedfa" }}
                    >
                      <div className="upper-top-cards-recom">
                        <div
                          style={{
                            fontSize: "24px",
                            fontWeight: "700",
                            textAlign: "center",
                          }}
                        >
                          <img
                            loading="lazy"
                            alt="recommanded"
                            src="https://assets-global.website-files.com/60e5f2de011b86acebc30db7/6447dd0049378e8f47155cdf_Stars.svg"
                          />{" "}
                          &nbsp; Recommended
                        </div>
                        <div className="under-sections">
                          <div className="cf-pricing-box-plan-wrapper-2">
                            <div className="cf-pricing-plan-text-2">Oak</div>
                            <p style={{ marginBottom: "32px" }}>
                              For growing and established YouTube Creators and
                              Brands, who want to boost their channel with AI
                            </p>
                            <div className="cf-pricing-plan-price-2">
                              $69.99
                              <span className="cf-pricing-plan-small-text-2">
                                /month
                              </span>
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-wrapper-2">
                            <div className="cf-pricing-plan-content-item-2">
                              <img
                                src="images/1.svg"
                                loading="lazy"
                                width={17}
                                alt=""
                                className="cf-pricing-plan-tick-icon-2"
                              />
                              <div className="cf-pricing-plan-content-text-2">
                                All of Palm features, plus Social Media Post
                                generator, Blog Writer incl. more subscribed
                                credits for all AI powered features.
                              </div>
                            </div>
                            <div className="cf-pricing-plan-content-item-2">
                              <img
                                src="images/1.svg"
                                loading="lazy"
                                width={17}
                                alt=""
                                className="cf-pricing-plan-tick-icon-2"
                              />
                              <div className="cf-pricing-plan-content-text-2">
                                Free 7-day Trial available with no-quibble
                                cancellation policy.
                              </div>
                            </div>
                            <div className="cf-pricing-plan-content-item-2">
                              <img
                                src="images/1.svg"
                                loading="lazy"
                                width={17}
                                alt=""
                                className="cf-pricing-plan-tick-icon-2"
                              />
                              <div className="cf-pricing-plan-content-text-2">
                                Add up to 10 YouTube channels you own or manage.
                              </div>
                            </div>
                          </div>
                          {/* <p className="cf-secondary-button-2 pricing-button w-buttons">
                        Start Free Trial
                      </p> */}
                          <p
                            className="cf-secondary-button-2 pricing-button"
                            onClick={(e) => handlePricing(e, priceTag.oackM)}
                          >
                            Start Free 7-days Trial
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    data-w-id="36d9533a-055b-4e2a-3186-0d13e50ca046"
                    style={{
                      WebkitTransform:
                        "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: 1,
                      display: "flex",
                    }}
                    className="cf-pricing-box-row-2 pricing-box-2"
                  >
                    <div
                      style={{ marginTop: "67px" }}
                      className="cf-pricing-box-2 pricing-1-box"
                    >
                      <div style={{ padding: "27px" }}>
                        <div className="cf-pricing-box-plan-wrapper-2">
                          <div className="cf-pricing-plan-text-2 pricing-1-plan-text">
                            Acorn
                          </div>
                          <p style={{ marginBottom: "32px" }}>
                            For new YouTube Creators who want access to
                            accelerated growth-oriented tools.
                          </p>
                          <div className="lower-price-opt">
                            <strike className="strick-opts">$9.99/month</strike>
                            <div
                              className="cf-pricing-plan-price-2"
                              style={{ marginBottom: "0px" }}
                            >
                              $8.33
                              <span className="cf-pricing-plan-small-text-2">
                                /month
                              </span>
                            </div>
                          </div>
                          <p className="warns">
                            save 17% with annual billing incl. 2 months free
                            access
                          </p>
                        </div>

                        <div className="cf-pricing-plan-content-wrapper-2">
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src="images/1.svg"
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Free Trial not available. 07 days full refund
                              policy. No-quibble Cancellation policy.
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src="images/1.svg"
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              1 YouTube channel
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src="images/1.svg"
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Standard Channel analytics dashboard
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src="images/1.svg"
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Credits can be purchased to access AI powered
                              features
                            </div>
                          </div>
                        </div>
                        {/* <p className="cf-secondary-button-2 pricing-button w-buttons">
                        Register
                      </p> */}
                        <p
                          className="cf-secondary-button-2 pricing-button w-buttons"
                          onClick={(e) => handlePricing(e, priceTag.acornY)}
                        >
                          Register Now
                        </p>
                      </div>
                    </div>
                    <div
                      style={{ marginTop: "67px" }}
                      className="cf-pricing-box-2 green-pricing-box"
                    >
                      <div style={{ padding: "27px" }}>
                        <div className="cf-pricing-box-plan-wrapper-2 white-border">
                          <div className="cf-pricing-plan-text-2">Palm</div>
                          <p style={{ marginBottom: "32px" }}>
                            For budding and growing YouTube Creators seeking to
                            make informed data-driven decisions with rapid
                            channel growth potential
                          </p>
                          <div className="lower-price-opt">
                            <strike className="strick-opts">
                              $29.99/month
                            </strike>
                            <div
                              className="cf-pricing-plan-price-2"
                              style={{ marginBottom: "0px" }}
                            >
                              $24.99
                              <span className="cf-pricing-plan-small-text-2">
                                /month
                              </span>
                            </div>
                          </div>
                          <p className="warns">
                            save over 17% with annual billing incl. 2 months
                            free access
                          </p>
                        </div>
                        <div className="cf-pricing-plan-content-wrapper-2">
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src="images/2.svg"
                              loading="lazy"
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              All of Acorn features, plus Advanced Channel
                              Analytics, AI features with subscribed credits etc
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src="images/2.svg"
                              loading="lazy"
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Free 7-day Trial available with no-quibble
                              cancellation policy.
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src="images/2.svg"
                              loading="lazy"
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Add up to 3 YouTube channels you own or manage.
                            </div>
                          </div>
                        </div>
                        {/* <p className="cf-primary-button-2 pricing-button w-buttons">
                        Start Free Trial
                      </p> */}
                        <p
                          className="cf-primary-button-2 pricing-button w-buttons"
                          onClick={(e) => handlePricing(e, priceTag.palmY)}
                        >
                          Start Free 7-days Trial
                        </p>
                      </div>
                    </div>
                    <div
                      className="cf-pricing-box-2 pricing-1-box"
                      style={{ backgroundColor: "#ceedfa" }}
                    >
                      <div className="upper-top-cards-recom">
                        <div
                          style={{
                            fontSize: "24px",
                            fontWeight: "700",
                            textAlign: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <img
                            loading="lazy"
                            alt="recommanded"
                            src="https://assets-global.website-files.com/60e5f2de011b86acebc30db7/6447dd0049378e8f47155cdf_Stars.svg"
                          />{" "}
                          &nbsp; Recommended
                        </div>
                        <div className="under-sections">
                          <div className="cf-pricing-box-plan-wrapper-2">
                            <div className="cf-pricing-plan-text-2">Oak</div>
                            <p style={{ marginBottom: "32px" }}>
                              For growing and established YouTube Creators and
                              Brands, who want to boost their channel with AI
                            </p>
                            <div className="lower-price-opt">
                              <strike className="strick-opts">
                                $69.99/month
                              </strike>
                              <div
                                className="cf-pricing-plan-price-2"
                                style={{ marginBottom: "0px" }}
                              >
                                $58.33
                                <span className="cf-pricing-plan-small-text-2">
                                  /month
                                </span>
                              </div>
                            </div>
                            <p className="warns">
                              save over 17% with annual billing incl. 2 months
                              free access
                            </p>
                          </div>
                          <div className="cf-pricing-plan-content-wrapper-2">
                            <div className="cf-pricing-plan-content-item-2">
                              <img
                                src="images/1.svg"
                                loading="lazy"
                                width={17}
                                alt=""
                                className="cf-pricing-plan-tick-icon-2"
                              />
                              <div className="cf-pricing-plan-content-text-2">
                                All of Palm features, plus Social Media Post
                                generator, Blog Writer incl. more subscribed
                                credits for all AI powered features.
                              </div>
                            </div>
                            <div className="cf-pricing-plan-content-item-2">
                              <img
                                src="images/1.svg"
                                loading="lazy"
                                width={17}
                                alt=""
                                className="cf-pricing-plan-tick-icon-2"
                              />
                              <div className="cf-pricing-plan-content-text-2">
                                Free 7-day Trial available with no-quibble
                                cancellation policy.
                              </div>
                            </div>
                            <div className="cf-pricing-plan-content-item-2">
                              <img
                                src="images/1.svg"
                                loading="lazy"
                                width={17}
                                alt=""
                                className="cf-pricing-plan-tick-icon-2"
                              />
                              <div className="cf-pricing-plan-content-text-2">
                                Add up to 10 YouTube channels you own or manage.
                              </div>
                            </div>
                          </div>
                          {/* <p className="cf-secondary-button-2 pricing-button w-buttons">
                        Start Free Trial
                      </p> */}
                          <p
                            className="cf-secondary-button-2 pricing-button"
                            onClick={(e) => handlePricing(e, priceTag.oackY)}
                          >
                            Start Free 7-days Trial
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <section className="combine-section_pricing5">
          <div className="combine-padding-global">
            <div className="combine-padding-section-medium">
              <h2 className="cf-text-opacity-70-italic center-gradient-heading">
              Unsure which plan to select?
                <br />
                Compare Features
              </h2>
              <div className="combine-space-large" id="feature-comp" />
              <div className="combine-container-large">
                <div className="combine-pricing5_component">
                  <div className="combine-pricing5_table-header">
                    <div className="combine-hide-mobile-landscape" />
                    <div className="combine-pricing5_details">
                      <div className="combine-text-weight-semibold">
                        <img
                          height="12"
                          width="76"
                          alt="acorn"
                          style={{ rotate: "35deg" }}
                          src="/images/acorn.png"
                        />
                        <br />
                        ACORN
                      </div>
                    </div>
                    <div className="combine-pricing5_details">
                      <div className="combine-text-weight-semibold">
                        <img
                          height="12"
                          width="50"
                          alt="palm"
                          style={{ width: "99px", height: "99px" }}
                          src="/images/palm.png"
                        />
                        <br />
                       <p style={{marginRight:"47px"}}>PALM</p> 
                      </div>
                    </div>
                    <div className="combine-pricing5_details">
                      <div className="combine-text-weight-semibold">
                        <img
                          height="12"
                          width="87"
                          alt="oak"
                          src="/images/oak.png"
                        />
                        <br />
                       <p> OAK</p>
                      </div>
                    </div>
                  </div>
                  <div className="combine-pricing5_table-feature">
                    <div className="combine-pricing5_table-title">
                      <div className="combine-text-weight-semibold">
                        Plan feature Title
                      </div>
                    </div>
                  </div>
                  <div className="combine-background-color-grey">
                    <div className="combine-pricing5_table-content">
                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1cfb-b342fa83"
                        className="combine-pricing5_table-item"
                      >
                        <div>Subscription Credits (Monthly/Annual)</div>
                        <div className="combine-pricing5_help">
                          <div className="combine-icon_small w-embed">
                            <svg
                              // width="currentWidth"
                              // height="currentHeight"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <div className="combine-pricing5_tooltip-wrapper">
                            <div className="combine-pricing5_tooltip-triangle w-embed">
                              <svg
                                // width="currentWidth"
                                // height="currentHeight"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div className="combine-pricing5_tooltip">
                              <div>This is some text inside of a tooltip.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="combine-text-size-regular">-</div>
                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                        className="combine-icon_color5"
                      >
                        <div className="combine-text-size-regular">
                          25000/month or <br /> 300000/annually
                        </div>
                      </div>
                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                        className="combine-icon_color5"
                      >
                        <div className="combine-text-size-regular">
                          50000/month or <br /> 600000/annually
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="combine-pricing5_table-content">
                    <div
                      id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d0c-b342fa83"
                      className="combine-pricing5_table-item"
                    >
                      <div className="text-block-4">Multi-Channel (s)</div>
                      <div className="combine-pricing5_help">
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div className="combine-pricing5_tooltip-wrapper">
                          <div className="combine-pricing5_tooltip-triangle w-embed">
                            <svg
                              // width="currentWidth"
                              // height="currentHeight"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <div className="combine-pricing5_tooltip">
                            <div>This is some text inside of a tooltip.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                      className="combine-icon_color5"
                    >
                      <div className="combine-text-size-regular">
                        max 1 channel
                      </div>
                    </div>
                    <div
                      id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                      className="combine-icon_color5"
                    >
                      <div className="combine-text-size-regular">
                        max 3 channel
                      </div>
                    </div>
                    <div
                      id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                      className="combine-icon_color5"
                    >
                      <div className="combine-text-size-regular">
                        max 10 channel
                      </div>
                    </div>
                  </div>
                  <div className="combine-background-color-grey">
                    <div className="combine-pricing5_table-content">
                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d1e-b342fa83"
                        className="combine-pricing5_table-item"
                      >
                        <div className="text-block-3">
                          Dashboard with Channel Analytics
                        </div>
                        <div className="combine-pricing5_help">
                          <div className="combine-icon_small w-embed">
                            <svg
                              // width="currentWidth"
                              // height="currentHeight"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <div className="combine-pricing5_tooltip-wrapper">
                            <div className="combine-pricing5_tooltip-triangle w-embed">
                              <svg
                                // width="currentWidth"
                                // height="currentHeight"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div className="combine-pricing5_tooltip">
                              <div>This is some text inside of a tooltip.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                        className="combine-icon_color5"
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                        className="combine-icon_color5"
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                        className="combine-icon_color5"
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="combine-pricing5_table-content">
                    <div
                      id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d0c-b342fa83"
                      className="combine-pricing5_table-item"
                    >
                      <div className="text-block-4">Advanced Channel stats</div>
                      <div className="combine-pricing5_help">
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div className="combine-pricing5_tooltip-wrapper">
                          <div className="combine-pricing5_tooltip-triangle w-embed">
                            <svg
                              // width="currentWidth"
                              // height="currentHeight"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <div className="combine-pricing5_tooltip">
                            <div>This is some text inside of a tooltip.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="combine-text-size-regular">-</div>
                    <div
                      id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                      className="combine-icon_color5"
                    >
                      <div className="combine-icon_small w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                      className="combine-icon_color5"
                    >
                      <div className="combine-icon_small w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="combine-background-color-grey">
                    <div className="combine-pricing5_table-content">
                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d1e-b342fa83"
                        className="combine-pricing5_table-item"
                      >
                        <div className="text-block-3">Keyword Research</div>
                        <div className="combine-pricing5_help">
                          <div className="combine-icon_small w-embed">
                            <svg
                              // width="currentWidth"
                              // height="currentHeight"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <div className="combine-pricing5_tooltip-wrapper">
                            <div className="combine-pricing5_tooltip-triangle w-embed">
                              <svg
                                // width="currentWidth"
                                // height="currentHeight"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div className="combine-pricing5_tooltip">
                              <div>This is some text inside of a tooltip.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                        className="combine-icon_color5"
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                        className="combine-icon_color5"
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                        className="combine-icon_color5"
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="combine-pricing5_table-content">
                    <div
                      id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d0c-b342fa83"
                      className="combine-pricing5_table-item"
                    >
                      <div className="text-block-4">Tag Extractor</div>
                      <div className="combine-pricing5_help">
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div className="combine-pricing5_tooltip-wrapper">
                          <div className="combine-pricing5_tooltip-triangle w-embed">
                            <svg
                              // width="currentWidth"
                              // height="currentHeight"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <div className="combine-pricing5_tooltip">
                            <div>This is some text inside of a tooltip.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                      className="combine-icon_color5"
                    >
                      <div className="combine-icon_small w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                      className="combine-icon_color5"
                    >
                      <div className="combine-icon_small w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                      className="combine-icon_color5"
                    >
                      <div className="combine-icon_small w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="combine-background-color-grey">
                    <div className="combine-pricing5_table-content">
                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d57-b342fa83"
                        className="combine-pricing5_table-item"
                      >
                        <div>Ask YTubeBooster</div>
                        <div className="combine-pricing5_help">
                          <div className="combine-icon_small w-embed">
                            <svg
                              // width="currentWidth"
                              // height="currentHeight"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <div className="combine-pricing5_tooltip-wrapper">
                            <div className="combine-pricing5_tooltip-triangle w-embed">
                              <svg
                                // width="currentWidth"
                                // height="currentHeight"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div className="combine-pricing5_tooltip">
                              <div>This is some text inside of a tooltip.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                        className="combine-icon_color5"
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                        className="combine-icon_color5"
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                        className="combine-icon_color5"
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="combine-pricing5_table-content">
                    <div
                      id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d68-b342fa83"
                      className="combine-pricing5_table-item"
                    >
                      <div>Title Generator</div>
                      <div className="combine-pricing5_help">
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div className="combine-pricing5_tooltip-wrapper">
                          <div className="combine-pricing5_tooltip-triangle w-embed">
                            <svg
                              // width="currentWidth"
                              // height="currentHeight"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <div className="combine-pricing5_tooltip">
                            <div>This is some text inside of a tooltip.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                      className="combine-icon_color5"
                    >
                      <div className="combine-icon_small w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                      className="combine-icon_color5"
                    >
                      <div className="combine-icon_small w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                      className="combine-icon_color5"
                    >
                      <div className="combine-icon_small w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="combine-background-color-grey">
                    <div className="combine-pricing5_table-content">
                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d7a-b342fa83"
                        className="combine-pricing5_table-item"
                      >
                        <div>Description Generator</div>
                        <div className="combine-pricing5_help">
                          <div className="combine-icon_small w-embed">
                            <svg
                              // width="currentWidth"
                              // height="currentHeight"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <div className="combine-pricing5_tooltip-wrapper">
                            <div className="combine-pricing5_tooltip-triangle w-embed">
                              <svg
                                // width="currentWidth"
                                // height="currentHeight"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div className="combine-pricing5_tooltip">
                              <div>This is some text inside of a tooltip.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                        className="combine-icon_color5"
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                        className="combine-icon_color5"
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </div>

                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                        className="combine-icon_color5"
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="combine-pricing5_table-content">
                    <div
                      id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d68-b342fa83"
                      className="combine-pricing5_table-item"
                    >
                      <div>Chrome Extension</div>
                      <div className="combine-pricing5_help">
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div className="combine-pricing5_tooltip-wrapper">
                          <div className="combine-pricing5_tooltip-triangle w-embed">
                            <svg
                              // width="currentWidth"
                              // height="currentHeight"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <div className="combine-pricing5_tooltip">
                            <div>This is some text inside of a tooltip.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="combine-text-size-regular">-</div>
                    <div
                      id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                      className="combine-icon_color5"
                    >
                      <div className="combine-icon_small w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                      className="combine-icon_color5"
                    >
                      <div className="combine-icon_small w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="combine-background-color-grey">
                    <div className="combine-pricing5_table-content">
                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d7a-b342fa83"
                        className="combine-pricing5_table-item"
                      >
                        <div>Mobile App</div>
                        <div className="combine-pricing5_help">
                          <div className="combine-icon_small w-embed">
                            <svg
                              // width="currentWidth"
                              // height="currentHeight"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <div className="combine-pricing5_tooltip-wrapper">
                            <div className="combine-pricing5_tooltip-triangle w-embed">
                              <svg
                                // width="currentWidth"
                                // height="currentHeight"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div className="combine-pricing5_tooltip">
                              <div>This is some text inside of a tooltip.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="combine-text-size-regular">-</div>
                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                        className="combine-icon_color5"
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </div>

                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                        className="combine-icon_color5"
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="combine-pricing5_table-content">
                    <div
                      id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d68-b342fa83"
                      className="combine-pricing5_table-item"
                    >
                      <div>Social Media Post Generator</div>
                      <div className="combine-pricing5_help">
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div className="combine-pricing5_tooltip-wrapper">
                          <div className="combine-pricing5_tooltip-triangle w-embed">
                            <svg
                              // width="currentWidth"
                              // height="currentHeight"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <div className="combine-pricing5_tooltip">
                            <div>This is some text inside of a tooltip.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="combine-text-size-regular">-</div>
                    <div className="combine-text-size-regular">-</div>
                    <div
                      id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                      className="combine-icon_color5"
                    >
                      <div className="combine-icon_small w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="combine-background-color-grey">
                    <div className="combine-pricing5_table-content">
                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d7a-b342fa83"
                        className="combine-pricing5_table-item"
                      >
                        <div>Blog Writer</div>
                        <div className="combine-pricing5_help">
                          <div className="combine-icon_small w-embed">
                            <svg
                              // width="currentWidth"
                              // height="currentHeight"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <div className="combine-pricing5_tooltip-wrapper">
                            <div className="combine-pricing5_tooltip-triangle w-embed">
                              <svg
                                // width="currentWidth"
                                // height="currentHeight"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div className="combine-pricing5_tooltip">
                              <div>This is some text inside of a tooltip.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="combine-text-size-regular">-</div>
                      <div className="combine-text-size-regular">-</div>

                      <div
                        id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                        className="combine-icon_color5"
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <PlaneDetails />

        <AccordianGroup />
        <section className="combine-section_contact2">
          <div className="combine-padding-global-2" id="contact-us">
            <div className="combine-container-large-2">
              <div className="combine-padding-section-medium-2">
                <div className="combine-contact2_component">
                  <div className="combine-contact2_content">
                    <h2 className="combine-heading-style-h2-2">Get in touch</h2>
                    {/* <div className="combine-text-size-regular-2">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Praesent ipsum arcu, tempor semper vulputate ut, volutpat
                      eget ex.
                    </div> */}
                    <div className="combine-contact2_feature">
                      <div className="combine-icon_color1">
                        <div className="combine-icon_medium w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 36 36"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_628_1151)">
                              <path
                                d="M10.3348 12.7048L18 18.0704L25.6652 12.7048C25.5367 12.2752 25.1358 11.9583 24.6667 11.9583H11.3333C10.8642 11.9583 10.4633 12.2752 10.3348 12.7048Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M25.7083 14.2004V23C25.7083 23.5715 25.2382 24.0417 24.6667 24.0417H11.3333C10.7618 24.0417 10.2917 23.5715 10.2917 23V14.2004L17.6416 19.3454C17.8568 19.496 18.1432 19.496 18.3584 19.3454L25.7083 14.2004Z"
                                fill="currentColor"
                              ></path>
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 12C0 5.37258 5.37258 0 12 0H24C30.6274 0 36 5.37258 36 12V24C36 30.6274 30.6274 36 24 36H12C5.37258 36 0 30.6274 0 24V12ZM26.9583 12.9896C26.9527 11.7325 25.9251 10.7083 24.6667 10.7083H11.3333C10.0749 10.7083 9.04732 11.7325 9.04169 12.9896M26.9583 13.0077V23C26.9583 24.2618 25.9285 25.2917 24.6667 25.2917H11.3333C10.0715 25.2917 9.04167 24.2618 9.04167 23V13.0077"
                                fill="currentColor"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_628_1151">
                                <rect
                                  width="36"
                                  height="36"
                                  fill="currentColor"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </div>
                      {/* <div className="combine-text-size-regular-2">
                        email@example.com
                      </div> */}
                    </div>
                    <img
                      src="images/icon-brix-template-Email.svg"
                      loading="lazy"
                      width="347"
                      alt="Email Icon - Elements Webflow Library - BRIX Templates"
                    />
                  </div>
                  <div className="combine-contact2_form-wrapper w-form">
                    <div
                      id="wf-form-Contact-2-Form"
                      name="wf-form-Contact-2-Form"
                      data-name="Contact 2 Form"
                      method="get"
                      className="combine-form_form"
                      aria-label="Contact 2 Form"
                    >
                      <div className="combine-form_field-wrapper">
                        <label
                          htmlFor="Contact-2-Name"
                          className="combine-form_label"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          value={naam}
                          onChange={(e) => setNaam(e.target.value)}
                          className="combine-form_input-grey w-input"
                          maxLength="256"
                          name="name"
                          data-name="Contact 2 Name"
                          placeholder="Enter your name"
                          id="name"
                          required=""
                        />
                      </div>
                      <div className="combine-form_field-wrapper">
                        <label htmlFor="email" className="combine-form_label">
                          Email
                        </label>
                        <input
                          type="email"
                          value={inputs.email}
                          className="combine-form_input-grey w-input"
                          maxLength="256"
                          name="email"
                          onChange={handleOnChange}
                          data-name="Contact 2 Email"
                          placeholder="Enter your email"
                          id="email"
                          required=""
                        />
                      </div>
                      <div className="combine-form_field-wrapper">
                        <label htmlFor="subject" className="combine-form_label">
                          Subject
                        </label>
                        <input
                          type="text"
                          onChange={handleOnChange}
                          value={inputs.subject}
                          className="combine-form_input-grey w-input"
                          maxLength="256"
                          name="subject"
                          data-name="Contact 2 Email 2"
                          placeholder="Write your subject"
                          id="subject"
                          required=""
                        />
                      </div>
                      <div className="combine-form_field-wrapper">
                        <label htmlFor="message" className="combine-form_label">
                          Message
                        </label>
                        <textarea
                          id="message"
                          onChange={handleOnChange}
                          value={inputs.message}
                          name="message"
                          maxLength="5000"
                          data-name="Contact 2 Message"
                          placeholder="Type anything..."
                          required=""
                          className="combine-form_text-area-grey w-input"
                        ></textarea>
                      </div>
                      <div className="combine-form_field-wrapper">
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey="6Lf8UAcaAAAAAK3XZgkujfFezjVLfxKVOOVZAHIE"
                        />
                        {/* <label className="w-checkbox combine-form_checkbox">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom combine-form_checkbox-icon"></div>
                          <input
                            type="checkbox"
                            id="Contact-2-Checkbox"
                            name="Contact-2-Checkbox"
                            data-name="Contact 2 Checkbox"
                            required=""
                            style={{
                              opacity: "0",
                              position: "absolute",
                              Zindex: "-1",
                            }}
                          />
                          <span
                            className="combine-form_checkbox-label w-form-label"
                            htmlFor="Contact-2-Checkbox"
                          >
                            I accept the{" "}
                            <Link to="/terms-services" target="_black" className="combine-form_link">
                              Terms of Service
                            </Link>{" "}
                            and{" "}
                            <Link to="/privacy-policy" target="_black" className="combine-form_link">
                              Privacy Policy
                            </Link>
                            .
                          </span>
                        </label> */}
                      </div>
                      {inputs.email.length &&
                      inputs.subject.length &&
                      inputs.message.length !== 0 ? (
                        <input
                          onClick={(e) => addContact(e)}
                          type="submit"
                          value="Send Message"
                          data-wait="Please wait..."
                          className="combine-button w-button"
                        />
                      ) : (
                        <input
                          // onClick={(e) => addContact(e)}
                          style={{ cursor: "default" }}
                          type="submit"
                          value="Send Message"
                          data-wait="Please wait..."
                          className="combine-button w-button"
                        />
                      )}
                    </div>
                    <div
                      className="combine-form_success-message-2 w-form-done"
                      tabIndex="-1"
                      role="region"
                      aria-label="Contact 2 Form success"
                    >
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div
                      className="combine-form_error-message-2 w-form-fail"
                      tabIndex="-1"
                      role="region"
                      aria-label="Contact 2 Form failure"
                    >
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="brix---footer-wrapper">
          <div className="brix---container-default w-container">
            <div className="brix---footer-top">
              <div className="w-layout-grid brix---grid-footer-v1">
                <div id="w-node-_4ba1bd23-3bf7-b8f8-c6a3-49685b2dc2e7-b342fa83">
                  <div className="brix---mg-bottom-24px">
                    <a
                      href="/landing#home"
                      className="brix---footer-logo-wrapper w-inline-block"
                    >
                      <img
                        style={{ width: "50%" }}
                        src="/images/ytb_beta_logo.svg"
                        alt="footer logo"
                        className="brix---footer-logo"
                      />
                    </a>
                  </div>
                  <div className="brix---mg-bottom-40px">
                    <div className="brix---color-neutral-600">
                      <p className="brix---paragraph-default">
                        {/* Lorem ipsum dolor sit amet consectetur adipiscing elit
                        aliquam mauris sed ma */}
                      </p>
                    </div>
                  </div>
                  <div className="w-layout-grid brix---social-media-grid-left">
                    <a
                      href="https://facebook.com/"
                      className="brix---icon-square-36px w-inline-block"
                    >
                      <img
                        src="images/facebook-social-media-icon-brix-templates.svg"
                        alt="Facebook - Elements Webflow Library - BRIX Templates"
                      />
                    </a>
                    <a
                      href="https://twitter.com/"
                      className="brix---icon-square-36px w-inline-block"
                    >
                      <img
                        src="images/twitter-social-media-icon-brix-templates.svg"
                        alt="Twitter - Elements Webflow Library - BRIX Templates"
                      />
                    </a>
                    <a
                      href="https://instagram.com/"
                      className="brix---icon-square-36px w-inline-block"
                    >
                      <img
                        src="images/instagram-social-media-icon-brix-templates.svg"
                        alt="Instagram - Elements Webflow Library - BRIX Templates"
                      />
                    </a>
                    <a
                      href="https://linkedin.com/"
                      className="brix---icon-square-36px w-inline-block"
                    >
                      <img
                        src="images/linkedin-social-media-icon-brix-templates.svg"
                        alt="Linkedin - Elements Webflow Library - BRIX Templates"
                      />
                    </a>
                    <a
                      href="https://youtube.com/"
                      className="brix---icon-square-36px w-inline-block"
                    >
                      <img
                        src="images/youtube-social-media-icon-brix-templates.svg"
                        alt="YouTube - Elements Webflow Library - BRIX Templates"
                      />
                    </a>
                  </div>
                </div>
                <div>
                  <div className="brix---footer-col-title">Product</div>
                  {
                    // eslint-disable-next-line
                  }{" "}
                  <ul className="brix---footer-list-wrapper">
                    <li className="brix---footer-list-item">
                      <a href="#feature-comp" className="brix---footer-link">
                        Features
                      </a>
                    </li>
                    <li className="brix---footer-list-item">
                      <a href="#pricings" className="brix---footer-link">
                        Pricing
                      </a>
                    </li>

                    <li className="brix---footer-list-item">
                      <a href="#testimonal" className="brix---footer-link">
                        Reviews
                      </a>
                    </li>
                  </ul>
                </div>

                <div>
                  <div className="brix---footer-col-title">Support</div>

                  <ul className="brix---footer-list-wrapper">
                    <li className="brix---footer-list-item">
                      <a href="#goto-top" className="brix---footer-link">
                        Getting started
                      </a>
                    </li>
                    <li className="brix---footer-list-item">
                      <Link
                        to="/privacy-policy"
                        target="_blank"
                        className="brix---footer-link"
                      >
                        Privacy Policy.
                      </Link>
                    </li>
                    <li className="brix---footer-list-item">
                      <Link
                        to="/terms-services"
                        target="_blank"
                        className="brix---footer-link"
                      >
                        Terms of Service
                      </Link>
                    </li>
                    <li className="brix---footer-list-item">
                      <Link
                        to="/limited-use-disclosure"
                        target="_blank"
                        className="brix---footer-link"
                      >
                        Limited Use Disclosure
                      </Link>
                    </li>
                  </ul>
                </div>
                <div></div>
              </div>
            </div>
            <div className="brix---footer-bottom">
              <p className="brix---paragraph-default">
                Copyright © 2023{" "}
                <a
                  href="https://www.ytubeboosterpro.app/"
                  className="brix---paragraph-link"
                >
                  YtubeBoosterpro.app
                </a>{" "}
                | All Rights Reserved |
              </p>
            </div>
          </div>
        </footer>
      </div>
      {/* [if lte IE 9]><![endif] */}
    </>
  );
};

export default LandingPage;
