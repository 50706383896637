import React from 'react'
import { Modal,Button } from 'rsuite'

const GraphModal = ({openGraph,handleCloseGraph}) => {
  return (
    <>
      <Modal
        backdrop="static"
        // keyboard={false}
        open={openGraph}
        onClose={handleCloseGraph}
      >
        <Modal.Header>
          <Modal.Title>Create New List</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container">
            kfkf
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseGraph} appearance="subtle">
            Cancel
          </Button>
          <Button
            // onClick={(e) => addList(e, saveInput, setSaveInput(""))}
            appearance="primary"
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default GraphModal