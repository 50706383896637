import React from 'react'
import { useState } from 'react';
import { Pagination, SelectPicker } from 'rsuite';

const Tags = ({tagsData,channelTitle}) => {
  const [active, setActive] = useState(1);
  const [limit, setLimit] = useState(50);
  const limitOptions = [10, 25, 50, 100];
  const startIdx = (active - 1) * limit;
  const endIdx = startIdx + limit;
  return (
    <div>
      <div className='d-flex justify-content-between' style={{marginTop:"20px"}}>
        <div>
        <h4>Most Used Tags of {channelTitle}</h4>
        <p>Potentially useful aggregate data on found videos.</p>
        </div>
        <div>
          <span>
            Show{" "}
            <SelectPicker
              value={limit}
              onChange={setLimit}
              cleanable={false}
              searchable={false}
              data={limitOptions.map((key) => ({ value: key, label: key }))}
            />{" "}
            Entries
          </span>
        </div>
        </div>
        <div className="containers-custom" style={{padding:"10px"}}>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Tag</th>
              <th scope="col">First used</th>
              <th scope="col">Last used</th>
              <th scope="col">Count</th>
            </tr>
          </thead>
          <tbody>
           {tagsData.slice(startIdx, endIdx).map((res,index) => (<tr key={index}>
              <th scope="row">{res.tag}</th>
              <td>{res.first_used}</td>
              <td>{res.last_used}</td>
              <td>{res.count}</td>
            </tr>))}
          </tbody>
        </table>
        <div className="d-flex justify-content-between">
          <div>
            <div
              style={{ padding: "20px" }}
              className="uk-card uk-card-default"
            >
              Showing page {active} of {tagsData.length} entries
            </div>
          </div>
          <div>
            <Pagination
              prev
              next
              first
              last
              size="md"
              maxButtons={10}
              page={Math.ceil(tagsData.length / limit)}
              limit={limit}
              total={tagsData.length}
              activePage={active}
              onChangePage={setActive}
              onChangeLimit={setLimit}
            />
          </div>
        </div>
        </div>
    </div>
  )
}

export default Tags