import React from 'react';
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import TextError from "../../../FormikControl/TextError";
import { useToaster } from "rsuite";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../Notification";
import {publicRequest} from '../../../api/axiosConfig/publicRequest'

const ForgotPassword = () => {
    const navigate = useNavigate();
    const toaster = useToaster();
    const log = () => {
        navigate('/login')
    }

    const initialValues = {
        email: "",
        // password: "",
        // A secure link to reset your password has been sent to your registered email id. Please access your email inbox and click on the link to reset/change your password.  
      }

      const validationSchema = Yup.object({
        email: Yup.string().required("Required*"),
        // password: Yup.string().required("Required*"),
        // confirm_password: Yup.string()
        //   .oneOf([Yup.ref("password"), null], "Passwords must match")
        //   .required("Required*"),
      });

      const sendLink = async (values) => {
          try{
              const data = await publicRequest.post(`/api/accounts/reset-email`,values);
              // console.log("forgotData",data);
              toaster.push(showSuccessNotification(data.data.success),{duration:"off"})

          }catch(err){
              console.log("err",err)
              toaster.push(showErrorNotification(err.response.data.error))
          }
// console.log(values)
      }
  return (
    <div className="container-fluide">
    <div className="d-flex for_background">
      <div
        className="container col-xl-7 col-lg-7 col-12"
        style={{ marginTop: "auto", marginBottom: "auto" }}
      >
        <div
          className="container-fluid"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className="f-account-container-l row"
            style={{ textAlign: "start" }}
          >
            <div className="col-12">
              <img alt="login" src="/images/YTBPRo.svg" width="178" />
              <br />
              <h5 className="d-header-welcome-username">Forgot/Reset Password</h5>
            </div>
            <div className="f-margin-bottom-25 col-12">
              <p className="enter-color">
                You can reset/change your password by entering your registered email in below.You will Receive a link to create a new password.
              </p>
            </div>
            <div className="col">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={sendLink}
              >
                {(formik) => {
                  return (
                    <Form>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                          style={{ float: "left" }}
                        >
                          Email
                        </label>
                        {/* <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                /> */}
                        <Field
                          type="email"
                          name="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                        <ErrorMessage name="email" component={TextError} />
                      </div>
                      {/* <div className="mb-3">
                        <label
                          htmlFor="exampleInputPassword1"
                          className="form-label"
                          style={{ float: "left" }}
                        >
                          Password
                        </label>
                        <Field
                          type="password"
                          name="password"
                          className="form-control"
                          id="exampleInputPassword1"
                          aria-describedby="emailHelp"
                        />
                        <ErrorMessage name="password" component={TextError} />
                      </div> */}

                      <div className="f-account-form-button-2">
                        <button
                          type="submit"
                          style={{ color: "#fff" }}
                          className="colors"
                        >
                            Send
                          {/* {isLogin === true ? "Loading" : "Login"} */}
                        </button>
                      </div>
                      <p
                        style={{ marginTop: "20px" }}
                        className="f-paragraph-small-2"
                      >
                        I have an account?{" "}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={log}
                          className="f-account-link-2"
                        >
                          Login
                        </span>
                      </p>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-lg-5 col-xl-5 d-none d-lg-block d-xl-block"
        style={{ height: "100vh" }}
      >
        <div className="f-account-image-wrapper-2">
          <div className="div-block-26">
            <div style={{ textAlign: "start" }}>
              <p className="paragraph-10">
                Welcome!!
                <br />
              </p>
              <h1 className="heading-13">
                YTubeBooster <span className="text-span-8">PRO</span>
              </h1>
              <p className="paragraph-9 mb-2">www.ytubeboosterpro.app</p>
              <div className="text-block-18">Tags</div>
              <div className="text-block-18">Videos</div>
              <div className="text-block-18">Channel Analytics</div>
              <div className="text-block-18">Views</div>
              <div className="text-block-18">Subscribers</div>
              <div className="text-block-18">and more...</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ForgotPassword