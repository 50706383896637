import React from 'react';
import {Notification} from 'rsuite'
  
  export const showSuccessNotification = (message) => {
    return <Notification type="success" header="Succcess" closable>
        <div style={{ width: 320 }}> {message}</div>
        </Notification>;
  };
  export const showErrorNotification = (message) => {
    return <Notification type="error" header="Error" closable>
        <div style={{ width: 320 }}> {message}</div>
        </Notification>;
  };
  