import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import TextError from "../../../FormikControl/TextError";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";
import { useToaster } from "rsuite";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../Notification";

const Profile = () => {
  const [profileName, setProfileName] = useState({});
  // const [open, setOpen] = useState(false);
  // const [mdata, setMData] = useState("");
  // const [channelDetails, setChannelDetails] = useState([]);
  // const [channelLtm, setChannelLtm] = useState([]);
  // const [enableDisable, setEnableDisable] = useState("");
  // const handleOpen = (data) => {
  //   setOpen(true);
  //   console.log("modalData", data);
  //   setMData(data);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleDelete = async (e, data) => {
  //   // console.log("deleteData",mdata);
  //   setOpen(false);
  //   try {
  //     await privateRequest.delete(`/api/youtube/channel-details/${mdata}/`);
  //     toaster.push(
  //       showSuccessNotification("You have successfully deleted the channel")
  //     );
  //     // console.log("deleted successfully",deleted)
  //   } catch (err) {
  //     toaster.push(showErrorNotification("Something went wrong"));
  //     // console.log("eoeroero",err)
  //   }
  //   setMData("");
  //   mapChannels();
  // };
  const toaster = useToaster();
  const initialValues = {
    first_name:
      profileName.first_name === undefined ? "" : profileName.first_name,
    last_name: profileName.last_name === undefined ? "" : profileName.last_name,
    email: profileName.email === undefined ? "" : profileName.email,
    // full_name: "",
    // email: "",
  };

  // const navigate = useNavigate();

  const profileData = async () => {
    try {
      const data = await privateRequest.get(
        `/api/accounts/userprofile/username/`
      );
      // console.log("profiledata", data.data[0]);
      setProfileName(data.data[0]);
    } catch (err) {
      console.log("err", err);
    }
  };

  const changePasswordInitialValue = {
    old_password: "",
    new_password: "",
    confirmpassword: "",
  };
  const changePasswordValidation = Yup.object({
    old_password: Yup.string().required("Required*"),
    // old_password: Yup.string().matches(regpass,"Minimum six characters, at least one uppercase letter, one lowercase letter, one number and one special character").required("Required*"),
    new_password: Yup.string().required("Required*"),
    confirmpassword: Yup.string()
      .oneOf([Yup.ref("new_password"), null], "Passwords must match")
      .required("Required*"),
  });

  const validationSchema = Yup.object({
    first_name: Yup.string().required("Required*"),
    last_name: Yup.string().required("Required*"),
  });

  const handleUpdate = async (values) => {
    // console.log("values", values);
    const peo = {
      first_name: values.first_name,
      last_name: values.last_name,
    };
    try {
      const updated = await privateRequest.put(
        `/api/accounts/userprofile/username/`,
        peo
      );
      // console.log("updated", updated.data.message);
      toaster.push(showSuccessNotification(updated.data.message));
    } catch (err) {
      console.log(err);
    }
    profileData();
  };
  const handleChangePassword = async (values) => {
    // console.log("values", values);
    const pass = {
      old_password: values.old_password,
      new_password: values.new_password,
    };
    try {
      const data = await privateRequest.put(
        `/api/accounts/userprofile/password/`,
        pass
      );
      // console.log("password",data.data.message)
      toaster.push(showSuccessNotification(data.data.message));
    } catch (err) {
      console.log("err", err.responce.data.old_password[0]);
      toaster.push(showErrorNotification(err.responce.data.old_password[0]));
    }
  };

  // const addChannel = () => {
  //   navigate("/connect");
  // };

  // const mapChannels = async () => {
  //   try {
  //     const { data } = await privateRequest.get(
  //       "/api/youtube/channel-details/"
  //     );
  //     setChannelDetails(data);
  //     console.log("dataYoutube", data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // const restriction = async () => {
  //   try {
  //     const data = await privateRequest.get(
  //       `/api/youtube/restrict-channel-access/`
  //     );
  //     console.log("restirction", data);
  //     setChannelLtm(data.data);
  //   } catch (err) {
  //     console.log("error", err);
  //   }
  // };

  // const enaDis = async () => {
  //   const data = await privateRequest.get(
  //     `/api/youtube/enable-disable-delete-button/`
  //   );
  //   console.log("enablesss", data.data["delete button"]);
  //   setEnableDisable(data.data["delete button"]);
  // };

  useEffect(() => {
    // enaDis();
    // restriction();
    profileData();
    // mapChannels();
  }, []);

  return (
    <div className="container-fluide mb-3">
      <h1 className="ais">Profile</h1>
      <div className="container">
        <div className="row gap-2">
          <div className="col-lg-12">
            <h2 className="f-tab-heading">Personal Details</h2>
            <div className="card-tools card">
              <div className="card-body">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleUpdate}
                  enableReinitialize={true}
                >
                  {(formik) => {
                    return (
                      <Form>
                        <div className="row">
                          <div className="mb-3 col-lg-6 col-md-6 col-12">
                            <label
                              htmlFor="exampleInputPassword1"
                              className="form-label"
                            >
                              First Name:
                            </label>
                            <Field
                              type="text"
                              name="first_name"
                              className="brix---input-large-button-inside w-input"
                              id="exampleInputPassword1"
                            />
                            <ErrorMessage
                              name="first_name"
                              component={TextError}
                            />
                          </div>
                          <div className="mb-3 col-lg-6 col-md-6 col-12">
                            <label
                              htmlFor="exampleInputPassword1"
                              className="form-label"
                            >
                              Last Name:
                            </label>
                            <Field
                              type="text"
                              name="last_name"
                              className="brix---input-large-button-inside w-input"
                              id="exampleInputPassword1"
                            />
                            <ErrorMessage
                              name="last_name"
                              component={TextError}
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-12">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label me-3"
                            >
                              Email:
                            </label>
                            <Field
                              type="email"
                              name="email"
                              className="brix---input-large-button-inside w-input"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                          <div>
                            <button type="submit" className="profile-buttons">
                              Update
                            </button>
                          </div>
                          <div></div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <h2 className="f-tab-heading">Change Password</h2>
            <div className="card-tools card ">
              <div className="card-body">
                <Formik
                  initialValues={changePasswordInitialValue}
                  validationSchema={changePasswordValidation}
                  onSubmit={handleChangePassword}
                  enableReinitialize={true}
                >
                  {(formik) => {
                    return (
                      <Form>
                        <div className="row">
                          <div className="mb-3 col-lg-4 col-md-4">
                            <div className="change-pass-align">
                              <label
                                htmlFor="exampleInputPassword1"
                                className="form-label me-3"
                              >
                                Current Password:
                              </label>
                              <Field
                                type="text"
                                name="old_password"
                                className="brix---input-large-button-inside w-input"
                                id="exampleInputPassword1"
                              />
                            </div>
                            <ErrorMessage
                              name="old_password"
                              component={TextError}
                            />
                          </div>
                          <div className="mb-3 col-lg-4 col-md-4">
                            <div className="change-pass-align">
                              <label
                                htmlFor="exampleInputPassword1"
                                className="form-label me-3"
                              >
                                New Password:
                              </label>
                              <Field
                                type="text"
                                name="new_password"
                                className="brix---input-large-button-inside w-input"
                                id="exampleInputPassword1"
                              />
                            </div>
                            <ErrorMessage
                              name="new_password"
                              component={TextError}
                            />
                          </div>
                          <div className="mb-3 col-lg-4 col-md-4">
                            <div className="change-pass-align">
                              <label
                                htmlFor="exampleInputPassword1"
                                className="form-label me-3"
                              >
                                Confirm Password:
                              </label>
                              <Field
                                type="text"
                                name="confirmpassword"
                                className="brix---input-large-button-inside w-input"
                                id="exampleInputPassword1"
                              />
                            </div>
                            <ErrorMessage
                              name="confirmpassword"
                              component={TextError}
                            />
                          </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-end">
                          <div>
                            <button type="submit" className="profile-buttons">
                              Update Password
                            </button>
                          </div>
                          <div></div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-12">
            <h2 className="f-tab-heading">Channel(s) Details</h2>
          </div>
          <div className="card-tools card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Sr.no</th>
                      <th scope="col">Channel Name</th>
                      <th scope="col">Date Added</th>
                      <th scope="col">Linked Youtube Channel</th>
                      <th scope="col">Delete Youtube Channel</th>
                    </tr>
                  </thead>
                  <tbody>
                    {channelDetails.map(
                      (
                        { channel_logo, channel_name, created_at, id },
                        index
                      ) => (
                        <tr key={id}>
                          <th scope="row">{index + 1}</th>
                          <td>{channel_name}</td>
                          <td>{created_at}</td>
                          <td>
                            <div className="prof-imgs">
                              <img
                                src={channel_logo}
                                alt="table1"
                                height="50px"
                              />
                            </div>
                          </td>
                          <td>
                            {enableDisable === "Disable" ? (
                              <button
                                disable
                                className="btn btn-danger"
                              >
                                Delete
                              </button>
                            ) : (
                              <button
                                onClick={() => handleOpen(id)}
                                className="btn btn-danger"
                              >
                                Delete
                              </button>
                            )}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
              {channelLtm === undefined ? (
                ""
              ) : channelLtm["Total channel Limit"] <=
                channelLtm["Number of Channel Present"] ? (
                ""
              ) : (
                <button onClick={addChannel} className="f-button-neutral-2">
                  Add Channels
                </button>
              )}
            </div>
          </div> */}
        </div>
      </div>
      {/* modal to delete the channels */}
      {/* <Modal backdrop="static" size="sm" open={open} onClose={handleClose}>
        <Modal.Header>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to delete this channel. You will not be able to add this channel again to your account for the next 90 days and is subject to slots being available based on your subscribed plan. In case of queries, please reach out to our Customer Support team from your dashboard</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="primary">
          No, I changed my mind
          </Button>
          <Button onClick={(e) => handleDelete(e, mdata)} color="red" appearance="primary">
          Yes, I want to delete anyway
          </Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
};

export default Profile;
