import React from "react";
import { Link } from "react-router-dom";

const Footers = () => {
  return (
    <footer className="brix---footer-wrapper">
      <div className="brix---container-default w-container">
        <div className="brix---footer-top">
          <div className="w-layout-grid brix---grid-footer-v1">
            <div id="w-node-_4ba1bd23-3bf7-b8f8-c6a3-49685b2dc2e7-b342fa83">
              <div className="brix---mg-bottom-24px">
                <a
                  href="/landing#home"
                  className="brix---footer-logo-wrapper w-inline-block"
                >
                  <img
                    style={{ width: "50%" }}
                    src="/images/YTBPRo.svg"
                    alt="footer logo"
                    className="brix---footer-logo"
                  />
                </a>
              </div>
              <div className="brix---mg-bottom-40px">
                <div className="brix---color-neutral-600">
                  <p className="brix---paragraph-default">
                    {/* Lorem ipsum dolor sit amet consectetur adipiscing elit
                        aliquam mauris sed ma */}
                  </p>
                </div>
              </div>
              <div className="w-layout-grid brix---social-media-grid-left">
                <a
                  href="https://facebook.com/"
                  className="brix---icon-square-36px w-inline-block"
                >
                  <img
                    src="images/facebook-social-media-icon-brix-templates.svg"
                    alt="Facebook - Elements Webflow Library - BRIX Templates"
                  />
                </a>
                <a
                  href="https://twitter.com/"
                  className="brix---icon-square-36px w-inline-block"
                >
                  <img
                    src="images/twitter-social-media-icon-brix-templates.svg"
                    alt="Twitter - Elements Webflow Library - BRIX Templates"
                  />
                </a>
                <a
                  href="https://instagram.com/"
                  className="brix---icon-square-36px w-inline-block"
                >
                  <img
                    src="images/instagram-social-media-icon-brix-templates.svg"
                    alt="Instagram - Elements Webflow Library - BRIX Templates"
                  />
                </a>
                <a
                  href="https://linkedin.com/"
                  className="brix---icon-square-36px w-inline-block"
                >
                  <img
                    src="images/linkedin-social-media-icon-brix-templates.svg"
                    alt="Linkedin - Elements Webflow Library - BRIX Templates"
                  />
                </a>
                <a
                  href="https://youtube.com/"
                  className="brix---icon-square-36px w-inline-block"
                >
                  <img
                    src="images/youtube-social-media-icon-brix-templates.svg"
                    alt="YouTube - Elements Webflow Library - BRIX Templates"
                  />
                </a>
              </div>
            </div>
            <div>
              <div className="brix---footer-col-title">Product</div>
              {
                // eslint-disable-next-line
              }{" "}
              <ul className="brix---footer-list-wrapper">
                <li className="brix---footer-list-item">
                  <a href="#feature-comp" className="brix---footer-link">
                    Features
                  </a>
                </li>
                <li className="brix---footer-list-item">
                  <a href="#pricings" className="brix---footer-link">
                    Pricing
                  </a>
                </li>

                <li className="brix---footer-list-item">
                  <a href="#testimonal" className="brix---footer-link">
                    Reviews
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <div className="brix---footer-col-title">Support</div>

              <ul className="brix---footer-list-wrapper">
                <li className="brix---footer-list-item">
                  <a href="#goto-top" className="brix---footer-link">
                    Getting started
                  </a>
                </li>
                <li className="brix---footer-list-item">
                  <Link
                    to="/privacy-policy"
                    target="_blank"
                    className="brix---footer-link"
                  >
                    Privacy Policy.
                  </Link>
                </li>
                <li className="brix---footer-list-item">
                  <Link
                    to="/terms-services"
                    target="_blank"
                    className="brix---footer-link"
                  >
                    Terms of Service
                  </Link>
                </li>
                <li className="brix---footer-list-item">
                  <Link
                    to="/limited-use-disclosure"
                    target="_blank"
                    className="brix---footer-link"
                  >
                    Limited Use Disclosure
                  </Link>
                </li>
              </ul>
            </div>
            <div></div>
          </div>
        </div>
        <div className="brix---footer-bottom">
          <p className="brix---paragraph-default">
            Copyright © 2023{" "}
            <a
              href="https://www.ytubeboosterpro.app/"
              className="brix---paragraph-link"
            >
              YtubeBoosterpro.app
            </a>{" "}
            | All Rights Reserved |
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footers;
