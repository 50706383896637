import React from "react";

const TopVideos = ({ channelVideoResponce,channelTitle }) => {
//   const [sortedData,setSortedData] = useState([])

  const sorted = [...channelVideoResponce].sort(
    (a, b) =>
      // const first = `${a?.total_credits_purchased}`;
      // const second = `${b?.total_credits_purchased}`;
      // return first.localeCompare(second)
      // a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      b["view"] - a["view"]
  );

  // console.log("sorted",sorted)
  return (
    <>
    <h4 style={{marginTop:"20px"}}>Top Most Trending Videos of {channelTitle}</h4>
      <p>Potentially useful aggregate data on found videos.</p>
    <div className="containers-custom uk-margin-bottom" style={{padding:"10px"}}>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Title</th>
            <th scope="col">Length</th>
            <th scope="col">Views</th>
            <th scope="col">Likes</th>
            <th scope="col">Comments</th>
            <th scope="col">Tag Count</th>
          </tr>
        </thead>
        <tbody>
          {sorted.slice(0, 10).map((res, index) => (
            <tr key={index}>
              <th scope="row">
              <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-youtube"
                  >
                    <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path>
                    <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon>
                  </svg>
              </th>
              <td className="table-title-data">{res.title}</td>
              <td>{res["length in seconds"]}</td>
              <td>{res.view}</td>
              <td>{res.likes}</td>
              <td>{res.comments_count}</td>
              <td>{res.tag_count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  );
};

export default TopVideos;
