import React, { useEffect, useRef, useState } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { tooltips } from "../Pages/StringStore";
import InfoOutlineIcon from "@rsuite/icons/InfoOutline";
import { Button, Modal, Tooltip, useToaster, Whisper } from "rsuite";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";
import { showSuccessNotification } from "../../../Notification";
import moment from "moment";

const Notes = () => {
  const editor = useRef();
  const [apple, setApple] = useState("");
  const [title, setTitle] = useState("");
  const [editorTypes, setEditorTypes] = useState(false);
  const [modeOpen, setModeOpen] = useState(false);
  const [addInput, setAddInput] = useState("");
  const [notesList, setNotesList] = useState([]);
  const [updatedDate, setUpdatedDate] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [modedelOpen, setModeDelOpen] = useState(false);

  const toaster = useToaster();

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
    // console.log("kfofk", sunEditor);
  };

  const handleOpneds = () => {
    setModeOpen(true);
  };
  const handleOpnedsDel = () => {
    setModeDelOpen(true);
  };

  const handleCloseds = () => {
    setModeOpen(false);
  };
  const handledelCloseds = () => {
    setModeDelOpen(false);
  };

  const handleThis = (content) => {
    // console.log("thiss", content);
    setApple(content);
  };

  const addYourNotes = async () => {
    // console.log("addYourNotes", apple, addInput);
    const postData = {
      name: addInput,
      add_notes: apple,
    };
    try {
       await privateRequest.post(
        `/api/accounts/notes-get-create/`,
        postData
      );
      // console.log("notes is added", added);
      setModeOpen(false);
      editor.current.setContents("");
      setEditorTypes(false);
      setApple("");
      toaster.push(showSuccessNotification("Operation completed successfully: Created the notes"));
    } catch (err) {
      console.log("err", err);
    }
    getNotes();
  };

  const updateNotes = async () => {
    const updateData = {
      name: title,
      add_notes: apple,
    };
    try {
       await privateRequest.put(
        `/api/accounts/notes/${updateId}/`,
        updateData
      );
      // console.log("updates", update);
      toaster.push(showSuccessNotification("Operation completed successfully: Updated the notes."));
    } catch (err) {
      console.log(err);
    }
    getNotes();
  };

  const handleChange = (data) => {
    // console.log("addedvalue", data);
    setEditorTypes(true);
    setTitle(data.name);
    setApple(data.add_notes);
    setUpdateId(data.id);
    setUpdatedDate(data.updated_at);
  };

  const addNew = () => {
    // console.log("editor.current",editor.current)
    editor.current.setContents("");
    setEditorTypes(false);
    setApple("");
  };

  const getNotes = async () => {
    try {
      const notesData = await privateRequest.get(
        `/api/accounts/notes-get-create/`
      );
      // console.log("notesData", notesData.data);
      setNotesList(notesData.data);
    } catch (err) {
      console.log("notesError", err);
    }
  };

  const parseFirstTwoLines = (html) => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(html, "text/html");
  
    var lines = [];
    var lineElements = doc.body.innerText.split("\n");
    for (var i = 0; i < 2 && i < lineElements.length; i++) {
      var line = lineElements[i].trim();
      if (line !== "") {
        lines.push(line);
      }
    }
  
    return lines.join("\n");
  }

  const deleteNotes = async (data) => {
    // console.log("id", updateId);
    handledelCloseds();
    setEditorTypes(false);
    try {
      await privateRequest.delete(`/api/accounts/notes/${updateId}/`);
      toaster.push(showSuccessNotification("You have successfully deleted the item."));
    } catch (err) {
      console.log("deletErr", err);
    }
    editor.current.setContents("");
    getNotes();
    setTitle("");
    setApple("");
    setAddInput("");
  };

  useEffect(() => {
    getNotes();
  }, []);

  return (
    <>
      <div className="ais d-flex justify-content-between">
        <div className="div-block-10">
          <h1 className="heading-5">
            Note Taking
            <span className="information ms-1">
              <Whisper
                placement="bottomEnd"
                controlId="control-id-click"
                trigger="hover"
                speaker={<Tooltip>{tooltips.notes}</Tooltip>}
              >
                <InfoOutlineIcon
                  style={{ marginBottom: "12px", color: "#211f54" }}
                />
              </Whisper>
            </span>
          </h1>
        </div>
        <button
          className="f-button-neutral-2 w-button"
          onClick={addNew}
          style={{
            backgroundColor: "#5167f6",
            border: "1px solid",
            fontWeight: "600",
            // float:"right"
          }}
          // type="submit"
        >
          ADD NOTES
        </button>
      </div>

      <div className="container-fluid notes-section">
        {notesList.length === 0 ? (
          <div className="columns-9 w-row">
            <div className="w-col-9 w-col" style={{ width: "100%" }}>
              <div className="column-14">
                {editorTypes === false ? (
                  <>
                    <SunEditor
                      setOptions={{
                        buttonList: [
                          ["font", "fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["align", "horizontalRule", "list", "table"],
                          ["fontColor", "hiliteColor"],
                          ["outdent", "indent"],
                          ["undo", "redo"],
                          ["removeFormat"],
                          ["outdent", "indent"],
                          ["link", "image"],
                          ["preview", "print"],
                          ["fullScreen", "showBlocks", "codeView"],
                        ],
                      }}
                      getSunEditorInstance={getSunEditorInstance}
                      placeholder="add your notes"
                      // setContents={apple}
                      height="50vh"
                      autoFocus={true}
                      onChange={handleThis}
                    />
                    <button
                      className="addnote uk-margin-top"
                      onClick={handleOpneds}
                    >
                      Save
                    </button>
                  </>
                ) : (
                  <>
                    <div className="fortextheader d-flex justify-content-between align-items-center uk-margin-small-bottom">
                      <h4
                        style={{
                          fontSize: "24px",
                          marginLeft: "10px",
                          marginRight: "8px",
                        }}
                      >
                        {title}
                      </h4>
                      <p style={{ fontSize: "12px", paddingRight: "10px" }}>
                        Last Edited on{" "}
                        {moment(updatedDate).format("Do dddd YYYY")}
                      </p>
                    </div>
                    <SunEditor
                      setOptions={{
                        buttonList: [
                          ["font", "fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["align", "horizontalRule", "list", "table"],
                          ["fontColor", "hiliteColor"],
                          ["outdent", "indent"],
                          ["undo", "redo"],
                          ["removeFormat"],
                          ["outdent", "indent"],
                          ["link", "image"],
                          ["preview", "print"],
                          ["fullScreen", "showBlocks", "codeView"],
                        ],
                      }}
                      getSunEditorInstance={getSunEditorInstance}
                      setContents={apple}
                      height="50vh"
                      autoFocus={true}
                      onChange={(e) => handleThis(e)}
                    />
                    <button
                      className="addnote uk-margin-top"
                      onClick={updateNotes}
                    >
                      Update
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="columns-9 w-row">
            <div
              className="w-col w-col-3 "
              style={{
                maxHeight: "652px",
                overflow: "hidden",
              }}
            >
              {/* <button onClick={addNew} className="addnote">
              Add Note
            </button> */}

              <div
                className="div-block-40"
                style={{
                  // overflow: "scroll",
                  position: "sticky",
                  maxHeight: "595px",
                }}
              >
                <ul className="list-8">
                  {notesList.map((res, index) => (
                    <li
                      className="d-flex"
                      style={{alignItems:"baseline",cursor: "pointer",borderBottom:"1px solid #DADADA"}}
                      key={res.id}
                      onClick={() => handleChange(res)}
                    >
                      <div className="mt-2 me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-book"
                        >
                          <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path>
                          <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path>
                        </svg>
                      </div>
                      <div className="notes-list-style">
                        <h4 className="notes-list-heading">{res.name}</h4>
                        <p className="notes-list-subtitle">{parseFirstTwoLines(res.add_notes).slice(0, 20).concat("...")}</p>
                      </div>
                      <div className="mt-2">
                        <svg
                          onClick={handleOpnedsDel}
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-trash"
                        >
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        </svg>
                      </div>
                      
                      {/* </div> */}
                    </li>
                    
                  ))}
                </ul>
              </div>
            </div>
            <div className="w-col-9 w-col">
              <div className="column-14">
                {editorTypes === false ? (
                  <>
                    <SunEditor
                      setOptions={{
                        buttonList: [
                          ["font", "fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["align", "horizontalRule", "list", "table"],
                          ["fontColor", "hiliteColor"],
                          ["outdent", "indent"],
                          ["undo", "redo"],
                          ["removeFormat"],
                          ["outdent", "indent"],
                          ["link", "image"],
                          ["preview", "print"],
                          ["fullScreen", "showBlocks", "codeView"],
                        ],
                      }}
                      getSunEditorInstance={getSunEditorInstance}
                      placeholder="add your notes"
                      // setContents={apple}
                      height="50vh"
                      autoFocus={true}
                      onChange={handleThis}
                    />
                    <button
                      className="addnote uk-margin-top"
                      onClick={handleOpneds}
                    >
                      Save
                    </button>
                  </>
                ) : (
                  <>
                    <div className="fortextheader d-flex justify-content-between align-items-center uk-margin-small-bottom">
                      <h4
                        style={{
                          fontSize: "24px",
                          marginLeft: "10px",
                          marginRight: "8px",
                        }}
                      >
                        {title}
                      </h4>
                      <p style={{ fontSize: "12px", paddingRight: "10px" }}>
                        Last Edited on{" "}
                        {moment(updatedDate).format("Do dddd YYYY")}
                      </p>
                    </div>
                    <SunEditor
                      setOptions={{
                        buttonList: [
                          ["font", "fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["align", "horizontalRule", "list", "table"],
                          ["fontColor", "hiliteColor"],
                          ["outdent", "indent"],
                          ["undo", "redo"],
                          ["removeFormat"],
                          ["outdent", "indent"],
                          ["link", "image"],
                          ["preview", "print"],
                          ["fullScreen", "showBlocks", "codeView"],
                        ],
                      }}
                      getSunEditorInstance={getSunEditorInstance}
                      setContents={apple}
                      height="50vh"
                      autoFocus={true}
                      onChange={(e) => handleThis(e)}
                    />
                    <button
                      className="addnote uk-margin-top"
                      onClick={updateNotes}
                    >
                      Update
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {/* add notes modal */}
        <Modal
          backdrop="static"
          keyboard={false}
          open={modeOpen}
          onClose={handleCloseds}
        >
          <Modal.Header>
            <Modal.Title>Add notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              className="form-control"
              type="text"
              value={addInput}
              onChange={(e) => setAddInput(e.target.value)}
              placeholder="Enter your Heading"
            />
            {/* <Input  type="text" value={addInput} onChange={(e) => setAddInput(e.target.value)} placeholder="Enter your Heading" /> */}
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: "#160042", color: "#fff" }}
              onClick={handleCloseds}
              appearance="subtle"
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "#160042", color: "#fff" }}
              onClick={addYourNotes}
              appearance="subtle"
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        {/* delete notes modal */}
        <Modal
          backdrop="static"
          keyboard={false}
          open={modedelOpen}
          onClose={handledelCloseds}
        >
          <Modal.Header>
            <Modal.Title>Delete notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6>Are you sure you want to delete this item? This action is irreversible.</h6>
            {/* <Input  type="text" value={addInput} onChange={(e) => setAddInput(e.target.value)} placeholder="Enter your Heading" /> */}
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: "#160042", color: "#fff" }}
              onClick={handledelCloseds}
              appearance="subtle"
            >
              NO
            </Button>
            <Button
              style={{ backgroundColor: "#160042", color: "#fff" }}
              onClick={deleteNotes}
              appearance="subtle"
            >
              YES
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Notes;
