import * as actionTypes from "../actionTypes";

export const loginActionStart = (userDetails) => {
    // console.log("userDetails", userDetails);
    return {
      type: actionTypes.LOGIN_START,
      payload: userDetails,
    };
  };
  
  export const loginActionSuccess = (user) => {
    // showSuccessNotification(user, configToast);
    // console.log("userre", user);
    return {
      type: actionTypes.LOGIN_SUCCESS,
      payload: user,
    };
  };
  
  export const loginActionFaild = (error) => {
    console.log("errorAction", error);
    // const navigate = useNavigate();
    // showErrorNotification(error, configToast);
    // navigate('/login')
    return {
      type: actionTypes.LOGIN_FAILD,
      payload: error,
    };
  };
  
  export const logoutAction = () => {
    return {
      type: actionTypes.LOGOUT,
    };
  };
  