import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { youtubeConnDataFetchStart } from "../../../redux/actions/youtubeAction";

const ChannelConnect = () => {
  const dispatch = useDispatch();
  const { youtubeData } = useSelector((state) => state.youtube);
  const handleData = () => {
    dispatch(youtubeConnDataFetchStart());
  };
  // console.log("youtubeData",youtubeData.length)
  useEffect(() => {
    handleData();
  }, []);
  return (
    <div className="container-fluid cf-pricing-hero-section" id="connects-back">
      <div className="d-flex justify-content-center">
        <div style={{ padding: "29px" }} className="cf-pricing-box-2 green-pricing-box" id="card-back">
          <div className="cf-pricing-box-plan-wrapper-2 black-border">
            <div className="cf-pricing-plan-text-2">Connect Channel</div>
          </div>
          <div className="cf-pricing-plan-content-wrapper-2">
            <div className="cf-pricing-plan-content-item-2">
              <img
                src="images/1.svg"
                loading="lazy"
                alt=""
                className="cf-pricing-plan-tick-icon-2"
              />
              <div className="cf-pricing-plan-content-text-2">
                You will need to use the primary Google email id which was used
                to create your channel on YouTube.
              </div>
            </div>
            <div className="cf-pricing-plan-content-item-2">
              <img
                src="images/1.svg"
                loading="lazy"
                alt=""
                className="cf-pricing-plan-tick-icon-2"
              />
              <div className="cf-pricing-plan-content-text-2">
                Please make sure that you give access to YTubeBooster app, if
                your email has 2-factor authentication / additional security
                protocols enabled.
              </div>
            </div>
            <div className="cf-pricing-plan-content-item-2">
              <img
                src="images/1.svg"
                loading="lazy"
                alt=""
                className="cf-pricing-plan-tick-icon-2"
              />
              <div className="cf-pricing-plan-content-text-2">
                When prompted please authenticate YTubeBooster app to avoid any
                issues while connecting the channel.
              </div>
            </div>
            {/* <div className="cf-pricing-plan-content-item-2">
                        <img
                          src="images/2.svg"
                          loading="lazy"
                          alt=""
                          className="cf-pricing-plan-tick-icon-2"
                        />
                        <div className="cf-pricing-plan-content-text-2">
                          Integrations with Figma, Jira, Dropbox
                        </div>
                      </div> */}
          </div>
          {/*                    
                    <a href={youtubeData} rel="noreferrer noopener">
    <p className="cf-primary-button-2 pricing-button w-buttons">Connect Channel</p>
    </a> */}
          {youtubeData.length === undefined ?
           <p
           style={{ background: "#fff",fontWeight:"700",border:"1px solid #D9D9D9", padding: "6px 0px", color: "#282828" }}
           className="cf-primary-button-2 pricing-button w-buttons"
         // onClick={(e) => handlePricing(e, "m")}
         >
           <img style={{ marginRight: "6px" }} alt="youtube" src="/images/youtube_social_icon_white.png" />
           Connect To Your YouTube Channel
         </p>
            :
            
            <a
              href={youtubeData}
              rel="noreferrer noopener"
              // className="cf-primary-button-2 pricing-button"
              id="butns-conn"
            // onClick={(e) => handlePricing(e, "m")}
            >
              <img style={{ marginRight: "6px" }} alt="youtube" src='/images/youtube_social_icon_white.png' />
              Connect To Your YouTube Channel
            </a>
            
          }
        </div>
      </div>
      {/* <p>ChannelConnect</p>
        <a href={youtubeData} rel="noreferrer noopener">
    <button className="btn btn-success mt-1">Connect Channel</button>
    </a> */}
    </div>
  );
};

export default ChannelConnect;
