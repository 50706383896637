import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal, Pagination, SelectPicker, toaster } from "rsuite";
import { adminPrivateRequest } from "../../../api/axiosConfig/adminPrivateRequest";
import moment from "moment";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../Notification";
import { ProgressBar } from "react-loader-spinner";
// import { privateRequest } from "../../../api/axiosConfig/privateRequest";

const AdminUsers = () => {
  const [usersInfo, setUserInfo] = useState([]);
  const [active, setActive] = useState(1);
  const [open, setOpen] = useState(false);
  // const [pageNumber, setPageNumber] = useState(5);
  const [loading, setLoading] = useState(false)
  const [limit, setLimit] = useState(5);
  const [searches, setSearches] = useState("");
  const [fliptered, setFliptered] = useState("");
  const [mdata, setMData] = useState("");
  const [upFilter, setUpFilter] = useState("");
  const [openUse, setOpenUse] = useState(false);
  const [channelDetails, setChannelDetils] = useState([]);
  const [channelHistory, setChannelHistory] = useState([]);
  const [couponsDetails, setCouponsDetails] = useState([]);

  const limitOptions = [5, 10, 20];
  const startIdx = (active - 1) * limit;
  const endIdx = startIdx + limit;

  const pageChanged = (data) => {
    setActive(1);
    setLimit(data);
  }

  // Function to convert keywords to CSV format
  const convertToCSV = (keywords) => {
    console.log("check the users", keywords);
    const csvContent =
      keywords === undefined
        ? ""
        : keywords
          .map(
            (keyword, index) =>
              `${keyword.id}, ${keyword.first_name} ${keyword.last_name},${keyword.email},${keyword.is_verified},${keyword.created_at},${keyword.status}`
          )
          .join("\n");
    // const csvContent = "maians"
    // keywords
    //   .map((keyword, index) => `"${keyword}"`)
    //   .join("\n");
    return `id.,fullname,email,is_verified,created_at,status\n${csvContent}`;
  };

  // Convert keywords to CSV format
  const csvData = convertToCSV(usersInfo);

  const downloadCSV = () => {
    const element = document.createElement("a");
    const file = new Blob([csvData], { type: "text/csv" });
    element.href = URL.createObjectURL(file);
    element.download = `users.csv`;
    document.body.appendChild(element);
    element.click();
  };

  // console.log("upfiler", upFilter);
  const handleOpen = (data) => {
    setOpen(true);
    console.log("modalData", data);
    setMData(data);
  };

  const handleOpenUse = async (data) => {
    console.log("channels", data);
    setOpenUse(true);
    setChannelDetils(data);
    setLoading(true)
    try {
      const history = await adminPrivateRequest.get(
        `/api/admin_panel/channel-history/${data.id}/`
      );
      console.log("history", history.data);
      setChannelHistory(history.data);
    } catch (err) {
      console.log(err);
    }
    try {
      const couponsDeta = await adminPrivateRequest.get(
        `/api/admin_panel/coupon-details/${data.id}/`
      );
      console.log("coponsDeta", couponsDeta.data);
      setCouponsDetails(couponsDeta.data);
    } catch (err) {
      console.log("errCoupons", err);
    }
    setLoading(false)
  };

  const handleCloseUsers = () => setOpenUse(false);
  const handleClose = () => setOpen(false);

  const deleteUsers = async (data) => {
    // console.log("deleteData",data)
    try {
      await adminPrivateRequest.delete(`/api/admin_panel/users/${data}/`);
      // console.log("User Deleted Successfully");
      handleClose();
      toaster.push(showSuccessNotification("User Deleted Successfully"));
    } catch (err) {
      console.log("err", err);
      handleClose();
      toaster.push(showErrorNotification("Something went wrong"));
    }
    getUsersData();
  };

  const handleSearches = (e) => {
    e.preventDefault();
    setFliptered(searches);
    console.log("usersInfo", usersInfo.length);
  };

  // const pageInfo = (data) => {
  //   setPageNumber(data);
  //   setActive(1);
  // };

  const getUsersData = async () => {
    setLoading(true)
    try {
      const data = await adminPrivateRequest.get(`/api/admin_panel/users/`);
      console.log("data", data.data);
      setUserInfo(data.data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false)
  };

  useEffect(() => {
    getUsersData();
  }, []);
  return (
    <div>
      <h1>User&nbsp;</h1>
      <div className="uk-child-width-1-1@m">
        <div className="uk-card uk-card-default uk-card-body">
          <div className="d-flex justify-content-between align-items-center mt-3">
            <button className="btn btn-sm btn-primary" onClick={downloadCSV}>
              Download Csv
            </button>
            <div className="d-flex">
              <h4 className="heading-14" style={{ textAlign: "start" }}>
                Results:&nbsp;
              </h4>
              {/* <Dropdown title={pageNumber}>
                <Dropdown.Item onClick={() => pageInfo(5)}>5</Dropdown.Item>
                <Dropdown.Item onClick={() => pageInfo(10)}>10</Dropdown.Item>
                <Dropdown.Item onClick={() => pageInfo(20)}>20</Dropdown.Item>
              </Dropdown> */}
              <span>
                Show{" "}
                <SelectPicker
                  value={limit}
                  onChange={pageChanged}
                  cleanable={false}
                  searchable={false}
                  data={limitOptions.map((key) => ({ value: key, label: key }))}
                />{" "}
                Entries
              </span>
            </div>
            <div className="d-flex align-items-center">
              <h6>Filter:&nbsp;</h6>
              <Dropdown title={upFilter.length === 0 ? "Filter" : upFilter}>
                <Dropdown.Item onClick={() => setUpFilter("Active")}>
                  Active
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setUpFilter("Cancelled")}>
                  Cancelled
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setUpFilter("Unverified")}>
                  UnVerified
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setUpFilter("YT Connected")}>
                  YT Connected
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setUpFilter("YT Not Connected")}>
                  YT Not Connected
                </Dropdown.Item>
              </Dropdown>
            </div>
            <form
              onSubmit={handleSearches}
              className="d-flex justify-content-end"
              style={{ width: "35%" }}
            >
              <input
                className="form-control me-2"
                onChange={(e) => setSearches(e.target.value)}
                value={searches}
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#f4f4f6",
                  border: "none",
                  paddingLeft: "20px",
                  maxWidth: "300px",
                  width: "100%",
                }}
                type="search"
                placeholder="Search your videos"
                aria-label="Search"
              />

              <button
                className="f-button-neutral-2 w-button"
                style={{
                  backgroundColor: "#5167f6",
                  border: "1px solid",
                  fontWeight: "600",
                }}
                type="submit"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-search"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
              </button>
            </form>
          </div>
          <div className="uk-margin-top">
            <table className="table table-striped">
              <thead className="table-header">
                <tr>
                  <th>FullName</th>
                  <th>Email Id</th>
                  <th>Regd Date</th>
                  {/* <th>Verified</th> */}
                  <th>Plans</th>
                  <th>Channel</th>
                  <th>Total Credits Purchased</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading === true ?
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="d-flex align-items-center justify-content-end">
                      LOADING &nbsp;
                      <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        // wrapperStyle={{}}
                        wrapperClass="progress-bar-wrapper"
                        borderColor="#53389e"
                        barColor="#53389e"
                      />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  : usersInfo
                    // eslint-disable-next-line
                    .filter((vdo) => {
                      if (
                        vdo.first_name
                          .toLowerCase()
                          .includes(fliptered.toLowerCase()) ||
                        vdo.email.toLowerCase().includes(fliptered.toLowerCase())
                      ) {
                        return vdo;
                      }
                    })
                    // eslint-disable-next-line
                    .filter((results) => {
                      if (
                        results.status
                          .toLowerCase()
                          .includes(upFilter.toLowerCase())
                      ) {
                        return results;
                      }
                    })
                    .slice(startIdx, endIdx)
                    .map((res) => (
                      <tr style={{ height: "50px" }} key={res.id}>
                        <td>{res.first_name} {res.last_name}</td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOpenUse(res)}
                        >
                          {res.email}
                        </td>
                        <td>{moment(res.created_at).format(" D-MM-YY")}</td>
                        {/* <td>
                          {res.is_verified === true
                            ? "Verified User"
                            : "unVerified Users"}
                        </td> */}
                        <td>
                          {res.user_subscription[0].plan_name === "acorn-yearly-test" ? "Acorn(Y)" : res.user_subscription[0].plan_name === "acorn-monthly-test" ? "Acorn(M)" : res.user_subscription[0].plan_name === "palm-monthly-test" ? "Palm(M)" : res.user_subscription[0].plan_name === "plam-yearly-test" ? "Palm(Y)" : res.user_subscription[0].plan_name === "oak-monthly-test" ? "Oak(M)" : res.user_subscription[0].plan_name === "oak-yearly-test" ? "Oak(Y)" : "Not Subscribed"}
                        </td>
                        <td>{res.user_youtube_account.length}</td>
                        <td>
                          {res.additional_credits[0] === undefined
                            ? 0
                            : res.additional_credits[0].credits}
                        </td>
                        <td>{res.status}</td>

                        <td>
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-trash-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleOpen(res.id)}
                            >
                              <polyline points="3 6 5 6 21 6"></polyline>
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                              <line x1="10" y1="11" x2="10" y2="17"></line>
                              <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                          </div>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
              <div>
                <div
                  style={{ padding: "20px" }}
                  className="uk-card uk-card-default"
                >
                  Showing page {active} of {usersInfo.length}
                </div>
              </div>
              <div>
                <Pagination
                  prev
                  next
                  first
                  last
                  size="md"
                  maxButtons={10}
                  page={Math.ceil(usersInfo.length / limit)}
                  limit={limit}
                  total={usersInfo.length}
                  activePage={active}
                  onChangePage={setActive}
                  onChangeLimit={setLimit}
                />
              </div>
            </div>
          </div>
          <div className="uk-margin-top uk-card uk-card-default uk-card-body">
            <li>Unverified - Account not verified</li>
            <li>YT Not Connected - Verified, Subscribed but YT channel not connected</li>
            <li>Trial period - In Trial period</li>
            <li>Active - YT connected and accessed Dashboard with Active Subscription</li>
            <li>Cancelled(T) - If Subscription not continued after Trial</li>
            <li>Cancelled(S) - Cancelled during Active Subscription</li>
          </div>
        </div>
      </div>
      <Modal size="xs" open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 14 14"
              fill="#2196f3"
              aria-hidden="true"
              focusable="false"
              class="rs-icon"
              aria-label="info"
              data-category="legacy"
            >
              <path d="M7 14A7 7 0 117 0a7 7 0 010 14zm0-9.333a1.167 1.167 0 100-2.334 1.167 1.167 0 000 2.334zm0 1.166c-.644 0-1.167.522-1.167 1.167v3.5a1.167 1.167 0 002.334 0V7c0-.644-.522-1.167-1.167-1.167z"></path>
            </svg>{" "}
            Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to delete</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            No
          </Button>
          <Button onClick={() => deleteUsers(mdata)} appearance="primary">
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* modal for to see channel info */}
      <Modal size="full" open={openUse} onClose={handleCloseUsers}>
        <Modal.Header>
          <Modal.Title>
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 14 14"
              fill="#2196f3"
              aria-hidden="true"
              focusable="false"
              class="rs-icon"
              aria-label="info"
              data-category="legacy"
            >
              <path d="M7 14A7 7 0 117 0a7 7 0 010 14zm0-9.333a1.167 1.167 0 100-2.334 1.167 1.167 0 000 2.334zm0 1.166c-.644 0-1.167.522-1.167 1.167v3.5a1.167 1.167 0 002.334 0V7c0-.644-.522-1.167-1.167-1.167z"></path>
            </svg>{" "}
            Users Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: "18px", fontWeight: "500" }}>
            User Youtube Account
          </p>
          {channelDetails.user_youtube_account === undefined
            ? ""
            : channelDetails.user_youtube_account.map((res) => (
              <div className="card mt-2">
                <div className="card-body">
                  <h4>Channel number:{res.multichannel}</h4>
                  <h5>Channel Name:{res.channel_name}</h5>
                  <h5>
                    Created At: &nbsp;{res.created_at === null ? "Data is not Present" : moment(res.created_at).format("DD-MM-YY")}
                  </h5>
                </div>
              </div>
            ))}

          <p style={{ fontSize: "18px", fontWeight: "500" }}>
            User Subscriptions
          </p>
          {channelDetails.user_subscription === undefined
            ? ""
            : channelDetails.user_subscription.map((res) => (
              <div className="card">
                <div className="card-body">
                  <h4>Plan Name:&nbsp;{res.plan_name}</h4>
                  <h5>
                    Starts at:&nbsp;{res.starts_at === null ? "Data is not Present" : moment(res.starts_at).format("DD-MM-YY")}
                  </h5>
                  <h5>
                    Next billing Date: &nbsp;
                    {res.next_billing_date === null ? "Data is not Present" : moment(res.next_billing_date).format("DD-MM-YY")}
                  </h5>
                  <h5>
                    Cancelled Date: &nbsp;
                    {res.canceled_date === null
                      ? "Data is not Present"
                      : moment(res.canceled_date).format("DD-MM-YY")}
                  </h5>
                  <h5>
                    Trail Expiry Date: &nbsp;
                    {res.trial_expiry_date === null ? "Data is not Present" : moment(res.trial_expiry_date).format("DD-MM-YY")}
                  </h5>
                  <h5>Credits: &nbsp;{res.credits === null ? "Data is not Present" : res.credits}</h5>
                  <h5>
                    Created At: &nbsp;{res.created_at === null ? "Data is not Present" : moment(res.created_at).format("DD-MM-YY")}
                  </h5>
                  <h5>GateWay Name: &nbsp;{res.gateway_name === null ? "Data is not Present" : res.gateway_name}</h5>
                  <h5>Status: &nbsp;{res.status === null ? "Data is not Present" : res.status}</h5>
                </div>
              </div>
            ))}

          <p
            className="uk-margin-top"
            style={{ fontSize: "18px", fontWeight: "500" }}
          >
            User Channel Delete History
          </p>

          {channelHistory.length === 0
            ? "Noting is Deleted"
            : channelHistory.map((res, index) => (
              <div key={index} className="card mt-2">
                <div className="card-body">
                  <h5>Channel Name:{res.channel_name}</h5>
                  <h5>
                    Deleted at:{moment(res.created_at).format("DD-MM-YY")}
                  </h5>
                </div>
              </div>
            ))}

          <p
            className="uk-margin-top"
            style={{ fontSize: "18px", fontWeight: "500" }}
          >
            User Coupons Details
          </p>
          {couponsDetails.length === 0
            ? "No Coupons Applied"
            : couponsDetails.map((res, index) => (
              <div className="card mt-2">
                <div className="card-body">
                  <h4>Coupon Name:{res.coupon_name}</h4>
                  <h5>Coupon Code:{res.copon_code}</h5>
                  <h5>Maximum Redemption{res.maximum_redemption}</h5>
                  <h5>Used Redemption{res.used_redemption}</h5>
                  <h5>
                    Created at:{moment(res.created_at).format("DD-MM-YY")}
                  </h5>
                  <h5>
                    Valid upto:{moment(res.valid_upto).format("DD-MM-YY")}
                  </h5>
                </div>
              </div>
            ))}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AdminUsers;
