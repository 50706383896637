import React, { useEffect, useState } from "react";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";
import { priceTag } from "../Pages/StringStore";
import PlaneDetails from "../Pages/PlaneDetails";

const AppSumoSubscription = () => {
    const [price, setPrice] = useState("");
    const [togged, setTogged] = useState(false);
    // const toaster = useToaster()
    // const [planInfo,setPlanInfo] = useState("")
    // const plan_info = async () => {
    //   const plan = await privateRequest.get(`/api/payments/plan-info/`);
    //   console.log("plan",plan.data[0].plan_info)
    //   // setPlanInfo(plan.data[0].plan_info)
    //  await subscriptions(plan.data[0].plan_info)
    // }
    const handleOpen = (data) => {
      // console.log("monthsss",data)
      setPrice(data);
    };
    const takeToggle = (data) => {
      if (togged === false) {
        setTogged(true);
      } else {
        setTogged(false);
      }
    };
    const subscriptions = async (pl) => {
      // console.log("priceisjdffj", price);
      const getPayload = {
        billing_period: price,
      };
      try {
        const data = await privateRequest.post(
          `/api/payments/checkout-session/`,
          getPayload
        );
        // console.log("paymentData", data);
        window.location.href = data.data.billing_url;
      } catch (err) {
        console.log("errors", err);
      }
    };
  
    const palne = async () => {
      // setPrice(priceTag.sumoAcornY);
      // const pricedata = priceTag.sumoAcornY
      try {
        const data = await privateRequest.get(`/api/payments/plan-info/`);
        // console.log("data", data.data[0].plan_info);
        const pricedata = data.data[0].plan_info;
        //   setPlanInfo(data.data[0].plan_info);
        setPrice(data.data[0].plan_info);
        toggleAuto(data.data[0].plan_info)
        // setPrice("option2")
        if (
          pricedata === priceTag.sumoAcornY ||
          pricedata === priceTag.sumoPalmY ||
          pricedata === priceTag.sumoOackY
        ) {
          // console.log("true toggle")
          setTogged(true);
        } else {
          // console.log("false toggle")
          setTogged(false);
        }
      } catch (err) {
        // console.log("dataerr", err);
      }
    };
    const toggleAuto = (sls) => {
      // console.log("autoTogged", sls);
      if (sls === priceTag.sumoAcornM || sls === priceTag.sumoPalmM || sls === priceTag.sumoOackM) {
        setTogged(false);
      } else {
        setTogged(true);
      }
    };
  
    useEffect(() => {
      palne();
      toggleAuto();
    }, []);
  return (
    <div>
      {/* black card */}
      <div
        className="cf-pricing-hero-section"
        style={{ borderRadius: "2px", paddingTop: "33px" }}
      >
        <div className="cf-wrapper-1200px-2">
          <div
            className="cf-pricing-toggle-header-wrapper-2 pricing-header-toggle"
            id="pricings"
          >
            <div className="cf-toggle-row-2">
              <div className="cf-toggle-text-2">Monthly</div>
              {togged === false ? (
                <div
                  data-w-id="36d9533a-055b-4e2a-3186-0d13e50c9fe3"
                  className="cf-toggle-contain-2 white-toggle"
                  onClick={takeToggle}
                >
                  <div
                    style={{
                      WebkitTransform:
                        "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    }}
                    className="cf-toggle-dot-2 green-toggle-dot"
                  />
                </div>
              ) : (
                <div
                  data-w-id="36d9533a-055b-4e2a-3186-0d13e50c9fe3"
                  className="cf-toggle-contain-2 white-toggle"
                  onClick={takeToggle}
                >
                  <div
                    style={{
                      WebkitTransform:
                        "translate3d(30px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(30px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(30px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(30px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    }}
                    className="cf-toggle-dot-2 green-toggle-dot"
                  />
                </div>
              )}
              <div className="cf-toggle-text-2">Appsumo Yearly</div>
              <div className="cf-toggle-save-container-2">
                <div className="cf-toggle-save-text-2">Save Up To 17%</div>
                <img
                  src="images/Arrow-6.svg"
                  loading="lazy"
                  alt=""
                  className="cf-toggle-arrow-icon-2"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cf-pricing-box-section">
          <div className="cf-wrapper-1200px-2">
            <div className="cf-pricing-box-wrapper-2 pricing-1-box">
              {togged === false ? (
                <div
                  data-w-id="36d9533a-055b-4e2a-3186-0d13e50c9fee"
                  style={{
                    WebkitTransform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 1,
                    display: "display",
                  }}
                  className="cf-pricing-box-row-2 pricing-box-1"
                >
                  <div style={{marginTop:"32px"}}  
                    className={
                      price === priceTag.sumoAcornM
                        ? `cf-pricing-box-2 green-pricing-box`
                        : `cf-pricing-box-2 pricing-1-box`
                    }
                  >
                    <div style={{padding:"27px"}}>
                    <div className="cf-pricing-box-plan-wrapper-2">
                        <div className="cf-pricing-plan-text-2 pricing-1-plan-text">
                          Acorn
                        </div>
                        <p style={{ marginBottom: "32px" }}>
                          For new YouTube Creators who want access to
                          accelerated growth-oriented tools.
                        </p>
                        <div className="cf-pricing-plan-price-2">
                          $9.99
                          <span className="cf-pricing-plan-small-text-2">
                            /month
                          </span>
                        </div>
                      </div>
                      <div className="cf-pricing-plan-content-wrapper-2">
                        <div className="cf-pricing-plan-content-item-2">
                          <img
                            src={
                              price === priceTag.sumoAcornM
                                ? "images/2.svg"
                                : "images/1.svg"
                            }
                            loading="lazy"
                            width={17}
                            alt=""
                            className="cf-pricing-plan-tick-icon-2"
                          />
                          <div className="cf-pricing-plan-content-text-2">
                            Free Trial not available. 07 days full refund
                            policy. No-quibble Cancellation policy.
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-item-2">
                          <img
                            src={
                              price === priceTag.sumoAcornM
                                ? "images/2.svg"
                                : "images/1.svg"
                            }
                            loading="lazy"
                            width={17}
                            alt=""
                            className="cf-pricing-plan-tick-icon-2"
                          />
                          <div className="cf-pricing-plan-content-text-2">
                            1 YouTube channel
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-item-2">
                          <img
                            src={
                              price === priceTag.sumoAcornM
                                ? "images/2.svg"
                                : "images/1.svg"
                            }
                            loading="lazy"
                            width={17}
                            alt=""
                            className="cf-pricing-plan-tick-icon-2"
                          />
                          <div className="cf-pricing-plan-content-text-2">
                            Standard Channel analytics dashboard
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-item-2">
                          <img
                            src={
                              price === priceTag.sumoAcornM
                                ? "images/2.svg"
                                : "images/1.svg"
                            }
                            loading="lazy"
                            width={17}
                            alt=""
                            className="cf-pricing-plan-tick-icon-2"
                          />
                          <div className="cf-pricing-plan-content-text-2">
                            Credits can be purchased to access AI powered
                            features
                          </div>
                        </div>
                      </div>
                    {price === priceTag.sumoAcornM ? (
                      <p className="choosen-btun">Current Plan</p>
                    ) : (
                      <p
                        className="cf-secondary-button-2 pricing-button w-buttons"
                        onClick={(e) => handleOpen(priceTag.sumoAcornM)}
                      >
                        Select Plan
                      </p>
                    )}
                    </div>
                  </div>
                  <div
                  style={{marginTop:"32px"}} 
                    className={
                      price === priceTag.sumoPalmM
                        ? `cf-pricing-box-2 green-pricing-box`
                        : `cf-pricing-box-2 pricing-1-box`
                    }
                  >
                    <div style={{padding:"27px"}}>
                    <div className={`cf-pricing-box-plan-wrapper-2 ${price === priceTag.sumoPalmM?`white-border`:`black-border`}`}>
                        <div className="cf-pricing-plan-text-2">Palm</div>
                        <p style={{ marginBottom: "32px" }}>
                          For budding and growing YouTube Creators seeking to
                          make informed data-driven decisions with rapid channel
                          growth potential
                        </p>
                        <div className="cf-pricing-plan-price-2">
                          $29.99
                          <span className="cf-pricing-plan-small-text-2">
                            /month
                          </span>
                        </div>
                      </div>
                      <div className="cf-pricing-plan-content-wrapper-2">
                      <div className="cf-pricing-plan-content-wrapper-2">
                        <div className="cf-pricing-plan-content-item-2">
                          <img
                            src={
                              price === priceTag.sumoPalmM
                                ? "images/2.svg"
                                : "images/1.svg"
                            }
                            loading="lazy"
                            width={17}
                            alt=""
                            className="cf-pricing-plan-tick-icon-2"
                          />
                          <div className="cf-pricing-plan-content-text-2">
                            Free Trial not available. 07 days full refund
                            policy. No-quibble Cancellation policy.
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-item-2">
                          <img
                            src={
                              price === priceTag.sumoPalmM
                                ? "images/2.svg"
                                : "images/1.svg"
                            }
                            loading="lazy"
                            width={17}
                            alt=""
                            className="cf-pricing-plan-tick-icon-2"
                          />
                          <div className="cf-pricing-plan-content-text-2">
                            1 YouTube channel
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-item-2">
                          <img
                            src={
                              price === priceTag.sumoPalmM
                                ? "images/2.svg"
                                : "images/1.svg"
                            }
                            loading="lazy"
                            width={17}
                            alt=""
                            className="cf-pricing-plan-tick-icon-2"
                          />
                          <div className="cf-pricing-plan-content-text-2">
                            Standard Channel analytics dashboard
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-item-2">
                          <img
                            src={
                              price === priceTag.sumoPalmM
                                ? "images/2.svg"
                                : "images/1.svg"
                            }
                            loading="lazy"
                            width={17}
                            alt=""
                            className="cf-pricing-plan-tick-icon-2"
                          />
                          <div className="cf-pricing-plan-content-text-2">
                            Credits can be purchased to access AI powered
                            features
                          </div>
                        </div>
                      </div>
                    </div>
                    {price === priceTag.sumoPalmM ? (
                      <p className="choosen-btun">Current Plan</p>
                    ) : (
                      <p
                        className="cf-secondary-button-2 pricing-button w-buttons"
                        onClick={(e) => handleOpen(priceTag.sumoPalmM)}
                      >
                        Select Plan
                      </p>
                    )}
                    </div>
                  </div>
                  <div
                      className="last-cfprice"
                      style={{ backgroundColor: "#ceedfa" }}
                    >
                      <div className="upper-top-cards-recom">
                      <div
                          style={{
                            fontSize: "24px",
                            fontWeight: "700",
                            textAlign: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <img
                            loading="lazy"
                            alt="recommended"
                            src="https://assets-global.website-files.com/60e5f2de011b86acebc30db7/6447dd0049378e8f47155cdf_Stars.svg"
                          />{" "}
                          &nbsp; Recommended
                        </div>
                        <div className={
                      price === priceTag.sumoOackM
                        ? `under-sections-2`
                        : `under-sections`
                    }
                    style={{marginTop:"8px",height:"687px"}}
                    >
                      <div className="cf-pricing-box-plan-wrapper-2">
                          <div className="cf-pricing-plan-text-2">Oak</div>
                          <p style={{ marginBottom: "32px" }}>
                            For growing and established YouTube Creators and
                            Brands, who want to boost their channel with AI
                          </p>
                          <div className="cf-pricing-plan-price-2">
                            $69.99
                            <span className="cf-pricing-plan-small-text-2">
                              /month
                            </span>
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-wrapper-2">
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src={
                              price === priceTag.sumoOackM
                                ? "images/2.svg"
                                : "images/1.svg"
                            }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              All of Palm features, plus Social Media Post
                              generator, Blog Writer incl. more subscribed
                              credits for all AI powered features.
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src={
                              price === priceTag.sumoOackM
                                ? "images/2.svg"
                                : "images/1.svg"
                            }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Free 7-day Trial available with no-quibble
                              cancellation policy.
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                               src={
                                price === priceTag.sumoOackM
                                  ? "images/2.svg"
                                  : "images/1.svg"
                              }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Add up to 10 YouTube channels you own or manage.
                            </div>
                          </div>
                        </div>
                        {/* <p className="cf-secondary-button-2 pricing-button w-buttons">
                        Start Free Trial
                      </p> */}
                        {price === priceTag.sumoOackM ? (
                      <p className="choosen-btun" style={{marginTop:"45px"}}>Current Plan</p>
                    ) : (
                      <p
                        // className="cf-secondary-button-2 pricing-button w-buttons"
                        onClick={(e) => handleOpen(priceTag.sumoOackM)}
                        className="cf-secondary-button-2 pricing-button"
                        style={{marginTop:"45px"}}
                      >
                        Select Plan
                      </p>
                    )}
                      </div>
                      </div>
                      
                    </div>
                  {/* <div
                    className={
                      price === priceTag.sumoOackM
                        ? `cf-pricing-box-2 green-pricing-box`
                        : `cf-pricing-box-2 pricing-1-box`
                    }
                  >
                    <div className="cf-pricing-box-plan-wrapper-2">
                      <div className="cf-pricing-plan-text-2">Oak</div>
                      <div className="cf-pricing-plan-note-text-2">
                        Free 7-day trial.Cancel anytime.
                      </div>
                      <div className="cf-pricing-plan-price-2">
                        $69.99
                        <span className="cf-pricing-plan-small-text-2">
                          /month
                        </span>
                      </div>
                    </div>
                   
                    {price === priceTag.sumoOackM ? (
                      <p className="choosen-btun">Current Plan</p>
                    ) : (
                      <p
                        className="cf-secondary-button-2 pricing-button w-buttons"
                        onClick={(e) => handleOpen(priceTag.sumoOackM)}
                      >
                        Start Free Trial
                      </p>
                    )}
                  </div> */}
                </div>
              ) : (
                <div
                  data-w-id="36d9533a-055b-4e2a-3186-0d13e50ca046"
                  style={{
                    WebkitTransform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 1,
                    display: "flex",
                  }}
                  className="cf-pricing-box-row-2 pricing-box-2"
                >
                  <div
                  style={{marginTop:"32px"}} 
                    className={
                      price === priceTag.sumoAcornY
                        ? `cf-pricing-box-2 green-pricing-box`
                        : `cf-pricing-box-2 pricing-1-box`
                    }
                  >
                    <div style={{padding:"27px"}}>
                    <div className="cf-pricing-box-plan-wrapper-2">
                        <div className="cf-pricing-plan-text-2 pricing-1-plan-text">
                          Acorn
                        </div>
                        <p style={{ marginBottom: "32px" }}>
                          For new YouTube Creators who want access to
                          accelerated growth-oriented tools.
                        </p>
                        <div className="lower-price-opt">
                          <strike className="strick-opts">$9.99/month</strike>
                          <div
                            className="cf-pricing-plan-price-2"
                            style={{ marginBottom: "0px" }}
                          >
                            $8.33
                            <span className="cf-pricing-plan-small-text-2">
                              /month
                            </span>
                          </div>
                        </div>
                        <p className="warns">
                          save 17% with annual billing incl. 2 months free
                          access
                        </p>
                      </div>
                      <div className="cf-pricing-plan-content-wrapper-2">
                        <div className="cf-pricing-plan-content-item-2">
                          <img
                            src={
                              price === priceTag.sumoAcornM
                                ? "images/2.svg"
                                : "images/1.svg"
                            }
                            loading="lazy"
                            width={17}
                            alt=""
                            className="cf-pricing-plan-tick-icon-2"
                          />
                          <div className="cf-pricing-plan-content-text-2">
                            Free Trial not available. 07 days full refund
                            policy. No-quibble Cancellation policy.
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-item-2">
                          <img
                            src={
                              price === priceTag.sumoAcornY
                                ? "images/2.svg"
                                : "images/1.svg"
                            }
                            loading="lazy"
                            width={17}
                            alt=""
                            className="cf-pricing-plan-tick-icon-2"
                          />
                          <div className="cf-pricing-plan-content-text-2">
                            1 YouTube channel
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-item-2">
                          <img
                            src={
                              price === priceTag.sumoAcornY
                                ? "images/2.svg"
                                : "images/1.svg"
                            }
                            loading="lazy"
                            width={17}
                            alt=""
                            className="cf-pricing-plan-tick-icon-2"
                          />
                          <div className="cf-pricing-plan-content-text-2">
                            Standard Channel analytics dashboard
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-item-2">
                          <img
                            src={
                              price === priceTag.sumoAcornY
                                ? "images/2.svg"
                                : "images/1.svg"
                            }
                            loading="lazy"
                            width={17}
                            alt=""
                            className="cf-pricing-plan-tick-icon-2"
                          />
                          <div className="cf-pricing-plan-content-text-2">
                            Credits can be purchased to access AI powered
                            features
                          </div>
                        </div>
                      </div>
                    {price === priceTag.sumoAcornY ? (
                      <p className="choosen-btun">Current Plan</p>
                    ) : (
                      <p
                        className="cf-secondary-button-2 pricing-button w-buttons"
                        onClick={(e) => handleOpen(priceTag.sumoAcornY)}
                      >
                        Select Plan
                      </p>
                    )}
                  </div>
                  </div>
                  <div
                  style={{marginTop:"32px"}} 
                    className={
                      price === priceTag.sumoPalmY
                        ? `cf-pricing-box-2 green-pricing-box`
                        : `cf-pricing-box-2 pricing-1-box`
                    }
                  >
                    <div style={{padding:"27px"}}>
                    <div className={`cf-pricing-box-plan-wrapper-2 ${price === priceTag.sumoPalmM?`white-border`:`black-border`}`}>
                        <div className="cf-pricing-plan-text-2">Palm</div>
                        <p style={{ marginBottom: "32px" }}>
                          For budding and growing YouTube Creators seeking to
                          make informed data-driven decisions with rapid channel
                          growth potential
                        </p>
                        <div className="lower-price-opt">
                          <strike className="strick-opts">$29.99/month</strike>
                          <div
                            className="cf-pricing-plan-price-2"
                            style={{ marginBottom: "0px" }}
                          >
                            $24.99
                            <span className="cf-pricing-plan-small-text-2">
                              /month
                            </span>
                          </div>
                        </div>
                        <p className="warns">
                          save over 17% with annual billing incl. 2 months free
                          access
                        </p>
                      </div>
                      <div className="cf-pricing-plan-content-wrapper-2">
                      <div className="cf-pricing-plan-content-wrapper-2">
                        <div className="cf-pricing-plan-content-item-2">
                          <img
                            src={
                              price === priceTag.sumoPalmY
                                ? "images/2.svg"
                                : "images/1.svg"
                            }
                            loading="lazy"
                            width={17}
                            alt=""
                            className="cf-pricing-plan-tick-icon-2"
                          />
                          <div className="cf-pricing-plan-content-text-2">
                            Free Trial not available. 07 days full refund
                            policy. No-quibble Cancellation policy.
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-item-2">
                          <img
                            src={
                              price === priceTag.sumoPalmY
                                ? "images/2.svg"
                                : "images/1.svg"
                            }
                            loading="lazy"
                            width={17}
                            alt=""
                            className="cf-pricing-plan-tick-icon-2"
                          />
                          <div className="cf-pricing-plan-content-text-2">
                            1 YouTube channel
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-item-2">
                          <img
                            src={
                              price === priceTag.sumoPalmY
                                ? "images/2.svg"
                                : "images/1.svg"
                            }
                            loading="lazy"
                            width={17}
                            alt=""
                            className="cf-pricing-plan-tick-icon-2"
                          />
                          <div className="cf-pricing-plan-content-text-2">
                            Standard Channel analytics dashboard
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-item-2">
                          <img
                            src={
                              price === priceTag.sumoPalmY
                                ? "images/2.svg"
                                : "images/1.svg"
                            }
                            loading="lazy"
                            width={17}
                            alt=""
                            className="cf-pricing-plan-tick-icon-2"
                          />
                          <div className="cf-pricing-plan-content-text-2">
                            Credits can be purchased to access AI powered
                            features
                          </div>
                        </div>
                      </div>
                    </div>
                    {price === priceTag.sumoPalmY ? (
                      <p className="choosen-btun">Current Plan</p>
                    ) : (
                      <p
                        className="cf-secondary-button-2 pricing-button w-buttons"
                        onClick={(e) => handleOpen(priceTag.sumoPalmY)}
                      >
                       Select Plan
                      </p>
                    )}
                  </div>
                  </div>
                  <div
                      className="last-cfprice"
                      style={{ backgroundColor: "#ceedfa" }}
                    >
                      <div className="upper-top-cards-recom">
                      <div
                        style={{
                          fontSize: "24px",
                          fontWeight: "700",
                          textAlign: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          loading="lazy"
                          alt="recommended"
                          src="https://assets-global.website-files.com/60e5f2de011b86acebc30db7/6447dd0049378e8f47155cdf_Stars.svg"
                        />{" "}
                        &nbsp; Recommended
                      </div>
                      <div className={
                      price === priceTag.sumoOackY
                        ? `under-sections-2`
                        : `under-sections`
                    }
                    style={{marginTop:"8px",height:"687px"}}
                    >
                      <div className="cf-pricing-box-plan-wrapper-2">
                          <div className="cf-pricing-plan-text-2">Oak</div>
                          <p style={{ marginBottom: "32px" }}>
                            For growing and established YouTube Creators and
                            Brands, who want to boost their channel with AI
                          </p>
                          <div className="lower-price-opt">
                            <strike className="strick-opts">
                              $69.99/month
                            </strike>
                            <div
                              className="cf-pricing-plan-price-2"
                              style={{ marginBottom: "0px" }}
                            >
                              $58.33
                              <span className="cf-pricing-plan-small-text-2">
                                /month
                              </span>
                            </div>
                          </div>
                          <p className="warns">
                            save over 17% with annual billing incl. 2 months
                            free access
                          </p>
                        </div>
                        <div className="cf-pricing-plan-content-wrapper-2">
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                             src={
                              price === priceTag.sumoOackY
                                ? "images/2.svg"
                                : "images/1.svg"
                            }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              All of Palm features, plus Social Media Post
                              generator, Blog Writer incl. more subscribed
                              credits for all AI powered features.
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                             src={
                              price === priceTag.sumoOackY
                                ? "images/2.svg"
                                : "images/1.svg"
                            }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Free 7-day Trial available with no-quibble
                              cancellation policy.
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                             src={
                              price === priceTag.sumoOackY
                                ? "images/2.svg"
                                : "images/1.svg"
                            }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Add up to 10 YouTube channels you own or manage.
                            </div>
                          </div>
                        </div>
                        {/* <p className="cf-secondary-button-2 pricing-button w-buttons">
                        Start Free Trial
                      </p> */}
                        {price === priceTag.sumoOackY ? (
                      <p className="choosen-btun">Current Plan</p>
                    ) : (
                      <p
                      className="cf-secondary-button-2 pricing-button"
                        onClick={(e) => handleOpen(priceTag.sumoOackY)}
                        // style={{marginTop:"19px"}}
                      >
                       Select Plan
                      </p>
                    )}
                      </div>
                      </div>
                      
                    </div>
                  {/* <div
                    className={
                      price === priceTag.sumoOackY
                        ? `cf-pricing-box-2 green-pricing-box`
                        : `cf-pricing-box-2 pricing-1-box`
                    }
                  >
                    <div className="cf-pricing-box-plan-wrapper-2">
                      <div className="cf-pricing-plan-text-2">Oak</div>
                      <div className="cf-pricing-plan-note-text-2">
                        Free 7-day trial.Cancel anytime.
                      </div>
                      <div className="cf-pricing-plan-price-2">
                        $699.99
                        <span className="cf-pricing-plan-small-text-2">
                          /Year
                        </span>
                      </div>
                    </div>
                    {price === priceTag.sumoOackY ? (
                      <p className="choosen-btun">Current Plan</p>
                    ) : (
                      <p
                        className="cf-secondary-button-2 pricing-button w-buttons"
                        onClick={(e) => handleOpen(priceTag.sumoOackY)}
                      >
                        Start Free Trial
                      </p>
                    )}
                  </div> */}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button
            className="round_button2 uk-margin-top"
            onClick={subscriptions}
          >
            Proceed to payment
          </button>
        </div>
      </div>

      <PlaneDetails />
    </div>
  )
}

export default AppSumoSubscription