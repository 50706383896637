import { privateRequest } from "../axiosConfig/privateRequest";

export const fetchChannelid = () => {
    return privateRequest.get('/api/youtube/channel-mapping/')
}

export const fetchModule_access = () => {
    return privateRequest.get(`/api/payments/module-access/`)
}
export const fetchSubscription_details = () => {
    return privateRequest.get(`/api/payments/user/subscription/`)
}

export const fetchTotalCredits = () => {
    return privateRequest.get(`/api/payments/display-total-credits/`)
}

export const fetchAdditonalCredits = () => {
    return privateRequest.get(`/api/payments/display-monthly-credits/`)
}

export const fetchChannelIdData = () => {
    return privateRequest.get('/api/youtube/multichannel-list/')
}

