import React from "react";
import { Panel, PanelGroup } from "rsuite";

const AccordianGroup = () => {
  return (
    <section className="frequent-ask" id="faq">
      <div className="row mt-5">
        <div className="col-lg-3">
          <p className="upper-frequents">ANSWERS TO</p>
          <p className="frequents">Frequently Asked Questions</p>
        </div>
        <div className="col-lg-9">
          <PanelGroup accordion>
            <Panel
              header="Is YTubeBooster PRO for me?"
              eventKey={1}
              id="panel1"
            >
              <p>
                <br />
              </p>
              <p></p>
              <p>
                <span
                  style={{
                    fontSize: "14px",
                    color: "#172b4d",
                    backgroundColor: "transparent",
                  }}
                >
                  YouTubeBooster PRO caters to a wide range of YouTube creators
                  and businesses looking to extend their reach and presence
                  online. If you are:
                </span>
              </p>
              <p></p>
              <p></p>
              <p></p>
              <ol>
                <li>
                  <p>
                    <strong>
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#172b4d",
                          backgroundColor: "transparent",
                        }}
                      >
                        A YouTube Creator:
                      </span>
                      </strong>
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#172b4d",
                          backgroundColor: "transparent",
                        }}
                      >
                        {" "}
                        Whether you&apos;re a content creator, vlogger, or
                        influencer looking to grow your subscriber base and
                        increase video visibility, YTubeBooster PRO can provide
                        valuable tools and insights.
                      </span>
                    
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#172b4d",
                          backgroundColor: "transparent",
                        }}
                      >
                        A Business Utilizing YouTube:
                      </span>
                      </strong>
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#172b4d",
                          backgroundColor: "transparent",
                        }}
                      >
                        {" "}
                        If your company uses YouTube as a marketing or content
                        distribution channel, YTubeBooster PRO can help optimize
                        your strategies, improve engagement, and drive more
                        traffic to your videos.
                      </span>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#172b4d",
                          backgroundColor: "transparent",
                        }}
                      >
                        Seeking Growth and Efficiency:
                      </span>
                      </strong>
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#172b4d",
                          backgroundColor: "transparent",
                        }}
                      >
                        {" "}
                        YTubeBooster PRO is ideal for those who want to expedite
                        their growth on YouTube, streamline their content
                        creation process, and make data-driven decisions for
                        better results.
                      </span>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#172b4d",
                          backgroundColor: "transparent",
                        }}
                      >
                        Interested in AI-Powered Solutions:
                      </span>
                      </strong>
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#172b4d",
                          backgroundColor: "transparent",
                        }}
                      >
                        {" "}
                        If you&apos;re enthusiastic about leveraging artificial
                        intelligence to enhance efficiency, and stay ahead of
                        the competition, YTubeBooster PRO offers AI-driven
                        features that can benefit your YouTube endeavors.
                      </span>
                  </p>
                </li>
              </ol>
              <p>
                <span
                  style={{
                    fontSize: "14px",
                    color: "rgb(23, 43, 77)",
                    backgroundColor: "transparent",
                  }}
                >
                  YTubeBooster PRO is a versatile tool suitable for YouTube
                  creators, businesses, and individuals aiming to boost their
                  performance, engage with their audience more effectively, and
                  harness the power of AI in their YouTube strategies.
                </span>
              </p>
              <p></p>
              <p>
                <br />
              </p>
              <p></p>
            </Panel>
            <Panel
              header="What is the subscription model of YTubeBooster PRO?"
              eventKey={2}
              id="panel2"
            >
              <p>

                <span
                  style={{
                    fontSize: "14px",
                    color: "#172b4d",
                    backgroundColor: "transparent",
                  }}
                >
                  YTubeBooster Pro provides a subscription model that includes
                  three plans, each with the option of monthly or yearly
                  subscription. These plans are designed based on the growth
                  stages of YouTube Creators and are named Acorn (previously
                  Boost Silver), Palm, and Oak. The Palm and Oak plans come with
                  pre-filled Credits, which can be primarily used for AI-powered
                  features available for each respective plan. However, Acorn
                  plan users must purchase Credits to access AI-powered features
                  available in this plan.
                </span>

                <br />
              </p>
            </Panel>
            <Panel
              header="What does YTubeBooster PRO do with my data?"
              eventKey={3}
              id="panel3"
            >

              <p>
                <span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>
                  With YTubeBooster PRO, we take data privacy and security
                  seriously. We understand the importance of safeguarding your
                  information, and we are committed to ensuring that your data
                  is handled with the utmost care and responsibility.
                </span>
              </p>

              <p>
                <span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>
                  Here&apos;s how YTubeBooster PRO treats your data:
                </span>
              </p>

              <p>
                <strong></strong>
              </p>

              <ol>
                <li>
                  <p>
                    <strong>
                      <span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>
                        Data Collection:
                      </span>
                      <span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>
                        {" "}
                        We may collect certain data, such as user preferences,
                        usage patterns, and interaction data, to improve our
                        service and tailor it to your needs. This data is
                        anonymised and aggregated whenever possible to protect
                        your privacy.
                      </span>
                    </strong>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      <span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>
                        Data Protection:
                      </span>
                      <span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>
                        {" "}
                        We implement robust security measures to protect your
                        data from unauthorized access, breaches, or misuse. Your
                        personal information is encrypted, and we adhere to
                        industry-standard practices to safeguard your data.
                      </span>
                    </strong>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      <span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>
                        Data Usage:
                      </span>
                      <span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>
                        {" "}
                        Your data is primarily used to enhance your experience
                        with YTubeBooster PRO. It helps us provide personalized
                        recommendations, insights, and optimizations to improve
                        your YouTube performance.
                      </span>
                    </strong>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      <span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>
                        Data Sharing:
                      </span>
                      <span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>
                        {" "}
                        We do not sell or share your personal data with third
                        parties for marketing purposes. Any data sharing with
                        trusted partners or service providers is done to deliver
                        our services effectively and in compliance with relevant
                        data protection laws.
                      </span>
                    </strong>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      <span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>
                        Transparency and Control:
                      </span>
                      <span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>
                        {" "}
                        You have control over your data. YTubeBooster PRO allows
                        you to manage your data preferences, including opting
                        out of certain data collection or usage if you wish.
                      </span>
                    </strong>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      <span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>
                        Data Deletion:
                      </span>
                      <span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>
                        {" "}
                        You can request the deletion of your data from our
                        systems at any time, in accordance with our data
                        retention policies.
                      </span>
                    </strong>
                  </p>
                </li>
              </ol>

              <p>
                <span style={{ fontsize: "14px", color: "rgb(23, 43, 77)", backgroundColor: "transparent" }}>
                  Our commitment to data privacy and security is an integral
                  part of our mission. We strive to maintain your trust by
                  upholding the highest standards in data protection and
                  handling, ensuring that your data is used responsibly and
                  ethically to provide you with a valuable and secure experience
                  with YTubeBooster PRO.
                </span>
              </p>

              <p>
                <br />
              </p>
              <p></p>
            </Panel>
            <Panel
              header="Can I add multiple YouTube channels and users to my account?"
              eventKey={4}
              id="panel4"
            >
              <p>When you subscribe to a plan, the number of channels available will vary. For instance, the Oak plan allows for up to 10 channels, Palm plan allows for up to 3 channels, and Acorn plan allows for 1 channel.
              </p>
            </Panel>
            <Panel
              header="Does YTubeBooster PRO publish all my optimisations direct onto my YouTube Channel?"
              eventKey={5}
              id="panel5"
            >
              <p><span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>No, YTubeBooster PRO does not automatically publish optimizations directly to your YouTube channel. We prioritize your control and discretion over the optimizations you publish via our app on your channel.</span></p>

              <p><span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>YTubeBooster PRO provides you with various tools and recommendations to optimize your YouTube content, such as video titles, descriptions, tags, and scheduling. These suggestions are designed to enhance the visibility and engagement of your videos. However, it&apos;s ultimately up to you, the channel owner or creator, to review these optimizations and decide when to apply them to your YouTube videos.</span></p>

              <p><span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>We empower you to make informed decisions about your content and give you the flexibility to choose which optimizations to implement and when to publish or update your videos. This approach ensures that you maintain complete control over your YouTube channel&apos;s content and strategy while benefiting from the insights and recommendations provided by YTubeBooster PRO.</span></p>

              <br />
            </Panel>
            <Panel
              header="Will I get access to all future updates and features?"
              eventKey={6}
              id="panel6"
            >
              <p><span style={{ backgroundColor: "transparent", color: "rgb(23, 43, 77)", fontSize: "14px", fontWeight: "var(--bs-body-font-weight)" }}>Yes, as a valued user of YTubeBooster PRO, you will have access to all future updates and features pertaining to your plan that we roll out. We are committed to continuously improving our platform to better serve your needs and stay at the forefront of YouTube optimization tools.</span><br />
              </p>

              <p><span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>Our dedication to your success means that you won&apos;t be left behind as we introduce new and enhanced functionalities. You can expect to benefit from the latest innovations, improvements, and tools that we develop to help you grow and optimize your YouTube channel.</span></p>

              <p><span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>We understand that staying competitive in the online landscape requires access to the most advanced features, and we&apos;re excited to share those advancements with you as part of your ongoing YTubeBooster PRO subscription. Your success is our priority, and providing access to future updates is a crucial part of our commitment to your YouTube growth and success</span></p>

              <br />
            </Panel>
            <Panel
              header="What if I change my mind and want to cancel my subscription?"
              eventKey={7}
              id="panel7"
            >
              <p><span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>​You have the option to cancel your subscription at any time. However, please note that we do not offer refunds. Once you initiate the cancellation of your subscription, you will be able to use the app until the end of your current subscription cycle, whether it is monthly or yearly. After your subscription cycle ends, you will lose access to your account, along with all the data saved in it. You can request cancellation of your subscription via your dashboard.</span></p>
              <p></p>
            </Panel>
            <Panel
              header="What payment methods do you support?"
              eventKey={8}
              id="panel8"
            >
              <p><span style={{ backgroundColor: "transparent", color: "rgb(23, 43, 77)", fontSize: "14px", fontWeight: "var(--bs-body-font-weight)" }}>We accept all major credit and debit cards including Visa, Mastercard and American Express. We do not currently accept PayPal payments.</span><br />
              </p>

              <p><span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>We process payments using Stripe, ensuring your card details are processed and stored securely in accordance with industry guidelines. Your card details are stored by Stripe, we do not have access to your full card details at any stage, and to guarantee your protection against fraud your card details can only be used to make payments to us via Stripe. We do not support payments via</span></p>

              <p><strong></strong></p>

              <ul>
                <li>
                  <p><strong><span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>Bank transfer</span></strong></p>
                </li>
                <li>
                  <p><strong><span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>Wire transfer</span></strong></p>
                </li>
                <li>
                  <p><strong><span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>E-checks and checks from international users.</span></strong></p>
                </li>
              </ul>

              <p><span style={{ fontSize: "14px", color: "rgb(23, 43, 77)", backgroundColor: "transparent" }}>Payments to us will appear on your card statement as:</span><strong><span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}> (To add)</span></strong></p>
              <p></p><p></p>
            </Panel>
            <Panel
              header=" Can I upgrade or downgrade to a different plan at a later time?"
              eventKey={9}
              id="panel9"
            >
              <p>​<span style={{ fontSize: "14px", color: "rgb(23, 43, 77)", backgroundColor: "transparent" }}>Yes, you have the flexibility to upgrade or downgrade your subscription plan at any time. The subscription cost will be calculated on a pro-rata basis, meaning you will only pay for the portion of time you use the upgraded or downgraded plan. However, please note that downgrading your plan may result in lost access to certain features or modules, including a reduction in the number of channels you have connected and pre-allocated Credits with your plan. We recommend that you review our Subscription Plans before making any decisions to upgrade or downgrade your current plan.</span>​<br />
              </p>
            </Panel>
            <Panel
              header="What’s the subscription commitment?"
              eventKey={10}
              id="panel10"
            >
              <p><span style={{ fontSize: "14px", color: "rgb(23, 43, 77)", backgroundColor: "transparent" }}>The first payment is only deducted after the trial period of 7 days (for Palm and Oak plans only), and the subscribed plan (monthly / yearly) would be activated. For Acorn, the payment is taken immediately however, you have 7 days to request for cancellation of subscription to receive a full refund. Beyond the first 7 days, we operate on a no refund policy for any of the plans however you can cancel your subscription at any time and use the app until the next billing period (monthly / yearly). If you have cancelled your subscription, you will not be billed in the next billing cycle. If you have any queries you can reach the customer support team via your dashboard.</span></p>
            </Panel>
            <Panel
              header="Do you offer free trials?"
              eventKey={11}
              id="panel11"
            >
              <p><span style={{ fontSize: "14px", color: "rgb(23, 43, 77)", backgroundColor: "transparent" }}>Our Palm and Oak plans offer a 7-day trial with predefined credits and a no-quibble cancellation policy. The Acorn plan doesn't have a trial period, but you can cancel anytime.</span></p>
            </Panel>
            <Panel
              header="Why am I charged credits over my subscription?"
              eventKey={12}
              id="panel12"
            >
              <p><span style={{ backgroundColor: "transparent", color: "rgb(23, 43, 77)", fontSize: "14px", fontWeight: "var(--bs-body-font-weight)" }}>If you are being charged credits over your subscription with YTubeBooster PRO, it&apos;s important to understand that credits are often used for specific actions or additional services that go beyond the scope of your regular subscription or limits. Here are a few reasons why you might be charged additional credits:</span><br />
              </p>

              <p><strong></strong></p>

              <ol>
                <li>
                  <p><strong><span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>Premium Services: </span></strong><span style={{ fontSize: " 14px", color: "rgb(23, 43, 77)", backgroundColor: "transparent" }}>YTubeBooster PRO may offer premium services or advanced features that are not included in the standard subscription package. These premium services may require the use of credits for access.</span></p>
                </li>
                <li>
                  <p><strong><span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>Exceeding Credit Limits:</span></strong><span style={{ fontSize: "14px", color: "rgb(23, 43, 77)", backgroundColor: "transparent" }}> Depending on your subscription plan, there may be usage limits for certain actions or features. If you exceed these limits, you may be charged extra credits to cover the additional usage. If you require unlimited credits please consider subscribing our OAK plan.</span></p>
                </li>
              </ol>

              <p><span style={{ fontSize: " 14px", color: "rgb(23, 43, 77)", backgroundColor: "transparent" }}>It&apos;s essential to review your subscription details and credit usage to understand the specific reasons for the additional charges. YTubeBooster PRO should provide transparency in its billing and credit usage policies, so you can track and manage your expenses effectively. If you have any concerns or questions about the charges, reach out to their customer support for clarification and assistance.</span></p>
            </Panel>
            <Panel
              header="How are credits calculated?"
              eventKey={13}
              id="panel13"
            >
              <p><span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>Credits used by YTubeBooster are typically calculated based on the usage of AI-driven features and some specific services within the application. Here&apos;s a general explanation of how credits are calculated:</span></p>

              <p><strong></strong></p>

              <ol>
                <li>
                  <p><strong><span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>Feature Usage: </span></strong><span style={{ fontSize: "14px", color: "rgb(23, 43, 77)", backgroundColor: "transparent" }}>Each AI-powered feature or service within YTubeBooster that relies on AI technology may consume a certain amount of credits per usage. The specific credits consumed can vary depending on the complexity and resource requirements of the feature.</span></p>
                </li>
                <li>
                  <p><strong><span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>Volume of Data: </span></strong><span style={{ fontSize: "14px", color: "rgb(23, 43, 77)", backgroundColor: "transparent" }}>Credits may also be calculated based on the volume of data processed or generated by the AI features. More extensive data processing or analysis may result in higher credit consumption</span><strong><span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>.</span></strong></p>
                </li>
                <li>
                  <p><strong><span style={{ fontSize: "14px", color: "#172b4d", backgroundColor: "transparent" }}>Subscription Level:</span></strong><span style={{ fontSize: "14px", color: "rgb(23, 43, 77)", backgroundColor: "transparent" }}> Your subscription plan with YTubeBooster may include a predefined credit allocation or limit. Exceeding this limit may result in additional credit charges.</span></p>
                </li>
              </ol>

              <p><span style={{ fontSize: "14px", color: "rgb(23, 43, 77)", backgroundColor: "transparent" }}>It&apos;s crucial to review the terms and conditions, as well as the pricing structure provided by YTubeBooster, to gain a clear understanding of how credits are calculated and allocated within the application. This transparency ensures that you can effectively manage your credit usage and budget while utilizing the AI-driven features and services offered by YTubeBooster.</span></p>
            </Panel>
            <Panel
              header="Does unused Credits rollover to next month/year and can I transfer credits to other users?"
              eventKey={14}
              id="panel14"
            >
              <p>​<span style={{ fontSize: "14px", color: "rgb(23, 43, 77)", backgroundColor: "transparent" }}>No, unused OpenAI credits in YTubeBooster do not rollover to the next month or year, and they cannot be transferred to other users. These credits are typically meant for usage within the specified billing period and are non-transferable. It&apos;s important to plan your credit usage accordingly to make the most of your allocated credits during your subscription period.</span>​<br />
              </p>
            </Panel>
          </PanelGroup>
        </div>
      </div>
    </section>
  );
};

export default AccordianGroup;
