import * as actionTypes from "../actionTypes";
const inititalState = {
    pricing: "",
  };

  export const pricingReducer = (state = inititalState, action) => {
    switch (action.type) {
    case actionTypes.CHOOSE_PRICING:
        return {
          ...state,
          pricing: action.payload,
        };
  
      default:
        return state;
    }
  }