// import axios from "axios";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { ProgressBar } from "react-loader-spinner";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";
import { useDispatch } from "react-redux";
import {
  fetchAdditionalCreditsStart,
  fetchTotalCreditsStart,
} from "../../../redux/actions/creditsActions";
import { useToaster } from "rsuite";
import { showErrorNotification } from "../../../Notification";
import AIheader from "./AIheader";
import { AiHeaders } from "./StringStore";

const Description = () => {
  const [description1, setDescription1] = useState("");
  const [description2, setDescription2] = useState("");
  const [description3, setDescription3] = useState("");
  const [descriptionLoader, setDescriptionLoader] = useState(false);
  const [descriptionAll, setDescriptionAll] = useState();
  const toaster = useToaster();
  const dispatch = useDispatch();

  // Function to convert keywords to CSV format
  const convertToCSV = (keywords) => {
    // console.log("check the description",keywords)
    const csvContent =
      keywords === undefined
        ? ""
        : keywords
            .map(
              (keyword, index) => `${index + 1}, ${keyword.replace(/,/g, "")}`
            )
            .join("\n");
    // const csvContent = "maians"
    // keywords
    //   .map((keyword, index) => `"${keyword}"`)
    //   .join("\n");
    return `No.,Description\n${csvContent}`;
  };

  // Convert keywords to CSV format
  const csvData = convertToCSV(descriptionAll);

  const downloadCSV = () => {
    const element = document.createElement("a");
    const file = new Blob([csvData], { type: "text/csv" });
    element.href = URL.createObjectURL(file);
    element.download = `description.csv`;
    document.body.appendChild(element);
    element.click();
  };

  const generateDescription = async (values) => {
    // console.log("values", values);
    try {
      setDescriptionLoader(true);
      const description = await privateRequest.post(
        `/api/youtube/description/creator/`,
        values
      );
      setDescriptionLoader(false);
      // console.log("description", description.data.data);
      setDescriptionAll(description.data.data);
      setDescription1(description.data.data[0]);
      setDescription2(description.data.data[1]);
      setDescription3(description.data.data[2]);
    } catch (err) {
      // console.log("err", err);
      toaster.push(showErrorNotification(err.response.data.message), {
        duration: 0,
      });
      setDescriptionLoader(false);
    }
    dispatch(fetchAdditionalCreditsStart());
    dispatch(fetchTotalCreditsStart());
  };
  const initialValues = {
    youtube_title: "",
  };
  return (
    <>
      <div className="mt-3 mb-4">
        <AIheader
          title={AiHeaders.topTitle}
          description={AiHeaders.topdescription}
          lasttext={AiHeaders.toplast}
        />
      </div>
      <div className="containers-custom">
        <div
          className="container-fluid pt-3 pb-3"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        >
          <div className="f-tab-heading">
            Add not more than 3 words to help our AI give you the 3 best
            descriptions for your video
          </div>
          <div className="brix---position-relative-2">
            <Formik
              initialValues={initialValues}
              // validationSchema={validationSchema}
              onSubmit={async (values, { resetForm }) => {
                await generateDescription(values);
                resetForm();
              }}
            >
              {(formik) => {
                return (
                  <Form>
                    <Field
                      type="text"
                      style={{ border: "none" }}
                      className="brix---input-large-button-inside w-input"
                      autoFocus={true}
                      maxLength="256"
                      name="youtube_title"
                      data-name="BRIX - Footer Email - V16"
                      placeholder="Enter your keywords to generate Description"
                      id="BRIX-Footer-Email-V16"
                    />
                    {formik.values.youtube_title.length === 0 ||
                    descriptionLoader === true ? (
                      <input
                        type="button"
                        style={{ border: "none", backgroundColor: "grey" }}
                        data-wait="Please wait..."
                        className="brix---btn-primary-inside-input w-button"
                        value="Generate"
                      />
                    ) : (
                      <input
                        type="submit"
                        value="Generate"
                        style={{ border: "none" }}
                        data-wait="Please wait..."
                        className="brix---btn-primary-inside-input w-button"
                      />
                    )}
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="description-table d-flex justify-content-between align-items-center">
            <h5 className="heading">Description Suggest</h5>
            {(description1.length === 0) |
            (description2.length === 0) |
            (description3.length === 0) ? (
              ""
            ) : (
              <button
                className="btn btn-sm btn-outline-primary "
                onClick={downloadCSV}
              >
                Download CSV
              </button>
            )}
          </div>

          {descriptionLoader === true ? (
            <div className="d-flex justify-content-center align-items-center mt-3">
              LOADING &nbsp;
              <ProgressBar
                height="80"
                width="80"
                ariaLabel="progress-bar-loading"
                // wrapperStyle={{}}
                wrapperClass="progress-bar-wrapper"
                borderColor="#53389e"
                barColor="#53389e"
              />
            </div>
          ) : (
            <>
              {description1.length === 0 ? (
                ""
              ) : (
                <div className="f-margin-bottom-40">
                  <h1 className="f-h2-heading">Description1</h1>
                  <div className="div-block-23" style={{ fontSize: "13px" }}>
                    <p>{description1}</p>
                  </div>
                  <CopyToClipboard text={description1}>
                    <p
                      className="f-smpost-gen-copy w-button"
                      style={{ cursor: "pointer" }}
                    >
                      Copy
                    </p>
                  </CopyToClipboard>
                </div>
              )}
              {description2.length === 0 ? (
                ""
              ) : (
                <div className="f-margin-bottom-40">
                  <h1 className="f-h2-heading">Description2</h1>
                  <div className="div-block-23" style={{ fontSize: "13px" }}>
                    <p>{description2}</p>
                  </div>
                  <CopyToClipboard text={description2}>
                    <p
                      className="f-smpost-gen-copy w-button"
                      style={{ cursor: "pointer" }}
                    >
                      Copy
                    </p>
                  </CopyToClipboard>
                </div>
              )}
              {description3.length === 0 ? (
                ""
              ) : (
                <div className="f-margin-bottom-40">
                  <h1 className="f-h2-heading">Description3</h1>
                  <div className="div-block-23" style={{ fontSize: "13px" }}>
                    <p>{description3}</p>
                  </div>
                  <CopyToClipboard text={description3}>
                    <p
                      className="f-smpost-gen-copy w-button"
                      style={{ cursor: "pointer" }}
                    >
                      Copy
                    </p>
                  </CopyToClipboard>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Description;
