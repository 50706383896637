export const country = [
    {
      name: "🇺🇸 United States",
      code: "US",
    },
    {
      name: "🇬🇧 United Kingdom",
      code: "gb",
    },
    {
      name:"🇦🇺 Australia",
      code:"au"
    },
    {
      name:"🇧🇷 Brazil",
      code:"br"
    },
    {
      name:"🇫🇷 France",
      code:"fr"
    },
    { name:"🇩🇪 Germany",code:"de"},
    {name:"🇪🇸 Spain", code:"es"},
    {name:"🇨🇦 Canada", code:"ca"},
    { name:"🇳🇱 Netherlands",code:"nl"},
    { name:"🇵🇱 Poland",code:"pl"},
    {name:"🇮🇹 Italy" ,code:"it"},
    { name:"🇮🇳 India",code:"in"},
    { name:"🇦🇫 Afghanistan",code:"af"},
    {name:"🇦🇱 Albania", code:"al"},
    { name:"🇩🇿 Algeria",code:"dz"},
    { name:"🇦🇸 American Samoa",code:"as"},
    { code:"ad",name:"🇦🇩 Andorra"},
    { code:"ao",name:"🇦🇴 Angola"},
    { code:"ai",name:"🇦🇮 Anguilla"},
    { code:"ag",name:"🇦🇬 Antigua & Barbuda"},
    { code:"ar",name:"🇦🇷 Argentina"},
    { code:"am",name:"🇦🇲 Armenia"},
    { code:"au",name:"🇦🇺 Australia"},
    { code:"at",name:"🇦🇹 Austria"},
    { code:"az",name:"🇦🇿 Azerbaijan"},
    { code:"bs",name:"🇧🇸 Bahamas"},
    { code:"bh",name:"🇧🇭 Bahrain"},
    { code:"bd",name:"🇧🇩 Bangladesh"},
    { code:"by",name:"🇧🇾 Belarus"},
    { code:"be",name:"🇧🇪 Belgium"},
    { code:"bz",name:"🇧🇿 Belize"},
    { code:"bj",name:"🇧🇯 Benin"},
    { code:"bt",name:"🇧🇹 Bhutan"},
    { code:"bo",name:"🇧🇴 Bolivia"},
    { code:"ba",name:"🇧🇦 Bosnia & Herzegovina"},
    { code:"bw",name:"🇧🇼 Botswana"},
    { code:"br",name:"🇧🇷 Brazil"},
    { code:"vg",name:"🇻🇬 British Virgin Islands"},
    { code:"bn",name:"🇧🇳 Brunei"},
    { code:"bg",name:"🇧🇬 Bulgaria"},
    { code:"bf",name:"🇧🇫 Burkina Faso"},
    { code:"bi",name:"🇧🇮 Burundi"},
    { code:"kh",name:"🇰🇭 Cambodia"},
    { code:"cm",name:"🇨🇲 Cameroon"},
    { code:"ca",name:"🇨🇦 Canada"},
    { code:"cv",name:"🇨🇻 Cape Verde"},
    { code:"cf",name:"🇨🇫 Central African Republic"},
    { code:"td",name:"🇹🇩 Chad"},
    { code:"cl",name:"🇨🇱 Chile"},
    { code:"co",name:"🇨🇴 Colombia"},
    { code:"cg",name:"🇨🇬 Congo - Brazzaville"},
    { code:"cd",name:"🇨🇩 Congo - Kinshasa"},
    { code:"ck",name:"🇨🇰 Cook Islands"},
    { code:"cr",name:"🇨🇷 Costa Rica"},
    { code:"ci",name:"🇨🇮 Côte d’Ivoire"},
    { code:"hr",name:"🇭🇷 Croatia"},
    { code:"cu",name:"🇨🇺 Cuba"},
    { code:"cy",name:"🇨🇾 Cyprus"},
    { code:"cz",name:"🇨🇿 Czechia"},
    { code:"dk",name:"🇩🇰 Denmark"},
    { code:"dj",name:"🇩🇯 Djibouti"},
    { code:"dm",name:"🇩🇲 Dominica"},
    { code:"do",name:"🇩🇴 Dominican Republic"},
    { code:"ec",name:"🇪🇨 Ecuador"},
    { code:"eg",name:"🇪🇬 Egypt"},
    { code:"sv",name:"🇸🇻 El Salvador"},
    { code:"ee",name:"🇪🇪 Estonia"},
    { code:"et",name:"🇪🇹 Ethiopia"},
    { code:"fj",name:"🇫🇯 Fiji"},
    { code:"fi",name:"🇫🇮 Finland"},
    { code:"fr",name:"🇫🇷 France"},
    { code:"ga",name:"🇬🇦 Gabon"},
    { code:"gm",name:"🇬🇲 Gambia"},
    { code:"ge",name:"🇬🇪 Georgia"},
    { code:"de",name:"🇩🇪 Germany"},
    { code:"gh",name:"🇬🇭 Ghana"},
    { code:"gi",name:"🇬🇮 Gibraltar"},
    { code:"gr",name:"🇬🇷 Greece"},
    { code:"gl",name:"🇬🇱 Greenland"},
    { code:"gp",name:"🇬🇵 Guadeloupe"},
    { code:"gt",name:"🇬🇹 Guatemala"},
    { code:"gg",name:"🇬🇬 Guernsey"},
    { code:"gy",name:"🇬🇾 Guyana"},
    { code:"ht",name:"🇭🇹 Haiti"},
    { code:"hn",name:"🇭🇳 Honduras"},
    { code:"hk",name:"🇭🇰 Hong Kong"},
    { code:"hu",name:"🇭🇺 Hungary"},
    { code:"is",name:"🇮🇸 Iceland"},
    { code:"in",name:"🇮🇳 India"},
    { code:"id",name:"🇮🇩 Indonesia"},
    { code:"iq",name:"🇮🇶 Iraq"},
    { code:"ie",name:"🇮🇪 Ireland"},
    { code:"im",name:"🇮🇲 Isle of Man"},
    { code:"il",name:"🇮🇱 Israel"},
    { code:"it",name:"🇮🇹 Italy"},
    { code:"jm",name:"🇯🇲 Jamaica"},
    { code:"jp",name:"🇯🇵 Japan"},
    { code:"je",name:"🇯🇪 Jersey"},
    { code:"jo",name:"🇯🇴 Jordan"},
    { code:"kz",name:"🇰🇿 Kazakhstan"},
    { code:"ke",name:"🇰🇪 Kenya"},
    { code:"ki",name:"🇰🇮 Kiribati"},
    { code:"kw",name:"🇰🇼 Kuwait"},
    { code:"kg",name:"🇰🇬 Kyrgyzstan"},
    { code:"la",name:"🇱🇦 Laos"},
    { code:"lv",name:"🇱🇻 Latvia"},
    { code:"lb",name:"🇱🇧 Lebanon"},
    { code:"ls",name:"🇱🇸 Lesotho"},
    { code:"ly",name:"🇱🇾 Libya"},
    { code:"li",name:"🇱🇮 Liechtenstein"},
    { code:"lt",name:"🇱🇹 Lithuania"},
    { code:"lu",name:"🇱🇺 Luxembourg"},
    { code:"mg",name:"🇲🇬 Madagascar"},
    { code:"mw",name:"🇲🇼 Malawi"},
    { code:"my",name:"🇲🇾 Malaysia"},
    { code:"mv",name:"🇲🇻 Maldives"},
    { code:"ml",name:"🇲🇱 Mali"},
    { code:"mt",name:"🇲🇹 Malta"},
    { code:"mu",name:"🇲🇺 Mauritius"},
    { code:"mx",name:"🇲🇽 Mexico"},
    { code:"fm",name:"🇫🇲 Micronesia"},
    { code:"md",name:"🇲🇩 Moldova"},
    { code:"mn",name:"🇲🇳 Mongolia"},
    { code:"me",name:"🇲🇪 Montenegro"},
    { code:"ms",name:"🇲🇸 Montserrat"},
    { code:"ma",name:"🇲🇦 Morocco"},
    { code:"mz",name:"🇲🇿 Mozambique"},
    { code:"mm",name:"🇲🇲 Myanmar (Burma)"},
    { code:"na",name:"🇳🇦 Namibia"},
    { code:"nr",name:"🇳🇷 Nauru"},
    { code:"np",name:"🇳🇵 Nepal"},
    { code:"nl",name:"🇳🇱 Netherlands"},
    { code:"nz",name:"🇳🇿 New Zealand"},
    { code:"ni",name:"🇳🇮 Nicaragua"},
    { code:"ne",name:"🇳🇪 Niger"},
    { code:"ng",name:"🇳🇬 Nigeria"},
    { code:"nu",name:"🇳🇺 Niue"},
    { code:"nf",name:"🇳🇫 Norfolk Island"},
    { code:"mk",name:"🇲🇰 North Macedonia"},
    { code:"no",name:"🇳🇴 Norway"},
    { code:"om",name:"🇴🇲 Oman"},
    { code:"pk",name:"🇵🇰 Pakistan"},
    { code:"ps",name:"🇵🇸 Palestine"},
    { code:"pa",name:"🇵🇦 Panama"},
    { code:"pg",name:"🇵🇬 Papua New Guinea"},
    { code:"py",name:"🇵🇾 Paraguay"},
    { code:"pe",name:"🇵🇪 Peru"},
    { code:"ph",name:"🇵🇭 Philippines"},
    { code:"pn",name:"🇵🇳 Pitcairn Islands"},
    { code:"pl",name:"🇵🇱 Poland"},
    { code:"pt",name:"🇵🇹 Portugal"},
    { code:"pr",name:"🇵🇷 Puerto Rico"},
    { code:"qa",name:"🇶🇦 Qatar"},
    { code:"ro",name:"🇷🇴 Romania"},
    { code:"ru",name:"🇷🇺 Russia"},
    { code:"rw",name:"🇷🇼 Rwanda"},
    { code:"ws",name:"🇼🇸 Samoa"},
    { code:"sm",name:"🇸🇲 San Marino"},
    { code:"st",name:"🇸🇹 São Tomé"},
    { code:"sa",name:"🇸🇦 Saudi Arabia"},
    { code:"sn",name:"🇸🇳 Senegal"},
    { code:"rs",name:"🇷🇸 Serbia"},
    { code:"sc",name:"🇸🇨 Seychelles"},
    { code:"sl",name:"🇸🇱 Sierra Leone"},
    { code:"sg",name:"🇸🇬 Singapore"},
    { code:"sk",name:"🇸🇰 Slovakia"},
    { code:"si",name:"🇸🇮 Slovenia"},
    { code:"sb",name:"🇸🇧 Solomon Islands"},
    { code:"so",name:"🇸🇴 Somalia"},
    { code:"za",name:"🇿🇦 South Africa"},
    { code:"kr",name:"🇰🇷 South Korea"},
    { code:"es",name:"🇪🇸 Spain"},
    { code:"lk",name:"🇱🇰 Sri Lanka"},
    { code:"sh",name:"🇸🇭 St. Helena"},
    { code:"vc",name:"🇻🇨 St. Vincent"},
    { code:"sr",name:"🇸🇷 Suriname"},
    { code:"se",name:"🇸🇪 Sweden"},
    { code:"ch",name:"🇨🇭 Switzerland"},
    { code:"tw",name:"🇹🇼 Taiwan"},
    { code:"tj",name:"🇹🇯 Tajikistan"},
    { code:"tz",name:"🇹🇿 Tanzania"},
    { code:"th",name:"🇹🇭 Thailand"},
    { code:"tl",name:"🇹🇱 Timor-Leste"},
    { code:"tg",name:"🇹🇬 Togo"},
    { code:"tk",name:"🇹🇰 Tokelau"},
    { code:"to",name:"🇹🇴 Tonga"},
    { code:"tt",name:"🇹🇹 Trinidad & Tobago"},
    { code:"tn",name:"🇹🇳 Tunisia"},
    { code:"tr",name:"🇹🇷 Turkey"},
    { code:"tm",name:"🇹🇲 Turkmenistan"},
    { code:"vi",name:"🇻🇮 U.S. Virgin Islands"},
    { code:"ug",name:"🇺🇬 Uganda"},
    { code:"ua",name:"🇺🇦 Ukraine"},
    { code:"ae",name:"🇦🇪 United Arab Emirates"},
    { code:"uy",name:"🇺🇾 Uruguay"},
    { code:"uz",name:"🇺🇿 Uzbekistan"},
    { code:"vu",name:"🇻🇺 Vanuatu"},
    { code:"ve",name:"🇻🇪 Venezuela"},
    { code:"vn",name:"🇻🇳 Vietnam"},
    { code:"zm",name:"🇿🇲 Zambia"},
    { code:"zw",name:"🇿🇼 Zimbabwe"}
    
  ];

  export const circular =
  {

   "question": {
    "can": [
      "can iphones get viruses",
      "can iphones get hacked",
      "can iphone 11 charge wirelessly",
      "can iphone share location with android",
      "can iphones block ads",
      "can iphones get viruses from websites",
      "can iphone 12 charge wirelessly",
      "can iphones charge each other",
      "can iphone 13 go underwater",
      "can iphone se charge wirelessly"
    ],
    "how": [
      "how iphones are made",
      "how iphone 14 look like",
      "how iphone changed the world",
      "how iphone track steps",
      "how iphone can block ads",
      "how iphone trade in works",
      "how iphone screenshot",
      "how iphone 13 pro max",
      "how iphone vibrate on silent",
      "how iphones work"
    ],
    "what": [
      "what iphone do i have",
      "what iphone should i get",
      "what iphones are waterproof",
      "what iphone has the best camera",
      "what iphone are we on",
      "what iphone is out now",
      "what iphone came out in 2020",
      "what iphones are 5g",
      "what iphone has 3 cameras",
      "what iphone came out in 2019"
    ],
    "which": [
      "which iphone has the best camera",
      "which iphone should i get",
      "which iphone do i have",
      "which iphone is the best",
      "which iphones are waterproof",
      "which iphone has 3 cameras",
      "which iphones are 5g",
      "which iphones have magsafe",
      "which iphone has the best battery life",
      "which iphone has the biggest screen"
    ],
    "who": [
      "who iphone invented",
      "who iphone is best",
      "who iphone cases",
      "iphone who made",
      "iphone who country made",
      "iphone who call",
      "who buys iphones near me",
      "who make iphone",
      "who designed iphone",
      "who found iphone"
    ],
    "why": [
      "why iphone gets hot",
      "why iphone is better than android",
      "why iphone says sos",
      "why iphone not charging",
      "why iphone not ringing",
      "why iphone won't turn on",
      "why iphone battery draining fast",
      "why iphone messages green",
      "why iphone battery yellow",
      "why iphone goes straight to voicemail"
    ],
    "will": [
      "will iphone 15 be usb c",
      "will iphone make a flip phone",
      "are iphones going away",
      "which iphone will soon be obsolete",
      "do iphones become obsolete"
    ],
    "are": [
      "are iphones waterproof",
      "are iphone 13 waterproof",
      "are iphone 14 waterproof",
      "are iphone 11 waterproof",
      "are iphone 12 waterproof",
      "are iphones made in china",
      "are iphones water resistant",
      "are iphones better than androids",
      "are iphones dual voltage",
      "are iphone xr waterproof"
    ]
  },
}