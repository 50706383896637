import React from "react";
import { Modal, Button } from "rsuite";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";
import { creditsPrice } from "../Pages/StringStore";

const CreditsModal = ({ tryOpen, handleCloseTry }) => {
    // const {subscribeData} = useSelector((state) => state.subscribe)
    const buyCredits = async (data) => {
        try{
     const creditsData = await privateRequest.post(`/api/payments/checkout-session/`,{ "billing_period": data})
    //  console.log("buyCredtis",creditsData)
     window.location.href = creditsData.data.billing_url;
        }catch(err){
            console.log(err)
        }
    }
  return (
    <div>
      <Modal
        backdrop="static"
        keyboard={false}
        open={tryOpen}
        onClose={handleCloseTry}
      >
        <Modal.Header>
          <Modal.Title>Buy Credits</Modal.Title>
        </Modal.Header>
        <table className="table">
          <thead>
            <tr>
              <th>Cost</th>
              <th>Credits</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {creditsPrice.map(({id,spell,doller,credits}) => (<tr key={id}>
              <td>${doller}</td>
              <td>{credits}</td>
              <td>
                <Button style={{backgroundColor:"#5167f6",fontFamily:"Poppins" ,fontSize:"12px",color:"#fff"}} onClick={() => buyCredits(spell)} size="xs" appearance="primary">Buy</Button>
              </td>
            </tr>))}
          </tbody>
        </table>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button style={{backgroundColor:"#160042",color:"#fff"}} onClick={handleCloseTry} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreditsModal;
