import React from "react";
import Headers from "./Headers";
import { Link } from "react-router-dom";
import Footers from "./Footers";
import styles from "../Pages/PrivacyPolicy.module.css";

const LimitedUse = () => {
  return (
    <div className="container">
      <Headers />
      <p className={`${styles.c23}`}>
        <span className={`${styles.c10} ${styles.c22}`}>
          Google API Services Usage Disclosure
        </span>
      </p>
      <p style={{ fontWeight: "400", fontSize: "18px" }}>
        YTubeBooster's use and transfer to any other app of information received
        from Google APIs will adhere to{" "}
        <a
          href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
          target="_blank"
          rel="noopener noreferrer" 
        >
          Google API Services User Data Policy
        </a>
        , including the Limited Use requirements.
      </p>
      <p style={{ fontWeight: "400", fontSize: "18px" }}>
        To the extent that the performance of the service entails YTubeBooster
        processing Personal Data as defined in the General Data Protection
        Regulation (or other applicable UK or EU legislation relating to data
        protection), such processing shall be subject to the data processing
        schedule.
      </p>
      <p style={{ fontWeight: "400", fontSize: "18px" }}>
        Our
        <Link to="/privacy-policy" target="_blank">
          {" "}
          privacy policy &nbsp;
        </Link>
        page documents in detail what data our app is requesting and why the
        requests access to Google user data
      </p>
      <Footers />
    </div>
  );
};

export default LimitedUse;
