import React, { useState } from "react";
import { Form, Formik } from "formik";
import CopyToClipboard from "react-copy-to-clipboard";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";
import { Tooltip, Whisper, useToaster } from "rsuite";
import InfoOutlineIcon from "@rsuite/icons/InfoOutline";
import { tooltips } from "../Pages/StringStore";
import { showErrorNotification } from "../../../Notification";

const TagExtractor = () => {
  const [multiVideo, setMultiVideo] = useState([]);
  const [isCheck, setIsCheck] = useState([]);
  const initialValues = {
    youtube_url: "",
  };
  const toaster = useToaster();

  const visibled = (e) => {
    const { name, checked } = e.target;
    // console.log("kw", name);
    setIsCheck([...isCheck, name]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== name));
    }
  };

  const ExtractTags = async (values) => {
    try {
      // console.log("data", data);
      const videos = await privateRequest.post(
        `/api/youtube/tag-extractor/`,
        values
      );
      if (videos.data.error === "Invalid Youtube URL") {
        // console.log(
        //   "error",
        //   "You have either entered an invalid YouTube URL or there are Typo errors"
        // );
        toaster.push(
          showErrorNotification(
            "You have either entered an invalid YouTube URL or there are typographical errors. Please check and try again."
          )
        );
      } else if (videos.data.error) {
        toaster.push(
          showErrorNotification(videos.data.error),{duration:0}
        );
        // console.log(videos.data.error);
        // showErrorNotification(videos?.data?.error, configToast);
      } else {
        setMultiVideo((res) => [videos.data, ...res]);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  // console.log("multiVideo", multiVideo);
  return (
    <>
      <div>
        <h1 className="ais" style={{ marginBottom: "0px" }}>
          Video Tag Extractor
          <span className="information ms-1">
            <Whisper
              placement="bottomEnd"
              controlId="control-id-click"
              trigger="hover"
              speaker={<Tooltip>{tooltips.tagExtractor}</Tooltip>}
            >
              <InfoOutlineIcon style={{ marginBottom: "12px" }} />
            </Whisper>
          </span>
        </h1>
      </div>
      <div
        className="containers-custom"
        style={{ paddingLeft: "30px", paddingTop: "16px" }}
      >
        <div className="f-tab-heading">
          Enter the YouTube video link to extract its tags. Select the tags and
          click copy to paste it into your video tag section.
        </div>
        <div className="container-fluid">
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            // onSubmit={ExtractTags}
            onSubmit={async (values, { resetForm }) => {
              await ExtractTags(values);
              resetForm();
            }}
          >
            {(formik) => {
              return (
                <Form>
                  <div className="row mt-2">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <div className="brix---position-relative-2">
                        <input
                          type="link"
                          className="brix---input-large-button-inside w-input"
                          autoFocus={true}
                          style={{ border: "none" }}
                          maxLength="256"
                          data-name="BRIX - Footer Email - V16"
                          placeholder="Paste the youtube video link to extract related tags"
                          id="BRIX-Footer-Email-V16"
                          name="youtube_url"
                          value={formik.values.youtube_url}
                          onChange={formik.handleChange}
                          // value={askyt}
                          //           onChange={(e) => setAskYt(e.target.value)}
                        />
                        {formik.values.youtube_url.length===0?
                        <input
                        type="button"
                        style={{ border: "none", backgroundColor: "grey" }}
                        data-wait="Please wait..."
                        className="brix---btn-primary-inside-input w-button"
                        value="Extract"
                      />
                        :
                        <input
                          type="submit"
                          data-wait="Please wait..."
                          value="Extract"
                          style={{ border: "none" }}
                          // onClick={(e) => ExtractTags(e, formik)}
                          className="brix---btn-primary-inside-input w-button"
                        />}
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <div className="container-fluid mb-4">
        <div className="row mt-2">
          <div className="row">
            {/* <button
                onClick={scrollToTops}
                style={{ display: visible ? "inline" : "none" }}
                className="btn btn-warning up-buttons"
              >
                <i class="fa-solid fa-chevron-up fol"></i>
              </button> */}
            {multiVideo.map((result, index) => (
              <div key={index} className="mb-2">
                <div
                  className="card tag-extract"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // width: "400px",
                    borderRadius: "15px",
                    // borderTopLeftRadius: "15px",
                    // borderTopRightRadius: "15px",
                  }}
                >
                  <img
                    src={result.video_data[0].url}
                    className="card-img-top-modified tag-extractor-images"
                    style={{ maxHeight: "150px" }}
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      {/* {result?.video_data?.map((res) => res?.title)} */}
                      {result.video_data[0].title}
                    </h5>
                    <p className="card-text" style={{marginBottom:"10px"}}>
                      Below are the tags used in this video
                    </p>

                    <div className="overflow-auto tagss">
                      {result.tags.split(",").map((res, index) => (
                        <label
                          key={index}
                          className="original-video-tag-badge"
                          style={
                            isCheck.includes(res)
                              ? {
                                  backgroundColor: "blue",
                                  color: "white",
                                  cursor: "pointer",
                                }
                              : { cursor: "pointer" }
                          }
                        >
                          <input
                            type="checkbox"
                            value={res}
                            name={res}
                            style={{ display: "none" }}
                            onChange={(e) => visibled(e)}
                            checked={isCheck.includes(res)}
                          />
                          <span>{res}</span>
                        </label>
                      ))}
                    </div>

                    <div style={{ marginTop: "20px", width: "100%" }}>
                      <CopyToClipboard text={isCheck}>
                        <p
                          className="btn btn-primary"
                          style={{background:"#4a3aff",fontSize:"14px"}}
                          // className="brix---btn-primary-inside-input w-button"
                          // style={{ width: "100%" }}
                        >
                          Copy Tags
                        </p>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TagExtractor;
