exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Sidebar_navflex__3n6F5 {\n  overflow-x: hidden;\n}\n.Sidebar_navigation__Gwvsx {\n  width: 200px;\n  position: fixed;\n  /* background-color: #5c42db; */\n  background-color: #0e0345;\n  padding: 20px;\n  height: 100vh;\n  overflow-y: auto;\n  border-top-right-radius: 15px;\n  border-bottom-right-radius: 15px;\n}\n\n.Sidebar_main-content__1T0Iw {\n  /* flex: 1;\n    padding-left: 20px; */\n  margin-left: 230px;\n  /* font-size: 200%;\n   padding: 0 3%; */\n}\n\n.Sidebar_navigation__Gwvsx .Sidebar_profile__7IipR {\n  display: flex;\n  align-items: center;\n  margin-bottom: 20px;\n}\n.Sidebar_profilename__1SZqN {\n  color: #fff;\n}\n.Sidebar_navigation__Gwvsx .Sidebar_profile__7IipR img {\n  /* width: 50px;\n    height: 50px; */\n  /* width: 67px; */\n  height: 24px;\n  /* border-radius: 19%; */\n  margin-right: 6px;\n  /* background-color: #fff; */\n}\n.Sidebar_navhowe__13-lE {\n  transition: all 0.2s ease-in-out;\n}\n\n.Sidebar_navhowe__13-lE:hover {\n  color: #fff !important;\n  transform: scale(1.1);\n}\n\n.Sidebar_navigation__Gwvsx ul {\n  font-size: 14px;\n  font-weight: 400;\n  list-style: none;\n  margin: 0;\n  padding: 0;\n}\n\n.Sidebar_navigation__Gwvsx li {\n  margin-bottom: 10px;\n}\n\n.Sidebar_navigation__Gwvsx a {\n  display: block;\n  color: #a4a9c9;\n  text-decoration: none;\n}\n\n.Sidebar_navigation__Gwvsx a:hover {\n  color: #444444;\n}\n", ""]);

// exports
exports.locals = {
	"navflex": "Sidebar_navflex__3n6F5",
	"navigation": "Sidebar_navigation__Gwvsx",
	"main-content": "Sidebar_main-content__1T0Iw",
	"profile": "Sidebar_profile__7IipR",
	"profilename": "Sidebar_profilename__1SZqN",
	"navhowe": "Sidebar_navhowe__13-lE"
};