import React,{useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { youtubeConnDataFetchStart } from "../../../redux/actions/youtubeAction";

const OtherAccount = () => {
    const navigate = useNavigate();
  const dispatch = useDispatch();
  const { youtubeData } = useSelector((state) => state.youtube);
  const handleData = () => {
    dispatch(youtubeConnDataFetchStart());
  };
  useEffect(() => {
    handleData();
  }, []);
  const OnLanding = () => {
    navigate("/dashboard");
  };
  return (
    <div
    className="container-fluid cf-pricing-hero-section"
    style={{ height: "100vh" }}
  >
    <div className="d-flex justify-content-center">
      <div
        className="cf-pricing-box-cards green-pricing-box"
        style={{ minHeight: "auto" }}
      >
        <div className="cf-pricing-box-plan-wrapper-2 white-border">
          <div className="cf-pricing-plan-text-2">
            The YouTube Channel you tried to connect with YTubeBoosterpro is
            already in use with another account.
          </div>
        </div>
        <div className="cf-pricing-plan-content-wrapper-2">
          <div className="cf-pricing-plan-content-item-2">
            <img
              src="images/2.svg"
              loading="lazy"
              alt=""
              className="cf-pricing-plan-tick-icon-2"
            />
            <div className="cf-pricing-plan-content-text-2">
              You can either connect a new YouTube channel or get in touch
              with the Customer Support team if you have any queries.
            </div>
          </div>
        </div>
        <a
          href={youtubeData}
          rel="noreferrer noopener"
          className="cf-primary-button-2 pricing-button w-buttons"
          // onClick={(e) => handlePricing(e, "m")}
        >
          Connect Channel
        </a>
        <div
          className="cf-primary-button-2 pricing-button w-buttons mt-2"
          onClick={OnLanding}
        >
          Continue To Your Dashborad
        </div>
      </div>
    </div>
  </div>
  )
}

export default OtherAccount