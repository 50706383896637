import * as actionTypes from '../actionTypes';

// const configToast = {
//     position: "top-right",
//     autoClose: 2000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: false,
//     draggable: false,
//     progress: undefined,
// }

export const youtubeConnDataFetchStart = () => {
    return {
        type: actionTypes.FETCH_YOUTUBE_START,
        // payload: {searchValue,from,to}
    }
}

export const youtubeConnDataFetchSuccess = (data) => {
    console.log('data', data)
    // showSuccessNotification('Data Loaded Succesfully', configToast)
    return {
        type: actionTypes.FETCH_YOUTUBE_SUCCESS,
        payload: data
    }
}

export const youtubeConnDataFetchFailed = (error) => {
    // showErrorNotification(error?.data?.message, configToast)
    return {
        type: actionTypes.FETCH_YOUTUBE_FAILD,
        payload: error
    }
}

// export const dashboardDataFetchStart = () => {
//     return {
//         type: actionTypes.FETCH_DASHBOARD_START,
//         // payload: {searchValue,from,to}
//     }
// }

// export const dashboardDataFetchSuccess = (data) => {
//     // showSuccessNotification('Data Loaded Succesfully', configToast)
//     return {
//         type: actionTypes.FETCH_DASHBOARD_SUCCESS,
//         payload: data
//     }
// }

// export const dashboardDataFetchFailed = (error) => {
//     showErrorNotification(error?.data?.message, configToast)
//     return {
//         type: actionTypes.FETCH_DASHBOARD_FAILD,
//         payload: error
//     }
// }