import React, { useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Tooltip, Whisper } from "rsuite";
import BlogPost from "../Pages/BlogPost";
import Description from "../Pages/Description";
import SmPost from "../Pages/SmPost";
import TitleGenerator from "../Pages/TitleGenerator";
import InfoOutlineIcon from "@rsuite/icons/InfoOutline";
import { tooltips } from "../Pages/StringStore";
import VideoContentGenerator from "../Pages/VideoContentGenerator";

const AiGenerator = () => {
  const [locate, setLocate] = useState("title");
  const { accessData } = useSelector((state) => state.accessModule);
  const selectedss = (e, data) => {
    // console.log("e", data);
    setLocate(data);
  };

  // console.log("accessData", accessData);
  return (
    <div>
      <h1 className="ais">
        AI Generator
        <span className="information ms-1">
          <Whisper
            placement="bottomEnd"
            controlId="control-id-click"
            trigger="hover"
            speaker={<Tooltip>{tooltips.Aigenerator}</Tooltip>}
          >
            <InfoOutlineIcon style={{ marginBottom: "12px" }} />
          </Whisper>
        </span>
      </h1>
      <div>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Nav
            variant="pills"
            style={{
              backgroundColor: "#eff0f6",
              paddingTop: "12px",
              paddingBottom: "12px",
              paddingLeft: "12px",
              borderRadius: "10px",
              fontSize: "14px",
            }}
            className="d-flex gap-3 pd-5"
          >
            {accessData.title_generator === false ? (
              ""
            ) : (
              <Nav.Item
                style={{
                  borderBottomColor: "#642eff",
                  borderRadius: "50px",
                }}
              >
                <Nav.Link
                  onClick={(e) => selectedss(e, "title")}
                  style={
                    locate === "title"
                      ? {
                          borderBottomColor: "#642eff",
                          backgroundColor: "#fff",
                          color: "#160042",
                          fontWeight: "500",
                          padding: "12px 44px",
                        }
                      : {
                          backgroundColor: "#eff0f6",
                          color: "#160042",
                          padding: "12px 44px",
                        }
                  }
                  eventKey="first"
                >
                  Title Generator
                </Nav.Link>
              </Nav.Item>
            )}
            {accessData.description_generator === false ? (
              ""
            ) : (
              <Nav.Item>
                <Nav.Link
                  eventKey="second"
                  onClick={(e) => selectedss(e, "descrip")}
                  style={
                    locate === "descrip"
                      ? {
                          borderBottomColor: "#642eff",
                          backgroundColor: "#fff",
                          color: "#160042",
                          padding: "12px 44px",
                        }
                      : {
                          backgroundColor: "#eff0f6",
                          color: "#160042",
                          padding: "12px 44px",
                        }
                  }
                >
                  Description Generator
                </Nav.Link>
              </Nav.Item>
            )}
            {accessData.blog_writer === false ? (
              ""
            ) : (
              <Nav.Item>
                <Nav.Link
                  eventKey="third"
                  onClick={(e) => selectedss(e, "blog")}
                  style={
                    locate === "blog"
                      ? {
                          borderBottomColor: "#642eff",
                          backgroundColor: "#fff",
                          color: "#160042",
                          padding: "12px 44px",
                        }
                      : {
                          backgroundColor: "#eff0f6",
                          color: "#160042",
                          padding: "12px 44px",
                        }
                  }
                >
                  Blog Writer
                </Nav.Link>
              </Nav.Item>
            )}
            {accessData.social_media_post_generator === false ? (
              ""
            ) : (
              <Nav.Item>
                <Nav.Link
                  eventKey="forth"
                  onClick={(e) => selectedss(e, "smpost")}
                  style={
                    locate === "smpost"
                      ? {
                          borderBottomColor: "#642eff",
                          backgroundColor: "#fff",
                          color: "#160042",
                          padding: "12px 44px",
                        }
                      : {
                          backgroundColor: "#eff0f6",
                          color: "#160042",
                          padding: "12px 44px",
                        }
                  }
                >
                  SM Post Generator
                </Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item>
                <Nav.Link
                  eventKey="fifth"
                  onClick={(e) => selectedss(e, "videocontent")}
                  style={
                    locate === "videocontent"
                      ? {
                          borderBottomColor: "#642eff",
                          backgroundColor: "#fff",
                          color: "#160042",
                          padding: "12px 44px",
                        }
                      : {
                          backgroundColor: "#eff0f6",
                          color: "#160042",
                          padding: "12px 44px",
                        }
                  }
                >
                  Video Content Generator
                </Nav.Link>
              </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <div
                // className="containers-custom"
                style={{ marginBottom: "10px" }}
              >
                <TitleGenerator />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <div
                
                style={{
                  marginBottom: "10px",
                  maxWidth: "1200px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Description />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <div >
                <BlogPost />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="forth">
              <SmPost />
            </Tab.Pane>
            <Tab.Pane eventKey="fifth">
              <VideoContentGenerator />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default AiGenerator;
