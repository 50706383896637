import React from "react";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Headers = () => {
  const navigate = useNavigate();
  const login = () => {
    navigate("/login");
  };

  return (
    <div className="navbar-no-shadow wf-section">
      <div
        data-animation="default"
        data-collapse="medium"
        data-duration={400}
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="navbar-no-shadow-container w-nav"
      >
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container fluid>
            <Navbar.Brand href="/landing#home">
              <p
                className="navbar-brand w-nav-brand"
                style={{
                  backgroundColor: "#fff",
                  padding: "5px",
                  borderRadius: "4px",
                }}
              >
                <img
                  src="/images/YTBPRo.svg"
                  loading="lazy"
                  width="200px"
                  alt="pro"
                />
              </p>
            </Navbar.Brand>
            {/* <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xxl`} /> */}
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <div className="w-icon-nav-menu" />{" "}
            </Navbar.Toggle>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-lg`}
              aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                  <p
                    className="navbar-brand w-nav-brand"
                    style={{
                      backgroundColor: "#fff",
                      padding: "5px",
                      borderRadius: "4px",
                    }}
                  >
                    <img
                      src="/images/YTBPRo.svg"
                      loading="lazy"
                      width="200px"
                      alt="pro"
                    />
                  </p>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav
                  className="justify-content-end flex-grow-1"
                  style={{ textAlign: "center" }}
                >
                  <Nav.Link href="/landing#home">Home</Nav.Link>
                  <Nav.Link href="/landing#feature-comp">Features</Nav.Link>
                  <Nav.Link href="/landing#pricings">Pricing</Nav.Link>
                  <Nav.Link href="/landing#faq">Faq</Nav.Link>
                  <Nav.Link href="/landing#contact-us">Contact Us</Nav.Link>
                  <Nav.Link
                    href="https://blog.ytubebooster.app/"
                    target="_blank"
                  >
                    Blogs
                  </Nav.Link>
                </Nav>
                <div className="nav-button-wrapper">
                  <p onClick={login} className="button-primary w-buttons">
                    Login
                  </p>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </div>
    </div>
  );
};

export default Headers;
