import React, { useEffect, useState } from "react";
// import { Accordion } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Field, Form, Formik } from "formik";
// import axios from "axios";
import { ProgressBar } from "react-loader-spinner";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";
import { Button, Modal, Tooltip, Whisper, useToaster } from "rsuite";
import { useDispatch } from "react-redux";
import {
  fetchAdditionalCreditsStart,
  fetchTotalCreditsStart,
} from "../../../redux/actions/creditsActions";
import { showErrorNotification } from "../../../Notification";
import moment from "moment";
import AIheader from "./AIheader";
import { AiHeaders } from "./StringStore";

const TitleGenerator = () => {
  const [titleList, setTitleList] = useState([]);
  const [titleGen, setTitleGen] = useState([]);
  const [titleLoader, setTitleLoader] = useState(false);
  const [modedelOpen, setModeDelOpen] = useState(false);
  const [ids, setIds] = useState();
  const deleteTitle = (data) => {
    setModeDelOpen(true);
    setIds(data.id);
  };

  const handledelCloseds = () => {
    setModeDelOpen(false);
  };

  const deleteSureTitle = async () => {
    try {
      const deleteData = await privateRequest.delete(
        `/api/payments/title/${ids}/`
      );
      console.log("delete", deleteData);
    } catch (err) {
      console.log("dekle", err);
    }
    handledelCloseds();
    getTitle();
  };
  const toaster = useToaster();
  const dispatch = useDispatch();

  const generateTitle = async (values) => {
    try {
      setTitleLoader(true);
      const titlesGenerations = await privateRequest.post(
        `/api/youtube/title/research/`,
        values
      );
      setTitleLoader(false);
      // console.log("titlesGenetations", titlesGenerations.data.data);
      setTitleGen(titlesGenerations.data.data);
    } catch (err) {
      console.log("err", err);
      toaster.push(showErrorNotification(err.response.data.message), {
        duration: 0,
      });
      setTitleLoader(false);
    }
    dispatch(fetchAdditionalCreditsStart());
    dispatch(fetchTotalCreditsStart());
  };
  const getTitle = async () => {
    try {
      const data = await privateRequest.get(`/api/payments/title/`);
      // console.log("titlegenter", data.data);
      setTitleList(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const initialValues = {
    tc_user_input: "",
  };

  const addTitle = async (e, data) => {
    const payloads = {
      title: data,
    };
    try {
      const data = await privateRequest.post(`/api/payments/title/`, payloads);
      console.log("addeddata", data);
    } catch (err) {
      console.log(err);
    }
    getTitle();
  };

  useEffect(() => {
    getTitle();
  }, []);
  return (
    <div className="row">
      <div className="mt-3 mb-4">
        <AIheader
          title={AiHeaders.titleGenTitle}
          description={AiHeaders.titleGendesctiption}
          lasttext = {AiHeaders.titleGenlast}
        />
      </div>
      <div
        className="mt-3 containers-custom col uk-margin-left uk-margin-right pe-4"
        // style={{ paddingLeft: "30px", paddingRight: "30px" }}
      >
        <div className="f-tab-heading ">
          Add not more than 3 words to help our AI give you the best 20 titles
          for your query
        </div>
        <div
          className="brix---position-relative-2"
          style={{ paddingLeft: "15px" }}
        >
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={generateTitle}
            // onSubmit={async (values, { resetForm }) => {
            //   await generateTitle(values);
            //   resetForm();
            // }}
          >
            {(formik) => {
              return (
                <Form>
                  <Field
                    type="text"
                    className="brix---input-large-button-inside w-input"
                    style={{ border: "none" }}
                    autoFocus={true}
                    maxLength="256"
                    name="tc_user_input"
                    data-name="BRIX - Footer Email - V16"
                    placeholder="Enter your keywords to generate Title"
                    id="BRIX-Footer-Email-V16"
                  />
                  {formik.values.tc_user_input.length === 0 ||
                  titleLoader === true ? (
                    <input
                      type="button"
                      style={{ border: "none", backgroundColor: "grey" }}
                      data-wait="Please wait..."
                      className="brix---btn-primary-inside-input w-button"
                      value="Generate"
                    />
                  ) : (
                    <input
                      type="submit"
                      value="Generate"
                      data-wait="Please wait..."
                      style={{ border: "none" }}
                      className="brix---btn-primary-inside-input w-button"
                    />
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
        <div style={{ paddingLeft: "15px" }}>
          <div className="title-table d-flex justify-content-between align-items-center">
            <h5 className="heading-title" style={{ marginBottom: "10px" }}>
              Title Suggestions
            </h5>
            <h5 className="heading-title" style={{ marginBottom: "10px" }}>
              Actions
            </h5>
          </div>
          {titleLoader === true ? (
            <div className="d-flex justify-content-center align-items-center mt-3">
              LOADING &nbsp;
              <ProgressBar
                height="80"
                width="80"
                ariaLabel="progress-bar-loading"
                // wrapperStyle={{}}
                wrapperClass="progress-bar-wrapper"
                borderColor="#53389e"
                barColor="#53389e"
              />
            </div>
          ) : (
            <>
              {titleGen.map((res, index) => (
                <div
                  key={index + 1}
                  className="d-flex justify-content-between align-items-center for-bor mb-2"
                  style={{ paddingLeft: "24px", paddingRight: "40px" }}
                >
                  <div className="text-block-10">{res}</div>
                  <div className="d-flex gap-1">
                    <div
                      className="button-2-save w-button"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => addTitle(e, res)}
                    >
                      <strong className="bold-text-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ color: "#fff" }}
                          width="15"
                          height="15"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-save"
                        >
                          <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                          <polyline points="17 21 17 13 7 13 7 21"></polyline>
                          <polyline points="7 3 7 8 15 8"></polyline>
                        </svg>
                      </strong>
                    </div>
                    <CopyToClipboard text={res}>
                      <div
                        className="button-2-copy w-button"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        <Whisper
                          placement="top"
                          controlId="control-id-click"
                          trigger="click"
                          speaker={<Tooltip>Copied</Tooltip>}
                        >
                          <strong className="bold-text-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ color: "#fff" }}
                              width="15"
                              height="15"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-copy"
                            >
                              <rect
                                x="9"
                                y="9"
                                width="13"
                                height="13"
                                rx="2"
                                ry="2"
                              ></rect>
                              <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                            </svg>
                          </strong>
                        </Whisper>
                      </div>
                    </CopyToClipboard>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <div className="col-lg-4 mt-4 mb-4 row uk-margin-right">
        <div className="col">
          <div
            className="f-tab-heading column-9"
            style={{ maxHeight: "600px", overflowY: "scroll" }}
          >
            <div className="f-tab-saved-heading">Saved Title</div>
            {titleList.map((res) => (
              <div
                key={res.id}
                className="text-block-10 title-list d-flex justify-content-between align-items-center"
              >
                <div>
                  {res.title}
                  <p style={{ fontSize: "10px", opacity: "0.7" }}>
                    created on: {moment(res.created_at).format(" Do dddd YYYY")}
                  </p>
                </div>
                <div className="d-flex">
                  <svg
                    onClick={() => deleteTitle(res)}
                    style={{ marginRight: "5px", cursor: "pointer" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-trash"
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  </svg>
                  <CopyToClipboard text={res.title}>
                    <div className="" style={{ cursor: "pointer" }}>
                      <Whisper
                        placement="top"
                        controlId="control-id-click"
                        trigger="click"
                        speaker={<Tooltip>Copied</Tooltip>}
                      >
                        <strong className="bold-text-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-copy"
                          >
                            <rect
                              x="9"
                              y="9"
                              width="13"
                              height="13"
                              rx="2"
                              ry="2"
                            ></rect>
                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                          </svg>
                        </strong>
                      </Whisper>
                    </div>
                  </CopyToClipboard>
                </div>
              </div>
            ))}
            {/* <p className="para">
              Click on the titles to reveal the AI suggested keywords.
            </p> */}
            {/* <Accordion>
              <Accordion.Item style={{ border: "1px solid #fff" }} eventKey="0">
                <Accordion.Header>Accordion Item 1</Accordion.Header>
                <Accordion.Body>
                  <div className="uui-text-size-medium-suggested-accordian-keyword text-color-white">
                    <h6 className="pill-recomd">
                      Recomended tags for your title
                    </h6>
                    <span className="kr-sugkey-keyword">Music</span>
                    <span className="kr-sugkey-keyword">music binaural</span>
                    <span className="kr-sugkey-keyword">beats binaural</span>
                    <span className="kr-sugkey-keyword">sleep binaural</span>
                    <span className="kr-sugkey-keyword">binaural beats</span>
                    <span className="kr-sugkey-keyword">
                      study binaural beats
                    </span>
                    <span className="kr-sugkey-keyword">meditation</span>
                    <span className="kr-sugkey-keyword">calming music</span>
                    <span className="kr-sugkey-keyword">meditation music</span>
                    <span className="kr-sugkey-keyword">relaxing music</span>
                    <span className="kr-sugkey-keyword">calm music</span>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-sm" style={{ color: "#53389e" }}>
                      copy tags
                    </button>
                    <button className="btn btn-sm" style={{ color: "#53389e" }}>
                      copy title
                    </button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item style={{ border: "1px solid #fff" }} eventKey="1">
                <Accordion.Header>Accordion Item #2</Accordion.Header>
                <Accordion.Body>
                  <div className="uui-text-size-medium-suggested-accordian-keyword text-color-white">
                    <h6 className="pill-recomd">
                      Recomended tags for your title
                    </h6>
                    <span className="kr-sugkey-keyword">Music</span>
                    <span className="kr-sugkey-keyword">music binaural</span>
                    <span className="kr-sugkey-keyword">beats binaural</span>
                    <span className="kr-sugkey-keyword">sleep binaural</span>
                    <span className="kr-sugkey-keyword">binaural beats</span>
                    <span className="kr-sugkey-keyword">
                      study binaural beats
                    </span>
                    <span className="kr-sugkey-keyword">meditation</span>
                    <span className="kr-sugkey-keyword">calming music</span>
                    <span className="kr-sugkey-keyword">meditation music</span>
                    <span className="kr-sugkey-keyword">relaxing music</span>
                    <span className="kr-sugkey-keyword">calm music</span>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-sm" style={{ color: "#53389e" }}>
                      copy tags
                    </button>
                    <button className="btn btn-sm" style={{ color: "#53389e" }}>
                      copy title
                    </button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
          </div>
        </div>
      </div>
      {/* delete title modal */}
      <Modal
        backdrop="static"
        size="xs"
        keyboard={false}
        open={modedelOpen}
        onClose={handledelCloseds}
      >
        <Modal.Header>{/* <Modal.Title>Delete </Modal.Title> */}</Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h6>Are you sure you want to delete</h6>
            <Modal.Footer>
              <Button
                style={{ backgroundColor: "#160042", color: "#fff" }}
                onClick={handledelCloseds}
                appearance="subtle"
              >
                NO
              </Button>
              <Button
                style={{ backgroundColor: "#160042", color: "#fff" }}
                onClick={deleteSureTitle}
                appearance="subtle"
              >
                YES
              </Button>
            </Modal.Footer>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TitleGenerator;
