import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
// import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ProgressBar } from "react-loader-spinner";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";
import { Tooltip, Whisper, useToaster } from "rsuite";
import { useDispatch } from "react-redux";
import {
  fetchAdditionalCreditsStart,
  fetchTotalCreditsStart,
} from "../../../redux/actions/creditsActions";
import { showErrorNotification } from "../../../Notification";
import ReactMarkdown from "react-markdown";
import AIheader from "./AIheader";
import { AiHeaders } from "./StringStore";

const SmPost = () => {
  const [facebooks, setFacebooks] = useState([]);
  const [instagrams, setInstagams] = useState([]);
  const [twitter, setTwitter] = useState([]);
  const [fb, setfb] = useState("");
  const [insta, setInsta] = useState("");
  const [twitters, setTwitters] = useState("");
  // eslint-disable-next-line
  const [tone, setTone] = useState("Formal");
  // const [height, setHeight] = useState('auto');
  const [loading, setLoading] = useState(false);
  const toaster = useToaster();
  const dispatch = useDispatch();
  //  const [twitter,setTwitter] = useState([])
  //  console.log('facebooks', facebooks)

  const markdown = (markdownContent) => {
    // Replace headers (e.g., # Header) with their text content
    markdownContent = markdownContent.replace(/^# (.+)$/gm, "$1");

    // Replace italic text (e.g., *italic*) with its content
    markdownContent = markdownContent.replace(/\*([^*]+)\*/g, "$1");

    // Replace bold text (e.g., **bold**) with its content
    markdownContent = markdownContent.replace(/\*\*([^*]+)\*\*/g, "$1");

    // Replace unordered lists with plain text
    markdownContent = markdownContent.replace(/^\s*-\s+/gm, "");

    // Replace hyperlinks (e.g., [text](url)) with their text content
    markdownContent = markdownContent.replace(/\[([^\]]+)\]\([^)]+\)/g, "$1");

    downloadTxtFile(markdownContent);
  };

  const downloadTxtFile = (fac) => {
    // console.log("facebookss",fac)
    // text content
    // const texts = ["line 1", "line 2", "line 3"];

    // file object
    const file = new Blob([fac], { type: "text/plain" });

    // anchor link
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    // eslint-disable-next-line
    element.download = "100ideas-" + ".txt";

    // simulate link click
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  // dropdown select tone
  const select_tone = [
    "Formal",
    "Casual",
    "Playful",
    "Authoritative",
    "Inspirational",
    "Informative",
    "Persuasive",
    "Empathetic",
  ];
  const addtext = async (values) => {
    // console.log("valuesyfyfy", values);
    // console.log("fbb",tone);
    const allData = {
      user_input: values.user_input,
      fb: fb,
      insta: insta,
      twitter: twitters,
    };
    // console.log("logicals", allData);

    try {
      setLoading(true);
      const data = await privateRequest.post(
        "/api/youtube/description/hashtags/instagram/",
        allData
      );
      setLoading(false);
      // console.log("data", data.data.facebook.replace(/\n/g,"<br />"));
      // setHeight(`${textarea.scrollHeight}px`);
      // console.log('textarea.scrollHeight', textarea.scrollHeight)
      setFacebooks(data.data.facebook);
      setInstagams(data.data.instagram);
      setTwitter(data.data.twitter);
    } catch (err) {
      console.log("err", err);
      toaster.push(showErrorNotification(err.response.data.message), {
        duration: 0,
      });
      setLoading(false);
    }
    dispatch(fetchAdditionalCreditsStart());
    dispatch(fetchTotalCreditsStart());
  };
  const initialValues = {
    user_input: "",
  };

  const handleFacebookCheck = (e) => {
    // console.log("haha", e.target.checked === true ? 1 : "");
    const checkmark = e.target.checked === true ? 1 : "";
    setfb(checkmark);
  };
  const handleInstaCheck = (e) => {
    // console.log("haha", e.target.checked === true ? 1 : "");
    const checkmark = e.target.checked === true ? 1 : "";
    setInsta(checkmark);
  };
  const handleTwitCheck = (e) => {
    // console.log("haha", e.target.checked === true ? 1 : "");
    const checkmark = e.target.checked === true ? 1 : "";
    setTwitters(checkmark);
  };

  return (
    <div className="container mt-3">
      <div className="maxwdit">
        <div className="mb-4">
          <AIheader
            title={AiHeaders.smtitle}
            description={AiHeaders.smdescription}
            lasttext={AiHeaders.smlast}
          />
        </div>
        <div>
          {/* <div className="f-heading-detail-small-2 uk-margin-bottom">
            Generate Social Media Posts for your video
          </div> */}
          <div className="row containers-custom">
            <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12">
              <Formik
                initialValues={initialValues}
                // validationSchema={validationSchema}
                onSubmit={async (values, { resetForm }) => {
                  await addtext(values);
                  resetForm();
                }}
              >
                {(formik) => {
                  return (
                    <Form>
                      <div className="brix---position-relative-2">
                        <Field
                          type="text"
                          className="brix---input-large-button-inside w-input"
                          autoFocus={true}
                          maxLength="256"
                          name="user_input"
                          style={{ border: "none" }}
                          data-name="BRIX - Footer Email - V16"
                          placeholder="Enter your keywords to generate social media post"
                          id="BRIX-Footer-Email-V16"
                        />
                        {(fb === 1) & (formik.values.user_input.length !== 0) ||
                        (insta === 1) &
                          (formik.values.user_input.length !== 0) ||
                        (twitters === 1) &
                          (formik.values.user_input.length !== 0) ? (
                          <input
                            type="submit"
                            style={{ border: "none" }}
                            data-wait="Please wait..."
                            className="brix---btn-primary-inside-input w-button"
                            value="Generate"
                          />
                        ) : (
                          <input
                            type="button"
                            style={{ border: "none", backgroundColor: "grey" }}
                            data-wait="Please wait..."
                            className="brix---btn-primary-inside-input w-button"
                            value="Generate"
                          />
                        )}
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
            <div className="col">
              <select
                name="country_code"
                // value={formik.values.country_code}
                // onChange={formik.handleChange}
                onChange={(e) => setTone(e.target.value)}
                style={{
                  height: "49px",
                  borderRadius: "10px",
                  border: "1px solid #fff",
                  width: "auto",
                  boxShadow: "0 2px 7px 0 rgba(20, 20, 43, 0.08)",
                  transition:
                    "box-shadow 300ms ease, color 300ms ease, border-color 300ms ease",
                }}
                className="form-select"
                aria-label="Default select example"
              >
                {select_tone.map((res, index) => (
                  <option key={index} value={res}>
                    {res}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="uk-margin-top">
            <label className="uk-margin-right">
              <input onChange={handleFacebookCheck} type="checkbox" />
              &nbsp;Facebook
            </label>

            <label className="uk-margin-right">
              <input onChange={handleInstaCheck} type="checkbox" />
              &nbsp;instagram
            </label>
            <label onChange={handleTwitCheck} className="uk-margin-right">
              <input type="checkbox" />
              &nbsp;Twitter
            </label>
          </div>
        </div>
        {loading === true ? (
          <div className="d-flex justify-content-center align-items-center mt-3">
            LOADING &nbsp;
            <ProgressBar
              height="80"
              width="80"
              ariaLabel="progress-bar-loading"
              // wrapperStyle={{}}
              wrapperClass="progress-bar-wrapper"
              borderColor="#53389e"
              barColor="#53389e"
            />
          </div>
        ) : (
          <>
            {facebooks === undefined ? (
              ""
            ) : facebooks.length === 0 ? (
              ""
            ) : (
              <div>
                <h1 className="f-h2-heading" style={{ color: "#0059DC" }}>
                  <span style={{ paddingRight: "10px", paddingBottom: "10px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-facebook"
                    >
                      <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                    </svg>
                  </span>
                  Facebook
                </h1>
                <div className="f-margin-bottom-40 custom-background">
                  {/* <p className="f-paragraph-large-2">
              {facebooks}
            </p> */}
                  {/* <textarea
                    style={{ fontSize: "14px" }}
                    disabled
                    className="form-control"
                    value={facebooks}
                    maxLength={5000}
                    cols="100"
                    rows="12"
                  ></textarea> */}
                  <ReactMarkdown className="markdown">
                    {facebooks}
                  </ReactMarkdown>
                  <div className="d-flex justify-content-end ">
                    <p
                      className="f-smpost-gen-copy w-button me-1"
                      style={{ cursor: "pointer" }}
                      onClick={() => markdown(facebooks)}
                    >
                      download Text
                    </p>
                    <CopyToClipboard text={facebooks}>
                      <Whisper
                        placement="top"
                        controlId="control-id-click"
                        trigger="click"
                        speaker={<Tooltip>Copied</Tooltip>}
                      >
                        <p
                          className="f-smpost-gen-copy w-button mt-0"
                          style={{ cursor: "pointer" }}
                        >
                          Copy
                        </p>
                      </Whisper>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            )}
            {instagrams === undefined ? (
              ""
            ) : instagrams.length === 0 ? (
              ""
            ) : (
              <div>
                <h1 className="f-h2-heading" style={{ color: "#BE2578" }}>
                  <span style={{ paddingRight: "10px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45"
                      height="45"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-instagram"
                    >
                      <rect
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="5"
                        ry="5"
                      ></rect>
                      <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                      <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                    </svg>
                  </span>
                  Instagram
                </h1>
                <div className="f-margin-bottom-40 custom-background">
                  {/* <p className="f-paragraph-large-2">
              {res.text}
            </p> */}
                  {/* <textarea
                    style={{ fontSize: "14px" }}
                    disabled
                    className="form-control"
                    value={instagrams}
                    maxLength={5000}
                    cols="100"
                    rows="12"
                  ></textarea> */}
                  <ReactMarkdown className="markdown">
                    {instagrams}
                  </ReactMarkdown>
                  <div className="d-flex justify-content-end ">
                    <p
                      className="f-smpost-gen-copy w-button me-1"
                      style={{ cursor: "pointer" }}
                      onClick={() => markdown(instagrams)}
                    >
                      download Text
                    </p>
                    <CopyToClipboard text={instagrams}>
                      <Whisper
                        placement="top"
                        controlId="control-id-click"
                        trigger="click"
                        speaker={<Tooltip>Copied</Tooltip>}
                      >
                        <p
                          className="f-smpost-gen-copy w-button mt-0"
                          style={{ cursor: "pointer" }}
                        >
                          Copy
                        </p>
                      </Whisper>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            )}
            {twitter === undefined ? (
              ""
            ) : twitter.length === 0 ? (
              ""
            ) : (
              <div>
                <h1 className="f-h2-heading" style={{ color: "#1DA1F2" }}>
                  <span style={{ paddingRight: "10px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-twitter"
                    >
                      <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                    </svg>
                  </span>
                  Twitter
                </h1>
                <div className="f-margin-bottom-40 custom-background">
                  <ReactMarkdown className="markdown">{twitter}</ReactMarkdown>
                  {/* <p className="f-paragraph-large-2">
            {res}
            </p> */}
                  {/* <textarea
                    style={{ fontSize: "14px" }}
                    disabled
                    className="form-control"
                    value={twitter}
                    maxLength={5000}
                    rows="12"
                    cols="100"
                  ></textarea> */}
                  <div className="d-flex justify-content-end ">
                    <p
                      className="f-smpost-gen-copy w-button me-1"
                      style={{ cursor: "pointer" }}
                      onClick={() => markdown(twitter)}
                    >
                      download Text
                    </p>
                    <CopyToClipboard text={twitter}>
                      <Whisper
                        placement="top"
                        controlId="control-id-click"
                        trigger="click"
                        speaker={<Tooltip>Copied</Tooltip>}
                      >
                        <p
                          className="f-smpost-gen-copy w-button mt-0"
                          style={{ cursor: "pointer" }}
                        >
                          Copy
                        </p>
                      </Whisper>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SmPost;
