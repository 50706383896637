import * as actionTypes from "../actionTypes";
const inititalState = {
  isDataFetch: false,
  totalCreditsData: "",
  additionalCreditsData:"",
  isError: false,
  error: {},
};

export const totalCreditsDetailsReducer = (state = inititalState, action) => {
    switch(action.type){
        case actionTypes.FETCH_TOTAL_CREDITS_DETAILS_START:
      return {
        ...state,
        isDataFetch: true,
        isError: false,
        error: {},
      };
      case actionTypes.FETCH_TOTAL_CREDITS_DETAILS_SUCCESS:
          return{
            ...state,
            isDataFetch: false,
            totalCreditsData: action.payload,
            isError: false,
            error: {},
          }
          case actionTypes.FETCH_TOTAL_CREDITS_DETAILS_FAILED:
            return {
              ...state,
              isError: true,
              isDataFetch: false,
              error: { ...action.payload },
            };

            case actionTypes.FETCH_ADDITONAL_CREDITS_DETAILS_START:
                return {
                  ...state,
                  isDataFetch: true,
                  isError: false,
                  error: {},
                };
                case actionTypes.FETCH_ADDITONAL_CREDITS_DETAILS_SUCCESS:
                    return{
                      ...state,
                      isDataFetch: false,
                      additionalCreditsData: action.payload,
                      isError: false,
                      error: {},
                    }
                    case actionTypes.FETCH_ADDITONAL_CREDITS_DETAILS_FAILED:
                      return {
                        ...state,
                        isError: true,
                        isDataFetch: false,
                        error: { ...action.payload },
                      };
          default:
            return state;

    }

}

