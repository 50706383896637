import React from "react";

const SocialMediaPage = ({
  formik,
  SocialMedai,
  applySocialLink,
  socialInputsFields,
  increaseInput,
  decreaseInputField,
  handleDynamicChange,
  socialMediaApp,
  getSocialLinks,
}) => {

  return (
    <>
      <div>
        <div className="uk-card uk-card-default uk-card-body">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="f-tab-heading-4">
              Insert social links to description
            </div>
            <div className="btn btn-warning me-2" style={{fontSize:"12px"}} onClick={increaseInput}>
              Add +
            </div>
          </div>
          {socialInputsFields.map(({ name, url }, index) => (
            <div className="social-media-dynamic">
              <div>
                <select
                  style={{
                    fontSize: "14px",
                    textAlign:"center",
                    maxWidth: "110px",
                    color: "#fff",
                    backgroundColor: "#6a11cb",
                    height: "39px",
                  }}
                  value={name}
                  onChange={(evnt) => handleDynamicChange(index, evnt)}
                  name="name"
                  className="form-control custom-select"
                >
                  <option value={name}>
                    {name !== "" ? name : "Select"}
                  </option>
                  {socialMediaApp.map((res) => (
                    <option key={res.id} value={res.media}>
                      {res.media}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-block-2">
                <div id="email-form" className="form-5">
                  <input
                    value={url}
                    type="text"
                    control="input"
                    name="url"
                    onChange={(evnt) => handleDynamicChange(index, evnt)}
                    style={{ fontSize: "14px", marginRight: "5px" }}
                    class="form-control"
                    placeholder="Insert link"
                    aria-label=""
                    aria-describedby="addon-wrapping"
                  />
                  <button
                    className="btn btn-danger"
                    onClick={(e) => decreaseInputField(e, index)}
                  >
                    -
                  </button>
                </div>
              </div>
            </div>
          ))}
          {/* <div className="div-block-22">
            <div className="icon-featured-large-social">
              <div className="uui-icon-1x1-xsmall-4 w-embed">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-facebook"
                >
                  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                </svg>
              </div>
            </div>
            <div className="form-block-2 w-form">
              <div id="email-form" className="form-5">
                <input
                  type="text"
                  className="text-field-2 w-input"
                  maxLength="256"
                  data-name="Name 2"
                  id="name-2"
                  placeholder="Paste Your Link here"
                  name="facebook"
                  // form={formik}
                  value={formik.values.facebook}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          </div> */}
          {/* <div className="div-block-22">
            <div className="icon-featured-large-social">
              <div className="uui-icon-1x1-xsmall-4 w-embed">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-twitter"
                >
                  <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                </svg>
              </div>
            </div>
            <div className="form-block-2 w-form">
              <div id="email-form" className="form-5">
                <input
                  type="text"
                  className="text-field-2 w-input"
                  maxLength="256"
                  data-name="Name 2"
                  id="name-2"
                  placeholder="Paste Your Link here"
                  name="twitter"
                  // form={formik}
                  value={formik.values.twitter}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          </div> */}
          {/* <div className="div-block-22">
            <div className="icon-featured-large-social">
              <div className="uui-icon-1x1-xsmall-4 w-embed">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-instagram"
                >
                  <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                </svg>
              </div>
            </div>
            <div className="form-block-2 w-form">
              <div id="email-form" className="form-5">
                <input
                  type="text"
                  className="text-field-2 w-input"
                  maxLength="256"
                  data-name="Name 2"
                  id="name-2"
                  placeholder="Paste Your Link here"
                  name="instagram"
                  // form={formik}
                  value={formik.values.instagram}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          </div> */}
          {/* <div className="div-block-22">
            <div className="icon-featured-large-social">
              <div className="uui-icon-1x1-xsmall-4 w-embed">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-reddit"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.167 8a.831.831 0 0 0-.83.83c0 .459.372.84.83.831a.831.831 0 0 0 0-1.661zm1.843 3.647c.315 0 1.403-.038 1.976-.611a.232.232 0 0 0 0-.306.213.213 0 0 0-.306 0c-.353.363-1.126.487-1.67.487-.545 0-1.308-.124-1.671-.487a.213.213 0 0 0-.306 0 .213.213 0 0 0 0 .306c.564.563 1.652.61 1.977.61zm.992-2.807c0 .458.373.83.831.83.458 0 .83-.381.83-.83a.831.831 0 0 0-1.66 0z" />
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.828-1.165c-.315 0-.602.124-.812.325-.801-.573-1.9-.945-3.121-.993l.534-2.501 1.738.372a.83.83 0 1 0 .83-.869.83.83 0 0 0-.744.468l-1.938-.41a.203.203 0 0 0-.153.028.186.186 0 0 0-.086.134l-.592 2.788c-1.24.038-2.358.41-3.17.992-.21-.2-.496-.324-.81-.324a1.163 1.163 0 0 0-.478 2.224c-.02.115-.029.23-.029.353 0 1.795 2.091 3.256 4.669 3.256 2.577 0 4.668-1.451 4.668-3.256 0-.114-.01-.238-.029-.353.401-.181.688-.592.688-1.069 0-.65-.525-1.165-1.165-1.165z" />
                </svg>{" "}
              </div>
            </div>
            <div className="form-block-2 w-form">
              <div id="email-form" className="form-5">
                <input
                  type="text"
                  className="text-field-2 w-input"
                  maxLength="256"
                  data-name="Name 2"
                  id="name-2"
                  placeholder="Paste Your Link here"
                  name="reddit"
                  // form={formik}
                  value={formik.values.reddit}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          </div> */}
          {/* <div className="div-block-22">
            <div className="icon-featured-large-social">
              <div className="uui-icon-1x1-xsmall-4 w-embed">
                <svg
                  style={{ color: "#281ca5" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-pinterest"
                  viewBox="0 0 16 16"
                >
                  {" "}
                  <path
                    d="M8 0a8 8 0 0 0-2.915 15.452c-.07-.633-.134-1.606.027-2.297.146-.625.938-3.977.938-3.977s-.239-.479-.239-1.187c0-1.113.645-1.943 1.448-1.943.682 0 1.012.512 1.012 1.127 0 .686-.437 1.712-.663 2.663-.188.796.4 1.446 1.185 1.446 1.422 0 2.515-1.5 2.515-3.664 0-1.915-1.377-3.254-3.342-3.254-2.276 0-3.612 1.707-3.612 3.471 0 .688.265 1.425.595 1.826a.24.24 0 0 1 .056.23c-.061.252-.196.796-.222.907-.035.146-.116.177-.268.107-1-.465-1.624-1.926-1.624-3.1 0-2.523 1.834-4.84 5.286-4.84 2.775 0 4.932 1.977 4.932 4.62 0 2.757-1.739 4.976-4.151 4.976-.811 0-1.573-.421-1.834-.919l-.498 1.902c-.181.695-.669 1.566-.995 2.097A8 8 0 1 0 8 0z"
                    fill="#281ca5"
                  ></path>{" "}
                </svg>
              </div>
            </div>
            <div className="form-block-2 w-form">
              <div id="email-form" className="form-5">
                <input
                  type="text"
                  className="text-field-2 w-input"
                  maxLength="256"
                  data-name="Name 2"
                  id="name-2"
                  placeholder="Paste Your Link here"
                  name="printrest"
                  // form={formik}
                  value={formik.values.printrest}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          </div> */}
          <div>
            <p
              onClick={(e) => getSocialLinks(e, formik)}
              className="add-descp-btn uk-button uk-border-rounded"
            >
              {" "}
              Predefined Settings
            </p>
            {socialInputsFields.length === 0 || socialInputsFields[0].name==="" || socialInputsFields[0].url===""
             ? (
              <button
                type="button"
                // onClick={(e) => SocialMedai(e, formik)}
                disabled
                className="add-descp-btn"
                style={{ float: "right", opacity: "0.7" }}
              >
                Add to Description
              </button>
            ) : (
              <button
                type="button"
                onClick={(e) => SocialMedai(e, formik)}
                className="add-descp-btn"
                style={{ float: "right" }}
              >
                Add to Description
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialMediaPage;
