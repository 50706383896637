import React from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TextError from "../../../FormikControl/TextError";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { publicRequest } from "../../../api/axiosConfig/publicRequest";
import { useToaster } from "rsuite";
import { showErrorNotification } from "../../../Notification";
import { useDispatch } from "react-redux";
import { getForgotResetPassNotification } from "../../../redux/actions/notificationsActions";

const ForgotPasswordEnter = () => {
  const { uid, token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toaster = useToaster();
  const initialValues = {
    password: "",
    confirm_password: "",
  };
  const validationSchema = Yup.object({
    password: Yup.string().required("Required*"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required*"),
  });

  const resetPass = async (values) => {
    const data1 = {
      password: values.password,
      uidb64: uid,
      token: token,
    };

    try {
      const resets = await publicRequest.patch(
        `/api/accounts/password-reset-complete/`,
        data1
      );
    //   console.log("datareset", resets.data.message);
    //   await toaster.push(showSuccessNotification(resets.data.message));
      dispatch(getForgotResetPassNotification(resets.data.message));
      await navigate("/login");
    } catch (err) {
      console.log("resetError", err);
      toaster.push(showErrorNotification(err.response.data.detail));
    }
    // console.log("reset",data1)
  };

  return (
    <div className="container-fluide">
      <div className="d-flex for_background">
        <div
          className="container col-xl-7 col-lg-7 col-12"
          style={{ marginTop: "auto", marginBottom: "auto" }}
        >
          <div
            className="container-fluid"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="f-account-container-l row"
              style={{ textAlign: "start" }}
            >
              <div className="col-12">
                <img alt="login" src="/images/YTBPRo.svg" width="178" />
                <br />
                <h5 className="d-header-welcome-username">
                  Forgot/Reset Password
                </h5>
              </div>
              <div className="f-margin-bottom-25 col-12">
                <p className="enter-color">
                  You can reset/change your password by entering your registered
                  email in below.You will Receive a link to create a new
                  password.
                </p>
              </div>
              <div className="col">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={resetPass}
                >
                  {(formik) => {
                    return (
                      <Form>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputPassword1"
                            className="form-label"
                            style={{ float: "left" }}
                          >
                            Password
                          </label>
                          <Field
                            name="password"
                            type="password"
                            className="form-control"
                            id="exampleInputPassword1"
                          />
                          <ErrorMessage name="password" component={TextError} />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputPassword2"
                            className="form-label"
                            style={{ float: "left" }}
                          >
                            Confirm Password
                          </label>
                          <Field
                            name="confirm_password"
                            type="password"
                            className="form-control"
                            id="exampleInputPassword2"
                          />
                          <ErrorMessage
                            name="confirm_password"
                            component={TextError}
                          />
                        </div>

                        <div className="f-account-form-button-2">
                          <button
                            type="submit"
                            style={{ color: "#fff" }}
                            className="colors"
                          >
                            Reset Password
                            {/* {isLogin === true ? "Loading" : "Login"} */}
                          </button>
                        </div>
                        <p
                          style={{ marginTop: "20px" }}
                          className="f-paragraph-small-2"
                        >
                          I have an account?{" "}
                          <span
                            style={{ cursor: "pointer" }}
                            //   onClick={log}
                            className="f-account-link-2"
                          >
                            Login
                          </span>
                        </p>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-5 col-xl-5 d-none d-lg-block d-xl-block"
          style={{ height: "100vh" }}
        >
          <div className="f-account-image-wrapper-2">
            <div className="div-block-26">
              <div style={{ textAlign: "start" }}>
                <p className="paragraph-10">
                  Welcome!!
                  <br />
                </p>
                <h1 className="heading-13">
                  YTubeBooster <span className="text-span-8">PRO</span>
                </h1>
                <p className="paragraph-9 mb-2">www.ytubebooster.app</p>
                <div className="text-block-18">Tags</div>
                <div className="text-block-18">Videos</div>
                <div className="text-block-18">Channel Analytics</div>
                <div className="text-block-18">Views</div>
                <div className="text-block-18">Subscribers</div>
                <div className="text-block-18">and more...</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordEnter;
