// **************************** LOGIN TYPES START ****************************
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILD = "LOGIN_FAILD";
export const LOGOUT = "LOGOUT";
// **************************** LOGIN TYPES END ****************************
// **************************** YOUTUBE FETCH TYPES START ****************************
export const FETCH_YOUTUBE_START = "FETCH_YOUTUBE_START";
export const FETCH_YOUTUBE_SUCCESS = "FETCH_YOUTUBE_SUCCESS";
export const FETCH_YOUTUBE_FAILD = "FETCH_YOUTUBE_FAILD";
// **************************** YOUTUBE FETCH TYPES END ****************************
// **************************** PRICING CLICK ******************************
export const CHOOSE_PRICING = "CHOOSE_PRICING"

// **************************** MultiChannel ****************************
export const Fetch_Channel_id = "Fetch_Channel_id";
export const FETCH_CHANNEL_START = "FETCH_CHANNEL_START";
export const FETCH_CHANNEL_FIRST = "FETCH_CHANNEL_FIRST";
export const FETCH_CHANNEL_SUCCESS = "FETCH_CHANNEL_SUCCESS";
export const FETCH_CHANNEL_FAILD = "FETCH_CHANNEL_FAILD";

// **************************** Notification ****************************
export const  GET_NOTIFICATION_DATA = "GET_NOTIFICATION_DATA"

// **************************** Module Access ****************************

export const FETCH_MODULE_DATA_START = "FETCH_MODULE_DATA_START"
export const FETCH_MODULE_DATA_SUCCESS = "FETCH_MODULE_DATA_SUCCESS"
export const FETCH_MODULE_DATA_FAILED = "FETCH_MODULE_DATA_FAILED"
// **************************** Subscription Details ****************************

export const FETCH_SUBSCRIPTION_DETAILS_START = "FETCH_SUBSCRIPTION_DETAILS_START"
export const FETCH_SUBSCRIPTION_DETAILS_SUCCESS = "FETCH_SUBSCRIPTION_DETAILS_SUCCESS"
export const FETCH_SUBSCRIPTION_DETAILS_FAILED = "FETCH_SUBSCRIPTION_DETAILS_FAILED"
// **************************** Total Credits Details ****************************

export const FETCH_TOTAL_CREDITS_DETAILS_START = "FETCH_TOTAL_CREDITS_DETAILS_START"
export const FETCH_TOTAL_CREDITS_DETAILS_SUCCESS = "FETCH_TOTAL_CREDITS_DETAILS_SUCCESS"
export const FETCH_TOTAL_CREDITS_DETAILS_FAILED = "FETCH_TOTAL_CREDITS_DETAILS_FAILED"
// **************************** Additional Credits Details ****************************

export const FETCH_ADDITONAL_CREDITS_DETAILS_START = "FETCH_ADDITONAL_CREDITS_DETAILS_START"
export const FETCH_ADDITONAL_CREDITS_DETAILS_SUCCESS = "FETCH_ADDITONAL_CREDITS_DETAILS_SUCCESS"
export const FETCH_ADDITONAL_CREDITS_DETAILS_FAILED = "FETCH_ADDITONAL_CREDITS_DETAILS_FAILED"

// **************************** Fetch Channel id Access ****************************

export const FETCH_CHANNEL_ID_DATA_START = "FETCH_CHANNEL_ID_DATA_START"
export const FETCH_CHANNEL_ID_DATA_SUCCESS = "FETCH_CHANNEL_ID_DATA_SUCCESS"
export const FETCH_CHANNEL_ID_DATA_FAILED = "FETCH_CHANNEL_ID_DATA_FAILED"