import React from "react";
import { useNavigate } from "react-router-dom";

const InvalidToken = () => {
  const navigate = useNavigate();
  const login = () => {
    navigate("/landing");
  };

  return (
    <div
      className="container-fluid cf-pricing-hero-section"
      style={{ height: "100vh" }}
    >
      <div className="d-flex justify-content-center">
        <div
          className="cf-pricing-box-cards green-pricing-box"
          style={{ minHeight: "0px" }}
        >
          <div
            className="cf-pricing-plan-content-wrapper-2"
            style={{ marginBottom: "0px" }}
          >
            <div className="cf-pricing-plan-text-2">
              Sorry! But you have entered invalid token
            </div>
            <div
              className="cf-pricing-plan-content-text-2"
              style={{ fontSize: "16px" }}
            >
              Click here to{" "}
              <span
                style={{ color: "#7ff3de", cursor: "pointer" }}
                onClick={login}
              >
                Register
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvalidToken;
