import React, { useState } from "react";
import { Pagination, SelectPicker } from "rsuite";

const Videos = ({ channelDetail, channelVideoResponce,channelTitle }) => {
  const [active, setActive] = useState(1);
  const [limit, setLimit] = useState(50);
  const limitOptions = [10, 25, 50, 100];
  const startIdx = (active - 1) * limit;
  const endIdx = startIdx + limit;
  // console.log("channelDetails",channelDetail)
  // console.log("active",active)
  // Utility function to convert seconds to minutes
const secondsToMinutes = (seconds) => {
  return Math.floor(seconds / 60);
}

  return (
    <div>
      <h4 style={{marginTop:"20px"}}>Channel Overview of {channelTitle}</h4>
      <p>Potentially useful aggregate data on found videos.</p>
      <div className="containers-custom" style={{ padding: "16px 8px" }}>
        <div className="d-flex justify-content-between gap-2">
          <div style={{ width: "45%" }}>
            <div className="card-pad">
              <div className="d-flex gap-3 align-items-center">
                <div className="card-purple">
                  <label>Total Video</label>
                </div>
                <p>
                  {channelDetail === undefined
                    ? "0"
                    : channelDetail["Total Video"]}{" "}
                  Videos
                </p>
              </div>
            </div>
            <div className="card-pad">
              <div className="d-flex gap-3 align-items-center">
                <div className="card-purple">
                  <label>Total Views</label>
                </div>
                <p>
                  {channelDetail === undefined
                    ? "0"
                    : channelDetail["Total Views"]}{" "}
                  Views
                </p>
              </div>
            </div>
            <div className="card-pad">
              <div className="d-flex gap-3 align-items-center">
                <div className="card-purple">
                  <label>Total Likes</label>
                </div>
                <p>
                  {channelDetail === undefined
                    ? "0"
                    : channelDetail["Total Likes"]}{" "}
                  Likes
                </p>
              </div>
            </div>
            <div className="card-pad">
              <div className="d-flex gap-3 align-items-center">
                <div className="card-purple">
                  <label>Total Comments</label>
                </div>
                <p>
                  {channelDetail === undefined
                    ? "0"
                    : channelDetail["Total comments"]}{" "}
                  Comments
                </p>
              </div>
            </div>
          </div>
          <div style={{ width: "45%" }}>
            <div className="card-pad">
              <div className="d-flex gap-3 align-items-center">
                <div className="card-purple">
                  <label>Average Video Length</label>
                </div>
                <p>
                  {channelDetail === undefined
                    ? "0"
                    : secondsToMinutes(channelDetail["Average Video Length in seconds"])}{" "}
                  Minutes
                </p>
              </div>
            </div>
            <div className="card-pad">
              <div className="d-flex gap-3 align-items-center">
                <div className="card-purple">
                  <label>Video with Tags</label>
                </div>
                <p>
                  {channelDetail === undefined
                    ? "0"
                    : channelDetail["Video with Tags"]}{" "}
                  Tags
                </p>
              </div>
            </div>
            <div className="card-pad">
              <div className="d-flex gap-3 align-items-center">
                <div className="card-purple">
                  <label>Total Video Length</label>
                </div>
                <p>
                  {channelDetail === undefined
                    ? "0"
                    : secondsToMinutes(channelDetail["Total Video Length in seconds"])}{" "}
                  Minutes
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-margin-top">
        <div className="d-flex align-items-center justify-content-between">
        <h4>Videos</h4>
        <div className="d-flex justify-content-end">
          <span>
            Show{" "}
            <SelectPicker
              value={limit}
              onChange={setLimit}
              cleanable={false}
              searchable={false}
              data={limitOptions.map((key) => ({ value: key, label: key }))}
            />{" "}
            Entries
          </span>
        </div>
        </div>
        <div className="containers-custom uk-margin-bottom" style={{padding:"10px"}}>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Title</th>
              <th scope="col">Length</th>
              <th scope="col">Views</th>
              <th scope="col">Likes</th>
              <th scope="col">Comments</th>
              <th scope="col">Tag Count</th>
            </tr>
          </thead>
          <tbody>
            {channelVideoResponce.slice(startIdx, endIdx).map((res, index) => (
              <tr key={index}>
                <th scope="row">
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-youtube"
                  >
                    <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path>
                    <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon>
                  </svg> */}
                <img src={res.thumbnail} alt="no thumbnail" style={{width:"24px"}} />
                </th>
                <td className="table-title-data">{res.title}</td>
                <td>{res["length in seconds"]}</td>
                <td>{res.view}</td>
                <td>{res.likes}</td>
                <td>{res.comments_count}</td>
                <td>{res.tag_count}</td>
              </tr>
            ))}
          </tbody>
        </table> 
        <div className="d-flex justify-content-between">
          <div>
            <div
              style={{ padding: "20px" }}
              className="uk-card uk-card-default"
            >
              Showing page {active} of {channelVideoResponce.length} entries
            </div>
          </div>
          <div>
            <Pagination
              prev
              next
              first
              last
              size="md"
              maxButtons={10}
              page={Math.ceil(channelVideoResponce.length / limit)}
              limit={limit}
              total={channelVideoResponce.length}
              activePage={active}
              onChangePage={setActive}
              onChangeLimit={setLimit}
            />
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Videos;
