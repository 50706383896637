import React from "react";
import { Tooltip, Whisper } from "rsuite";
import { plansTools } from "./StringStore";

const PlaneDetails = () => {
  return (
    <section className="combine-section_pricing5">
      <div className="combine-padding-global">
        <div className="combine-padding-section-medium">
          <h2 className="cf-text-opacity-70-italic center-gradient-heading">
            Unsure which plan to select?
            <br />
            Compare Features
          </h2>
          {/* <div className="combine-container-small">
                <div className="combine-text-align-center">
                  <div className="combine-text-size-regular">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla.
                  </div>
                </div>
              </div> */}
          <div className="combine-space-large" id="feature-comp" />
          <div className="combine-container-large">
            <div className="combine-pricing5_component">
              <div className="combine-pricing5_table-header">
                <div className="combine-hide-mobile-landscape" />
                <div className="combine-pricing5_details">
                  <div className="combine-text-weight-semibold">
                    <img
                      height="12"
                      width="76"
                      alt="acorn"
                      style={{ rotate: "35deg" }}
                      src="/images/acorn.png"
                    />
                    <br />
                    ACORN PLAN
                  </div>
                </div>
                <div className="combine-pricing5_details">
                  <div className="combine-text-weight-semibold">
                    <img
                      height="12"
                      width="50"
                      alt="palm"
                      style={{ width: "99px", height: "99px" }}
                      src="/images/palm.png"
                    />
                    <br />
                    PALM PLAN
                  </div>
                </div>
                <div className="combine-pricing5_details">
                  <div className="combine-text-weight-semibold">
                    <img
                      height="12"
                      width="87"
                      alt="oak"
                      src="/images/oak.png"
                    />
                    <br />
                    OAK PLAN
                  </div>
                </div>
              </div>
              <div className="combine-pricing5_table-feature">
                <div className="combine-pricing5_table-title">
                  <div className="combine-text-weight-semibold">
                    Plan feature Title
                  </div>
                </div>
              </div>
              <div className="combine-background-color-grey">
                <div className="combine-pricing5_table-content">
                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1cfb-b342fa83"
                    className="combine-pricing5_table-item"
                  >
                    <div>Subscription Credits (Monthly/Annual)</div>
                    <div className="combine-pricing5_help">
                      {/* <Whisper
                        placement="top"
                        controlId="control-id-click"
                        trigger="hover"
                        speaker={
                          <Tooltip>{plansTools.subscription_credits}</Tooltip>
                        }
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </Whisper> */}
                      <div className="combine-pricing5_tooltip-wrapper">
                        <div className="combine-pricing5_tooltip-triangle w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div className="combine-pricing5_tooltip">
                          <div>This is some text inside of a tooltip.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="combine-text-size-regular">-</div>
                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                    className="combine-icon_color5"
                  >
                    <div className="combine-text-size-regular">
                      25000/month or <br /> 300000/annually
                    </div>
                  </div>
                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                    className="combine-icon_color5"
                  >
                    <div className="combine-text-size-regular">
                      50000/month or <br /> 600000/annually
                    </div>
                  </div>
                </div>
              </div>
              <div className="combine-pricing5_table-content">
                <div
                  id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d0c-b342fa83"
                  className="combine-pricing5_table-item"
                >
                  <div className="text-block-4">Multi-Channel (s)</div>
                  <div className="combine-pricing5_help">
                    {/* <Whisper
                      placement="top"
                      controlId="control-id-click"
                      trigger="hover"
                      speaker={<Tooltip>{plansTools.multi_channel}</Tooltip>}
                    >
                      <div className="combine-icon_small w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </Whisper> */}
                    <div className="combine-pricing5_tooltip-wrapper">
                      <div className="combine-pricing5_tooltip-triangle w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      <div className="combine-pricing5_tooltip">
                        <div>This is some text inside of a tooltip.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                  className="combine-icon_color5"
                >
                  <div className="combine-text-size-regular">max 1 channel</div>
                  {/* <div className="combine-icon_small w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div> */}
                </div>
                <div
                  id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                  className="combine-icon_color5"
                >
                  <div className="combine-text-size-regular">max 3 channel</div>
                </div>
                <div
                  id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                  className="combine-icon_color5"
                >
                  <div className="combine-text-size-regular">
                    max 10 channel
                  </div>
                </div>
              </div>
              <div className="combine-background-color-grey">
                <div className="combine-pricing5_table-content">
                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d1e-b342fa83"
                    className="combine-pricing5_table-item"
                  >
                    <div className="text-block-3">
                      Dashboard with Channel Analytics
                    </div>
                    <div className="combine-pricing5_help">
                      {/* <Whisper
                        placement="top"
                        controlId="control-id-click"
                        trigger="hover"
                        speaker={
                          <Tooltip>
                            {plansTools.dashboard_with_channel_analysis}
                          </Tooltip>
                        }
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </Whisper> */}
                      <div className="combine-pricing5_tooltip-wrapper">
                        <div className="combine-pricing5_tooltip-triangle w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div className="combine-pricing5_tooltip">
                          <div>This is some text inside of a tooltip.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                    className="combine-icon_color5"
                  >
                    <div className="combine-icon_small w-embed">
                      <svg
                        // width="currentWidth"
                        // height="currentHeight"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </div>
                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                    className="combine-icon_color5"
                  >
                    <div className="combine-icon_small w-embed">
                      <svg
                        // width="currentWidth"
                        // height="currentHeight"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </div>
                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                    className="combine-icon_color5"
                  >
                    <div className="combine-icon_small w-embed">
                      <svg
                        // width="currentWidth"
                        // height="currentHeight"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="combine-pricing5_table-content">
                <div
                  id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d0c-b342fa83"
                  className="combine-pricing5_table-item"
                >
                  <div className="text-block-4">Advanced Channel stats</div>
                  <div className="combine-pricing5_help">
                    {/* <Whisper
                      placement="top"
                      controlId="control-id-click"
                      trigger="hover"
                      speaker={
                        <Tooltip>{plansTools.advance_channel_state}</Tooltip>
                      }
                    >
                      <div className="combine-icon_small w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </Whisper> */}
                    <div className="combine-pricing5_tooltip-wrapper">
                      <div className="combine-pricing5_tooltip-triangle w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      <div className="combine-pricing5_tooltip">
                        <div>This is some text inside of a tooltip.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="combine-text-size-regular">-</div>
                <div
                  id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                  className="combine-icon_color5"
                >
                  <div className="combine-icon_small w-embed">
                    <svg
                      // width="currentWidth"
                      // height="currentHeight"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                  className="combine-icon_color5"
                >
                  <div className="combine-icon_small w-embed">
                    <svg
                      // width="currentWidth"
                      // height="currentHeight"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="combine-background-color-grey">
                <div className="combine-pricing5_table-content">
                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d1e-b342fa83"
                    className="combine-pricing5_table-item"
                  >
                    <div className="text-block-3">Keyword Research</div>
                    <div className="combine-pricing5_help">
                      <Whisper
                        placement="top"
                        controlId="control-id-click"
                        trigger="hover"
                        speaker={
                          <Tooltip>{plansTools.keywordResearch}</Tooltip>
                        }
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </Whisper>
                      <div className="combine-pricing5_tooltip-wrapper">
                        <div className="combine-pricing5_tooltip-triangle w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div className="combine-pricing5_tooltip">
                          <div>This is some text inside of a tooltip.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                    className="combine-icon_color5"
                  >
                    <div className="combine-icon_small w-embed">
                      <svg
                        // width="currentWidth"
                        // height="currentHeight"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </div>
                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                    className="combine-icon_color5"
                  >
                    <div className="combine-icon_small w-embed">
                      <svg
                        // width="currentWidth"
                        // height="currentHeight"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </div>
                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                    className="combine-icon_color5"
                  >
                    <div className="combine-icon_small w-embed">
                      <svg
                        // width="currentWidth"
                        // height="currentHeight"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="combine-pricing5_table-content">
                <div
                  id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d0c-b342fa83"
                  className="combine-pricing5_table-item"
                >
                  <div className="text-block-4">Tag Extractor</div>
                  <div className="combine-pricing5_help">
                    <Whisper
                      placement="top"
                      controlId="control-id-click"
                      trigger="hover"
                      speaker={<Tooltip>{plansTools.tag_extract}</Tooltip>}
                    >
                      <div className="combine-icon_small w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </Whisper>
                    <div className="combine-pricing5_tooltip-wrapper">
                      <div className="combine-pricing5_tooltip-triangle w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      <div className="combine-pricing5_tooltip">
                        <div>This is some text inside of a tooltip.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                  className="combine-icon_color5"
                >
                  <div className="combine-icon_small w-embed">
                    <svg
                      // width="currentWidth"
                      // height="currentHeight"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                  className="combine-icon_color5"
                >
                  <div className="combine-icon_small w-embed">
                    <svg
                      // width="currentWidth"
                      // height="currentHeight"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                  className="combine-icon_color5"
                >
                  <div className="combine-icon_small w-embed">
                    <svg
                      // width="currentWidth"
                      // height="currentHeight"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              {/* <div className="combine-pricing5_table-feature">
                    <div className="combine-pricing5_table-title">
                      <div className="combine-text-weight-semibold">
                        Plan feature Title
                      </div>
                    </div>
                  </div> */}
              <div className="combine-background-color-grey">
                <div className="combine-pricing5_table-content">
                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d57-b342fa83"
                    className="combine-pricing5_table-item"
                  >
                    <div>Ask YTubeBooster</div>
                    <div className="combine-pricing5_help">
                      <Whisper
                        placement="top"
                        controlId="control-id-click"
                        trigger="hover"
                        speaker={<Tooltip>{plansTools.ask_ytb}</Tooltip>}
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </Whisper>
                      <div className="combine-pricing5_tooltip-wrapper">
                        <div className="combine-pricing5_tooltip-triangle w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div className="combine-pricing5_tooltip">
                          <div>This is some text inside of a tooltip.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                    className="combine-icon_color5"
                  >
                    <div className="combine-icon_small w-embed">
                      <svg
                        // width="currentWidth"
                        // height="currentHeight"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                          fill="currentColor"
                        />
                      </svg>
                      {/* (Credits required*) */}
                    </div>
                    {/* <br/>
                        <div className="combine-text-size-regular">
                        (Credits required*)
                    </div> */}
                  </div>
                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                    className="combine-icon_color5"
                  >
                    <div className="combine-icon_small w-embed">
                      <svg
                        // width="currentWidth"
                        // height="currentHeight"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </div>
                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                    className="combine-icon_color5"
                  >
                    <div className="combine-icon_small w-embed">
                      <svg
                        // width="currentWidth"
                        // height="currentHeight"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="combine-pricing5_table-content">
                <div
                  id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d68-b342fa83"
                  className="combine-pricing5_table-item"
                >
                  <div>Title Generator</div>
                  <div className="combine-pricing5_help">
                    <Whisper
                      placement="top"
                      controlId="control-id-click"
                      trigger="hover"
                      speaker={<Tooltip>{plansTools.ai_title}</Tooltip>}
                    >
                      <div className="combine-icon_small w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </Whisper>
                    <div className="combine-pricing5_tooltip-wrapper">
                      <div className="combine-pricing5_tooltip-triangle w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      <div className="combine-pricing5_tooltip">
                        <div>This is some text inside of a tooltip.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                  className="combine-icon_color5"
                >
                  <div className="combine-icon_small w-embed">
                    <svg
                      // width="currentWidth"
                      // height="currentHeight"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                        fill="currentColor"
                      />
                    </svg>
                    {/* (Credits required*) */}
                  </div>
                </div>
                <div
                  id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                  className="combine-icon_color5"
                >
                  <div className="combine-icon_small w-embed">
                    <svg
                      // width="currentWidth"
                      // height="currentHeight"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                        fill="currentColor"
                      />
                    </svg>
                    {/* (Credits required*) */}
                  </div>
                </div>
                <div
                  id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                  className="combine-icon_color5"
                >
                  <div className="combine-icon_small w-embed">
                    <svg
                      // width="currentWidth"
                      // height="currentHeight"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                        fill="currentColor"
                      />
                    </svg>
                    {/* (Credits required*) */}
                  </div>
                </div>
              </div>
              <div className="combine-background-color-grey">
                <div className="combine-pricing5_table-content">
                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d7a-b342fa83"
                    className="combine-pricing5_table-item"
                  >
                    <div>Description Generator</div>
                    <div className="combine-pricing5_help">
                      <Whisper
                        placement="top"
                        controlId="control-id-click"
                        trigger="hover"
                        speaker={<Tooltip>{plansTools.ai_description}</Tooltip>}
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </Whisper>
                      <div className="combine-pricing5_tooltip-wrapper">
                        <div className="combine-pricing5_tooltip-triangle w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div className="combine-pricing5_tooltip">
                          <div>This is some text inside of a tooltip.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                    className="combine-icon_color5"
                  >
                    <div className="combine-icon_small w-embed">
                      <svg
                        // width="currentWidth"
                        // height="currentHeight"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                          fill="currentColor"
                        />
                      </svg>
                      {/* (Credits required*) */}
                    </div>
                  </div>
                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                    className="combine-icon_color5"
                  >
                    <div className="combine-icon_small w-embed">
                      <svg
                        // width="currentWidth"
                        // height="currentHeight"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </div>
                  {/* <div className="combine-text-size-regular">-</div> */}

                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                    className="combine-icon_color5"
                  >
                    <div className="combine-icon_small w-embed">
                      <svg
                        // width="currentWidth"
                        // height="currentHeight"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="combine-pricing5_table-content">
                <div
                  id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d68-b342fa83"
                  className="combine-pricing5_table-item"
                >
                  <div>Chrome Extension</div>
                  <div className="combine-pricing5_help">
                    {/* <Whisper
                      placement="top"
                      controlId="control-id-click"
                      trigger="hover"
                      speaker={<Tooltip>{plansTools.chrome_extansion}</Tooltip>}
                    >
                      <div className="combine-icon_small w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </Whisper> */}
                    <div className="combine-pricing5_tooltip-wrapper">
                      <div className="combine-pricing5_tooltip-triangle w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      <div className="combine-pricing5_tooltip">
                        <div>This is some text inside of a tooltip.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="combine-text-size-regular">-</div>
                <div
                  id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                  className="combine-icon_color5"
                >
                  <div className="combine-icon_small w-embed">
                    <svg
                      // width="currentWidth"
                      // height="currentHeight"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                        fill="currentColor"
                      />
                    </svg>
                    {/* (Credits required*) */}
                  </div>
                </div>
                <div
                  id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                  className="combine-icon_color5"
                >
                  <div className="combine-icon_small w-embed">
                    <svg
                      // width="currentWidth"
                      // height="currentHeight"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                        fill="currentColor"
                      />
                    </svg>
                    {/* (Credits required*) */}
                  </div>
                </div>
              </div>
              <div className="combine-background-color-grey">
                <div className="combine-pricing5_table-content">
                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d7a-b342fa83"
                    className="combine-pricing5_table-item"
                  >
                    <div>Mobile App</div>
                    <div className="combine-pricing5_help">
                      {/* <Whisper
                        placement="top"
                        controlId="control-id-click"
                        trigger="hover"
                        speaker={<Tooltip>{plansTools.mobile_app}</Tooltip>}
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </Whisper> */}
                      <div className="combine-pricing5_tooltip-wrapper">
                        <div className="combine-pricing5_tooltip-triangle w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div className="combine-pricing5_tooltip">
                          <div>This is some text inside of a tooltip.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="combine-text-size-regular">-</div>
                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                    className="combine-icon_color5"
                  >
                    <div className="combine-icon_small w-embed">
                      <svg
                        // width="currentWidth"
                        // height="currentHeight"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                          fill="currentColor"
                        />
                      </svg>
                      {/* (Credits required*) */}
                    </div>
                  </div>

                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                    className="combine-icon_color5"
                  >
                    <div className="combine-icon_small w-embed">
                      <svg
                        // width="currentWidth"
                        // height="currentHeight"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div className="combine-pricing5_table-content">
                <div
                  id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d68-b342fa83"
                  className="combine-pricing5_table-item"
                >
                  <div>Social Media Post Generator</div>
                  <div className="combine-pricing5_help">
                    <Whisper
                      placement="top"
                      controlId="control-id-click"
                      trigger="hover"
                      speaker={<Tooltip>{plansTools.ai_social}</Tooltip>}
                    >
                      <div className="combine-icon_small w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </Whisper>
                    <div className="combine-pricing5_tooltip-wrapper">
                      <div className="combine-pricing5_tooltip-triangle w-embed">
                        <svg
                          // width="currentWidth"
                          // height="currentHeight"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      <div className="combine-pricing5_tooltip">
                        <div>This is some text inside of a tooltip.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="combine-text-size-regular">-</div>
                <div className="combine-text-size-regular">-</div>
                <div
                  id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                  className="combine-icon_color5"
                >
                  <div className="combine-icon_small w-embed">
                    <svg
                      // width="currentWidth"
                      // height="currentHeight"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                        fill="currentColor"
                      />
                    </svg>
                    {/* (Credits required*) */}
                  </div>
                </div>
              </div>
              <div className="combine-background-color-grey">
                <div className="combine-pricing5_table-content">
                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d7a-b342fa83"
                    className="combine-pricing5_table-item"
                  >
                    <div>Blog Writer</div>
                    <div className="combine-pricing5_help">
                      <Whisper
                        placement="top"
                        controlId="control-id-click"
                        trigger="hover"
                        speaker={<Tooltip>{plansTools.ai_blog}</Tooltip>}
                      >
                        <div className="combine-icon_small w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.9068 8.27129C11.314 8.03197 11.7927 7.94449 12.2582 8.02434C12.7238 8.10419 13.146 8.34622 13.4502 8.70755C13.7543 9.06889 13.9208 9.52621 13.9201 9.99853L13.9201 10C13.9201 10.4692 13.555 10.9582 12.8654 11.418C12.551 11.6276 12.2296 11.789 11.9827 11.8987C11.8607 11.9529 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0406 11.6188 12.0461L11.6028 12.0517C11.0796 12.2267 10.7969 12.7927 10.9714 13.3162C11.1461 13.8402 11.7124 14.1234 12.2363 13.9487C12.2363 13.9487 12.184 13.9669 12.2888 13.9305C12.3174 13.9204 12.3566 13.9061 12.405 13.8876C12.5016 13.8508 12.6358 13.7971 12.795 13.7263C13.1107 13.586 13.5392 13.3725 13.9748 13.0821C14.7851 12.5419 15.9197 11.5313 15.9201 10.0009C15.9214 9.0565 15.5884 8.14207 14.9802 7.41955C14.3719 6.69688 13.5274 6.21283 12.5963 6.05313C11.6653 5.89343 10.7078 6.06839 9.89339 6.54702C9.07898 7.02565 8.46025 7.77707 8.14678 8.66818C7.96351 9.18917 8.23728 9.76008 8.75827 9.94336C9.27926 10.1266 9.85018 9.85286 10.0335 9.33187C10.1902 8.88631 10.4996 8.5106 10.9068 8.27129ZM12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18H12.0101C12.5624 18 13.0101 17.5523 13.0101 17C13.0101 16.4477 12.5624 16 12.0101 16H12.0001Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </Whisper>
                      <div className="combine-pricing5_tooltip-wrapper">
                        <div className="combine-pricing5_tooltip-triangle w-embed">
                          <svg
                            // width="currentWidth"
                            // height="currentHeight"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.04783 8.44022L0 16H16L9.95217 8.44021C8.95136 7.1892 7.04864 7.1892 6.04783 8.44022Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div className="combine-pricing5_tooltip">
                          <div>This is some text inside of a tooltip.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="combine-text-size-regular">-</div>
                  <div className="combine-text-size-regular">-</div>

                  <div
                    id="w-node-_51b50e85-d0aa-a54b-7a58-b70bae3a1d39-b342fa83"
                    className="combine-icon_color5"
                  >
                    <div className="combine-icon_small w-embed">
                      <svg
                        // width="currentWidth"
                        // height="currentHeight"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0404 8.70711C18.431 8.31658 18.431 7.68342 18.0404 7.29289C17.6499 6.90237 17.0168 6.90237 16.6262 7.29289L10 13.9191L7.37377 11.2929C6.98325 10.9024 6.35008 10.9024 5.95956 11.2929C5.56904 11.6834 5.56904 12.3166 5.95956 12.7071L9.29289 16.0404C9.68342 16.431 10.3166 16.431 10.7071 16.0404L18.0404 8.70711Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlaneDetails;
