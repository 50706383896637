import {takeEvery, all, put, call} from 'redux-saga/effects';

import * as actionTypes from '../redux/actionTypes';

// actions
import { fetchTotalCredits } from '../api/service/channelid';

import { fetchTotalCreditsFailed, fetchTotalCreditsSuccess } from '../redux/actions/creditsActions';

// services

// import { fetchYoutube} from '../api/services/connect';



// Worker saga
// ***************************** Youtube START *****************************
function* loadTotalCreditsDetails(action){
    try{
        // console.log('action', action.payload);
        const {data} = yield call(fetchTotalCredits, action.payload);
        // console.log('poper', data)
        yield put(fetchTotalCreditsSuccess(data[0]))
        // yield put(youtubeConnDataFetchSuccess(data['Please go to this URL: ']));
    }catch(error){
        // console.log("accessError",error)
        yield put(fetchTotalCreditsFailed(error));
    }
}

// watcher saga
function* watchTotalCreditsDetails() {
    yield takeEvery(actionTypes.FETCH_TOTAL_CREDITS_DETAILS_START, loadTotalCreditsDetails);
}

export function* TotalCreditsDetailsSaga(){
    yield all([watchTotalCreditsDetails()])
}