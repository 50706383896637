import { takeEvery, all, put, call } from "redux-saga/effects";

import * as actionTypes from "../redux/actionTypes";

// actions
import { fetchAdditonalCredits } from "../api/service/channelid";

import {
  fetchAdditionalCreditsFailed,
  fetchAdditionalCreditsSuccess,
} from "../redux/actions/creditsActions";

// services

// import { fetchYoutube} from '../api/services/connect';

// Worker saga
// ***************************** Youtube START *****************************
function* loadAdditionalCreditsDetails(action) {
  try {
    // console.log('action', action.payload);
    const { data } = yield call(fetchAdditonalCredits, action.payload);
    // console.log('additionalCreditds', data)
    yield put(fetchAdditionalCreditsSuccess(data[0]));
    // yield put(youtubeConnDataFetchSuccess(data['Please go to this URL: ']));
  } catch (error) {
    // console.log("accessError",error)
    yield put(fetchAdditionalCreditsFailed(error));
  }
}

// watcher saga
function* watchAdditionalCreditDetails() {
  yield takeEvery(
    actionTypes.FETCH_ADDITONAL_CREDITS_DETAILS_START,
    loadAdditionalCreditsDetails
  );
}

export function* additionalCreditsDetailsSaga() {
  yield all([watchAdditionalCreditDetails()]);
}
