import React, { useEffect, useState } from "react";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";
import { useSelector } from "react-redux";
import { Button, Modal, useToaster } from "rsuite";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../Notification";

const GlobalSocialMedailink = () => {
  // const [getLink, setGetLink] = useState(false);
  // const [facebooks, setFacebooks] = useState("");
  // const [twitter, setTwitter] = useState("");
  // const [instagram, setInstagram] = useState("");
  // const [reddit, setReddit] = useState("");
  // const [pinterest, setPrintrest] = useState("");
  // const [tumbler, setTumbler] = useState("");
  const { channelid } = useSelector((state) => state.channels);
  const { fetchId } = useSelector((state) => state.channelIdData);
  const [socialInputsFields, setsocialInputsFields] = useState([]);
  const [socialLizing, setSocialLizing] = useState([]);
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState();
  // eslint-disable-next-line
  const [loadingButton, setLoadingButton] = useState(false);

  const toaster = useToaster();

  const handleOpen = (e, { id }) => {
    setShow(true);
    setDeleteId(id);
    // console.log("delete id",id)
  };

  const handleClose = () => {
    setShow(false);
    setDeleteId();
  };

  const socialMediaApp = [
    { id: 1, media: "Facebook" },
    { id: 2, media: "YouTube" },
    { id: 3, media: "WhatsApp" },
    { id: 4, media: "Instagram" },
    { id: 5, media: "WeChat" },
    { id: 6, media: "TikTok" },
    { id: 7, media: "Telegram" },
    { id: 8, media: "Snapchat" },
    { id: 9, media: "Kuaishou" },
    { id: 10, media: "Qzone" },
    { id: 11, media: "Sina Weibo" },
    { id: 12, media: "QQ" },
    { id: 13, media: "X" },
    { id: 14, media: "Pinterest" },
    { id: 15, media: "Reddit" },
    { id: 16, media: "LinkedIn" },
    { id: 17, media: "Quora" },
    { id: 18, media: "Discord" },
    { id: 19, media: "Twitch" },
    { id: 20, media: "Tumblr" },
    { id: 21, media: "Threads" },
    { id: 22, media: "Mastodon" },
    { id: 23, media: "Bluesky" },
  ];

  const getSocialLink = async () => {
    try {
      const getData = await privateRequest.get(
        `/api/youtube/list-create/social-media-links/?multichannel=${
          channelid === null ? fetchId : channelid
        }`
      );
      // console.log("getData social", getData.data);
      // setSocialMedia(getData?.data);
      setSocialLizing(getData.data);
      // setsocialInputsFields(getData?.data)
    } catch (err) {
      console.log("socialgetError", err.response);
    }
  };

  const addSocialLinksGlobal = async (e, { name, url }, index) => {
    // console.log("name", name);
    // console.log("url", url);
    setLoadingButton(true);
    const payload = {
      multichannel: channelid === null ? fetchId : channelid,
      name: name,
      url: url,
    };
    try {
      // eslint-disable-next-line
      const medialink = await privateRequest.post(
        `/api/youtube/list-create/social-media-links/`,
        payload
      );
      // console.log("mediaLink", medialink);
      toaster.push(showSuccessNotification(`Successfully added the link`));
      getSocialLink();
      decreaseInputField(e, index);
    } catch (err) {
      // if(err?.response?.data?.error[0]){
      //   showErrorNotification(err.response.data.error[0],configToast)
      // }else if(err?.response?.data){
      // showErrorNotification(
      //   "You have entered an invalid url or check your input,it should not be empty",
      //   configToast
      // );
      // console.log("post err", err.response.data);
      toaster.push(showErrorNotification(`Something went wrong`));
    }
    setLoadingButton(false);
  };

  const handleDynamicUpdateChange = (i, e) => {
    let newFormValues = [...socialLizing];
    newFormValues[i][e.target.name] = e.target.value;
    // console.log("socialization",e.target.value)
    setSocialLizing(newFormValues);
  };

  const increaseInput = () => {
    //   setsocialInputsFields([...socialInputsFields, {
    //     name:'',socialLink:''
    // } ])
    setsocialInputsFields([...socialInputsFields, { name: "", url: "" }]);
    // console.log("increasefield",socialInputsFields)
  };

  const decreaseInputField = (e, i) => {
    // console.log("decrising",i)
    // e.preventDefault();
    let newFormValues = [...socialInputsFields];
    newFormValues.splice(i, 1);
    setsocialInputsFields(newFormValues);
    // handleDynamicChange(newFormValues,e)
  };

  const handleDynamicChange = (i, e) => {
    let newFormValues = [...socialInputsFields];
    newFormValues[i][e.target.name] = e.target.value;
    setsocialInputsFields(newFormValues);
  };

  const updateSocialMedai = async (e, { name, id, url, multichannel }) => {
    console.log("updatedId", id, name, url);
    const payload = {
      name: name,
      url: url,
    };
    try {
      // eslint-disable-next-line
      const pathDta = await privateRequest.patch(
        `/api/youtube/update-destroy/social-media-links/${id}/`,
        payload
      );
      // showSuccessNotification("Successfully Updated", configToast);
      toaster.push(showSuccessNotification(`Successfully Updated`));
      getSocialLink();
    } catch (err) {
      console.log("social patch err", err.response.data);
    }
  };

  const deleteUpdateSocialMedai = async () => {
    try {
      await privateRequest.delete(
        `/api/youtube/update-destroy/social-media-links/${deleteId}/`
      );
      toaster.push(showSuccessNotification(`Successfully deleted`));
      // showSuccessNotification(`Successfully deleted`, configToast);
      getSocialLink();
    } catch (err) {
      console.log(err.response.data);
      toaster.push(showErrorNotification(`Something went wrong`));
    }
    handleClose();
  };

  // eslint-disable-next-line
  // const saveLink = async () => {
  //   setGetLink(true);
  //   const LinkData = {
  //     facebook: facebooks,
  //     instagram: instagram,
  //     twitter: twitter,
  //     reddit: reddit,
  //     pinterest: pinterest,
  //     tumbler: tumbler,
  //   };
  //   try {
  //     // eslint-disable-next-line
  //     const features = await privateRequest.put(
  //       `/api/youtube/social-media-links/${channelid === null ? fetchId : channelid
  //       }/`,
  //       LinkData
  //     );
  //     //  console.log('featurs',features)
  //   } catch (err) {
  //     console.log(err);
  //   }
  //   setGetLink(false);
  //   getSocialLink();
  // };

  // const getSocialLink = async () => {
  //   try {
  //     const data = await privateRequest.get(
  //       `/api/youtube/social-media-links/${channelid === null ? fetchId : channelid
  //       }/`
  //     );
  //     // console.log("data",data.data.social_media_links.Links);
  //     const applyLink =
  //       data.data.social_media_links === null
  //         ? ""
  //         : data.data.social_media_links.Links;
  //     setFacebooks(applyLink.facebook);
  //     setInstagram(applyLink.instagram);
  //     setPrintrest(applyLink.pinterest);
  //     setReddit(applyLink.reddit);
  //     setTumbler(applyLink.tumbler);
  //     setTwitter(applyLink.twitter);
  //   } catch (err) {
  //     console.log("err", err);
  //   }
  // };

  useEffect(() => {
    getSocialLink();
  }, [fetchId]);
  return (
    <div className="uk-card uk-card-default uk-card-hover uk-card-body uk-width-2-2@m">
      <div className="d-flex justify-content-between">
        <h3 className="heading-14">SOCIAL MEDIA LINKS</h3>
        <button
          className="add-descp-btn"
          style={{
            lineHeight: "normal",
            padding: "12px 30px",
            fontSize: "14px",
            cursor: "pointer",
          }}
          // onClick={saveLink}
          onClick={increaseInput}
        >
          Add +
        </button>
      </div>{" "}
      <br />
      <p className="uk-margin-bottom global-sub-text">
        Enter your Social media links below
      </p>
      {socialLizing.map(({ name, url, id, multichannel }, index) => (
        <div key={index} className="input-group flex-nowrap mb-2">
          {/* {console.log("multiinputres",name)} */}
          <select
            style={{
              fontSize: "14px",
              maxWidth: "110px",
              textAlign:"center",
              color: "#fff",
              backgroundColor: "#6a11cb",
            }}
            onChange={(evnt) => handleDynamicUpdateChange(index, evnt)}
            name="name"
            className="form-control custom-select"
          >
            <option>{name}</option>
            {socialMediaApp.map((res) => (
              <option key={res.id} value={res.media}>
                {res.media}
              </option>
            ))}
          </select>

          <input
            value={url}
            type="text"
            control="input"
            name="url"
            onChange={(evnt) => handleDynamicUpdateChange(index, evnt)}
            // name="rediit"
            // form={formik}
            // value={formik.values.rediit}
            // onChange={formik.handleChange}
            style={{ fontSize: "14px" }}
            className="form-control"
            placeholder="Insert link"
            aria-label=""
            aria-describedby="addon-wrapping"
          />
          <button
            className="btn btn-primary"
            onClick={(e) =>
              updateSocialMedai(e, {
                name,
                url,
                id,
                multichannel,
              })
            }
          >
            update
          </button>
          <button
            className="btn btn-danger"
            // onClick={(e) =>
            //   deleteUpdateSocialMedai(e, {
            //     name,
            //     url,
            //     id,
            //     multichannel,
            //   })
            // }
            onClick={(e) => handleOpen(e, { id })}
          >
            Delete
          </button>
        </div>
      ))}
      {socialInputsFields.map(({ name, url }, index) => (
        <div key={index} className="input-group mb-3">
          <div className="input-group flex-nowrap mb-2">
            {/* {console.log("multiinputres",name)} */}
            <select
              style={{
                fontSize: "14px",
                maxWidth: "110px",
                textAlign:"center",
                color: "#fff",
                backgroundColor: "#6a11cb",
              }}
              value={name}
              onChange={(evnt) => handleDynamicChange(index, evnt)}
              name="name"
              className="form-control custom-select"
            >
              <option>select</option>
              {socialMediaApp.map((res) => (
                <option key={res.id} value={res.media}>
                  {res.media}
                </option>
              ))}
            </select>

            <input
              value={url}
              type="text"
              control="input"
              name="url"
              onChange={(evnt) => handleDynamicChange(index, evnt)}
              // name="rediit"
              // form={formik}
              // value={formik.values.rediit}
              // onChange={formik.handleChange}
              style={{ fontSize: "14px" }}
              className="form-control"
              placeholder="Insert link"
              aria-label=""
              aria-describedby="addon-wrapping"
            />
            {loadingButton === true ? (
              <>
                :
                <button
                  // type="submit"
                  className="btn btn-primary btn-sm m-b-xs"
                  disabled
                >
                  Save
                </button>
                <button
                  className="btn btn-danger"
                  onClick={(e) => decreaseInputField(e, index)}
                  disabled
                >
                  -
                </button>
              </>
            ) : (
              <>
                <button
                  // type="submit"
                  className="btn btn-primary btn-sm m-b-xs"
                  onClick={(e) => addSocialLinksGlobal(e, { name, url }, index)}
                >
                  Save
                </button>
                <button
                  className="btn btn-danger"
                  onClick={(e) => decreaseInputField(e, index)}
                >
                  -
                </button>
              </>
            )}
          </div>
        </div>
      ))}
      {/* <span className="input-group-text" id="basic-addon1">
          @
        </span>
        <input
          type="text"
          className="form-control"
          placeholder="Facebook"
          aria-label="Username"
          aria-describedby="basic-addon1"
          value={facebooks}
          onChange={(e) => setFacebooks(e.target.value)}
        /> */}
      {/* <div className="input-group mb-3">
        <span className="input-group-text" id="basic-addon1">
          @
        </span>
        <input
          type="text"
          className="form-control"
          placeholder="Facebook"
          aria-label="Username"
          aria-describedby="basic-addon1"
          value={facebooks}
          onChange={(e) => setFacebooks(e.target.value)}
        />
      </div> */}
      {/* <div className="input-group mb-3">
        <span className="input-group-text" id="basic-addon1">
          @
        </span>
        <input
          type="text"
          className="form-control"
          placeholder="twitter"
          aria-label="Username"
          value={twitter}
          onChange={(e) => setTwitter(e.target.value)}
          aria-describedby="basic-addon1"
        />
      </div> */}
      {/* <div className="input-group mb-3">
        <span className="input-group-text" id="basic-addon1">
          @
        </span>
        <input
          type="text"
          className="form-control"
          placeholder="instargram"
          aria-label="Username"
          value={instagram}
          onChange={(e) => setInstagram(e.target.value)}
          aria-describedby="basic-addon1"
        />
      </div>
      <div className="input-group mb-3">
        <span className="input-group-text" id="basic-addon1">
          @
        </span>
        <input
          type="text"
          className="form-control"
          placeholder="printrest"
          aria-label="Username"
          value={pinterest}
          onChange={(e) => setPrintrest(e.target.value)}
          aria-describedby="basic-addon1"
        />
      </div>
      <div className="input-group mb-3">
        <span className="input-group-text" id="basic-addon1">
          @
        </span>
        <input
          type="text"
          className="form-control"
          placeholder="reddit"
          value={reddit}
          onChange={(e) => setReddit(e.target.value)}
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </div>
      <div className="input-group mb-3">
        <span className="input-group-text" id="basic-addon1">
          @
        </span>
        <input
          type="text"
          className="form-control"
          placeholder="tumbler"
          value={tumbler}
          onChange={(e) => setTumbler(e.target.value)}
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </div> */}
      <Modal
        open={show}
        onClose={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          {/* <Modal.Title>Modal title</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
        <h5>Are you sure you want to delete this link?</h5>
          <p>This action cannot be undone.</p>
        </Modal.Body>
        <Modal.Footer>
        <Button onClick={handleClose} appearance="primary">
            No, I changed my mind
          </Button>
          <Button
            onClick={deleteUpdateSocialMedai}
            color="red"
            appearance="primary"
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GlobalSocialMedailink;
