import React, { useEffect, useState } from "react";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";
import { useSelector } from "react-redux";
import { Modal, Tooltip, Whisper, useToaster, Button } from "rsuite";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../Notification";
import CopyToClipboard from "react-copy-to-clipboard";

const GlobalVideoHashTag = () => {
  const [isCheck, setIsCheck] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [nameTag, setNameTag] = useState("");
  // const [mdata, setMData] = useState("");
  const [tagsid, setTagsid] = useState(null);
  const [open, setOpen] = useState(false);
  const { channelid } = useSelector((state) => state.channels);
  const { fetchId } = useSelector((state) => state.channelIdData);

  const toaster = useToaster();

  const handleOpen = (data) => {
    setOpen(true);
    // console.log("modalData", data);
    // setMData(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (e) => {
    // console.log("deleteData",mdata);
    setOpen(false);
    try {
      await privateRequest.delete(
        `/api/youtube/tag-list/get-update-delete/${tagsid}/`
      );
      toaster.push(
        showSuccessNotification("You have successfully deleted the item")
      );
      // console.log("deleted successfully",deleted)
    } catch (err) {
      toaster.push(showErrorNotification("Something went wrong"));
      // console.log("eoeroero",err)
    }
    setTagsid(null);
    getTagListData();
    // setMData("");
    // mapChannels();
  };

  const showTags = async (data) => {
    // console.log("dataTags", data);
    setTagsid(data);
    try {
      const pureTags = await privateRequest.get(
        `/api/youtube/tag-list/get-update-delete/${data}/`
      );
      // console.log("getpureTags", pureTags.data.keywords);
      setNameTag(pureTags.data.name);
      const soags = pureTags.data.keywords.split(",").map((res) => res);
      setIsCheck([...soags]);
    } catch (err) {
      console.log("errTgs", err);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key !== "Enter" && e.key !== ",") return;
    const value = e.target.value.split(",");
    setIsCheck([...isCheck, ...value]);
    // setIsDirty();
    e.target.value = "";
  };

  const removeTag = (index) => {
    setIsCheck(isCheck.filter((el, i) => i !== index));
    // setIsDirty();
  };

  const getTagListData = async () => {
    try {
      const getTags = await privateRequest.get(
        `/api/youtube/tag-list/${channelid === null ? fetchId : channelid}/`
      );
      // console.log("getTAGS", getTags.data);
      setTagData(getTags.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const updateTags = async () => {
    // console.log("tagsIds", isCheck.toString());
    const forPayload = {
      name: nameTag,
      keywords: isCheck.toString(),
    };
    // console.log("payload", forPayload);
    try {
      // eslint-disable-next-line
      const data = await privateRequest.put(
        `/api/youtube/tag-list/get-update-delete/${tagsid}/`,
        forPayload
      );
      // console.log("hupdatedTags", data);
      toaster.push(
        showSuccessNotification("The Tags has been successfully Updated")
      );
    } catch (err) {
      console.log("updateTagsERROR", err);
    }
  };
  useEffect(() => {
    getTagListData();
  }, [fetchId]);

  return (
    <div className="uk-card uk-card-default uk-card-hover uk-card-body uk-width-2-2@m">
      <div className="d-flex justify-content-between">
        <div>
          <h3 className="heading-14">SAVED VIDEO HASHTAGS</h3>
          <p className="global-sub-text">View and Manage your saved Hashtags</p>
        </div>
        {tagsid === null ? (
          <button
            className="add-descp-btn"
            style={{
              lineHeight: "normal",
              padding: "12px 30px",
              fontSize: "14px",
              // cursor: "pointer",
              height: "fit-content",
              opacity: "0.5",
            }}
            // onClick={updateTags}
          >
            Update Tags
          </button>
        ) : (
          <button
            className="add-descp-btn"
            style={{
              lineHeight: "normal",
              padding: "12px 30px",
              fontSize: "14px",
              cursor: "pointer",
              height: "fit-content",
            }}
            onClick={updateTags}
          >
            Update Tags
          </button>
        )}
      </div>{" "}
      <br />
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <select
            name="country_code"
            onChange={(e) => showTags(e.target.value)}
            style={{
              height: "auto",
              borderRadius: "10px",
              border: "1px solid black",
              width: "auto",
            }}
            className="form-select"
            aria-label="Default select example"
          >
            <option>---</option>
            {tagData.map((res) => (
              <option key={res.id} value={res.id}>
                {res.name}
              </option>
            ))}
            {/* <option value="Affliate2">Tag 2</option>
          <option value="Affliate3">Tag 3</option> */}
          </select>
        </div>
        <div className="d-flex justify-content-between align-items-center gap-3">
          <div>
            {nameTag.length === 0 ? (
              <svg
                style={{ opacity: "0.4" }}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-trash"
              >
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
              </svg>
            ) : (
              <svg
                style={{ cursor: "pointer" }}
                onClick={handleOpen}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-trash"
              >
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
              </svg>
            )}
          </div>
          <div>
            {nameTag.length === 0 ? (
              <svg
                style={{ opacity: "0.4", cursor: "default" }}
                xmlns="http://www.w3.org/2000/svg"
                // className="global-icon-setting"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-copy"
              >
                <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
              </svg>
            ) : (
              <CopyToClipboard text={isCheck.toString()}>
                <Whisper
                  placement="top"
                  controlId="control-id-click"
                  trigger="click"
                  speaker={<Tooltip>Copied</Tooltip>}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    // className="global-icon-setting"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-copy"
                  >
                    <rect
                      x="9"
                      y="9"
                      width="13"
                      height="13"
                      rx="2"
                      ry="2"
                    ></rect>
                    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                  </svg>
                </Whisper>
              </CopyToClipboard>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="uui-text-size-medium original-video-tag-color">
          {isCheck.length === 0
            ? null
            : isCheck.map((tag, index) => (
                <div
                  key={index}
                  className="original-video-tag-badge"
                  style={{ fontSize: "17px" }}
                >
                  <span>{tag}</span>{" "}
                  <span className="close" onClick={() => removeTag(index)}>
                    ×
                  </span>
                </div>
              ))}
          <input
            type="text"
            name="tagi"
            // value={isCheck}
            // onChange={handleKeyDown}
            onKeyDown={(e) => handleKeyDown(e)}
            className="tags-inputs forother"
            style={{
              fontSize: "17px",
              fontWeight: "500",
              border: "none",
              outline: "none",
            }}
            placeholder="Type a tag"
          />
        </div>
      </div>
      <Modal backdrop="static" size="sm" open={open} onClose={handleClose}>
        <Modal.Header>
          {/* <Modal.Title>
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 14 14"
              fill="#2196f3"
              aria-hidden="true"
              focusable="false"
              className="rs-icon"
              aria-label="info"
              data-category="legacy"
            >
              <path d="M7 14A7 7 0 117 0a7 7 0 010 14zm0-9.333a1.167 1.167 0 100-2.334 1.167 1.167 0 000 2.334zm0 1.166c-.644 0-1.167.522-1.167 1.167v3.5a1.167 1.167 0 002.334 0V7c0-.644-.522-1.167-1.167-1.167z"></path>
            </svg>{" "}
            Information
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to delete this item?</h5>
          <p>This action cannot be undone.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="primary">
            No, I changed my mind
          </Button>
          <Button
            onClick={(e) => handleDelete(e)}
            color="red"
            appearance="primary"
          >
            Yes, I want to delete anyway
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GlobalVideoHashTag;
