import React, { useState } from "react";
import { Drawer, Button, Modal } from "rsuite";
// import CollapseCompo from "./CollapseCompo";
import { Accordion } from "react-bootstrap";
import EditIcon from "@rsuite/icons/Edit";

const ViewList = ({
  handleViewClose,
  viewOpen,
  keywordSavedResearches,
  deleteTags,
  EditTags,
}) => {
  // const [openId, setOpenId] = useState(null)
  // const [wholeData,setWholeData] = useState("")
  const [editData,setEditData] = useState();
  const [editInput, setEditInput] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  

  const handleOpenEdit = (res) => {
    setOpenEdit(true);
    handleViewClose();
    setEditInput(res.name)
    setEditData(res)
  };
  const handleCloseEdit = () =>{
    setOpenEdit(false);
    setEditInput();
  };

  // const toggleHandler = (res) => () => {
  //     // toggle closed if already open, otherwise set new open id
  //     setOpenId((openId) => (openId === res.id ? null : res.id));
  //     setWholeData(res)
  //   };

  return (
    <>
      <Drawer backdrop="static" open={viewOpen} onClose={handleViewClose}>
        <Drawer.Header>
          <Drawer.Title>View List</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={handleViewClose}>Cancel</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <>
            <Accordion>
              {keywordSavedResearches.map((res) => (
                <div key={res.id}>
                  <Accordion.Item
                    style={{ border: "1px solid #d3cdcd", marginTop: "3px" }}
                    eventKey={res.id}
                  >
                    <Accordion.Header>
                      {res.name} &nbsp;
                      <span onClick={() => handleOpenEdit(res)}>
                        <EditIcon />
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="uui-text-size-medium-suggested-accordian-keyword text-color-white">
                        <h6 className="pill-recomd">{res.name}</h6>
                        {res.keywords === null
                          ? ""
                          : res.keywords.split(",").map((result, index) => (
                              <span key={index} className="kr-sugkey-keyword">
                                {result}
                              </span>
                            ))}
                      </div>
                      <div className="d-flex justify-content-end mt-1">
                        <button
                          className="add-descp-btn"
                          onClick={(e) => deleteTags(e, res)}
                        >
                          Delete
                        </button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* <CollapseCompo wholeData={wholeData} open={openId === res.id} onClick={toggleHandler(res)}  /> */}
                </div>
              ))}
            </Accordion>
          </>
        </Drawer.Body>
      </Drawer>
      <Modal
        backdrop="static"
        keyboard={false}
        open={openEdit}
        onClose={handleCloseEdit}
      >
        <Modal.Header>
          <Modal.Title>Edit List</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container">
            <div className="col-md-12">
              <label for="validationCustom01" className="form-label">
                Edit List Name
              </label>
              <input
                control="input"
                name="name"
                type="input"
                value={editInput}
                class="form-control"
                id="exampleFormControlInput1"
                onChange={(e) => setEditInput(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseEdit} appearance="subtle">
            Cancel
          </Button>
          <Button onClick={(e) => EditTags(e,editData,editInput,handleCloseEdit())} appearance="primary">
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewList;
