import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import CopyToClipboard from "react-copy-to-clipboard";
import SavedModal from "../Pages/SavedModal";
import ViewList from "../Pages/ViewList";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";
import { ProgressBar } from "react-loader-spinner";
import { showErrorNotification, showSuccessNotification } from "../../../Notification";
import { Tooltip, useToaster, Whisper } from "rsuite";
import { useSelector } from "react-redux";
import InfoOutlineIcon from "@rsuite/icons/InfoOutline";
import { tooltips } from "../Pages/StringStore";
import GraphModal from "../Pages/GraphModal";

const KeywordResearch = () => {
  const [isCheck, setIsCheck] = useState([]);
  const [keyTag, setKeyTag] = useState([]);
  const [open, setOpen] = useState(false);
  const [sortUp, setSortUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listings, setListings] = useState([]);
  const [viewOpen, setViewOpen] = useState(false);
  const [openGraph, setOpenGraph] = useState(false);

  const { channelid } = useSelector((state) => state.channels);
  const { fetchId } = useSelector((state) => state.channelIdData);
  const toaster = useToaster();

  const handleViewOpen = () => setViewOpen(true);
  const handleViewClose = () => setViewOpen(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // graph open close
  const handleOpenGraph = () => setOpenGraph(true);
  const handleCloseGraph = () => setOpenGraph(false);

  // for sorting up keywords
  const sortingup = () => {
    const sorted = [...keyTag].sort((a, b) => {
      // a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      // a[col] - b[col]
      const first = `${a.keyword}`;
      const second = `${b.keyword}`;
      return first.localeCompare(second);
    });
    // console.log("iamsorted",listings)
    setKeyTag(sorted);
    setSortUp(true);
    // setTypeName("Old to New")
    // setOrder("down");
  };
  // for sorting down keywords
  const sortingdown = () => {
    const sorted = [...keyTag].sort((a, b) => {
      // a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      // a[col] - b[col]
      const first = `${a.keyword}`;
      const second = `${b.keyword}`;
      return second.localeCompare(first);
    });
    // console.log("iamsorted",listings)
    setKeyTag(sorted);
    setSortUp(false);
    // setTypeName("Old to New")
    // setOrder("down");
  };

  // for all number up
  const allSortup = (data) => {
    // console.log("allsorts",data);
    // let marks = [12, 25, 31, 23, 75, 81, 100];
    //  const sorted = marks.sort(function (a, b) { return a - b });
    const sorted = [...keyTag].sort((a, b) => {
      // a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      // a[col] - b[col]
      const first = `${a[data]}`;
      // console.log("dadd",first)
      const second = `${b[data]}`;
      return first - second;
    });
    // console.log("iamsorted",sorted)
    setKeyTag(sorted);
    setSortUp(true);
  };
  // for all number up
  const allSortdown = (data) => {
    // console.log("allsorts",data);
    //  const sorted = marks.sort(function (a, b) { return b - a });
    const sorted = [...keyTag].sort((a, b) => {
      // a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      // a[col] - b[col]
      const first = `${a[data]}`;
      // console.log("dadd",first)
      const second = `${b[data]}`;
      return second - first;
    });
    // console.log("iamsorted",sorted)
    setKeyTag(sorted);
    setSortUp(false);
  };

  const deleteTags = async (e, data) => {
    // console.log("deleteTags", data);
    try {
      await privateRequest.delete(
        `/api/youtube/tag-list/get-update-delete/${data.id}/`
      );
      // console.log("deletedTags", deleted);
      toaster.push(
        showSuccessNotification(
          "Operation completed successfully: Deleted from the list."
        )
      );
    } catch (err) {
      console.log("delete", err);
    }
    viewList();
  };

  const EditTags = async (e, data, edits) => {
    // console.log("allData", data);
    // console.log("edits", edits);
    const datas = {
      name: edits,
      keywords: data.keywords,
    };
    try {
      await privateRequest.put(
        `/api/youtube/tag-list/get-update-delete/${data.id}/`,
        datas
      );
      // console.log("updates", updates);
      toaster.push(
        showSuccessNotification(
          "Operation completed successfully: Updated in the list."
        )
      );
    } catch (err) {
      console.log("err", err);
    }
    viewList();
  };

  const addList = async (e, val) => {
    // console.log("val", val);
    // console.log("IsCheck", isCheck.toString());
    const key = isCheck.toString();
    try {
      await privateRequest.post(`/api/youtube/tag-list/`, {
        multichannel: channelid === null ? fetchId : channelid,
        name: val,
        keywords: `${key}`,
      });
      // console.log("POSTdATA", post_data);
      toaster.push(
        showSuccessNotification(
          "Operation completed successfully: Added to the list."
        )
      );
    } catch (err) {
      console.log("err", err);
    }
    handleClose();
    viewList();
  };

  const viewList = async () => {
    try {
      const list = await privateRequest.get(
        `/api/youtube/tag-list/${channelid === null ? fetchId : channelid}/`
      );
      // console.log("list", list.data);
      setListings(list.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const visibled = (e) => {
    const { name, checked } = e.target;
    // console.log("kw", name);
    setIsCheck([...isCheck, name]);
    // setIsDirty();
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== name));
    }
  };

  const initialValues = {
    keyword: "",
  };

  const generateAnswer = async (values) => {
    // console.log("keywordsCheck", values);
    //   try {
    //     setLoading(true);
    //     const data = await privateRequest.post(
    //       `/api/youtube/tag-research/`,
    //       values
    //     );
    //     setLoading(false);
    //     // console.log("data", data.data);
    //     setKeyTag(data.data);
    //   } catch (err) {
    //     setLoading(true);
    //     console.log("err", err);
    //     setLoading(false);
    //   }
    // };
    try {
      setLoading(true);
      const data = await privateRequest.post(
        `/api/youtube/keyword-research/`,
        values
      );
      setLoading(false);
      console.log("data", data);
      if(data.data.message === "You are not subscribed to this API."){
        toaster.push(showErrorNotification("You are not subscribed to this API."))
      }
      setKeyTag(data.data.related_keywords===undefined?null:data.data.related_keywords);
    } catch (err) {
      setLoading(true);
      // console.log("err", err);
      toaster.push(showErrorNotification("Some thing went wrong"))
      setLoading(false);
    }
  };

  useEffect(() => {
    viewList();
  }, [channelid, fetchId]);
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="ais" style={{ marginBottom: "0px" }}>
          Keyword Video Research{" "}
          <span className="information">
            <Whisper
              placement="bottomEnd"
              controlId="control-id-click"
              trigger="hover"
              speaker={<Tooltip>{tooltips.keywordresearch}</Tooltip>}
            >
              <InfoOutlineIcon style={{ marginBottom: "12px" }} />
            </Whisper>
          </span>
        </h1>
        <div>
          <button
            type="button"
            // onClick={(e) => addVideoPlaylist(e, formik)}
            className="add-descp-btn me-2"
            onClick={handleViewOpen}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#fff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-eye"
            >
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
              <circle cx="12" cy="12" r="3"></circle>
            </svg>
            &nbsp; VIEW LIST
          </button>
        </div>
      </div>
      {/* <div  className="containers-custom uk-padding-small">
      <p className="notify">Discover the perfect keywords for your YouTube videos with our advanced Keyword Research Tool. Uncover high-performing, low-competition keywords that will boost your video's visibility and drive more organic traffic to your channel. Stay ahead of the competition and optimize your content effortlessly.</p>
      </div> */}
      <div
        className="containers-custom uk-padding-small"
        // style={{ paddingLeft: "30px", paddingTop: "16px" }}
      >
        <div className="f-tab-heading">
          Enter your video idea to generate relevant tags. Select the relevant
          tags and copy it to clipboard or save in the list
        </div>
        <div className="container-fluid">
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              await generateAnswer(values);
              resetForm();
            }}
          >
            {(formik) => {
              return (
                <Form>
                  <div className="row mt-2">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <div className="brix---position-relative-2">
                        <input
                          type="text"
                          className="brix---input-large-button-inside w-input"
                          autoFocus={true}
                          style={{ border: "none" }}
                          maxLength="256"
                          data-name="BRIX - Footer Email - V16"
                          placeholder="Search for video related keywords"
                          id="BRIX-Footer-Email-V16"
                          name="keyword"
                          value={formik.values.keyword}
                          onChange={formik.handleChange}
                          // value={askyt}
                          //           onChange={(e) => setAskYt(e.target.value)}
                        />
                        {formik.values.keyword.length === 0 ||
                        loading === true ? (
                          <input
                            type="button"
                            style={{ border: "none", backgroundColor: "grey" }}
                            data-wait="Please wait..."
                            className="brix---btn-primary-inside-input w-button"
                            value="Generate"
                          />
                        ) : (
                          <input
                            type="submit"
                            data-wait="Please wait..."
                            value="Generate"
                            style={{ border: "none" }}
                            className="brix---btn-primary-inside-input w-button"
                          />
                        )}{" "}
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <div className="container-fluide mt-2">
        {loading === true ? (
          <div className="d-flex justify-content-center align-items-center mt-3">
            LOADING &nbsp;
            <ProgressBar
              height="80"
              width="80"
              ariaLabel="progress-bar-loading"
              // wrapperStyle={{}}
              wrapperClass="progress-bar-wrapper"
              borderColor="#53389e"
              barColor="#53389e"
            />
          </div>
        ) : keyTag === null || keyTag.length === 0 ? (
          ""
        ) : (
          // <div className="card">
          /* <div className="card-body "> */
          <>
            <div className="d-flex gap-2 justify-content-center uk-margin-top">
              <CopyToClipboard text={isCheck}>
                <button className="add-descp-btn">COPY TO CLIPBOARD</button>
              </CopyToClipboard>
              <button
                type="button"
                // onClick={(e) => addVideoPlaylist(e, formik)}
                className="add-descp-btn"
                onClick={handleOpen}
              >
                SAVE TO LIST
              </button>
              {/* <button className="btn btn-success" >
                SAVED TO LIST
              </button> */}

              {/* <button className="btn btn-success" >
               
              </button> */}
            </div>

            <div className="mt-2">
              <div className="uui-text-size-medium original-video-tag-color">
                <div className="row">
                  {/* <div className="col-4">
                    <div className="uk-card uk-card-body uk-card-default">
                      <div className="uk-margin-bottom d-flex justify-content-between align-items-center">
                      <h5 style={{color:"black"}}>Frequent</h5>
                      <Badge color="red" content="HARD"></Badge>
                      </div>
                      <hr style={{borderBottom:"2px solid black",opacity:"1",marginTop:"0px"}}/>
                      <div className="d-flex justify-content-between align-items-center">
                      
                            <label
                              // className="d-flex align-items-center"
                            >
                              <input
                              style={{width:"17px",height:"17px",opacity:"0.5"}}
                                type="checkbox"
                                value={"happy"}
                                name={"happy"}
                                onChange={(e) => visibled(e)}
                                checked={isCheck.includes("happy")}
                              />&nbsp;
                             <p style={{fontWeight:"400",color:"black",marginLeft:"5px"}}>happy</p> 
                              
                            </label>
                            <span style={{opacity:"0.6"}}>200</span>
                        
                      </div>
                    </div>
                  </div> */}
                  <div className="col-lg-4 col-md-12 col-12">
                    <div className="uk-card uk-card-body uk-card-default">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-centers">
                          <p className="keyword-indicator-bg">
                            Diffculty:&nbsp;
                            <span className="hard-badge">HIGH</span>
                          </p>
                        </div>
                        {/* <div>
                          <button className="add-descp-btn" onClick={handleOpenGraph}>Open Graph</button>
                        </div> */}
                        <label className="keyword-info-bg">
                          Most used keyword across Youtube
                        </label>
                      </div>
                      <table className="table table-responsive table-borderless">
                        <thead>
                          <tr
                            style={{
                              borderBottom: "2px solid black",
                              opacity: "1",
                              marginTop: "0px",
                            }}
                          >
                            {/* <div className="d-flex justify-content-between"> */}
                            <th
                              scope="col"
                              style={{ width: "27%" }}
                              className="frequent-head"
                            >
                              <div>
                                Keywords
                                {sortUp === false ? (
                                  <span onClick={sortingup}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-up"
                                    >
                                      <line
                                        x1="12"
                                        y1="19"
                                        x2="12"
                                        y2="5"
                                      ></line>
                                      <polyline points="5 12 12 5 19 12"></polyline>
                                    </svg>
                                  </span>
                                ) : (
                                  <span onClick={sortingdown}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-down"
                                    >
                                      <line
                                        x1="12"
                                        y1="5"
                                        x2="12"
                                        y2="19"
                                      ></line>
                                      <polyline points="19 12 12 19 5 12"></polyline>
                                    </svg>
                                  </span>
                                )}
                              </div>
                            </th>
                            <th
                              scope="col"
                              style={{ width: "28%" }}
                              className="frequent-head"
                            >
                              <div>
                                Competition
                                {sortUp === false ? (
                                  <span
                                    onClick={() =>
                                      allSortup("competition_score")
                                    }
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-up"
                                    >
                                      <line
                                        x1="12"
                                        y1="19"
                                        x2="12"
                                        y2="5"
                                      ></line>
                                      <polyline points="5 12 12 5 19 12"></polyline>
                                    </svg>
                                  </span>
                                ) : (
                                  <span
                                    onClick={() =>
                                      allSortdown("competition_score")
                                    }
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-down"
                                    >
                                      <line
                                        x1="12"
                                        y1="5"
                                        x2="12"
                                        y2="19"
                                      ></line>
                                      <polyline points="19 12 12 19 5 12"></polyline>
                                    </svg>
                                  </span>
                                )}
                              </div>
                            </th>
                            <th
                              scope="col"
                              style={{ width: "25%" }}
                              className="frequent-head"
                            >
                              <div>
                                Overall
                                {sortUp === false ? (
                                  <span
                                    onClick={() => allSortup("overallscore")}
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-up"
                                    >
                                      <line
                                        x1="12"
                                        y1="19"
                                        x2="12"
                                        y2="5"
                                      ></line>
                                      <polyline points="5 12 12 5 19 12"></polyline>
                                    </svg>
                                  </span>
                                ) : (
                                  <span
                                    onClick={() => allSortdown("overallscore")}
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-down"
                                    >
                                      <line
                                        x1="12"
                                        y1="5"
                                        x2="12"
                                        y2="19"
                                      ></line>
                                      <polyline points="19 12 12 19 5 12"></polyline>
                                    </svg>
                                  </span>
                                )}
                              </div>
                            </th>
                            <th
                              scope="col"
                              style={{ width: "26%" }}
                              className="frequent-head"
                            >
                              <div>
                                Monthly Search
                                {sortUp === false ? (
                                  <span
                                    onClick={() => allSortup("monthlysearch")}
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-up"
                                    >
                                      <line
                                        x1="12"
                                        y1="19"
                                        x2="12"
                                        y2="5"
                                      ></line>
                                      <polyline points="5 12 12 5 19 12"></polyline>
                                    </svg>
                                  </span>
                                ) : (
                                  <span
                                    onClick={() => allSortdown("monthlysearch")}
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-down"
                                    >
                                      <line
                                        x1="12"
                                        y1="5"
                                        x2="12"
                                        y2="19"
                                      ></line>
                                      <polyline points="19 12 12 19 5 12"></polyline>
                                    </svg>
                                  </span>
                                )}
                              </div>
                            </th>
                            {/* </div> */}
                          </tr>
                          {/* <hr style={{borderBottom:"2px solid black",opacity:"1",marginTop:"0px"}}/> */}
                        </thead>
                        <tbody>
                          {/* <div className="card-body"> */}
                          {keyTag.length === 0
                            ? ""
                            : keyTag.map(
                                (
                                  {
                                    keyword,
                                    competition_score,
                                    overallscore,
                                    difficulty,
                                    monthlysearch,
                                  },
                                  index
                                ) =>
                                  difficulty === "High" ? (
                                    <tr className="label-styles" key={index}>
                                      <td>
                                        <label
                                          className="d-flex align-items-center"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <input
                                            type="checkbox"
                                            style={{
                                              width: "17px",
                                              height: "17px",
                                              opacity: "0.5",
                                            }}
                                            value={keyword}
                                            name={keyword}
                                            onChange={(e) => visibled(e)}
                                            checked={isCheck.includes(keyword)}
                                          />
                                          <p
                                            style={{
                                              fontWeight: "400",
                                              color: "black",
                                              marginLeft: "5px",
                                            }}
                                          >
                                            {keyword}
                                          </p>
                                        </label>
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {competition_score}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {overallscore}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {monthlysearch}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )
                              )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-12">
                    <div className="uk-card uk-card-body uk-card-default">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-centers">
                          <p className="keyword-indicator-bg">
                            Diffculty:&nbsp;
                            <span className="medium-badge">MEDIUM</span>
                          </p>
                        </div>
                        <label className="keyword-info-bg">
                          Trending Keywords across Youtube
                        </label>
                      </div>
                      <table className="table table-responsive table-borderless">
                        <thead>
                          <tr
                            style={{
                              borderBottom: "2px solid black",
                              opacity: "1",
                              marginTop: "0px",
                            }}
                          >
                            {/* <div className="d-flex justify-content-between"> */}
                            <th
                              scope="col"
                              style={{ width: "27%" }}
                              className="frequent-head"
                            >
                              <div>
                                Keywords
                                {sortUp === false ? (
                                  <span onClick={sortingup}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-up"
                                    >
                                      <line
                                        x1="12"
                                        y1="19"
                                        x2="12"
                                        y2="5"
                                      ></line>
                                      <polyline points="5 12 12 5 19 12"></polyline>
                                    </svg>
                                  </span>
                                ) : (
                                  <span onClick={sortingdown}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-down"
                                    >
                                      <line
                                        x1="12"
                                        y1="5"
                                        x2="12"
                                        y2="19"
                                      ></line>
                                      <polyline points="19 12 12 19 5 12"></polyline>
                                    </svg>
                                  </span>
                                )}
                              </div>
                            </th>
                            <th
                              scope="col"
                              style={{ width: "28%" }}
                              className="frequent-head"
                            >
                              <div>
                                Competition
                                {sortUp === false ? (
                                  <span
                                    onClick={() =>
                                      allSortup("competition_score")
                                    }
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokewinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-up"
                                    >
                                      <line
                                        x1="12"
                                        y1="19"
                                        x2="12"
                                        y2="5"
                                      ></line>
                                      <polyline points="5 12 12 5 19 12"></polyline>
                                    </svg>
                                  </span>
                                ) : (
                                  <span
                                    onClick={() =>
                                      allSortdown("competition_score")
                                    }
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-down"
                                    >
                                      <line
                                        x1="12"
                                        y1="5"
                                        x2="12"
                                        y2="19"
                                      ></line>
                                      <polyline points="19 12 12 19 5 12"></polyline>
                                    </svg>
                                  </span>
                                )}
                              </div>
                            </th>
                            <th
                              scope="col"
                              style={{ width: "25%" }}
                              className="frequent-head"
                            >
                              <div>
                                Overall
                                {sortUp === false ? (
                                  <span
                                    onClick={() => allSortup("overallscore")}
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-up"
                                    >
                                      <line
                                        x1="12"
                                        y1="19"
                                        x2="12"
                                        y2="5"
                                      ></line>
                                      <polyline points="5 12 12 5 19 12"></polyline>
                                    </svg>
                                  </span>
                                ) : (
                                  <span
                                    onClick={() => allSortdown("overallscore")}
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-down"
                                    >
                                      <line
                                        x1="12"
                                        y1="5"
                                        x2="12"
                                        y2="19"
                                      ></line>
                                      <polyline points="19 12 12 19 5 12"></polyline>
                                    </svg>
                                  </span>
                                )}
                              </div>
                            </th>
                            <th
                              scope="col"
                              style={{ width: "26%" }}
                              className="frequent-head"
                            >
                              <div>
                                Monthly Search
                                {sortUp === false ? (
                                  <span
                                    onClick={() => allSortup("monthlysearch")}
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-up"
                                    >
                                      <line
                                        x1="12"
                                        y1="19"
                                        x2="12"
                                        y2="5"
                                      ></line>
                                      <polyline points="5 12 12 5 19 12"></polyline>
                                    </svg>
                                  </span>
                                ) : (
                                  <span
                                    onClick={() => allSortdown("monthlysearch")}
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-down"
                                    >
                                      <line
                                        x1="12"
                                        y1="5"
                                        x2="12"
                                        y2="19"
                                      ></line>
                                      <polyline points="19 12 12 19 5 12"></polyline>
                                    </svg>
                                  </span>
                                )}
                              </div>
                            </th>
                            {/* </div> */}
                          </tr>
                          {/* <hr style={{borderBottom:"2px solid black",opacity:"1",marginTop:"0px"}}/> */}
                        </thead>
                        <tbody>
                          {keyTag.length === 0
                            ? ""
                            : keyTag.map(
                                (
                                  {
                                    keyword,
                                    competition_score,
                                    overallscore,
                                    difficulty,
                                    monthlysearch,
                                  },
                                  index
                                ) =>
                                  difficulty === "Medium" ? (
                                    <tr className="label-styles" key={index}>
                                      <td>
                                        <label
                                          className="d-flex align-items-center"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <input
                                            type="checkbox"
                                            style={{
                                              width: "17px",
                                              height: "17px",
                                              opacity: "0.5",
                                            }}
                                            value={keyword}
                                            name={keyword}
                                            onChange={(e) => visibled(e)}
                                            checked={isCheck.includes(keyword)}
                                          />
                                          <p
                                            style={{
                                              fontWeight: "400",
                                              color: "black",
                                              marginLeft: "5px",
                                            }}
                                          >
                                            {keyword}
                                          </p>
                                        </label>
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {competition_score}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {overallscore}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {monthlysearch}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )
                              )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-12">
                    <div className="uk-card uk-card-body uk-card-default">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-centers">
                          <p className="keyword-indicator-bg">
                            Diffculty:&nbsp;
                            <span className="easy-badge">LOW</span>
                          </p>
                        </div>
                        <label className="keyword-info-bg">
                          Keywords with future potential on Youtube
                        </label>
                      </div>
                      <table className="table table-responsive table-borderless">
                        <thead>
                          <tr
                            style={{
                              borderBottom: "2px solid black",
                              opacity: "1",
                              marginTop: "0px",
                            }}
                          >
                            {/* <div className="d-flex justify-content-between"> */}
                            <th
                              scope="col"
                              style={{ width: "27%" }}
                              className="frequent-head"
                            >
                              <div>
                                Keywords
                                {sortUp === false ? (
                                  <span onClick={sortingup}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-up"
                                    >
                                      <line
                                        x1="12"
                                        y1="19"
                                        x2="12"
                                        y2="5"
                                      ></line>
                                      <polyline points="5 12 12 5 19 12"></polyline>
                                    </svg>
                                  </span>
                                ) : (
                                  <span onClick={sortingdown}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-down"
                                    >
                                      <line
                                        x1="12"
                                        y1="5"
                                        x2="12"
                                        y2="19"
                                      ></line>
                                      <polyline points="19 12 12 19 5 12"></polyline>
                                    </svg>
                                  </span>
                                )}
                              </div>
                            </th>
                            <th
                              scope="col"
                              style={{ width: "28%" }}
                              className="frequent-head"
                            >
                              <div>
                                Competition
                                {sortUp === false ? (
                                  <span
                                    onClick={() =>
                                      allSortup("competition_score")
                                    }
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-up"
                                    >
                                      <line
                                        x1="12"
                                        y1="19"
                                        x2="12"
                                        y2="5"
                                      ></line>
                                      <polyline points="5 12 12 5 19 12"></polyline>
                                    </svg>
                                  </span>
                                ) : (
                                  <span
                                    onClick={() =>
                                      allSortdown("competition_score")
                                    }
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-down"
                                    >
                                      <line
                                        x1="12"
                                        y1="5"
                                        x2="12"
                                        y2="19"
                                      ></line>
                                      <polyline points="19 12 12 19 5 12"></polyline>
                                    </svg>
                                  </span>
                                )}
                              </div>
                            </th>
                            <th
                              scope="col"
                              style={{ width: "25%" }}
                              className="frequent-head"
                            >
                              <div>
                                Overall
                                {sortUp === false ? (
                                  <span
                                    onClick={() => allSortup("overallscore")}
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-up"
                                    >
                                      <line
                                        x1="12"
                                        y1="19"
                                        x2="12"
                                        y2="5"
                                      ></line>
                                      <polyline points="5 12 12 5 19 12"></polyline>
                                    </svg>
                                  </span>
                                ) : (
                                  <span
                                    onClick={() => allSortdown("overallscore")}
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-down"
                                    >
                                      <line
                                        x1="12"
                                        y1="5"
                                        x2="12"
                                        y2="19"
                                      ></line>
                                      <polyline points="19 12 12 19 5 12"></polyline>
                                    </svg>
                                  </span>
                                )}
                              </div>
                            </th>
                            <th
                              scope="col"
                              style={{ width: "26%" }}
                              className="frequent-head"
                            >
                              <div>
                                Monthly Search
                                {sortUp === false ? (
                                  <span
                                    onClick={() => allSortup("monthlysearch")}
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-up"
                                    >
                                      <line
                                        x1="12"
                                        y1="19"
                                        x2="12"
                                        y2="5"
                                      ></line>
                                      <polyline points="5 12 12 5 19 12"></polyline>
                                    </svg>
                                  </span>
                                ) : (
                                  <span
                                    onClick={() => allSortdown("monthlysearch")}
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-arrow-down"
                                    >
                                      <line
                                        x1="12"
                                        y1="5"
                                        x2="12"
                                        y2="19"
                                      ></line>
                                      <polyline points="19 12 12 19 5 12"></polyline>
                                    </svg>
                                  </span>
                                )}
                              </div>
                            </th>
                            {/* </div> */}
                          </tr>
                          {/* <hr style={{borderBottom:"2px solid black",opacity:"1",marginTop:"0px"}}/> */}
                        </thead>
                        <tbody>
                          {keyTag.length === 0
                            ? ""
                            : keyTag.map(
                                (
                                  {
                                    keyword,
                                    competition_score,
                                    overallscore,
                                    difficulty,
                                    monthlysearch,
                                  },
                                  index
                                ) =>
                                  difficulty === "Low" ? (
                                    <tr className="label-styles" key={index}>
                                      <td>
                                        <label
                                          className="d-flex align-items-center col"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <input
                                            type="checkbox"
                                            style={{
                                              width: "17px",
                                              height: "17px",
                                              opacity: "0.5",
                                            }}
                                            value={keyword}
                                            name={keyword}
                                            onChange={(e) => visibled(e)}
                                            checked={isCheck.includes(keyword)}
                                          />
                                          <p
                                            style={{
                                              fontWeight: "400",
                                              color: "black",
                                              marginLeft: "5px",
                                            }}
                                          >
                                            {keyword}
                                          </p>
                                        </label>
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {competition_score}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {overallscore}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {monthlysearch}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )
                              )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          /* </div> */
          /* </div> */
        )}
        <SavedModal
          addList={addList}
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
        />
        <ViewList
          keywordSavedResearches={listings}
          viewOpen={viewOpen}
          handleViewOpen={handleViewOpen}
          handleViewClose={handleViewClose}
          deleteTags={deleteTags}
          EditTags={EditTags}
        />
        <GraphModal
          openGraph={openGraph}
          handleCloseGraph={handleCloseGraph}
          handleOpenGraph={handleOpenGraph}
        />
      </div>
    </>
  );
};

export default KeywordResearch;
