import React,{ useRef, useEffect } from 'react';
import * as d3 from 'd3';
// import ReactCalendarHeatmap from 'react-calendar-heatmap';
// import 'react-calendar-heatmap/dist/styles.css';
// import CalendarHeatmap from 'react-calendar-heatmap';


const HeatMaps = ({videoPloting,channelTitle}) => {
  // console.log("plotting",videoPloting)
    const contributionsData = videoPloting

      const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const svgRef = useRef();

      
    //   useEffect(() => {
    //     const svg = d3.select(svgRef.current);
    
    //     const margin = { top: 80, right: 40, bottom: 40, left: 120 };
    //     const width = 1000 - margin.left - margin.right;
    //     const height = 400 - margin.top - margin.bottom;
    
    //     const xScale = d3.scaleBand()
    //       .domain([...Array(24).keys()]) // Hours from 0 to 23
    //       .range([0, width]);
    
    //     const yScale = d3
    //       .scaleBand()
    //       .domain([...new Set(contributionsData.map(d => d.day))])
    //       .range([0, height]);
    
    //     const colorScale = d3
    //       .scaleSequential(d3.interpolateBlues)
    //       .domain([0, d3.max(contributionsData, d => d.count)]);
    
    //     svg.selectAll('*').remove();
    
    //     const g = svg
    //       .append('g')
    //       .attr('transform', `translate(${margin.left},${margin.top})`);
    
    //     g.selectAll('rect')
    //       .data(contributionsData)
    //       .enter()
    //       .append('rect')
    //       .attr('x', d => xScale(d.hour))
    //       .attr('y', d => yScale(d.day))
    //       .attr('width', xScale.bandwidth())
    //       .attr('height', yScale.bandwidth())
    //       .attr('fill', d => colorScale(d.count));
    
    //     const xAxis = d3.axisBottom(xScale)
    //       .tickFormat(d => {
    //         if (d === 0) {
    //           return '12 AM'; // Special formatting for 12 AM
    //         } else if (d === 12) {
    //           return '12 PM'; // Special formatting for 12 PM
    //         } else if (d > 12) {
    //           return `${d - 12} PM`; // PM formatting for hours after 12 PM
    //         } else {
    //           return `${d} AM`; // AM formatting for hours before 12 PM
    //         }
    //       });
    
    //     const yAxis = d3.axisLeft(yScale);
    
    //     g.append('g')
    //       .attr('transform', `translate(0,${height})`)
    //       .call(xAxis)
    //       .selectAll('.tick text') // Select the tick labels
    //     .style('font-size', '12px') // Apply font size here;
    //     .style('font-weight', '800');
    
    //     g.append('g')
    //     .call(yAxis)
    //     .selectAll('.tick text') // Select the tick labels
    //     .style('font-size', '16px') // Apply font size here;
    //     .style('font-weight', '800');
    //   }, []);

    useEffect(() => {
        const svg = d3.select(svgRef.current);
    
        const margin = { top: 50, right: 40, bottom: 80, left: 120 };
        const width = 1000 - margin.left - margin.right;
        const height = 400 - margin.top - margin.bottom;
    
        const xScale = d3.scaleBand()
          .domain([...Array(24).keys()]) // Hours from 0 to 23
          .range([0, width]);
    
        const yScale = d3
          .scaleBand()
          .domain(dayNames)
          .range([0, height]);
    
        const colorScale = d3
          .scaleSequential(d3.interpolatePurples)
          .domain([0, d3.max(contributionsData, d => d.count)]);
    
        svg.selectAll('*').remove();
    
        const g = svg
          .append('g')
          .attr('transform', `translate(${margin.left},${margin.top})`);
    // eslint-disable-next-line
        const rects = g.selectAll('rect')
          .data(contributionsData)
          .enter()
          .append('rect')
          .attr('x', d => xScale(d.hour))
          .attr('y', d => yScale(d.day))
          .attr('width', xScale.bandwidth())
          .attr('height', yScale.bandwidth())
          .attr('fill', d => colorScale(d.count))
          .on('mouseenter', (event, d) => {
            // Display count within the cell
            g.append('text')
              .attr('class', 'count-label')
              .attr('x', xScale(d.hour) + xScale.bandwidth() / 2)
              .attr('y', yScale(d.day) + yScale.bandwidth() / 2)
              .attr('text-anchor', 'middle')
              .attr('dy', '0.3em')
              .attr('fill', 'black') 
              .text(`${d.day}:${d.count}`)
              .style('font-size', '12px')
              .style('font-weight', '800');
          })
          .on('mouseleave', () => {
            // Remove count label on mouse leave
            g.selectAll('.count-label').remove();
          });
    
        const xAxis = d3.axisBottom(xScale)
          .tickFormat(d => {
            if (d === 0) {
              return '12 AM';
            } else if (d === 12) {
              return '12 PM';
            } else if (d > 12) {
              return `${d - 12} PM`;
            } else {
              return `${d} AM`;
            }
          });
    
        const yAxis = d3.axisLeft(yScale);
    
        g.append('g')
          .attr('transform', `translate(0,${height})`)
          .call(xAxis)
          .selectAll('.tick text')
          .style('font-size', '10px')
          .style('font-weight', '800');
    
        g.append('g')
          .call(yAxis)
          .selectAll('.tick text')
          .style('font-size', '16px')
          .style('font-weight', '800');
      }, [videoPloting]);
      
  return (
    <div style={{marginTop:"20px"}}>
        {/* <ReactCalendarHeatmap 
        startDate={new Date('2023-08-01')}
        endDate={new Date('2023-08-31')}
        values={contributionsData}
        classForValue={(value) => {
          if (!value) {
            return 'color-empty';
          }
          return `color-github-${value.count}`;
        }}/> */}
        <h4>The Upload Frequency and Days Which {channelTitle} follows</h4>
        <p>Potentially useful aggregate data on found videos.</p>
        <div className="containers-custom">
        <svg ref={svgRef} width={1000} height={400}></svg>
        </div>
    </div>
  )
}

export default HeatMaps