import React from "react";
import { useState, useEffect, useRef } from "react";
import { Tab, Nav } from "react-bootstrap";
import { Formik, Form } from "formik";
import DrawerPage from "../Pages/DrawerPage";
import SocialMediaPage from "../Pages/SocialMediaPage";
import AffliatePage from "../Pages/AffliatePage";
import VideoAndPlaylist from "../Pages/VideoAndPlaylist";
import VideoRecommand from "../Pages/VideoRecommand";
// import Thumbnail from "../Pages/Thumbnail";
import ThumbnailC from "../Pages/ThumbnailC";
import { useParams } from "react-router-dom";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";
import CopyToClipboard from "react-copy-to-clipboard";
import { Tooltip, Whisper, useToaster } from "rsuite";
import { showSuccessNotification } from "../../../Notification";
import { useSelector } from "react-redux";
import { fileRequest } from "../../../api/axiosConfig/fileRequest";
// import { ScriptTag } from "react-script-tag";
import GoogleTrend from "../Pages/GoogleTrend";
// import GoogleTrendsComponent from "../Pages/NewGoogleTrends";
import GoogleTrends2 from "../Pages/GoogleTrends2";
import { country } from "../Pages/Country";
import ContentLoader from "react-content-loader";
// import Modals from "../Pages/Modals";

const Optimize = () => {
  const [locate, setLocate] = useState("recommand");
  const [locate2, setLocate2] = useState("long");
  const [isCheck, setIsCheck] = useState([]);
  const [countrycode, setCountrycode] = useState("us");
  const [show, setShow] = useState(false);
  const [focusword, setFocusword] = useState("");
  const toaster = useToaster();
  const [socialInputsFields, setsocialInputsFields] = useState([]);

  const { channelid } = useSelector((state) => state.channels);
  const { fetchId } = useSelector((state) => state.channelIdData);

  const cntryCode = (e) => {
    // console.log("iniresting", e);
    setCountrycode(e);
    // getSearchWord();
    // reRendering();
  };

  const increaseInput = () => {
    //   setsocialInputsFields([...socialInputsFields, {
    //     name:'',socialLink:''
    // } ])
    setsocialInputsFields([...socialInputsFields, { name: "", url: "" }]);
  };

  const decreaseInputField = (e, i) => {
    // console.log("decrising",i)
    // e.preventDefault();
    let newFormValues = [...socialInputsFields];
    newFormValues.splice(i, 1);
    setsocialInputsFields(newFormValues);
    // handleDynamicChange(newFormValues,e)
  };

  const handleDynamicChange = (i, e) => {
    let newFormValues = [...socialInputsFields];
    newFormValues[i][e.target.name] = e.target.value;
    setsocialInputsFields(newFormValues);
  };

  const socialMediaApp = [
    { id: 1, media: "Facebook" },
    { id: 2, media: "YouTube" },
    { id: 3, media: "WhatsApp" },
    { id: 4, media: "Instagram" },
    { id: 5, media: "WeChat" },
    { id: 6, media: "TikTok" },
    { id: 7, media: "Telegram" },
    { id: 8, media: "Snapchat" },
    { id: 9, media: "Kuaishou" },
    { id: 10, media: "Qzone" },
    { id: 11, media: "Sina Weibo" },
    { id: 12, media: "QQ" },
    { id: 13, media: "X" },
    { id: 14, media: "Pinterest" },
    { id: 15, media: "Reddit" },
    { id: 16, media: "LinkedIn" },
    { id: 17, media: "Quora" },
    { id: 18, media: "Discord" },
    { id: 19, media: "Twitch" },
    { id: 20, media: "Tumblr" },
    { id: 21, media: "Threads" },
    { id: 22, media: "Mastodon" },
    { id: 23, media: "Bluesky" },
  ];

  const reRendering = () => {
    return (
      <>
        <div id="widget">
          <div>
            <GoogleTrend
              type="TIMESERIES"
              keyword={focusword}
              url="https://ssl.gstatic.com/trends_nrtr/2051_RC11/embed_loader.js"
            />
          </div>
        </div>
        <div className="uk-margin-top">
          <select
            name="country_code"
            value={countrycode}
            // onChange={formik.handleChange}
            onChange={(e) => cntryCode(e.target.value)}
            style={{
              height: "49px",
              borderRadius: "10px",
              border: "1px solid #fff",
              width: "auto",
            }}
            className="form-select"
            aria-label="Default select example"
          >
            {country.map((res, index) => (
              <option key={index} value={res.code}>
                {res.name}
              </option>
            ))}
          </select>
          <div id="widget2" className="d-flex gap-2 mt-2">
            <div>
              <GoogleTrends2
                style={{ marginTop: "15px" }}
                type="GEO_MAP"
                countrys={countrycode.toUpperCase()}
                keyword={focusword}
                url="https://ssl.gstatic.com/trends_nrtr/2051_RC11/embed_loader.js"
              />
            </div>
            <div>
              <GoogleTrends2
                style={{ marginTop: "15px" }}
                type="GEO_MAP"
                countrys=""
                keyword={focusword}
                url="https://ssl.gstatic.com/trends_nrtr/2051_RC11/embed_loader.js"
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const { id } = useParams();
  // console.log("id", id);

  const [getYtubeData, setGetYtubeData] = useState([]);
  const [imageUplaodBtn, setImageUploadBtn] = useState(false);
  const [compareTag, setCompareTag] = useState([]);
  const [vdoTitle, setVdoTitle] = useState("");
  const [vdoDescription, setVdoDescription] = useState("");
  const [videoLinkDesc, setVideoLinkDesc] = useState("");
  const [bestdata, setBestData] = useState("");
  const [videoLinkDesc3, setVideoLinkDesc3] = useState("");
  const [videoLinkDesc4, setVideoLinkDesc4] = useState("");
  const [playLinkDesc1, setPlayLinkDesc1] = useState("");
  const [playLinkDesc2, setPlayLinkDesc2] = useState("");
  const [playLinkDesc3, setPlayLinkDesc3] = useState("");
  const [playLinkDesc4, setPlayLinkDesc4] = useState("");
  const [longTail, setLongTail] = useState([]);
  const [savedTail, setSavedTail] = useState([]);
  const [keywordTail, setKeywordTail] = useState([]);
  const [getDisclaimer, setGetDisclaimer] = useState([]);
  const [getAffliateLink, setAffliateLink] = useState([]);
  const [facebooks, setFacebooks] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [reddit, setReddit] = useState("");
  const [pinterest, setPrintrest] = useState("");
  const [loader, setLoader] = useState(false);
  // const [tumbler,setTumbler] = useState("");

  const getSocialLink = async () => {
    try {
      const data = await privateRequest.get(
        `/api/youtube/social-media-links/${
          channelid === null ? fetchId : channelid
        }/`
      );
      // console.log("data",data.data.social_media_links.Links);
      const applyLink =
        data.data.social_media_links === null
          ? ""
          : data.data.social_media_links.Links;
      setFacebooks(applyLink.facebook);
      setInstagram(applyLink.instagram);
      setPrintrest(applyLink.pinterest);
      setReddit(applyLink.reddit);
      // setTumbler(applyLink.tumbler);
      setTwitter(applyLink.twitter);
    } catch (err) {
      console.log("err", err);
    }
  };

  const applySocialLink = (e, { setFieldValue, values }) => {
    setFieldValue("facebook", facebooks);
    setFieldValue("twitter", twitter);
    setFieldValue("instagram", instagram);
    setFieldValue("printrest", pinterest);
    setFieldValue("reddit", reddit);
  };

  // api for affliate disclaimer link
  const getSaveDisclaimer = async () => {
    try {
      const disclaimer = await privateRequest.get(
        `/api/youtube/disclaimer-manager/${
          channelid === null ? fetchId : channelid
        }`
      );
      // console.log("disclaimer",disclaimer)
      setGetDisclaimer(disclaimer.data);
    } catch (err) {
      console.log("errordata", err);
    }
  };

  const getAffliateLinkData = async () => {
    try {
      const getAffliate = await privateRequest.get(
        `/api/youtube/affiliate-links-manager/${
          channelid === null ? fetchId : channelid
        }/`
      );
      // console.log("getAffliate", getAffliate.data);
      setAffliateLink(getAffliate.data);
    } catch (err) {
      console.log("erraffliate", err);
    }
    // postAffliateLinkData()
  };

  const thumbnailref = useRef(null);
  // console.log("videoTitle",vdoTitle.length)
  console.log(
    videoLinkDesc,
    bestdata,
    videoLinkDesc3,
    videoLinkDesc4,
    playLinkDesc1,
    playLinkDesc2,
    playLinkDesc3,
    playLinkDesc4
  );
  const ref = useRef(null);
  // file reader for images
  const handleImageChange = async (e, { setFieldValue, values }) => {
    // console.log("handleChangers", e.target.files[0]);
    if (e.target.files[0] === undefined) {
      setImageUploadBtn(false);
      setFieldValue("file", null);
    } else {
      setFieldValue("file", e.target.files[0]);
      setImageUploadBtn(true);
    }
    // const data = await fileRequest.post(`/api/youtube/thumbnail/?video_id=${id}&multichannel=${channelid}`,e.target.files[0])
    //  console.log("uploadding",e.target.files[0])
  };

  const uploading = async (e, { setFieldValue, values }) => {
    // console.log("uploadthumbnail",formik.values.file);
    const datafile = {
      thumbnail: values.file,
    };
    try {
      const data = await fileRequest.post(
        `/api/youtube/thumbnail/?video_id=${id}&multichannel=${
          channelid === null ? fetchId : channelid
        }`,
        datafile
      );
      //  console.log("uploadding",data.data.message);
      toaster.push(showSuccessNotification(data.data.message));
      optimize_data();
    } catch (err) {
      console.log("err", err);
    }
  };
  const selectedss = (e, data) => {
    // console.log("e", data);
    setLocate(data);
    setLocate2(data);
  };

  const discardChange = ({ resetForm }) => {
    resetForm();
  };

  const searchWord = async (adata) => {
    try {
      // eslint-disable-next-line
      const data = await privateRequest.patch(
        `/api/youtube/search-word/?multichannel=${
          channelid === null ? fetchId : channelid
        }&video_id=${id}`,
        {
          search_word: adata,
        }
      );
      // console.log("dodod", data);
    } catch (err) {
      console.log("error", err);
    }
    getSearchWord();
  };

  const getSearchWord = async () => {
    setFocusword("");
    try {
      const getData = await privateRequest.get(
        `/api/youtube/search-word/?multichannel=${
          channelid === null ? fetchId : channelid
        }&video_id=${id}`
      );
      // console.log("dataaaaa", getData.data.search_word);
      setFocusword(getData.data.search_word);
      setKeywordTail(getData.data.keywords);
      reRendering();
    } catch (err) {
      console.log("errfocus", err);
      setFocusword("");
    }
  };

  // fetching or getting the optimize data from api
  const optimize_data = async () => {
    setLoader(true);
    try {
      const data = await privateRequest.get(
        `/api/youtube/optimize/video/?multichannel=${
          channelid === null ? fetchId : channelid
        }&video_id=${id}`
      );
      // console.log("optimizeDara", data.data[0].video_title);
      setVdoTitle(data.data[0].video_title);
      setVdoDescription(data.data[0].video_description);
      setGetYtubeData(data.data[0]);
      // const bro = data.data[0];
      // console.log("hohohoh", bro);
      const tgiii = data.data[0].tags.replace(/["']/g, "").split(",");
      // console.log("splittags", tgiii);
      setCompareTag(tgiii);
      setIsCheck(tgiii);
    } catch (err) {
      console.log("err", err);
    }
    setLoader(false);
  };

  // console.log("getYtubeData",getYtubeData.video_title)

  const handleKeyDown = (e) => {
    if (e.key !== "Enter" && e.key !== ",") return;
    const value = e.target.value.split(",");
    setIsCheck([...isCheck, ...value]);
    // setIsDirty();
    e.target.value = "";
  };
  const visibled = (e) => {
    const { name, checked } = e.target;
    // console.log("kw", name);
    setIsCheck([...isCheck, name]);
    // setIsDirty();
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== name));
    }
  };
  const removeTag = (index) => {
    setIsCheck(isCheck.filter((el, i) => i !== index));
    // setIsDirty();
  };
  const findMore = () => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const handlekeywordsSubmit = async (e, values) => {
    // console.log("values", values);
    await handleShow();
    findMore();
    const data = {
      keyword: values.keyword,
    };
    try {
      const that = await privateRequest.post(
        `/api/youtube/long-tail-tag-research/${
          channelid === null ? fetchId : channelid
        }/`,
        data
      );
      // console.log("that", that.data.long_tail_keywords);
      setLongTail(that.data.long_tail_keywords);
    } catch (err) {
      console.log("forkeyerror", err);
    }
    searchWord(values.keyword);
  };

  const getAppile = async () => {
    try {
      const list = await privateRequest.get(
        `/api/youtube/tag-list/${channelid === null ? fetchId : channelid}/`
      );
      // console.log("List", list.data);
      setSavedTail(list.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleSubmit = async (e, values) => {
    e.preventDefault();
    // console.log("values", values);
    setLoader(true);
    let finalTags = isCheck.toString();
    const uploading = {
      multichannel: channelid === null ? fetchId : channelid,
      video_id: id,
      video_description: values.video_description,
      tags: finalTags,
      video_title: values.video_title,
    };
    try {
      await privateRequest.patch(
        `/api/youtube/update-youtube-video-data/`,
        uploading
      );
      // console.log("uploading", uploaded);
      toaster.push(
        showSuccessNotification(
          "Your YouTube video has been updated. It may take up to 3 minutes for the changes to appear on your YouTube channel. It sometimes takes 3-24 hours. It might take a refresh to see the changes in your video."
        )
      );
    } catch (err) {
      console.log("err", err);
    }
    setLoader(false);
    // console.log("uploading",uploading)
  };
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const addVideoPlaylist = (e, { setFieldValue, values }) => {
    setFieldValue(
      "video_description",
      `${values.video_description}\nPlayList:\n${
        values.videoPlayListTitle1 === ""
          ? ""
          : `${values.videoPlayListTitle1}: ${values.videoPlayListLink1}\n`
      }${
        values.videoPlayListTitle2 === ""
          ? ""
          : `${values.videoPlayListTitle2}: ${values.videoPlayListLink2}\n`
      }${
        values.videoPlayListTitle3 === ""
          ? ""
          : `${values.videoPlayListTitle3}: ${values.videoPlayListLink3}\n`
      }${
        values.videoPlayListTitle4 === ""
          ? ""
          : `${values.videoPlayListTitle4}: ${values.videoPlayListLink4}\n`
      }`
    );
    setPlayLinkDesc1("");
    setPlayLinkDesc2("");
    setPlayLinkDesc3("");
    setPlayLinkDesc4("");
    setFieldValue("videoPlayListTitle1", "");
    setFieldValue("videoPlayListLink1", "");
    setFieldValue("videoPlayListTitle2", "");
    setFieldValue("videoPlayListLink2", "");
    setFieldValue("videoPlayListTitle3", "");
    setFieldValue("videoPlayListLink3", "");
    setFieldValue("videoPlayListTitle4", "");
    setFieldValue("videoPlayListLink4", "");
  };

  const addVideoandLinkToDescription = (e, { setFieldValue, values }) => {
    setFieldValue(
      "video_description",
      `${values.video_description}\nVideo:\n${
        values.videoTitle1 === ""
          ? ""
          : `${values.videoTitle1}: ${values.videoLink1}\n`
      }${
        values.videoTitle2 === ""
          ? ""
          : `${values.videoTitle2}: ${values.videoLink2}\n`
      }${
        values.videoTitle3 === ""
          ? ""
          : `${values.videoTitle3}: ${values.videoLink3}`
      }${
        values.videoTitle4 === ""
          ? ""
          : `${values.videoTitle4}: ${values.videoLink4}`
      }`
    );
    setVideoLinkDesc("");
    setBestData("");
    setVideoLinkDesc3("");
    setVideoLinkDesc4("");
    setFieldValue("videoTitle1", "");
    setFieldValue("videoLink1", "");
    setFieldValue("videoTitle2", "");
    setFieldValue("videoLink2", "");
    setFieldValue("videoTitle3", "");
    setFieldValue("videoLink3", "");
    setFieldValue("videoTitle4", "");
    setFieldValue("videoLink4", "");
  };

  const SocialMedai = (e, { values, setFieldValue }) => {
    // console.log("Socialvalues", values);
    let ddynamicSocial = socialInputsFields.map((res) =>
      res.name === "" ? "" : `${res.name}: ${res.url}\n`
    );

    ddynamicSocial = ddynamicSocial.join("");
    setFieldValue(
      "video_description",
      `${values.video_description}\nSocial Link:\n${
        values.facebook === "" ? "" : `Facebook:${values.facebook}\n`
      }${values.twitter === "" ? "" : `Twitter:${values.twitter}\n`}${
        values.instagram === "" ? "" : `Instagram:${values.instagram}\n`
      }${values.printrest === "" ? "" : `Pinterest:${values.printrest}\n`}${
        values.reddit === "" ? "" : `Reddit:${values.reddit}\n`
      }${ddynamicSocial === "" ? "" : ddynamicSocial}\n`
    );
    setFieldValue("facebook", "");
    setFieldValue("twitter", "");
    setFieldValue("instagram", "");
    setFieldValue("printrest", "");
    setFieldValue("reddit", "");
    setsocialInputsFields([]);
  };

  const disclaimers = (e, { setFieldValue }) => {
    // console.log("desclaimersss",e);
    setFieldValue("affliateDisclaimer", e);
    // setFieldValue("affititle", "");
  };

  const periferalEdit = (e, { setFieldValue }) => {
    const tryit = JSON.parse(e);
    setFieldValue("affititle", tryit.list_name);
    setFieldValue("affiLink", tryit.link1);
  };

  const affliateLink = (e, { setFieldValue, values }) => {
    setFieldValue(
      "video_description",
      `${values.video_description}\n${
        values.affititle === "" ? "" : values.affititle
      }\n${
        values.affiLink === "" ? "" : `AffliateLink :  ${values.affiLink}\n`
      }`
    );
    setFieldValue("affititle", "");
    setFieldValue("affiLink", "");
  };
  const affliateDesclaimer = (e, { setFieldValue, values }) => {
    setFieldValue(
      "video_description",
      `${values.video_description}\n${
        values.affliateDisclaimer === ""
          ? ""
          : `Disclaimer:\n${values.affliateDisclaimer}\n`
      }`
    );
    setFieldValue("affliateDisclaimer", "");
  };

  const getSocialLinks = async () => {
    try {
      const getData = await privateRequest.get(
        `/api/youtube/list-create/social-media-links/?multichannel=${
          channelid === null ? fetchId : channelid
        }`
      );
      console.log("getData social", getData.data);
      // setSocialMedia(getData?.data);
      // setSocialLizing(getData.data);
      setsocialInputsFields(getData.data);
    } catch (err) {
      console.log("socialgetError", err.response.data);
    }
  };

  const initialValues = {
    video_title: vdoTitle,
    video_description: vdoDescription,
    keyword: "",
    facebook: "",
    twitter: "",
    instagram: "",
    reddit: "",
    printrest: "",
    affititle: "",
    affiLink: "",
    affliateDisclaimer: "",
    videoTitle1: "",
    videoLink1: "",
    videoTitle2: "",
    videoLink2: "",
    videoTitle3: "",
    videoLink3: "",
    videoTitle4: "",
    videoLink4: "",
    videoPlayListTitle1: "",
    videoPlayListLink1: "",
    videoPlayListTitle2: "",
    videoPlayListLink2: "",
    videoPlayListTitle3: "",
    videoPlayListLink3: "",
    videoPlayListTitle4: "",
    videoPlayListLink4: "",
    tags: "",
    file: null,
  };

  useEffect(() => {
    // applyData();
    optimize_data();
    getAppile();
    getSearchWord();
    getSaveDisclaimer();
    getAffliateLinkData();
    getSocialLink();
  }, [fetchId]);

  // console.log("isCheck", isCheck);
  return (
    <div className="container-fluid">
      <Formik enableReinitialize initialValues={initialValues}>
        {(formik) => {
          return (
            <Form>
              <h1
                className="mt-3"
                style={{
                  textAlign: "start",
                  fontWeight: "800",
                  maxWidth: "97vw",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                Video Optimization
              </h1>
              <div
                className="mt-3"
                style={{
                  textAlign: "start",
                  fontWeight: "800",
                  maxWidth: "97vw",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <div className="d-flex align-items-center justify-content-end me-4">
                  {vdoTitle.length === formik.values.video_title.length &&
                  vdoDescription.length ===
                    formik.values.video_description.length &&
                  (isCheck.toString().length === compareTag.toString().length) |
                    (loader === true) ? (
                    <button
                      className="p-2 ps-3 pe-3 btn btn-primary"
                      type="button"
                      disabled
                      // onClick={(e) => handleSubmit(e, formik.values)}
                      // onClick={(e) => handleSubmit(e, formik.values)}
                      style={{
                        border: "1px solid #0d6efd",
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        backgroundColor: "#642eff",
                      }}
                    >
                      <svg
                        style={{ color: "#fff" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-youtube"
                      >
                        <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path>
                        <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon>
                      </svg>
                      &nbsp;&nbsp;
                      <span style={{ fontWeight: "600", color: "#fff" }}>
                        Upload to Youtube
                      </span>
                    </button>
                  ) : (
                    <button
                      className="p-2 ps-3 pe-3 btn btn-primary"
                      type="button"
                      // disabled
                      // onClick={(e) => handleSubmit(e, formik.values)}
                      onClick={(e) => handleSubmit(e, formik.values)}
                      style={{
                        border: "1px solid #0d6efd",
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        backgroundColor: "#642eff",
                      }}
                    >
                      <svg
                        style={{ color: "#fff" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-youtube"
                      >
                        <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path>
                        <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon>
                      </svg>
                      &nbsp;&nbsp;
                      <span style={{ fontWeight: "600", color: "#fff" }}>
                        Upload to Youtube
                      </span>
                    </button>
                  )}
                  {loader === true ? (
                    <button
                      className="p-2 ps-3 pe-3 btn btn-outline-danger"
                      type="button"
                      disabled
                      style={{
                        border: "1px solid #dc3545",
                        borderLeft: "none",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                      }}
                      // onClick={() => discardChange(formik)}
                    >
                      {" "}
                      Discard
                    </button>
                  ) : (
                    <button
                      className="p-2 ps-3 pe-3 btn btn-outline-danger"
                      type="button"
                      style={{
                        border: "1px solid #dc3545",
                        borderLeft: "none",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                      }}
                      onClick={() => discardChange(formik)}
                    >
                      {" "}
                      Discard
                    </button>
                  )}
                </div>
              </div>
              {loader === true ? (
                <div>
                  <ContentLoader
                    viewBox="0 0 500 300"
                    style={{ width: "100vw", height: "100vh" }}
                  >
                    <rect x="0" y="8" rx="0" ry="0" width="40" height="18" />
                    {/* <circle cx="492" cy="16" r="8" />
                    <circle cx="472" cy="16" r="8" /> */}
                    {/* <rect x="362" y="8" rx="7" ry="7" width="96" height="16" /> */}

                    <rect x="0" y="39" rx="0" ry="0" width="34" height="8" />
                    <rect x="50" y="39" rx="0" ry="0" width="36" height="8" />
                    <rect x="102" y="39" rx="0" ry="0" width="34" height="8" />
                    <rect x="152" y="39" rx="0" ry="0" width="34" height="8" />
                    <rect x="202" y="39" rx="0" ry="0" width="36" height="8" />
                    <rect x="254" y="39" rx="0" ry="0" width="34" height="8" />

                    <rect x="477" y="39" rx="0" ry="0" width="10" height="8" />

                    <rect
                      x="19"
                      y="64"
                      rx="0"
                      ry="0"
                      width="465"
                      height="155"
                    />

                    <rect
                      x="18"
                      y="225"
                      rx="0"
                      ry="0"
                      width="141"
                      height="38"
                    />
                    <rect
                      x="182"
                      y="225"
                      rx="0"
                      ry="0"
                      width="141"
                      height="38"
                    />
                    <rect
                      x="343"
                      y="225"
                      rx="0"
                      ry="0"
                      width="141"
                      height="38"
                    />
                    <rect
                      x="18"
                      y="270"
                      rx="0"
                      ry="0"
                      width="141"
                      height="38"
                    />
                    <rect
                      x="182"
                      y="270"
                      rx="0"
                      ry="0"
                      width="141"
                      height="38"
                    />
                    <rect
                      x="343"
                      y="270"
                      rx="0"
                      ry="0"
                      width="141"
                      height="38"
                    />
                  </ContentLoader>
                </div>
              ) : (
                <div
                  style={{
                    maxWidth: "80%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <div className="key-re">
                    Focus Keyword Research for this video
                  </div>
                  <div className="brix---position-relative-2">
                    <input
                      type="text"
                      className="brix---input-large-button-inside w-input"
                      autoFocus={true}
                      maxLength="256"
                      data-name="BRIX - Footer Email - V16"
                      placeholder="Enter your keywords"
                      id="BRIX-Footer-Email-V16"
                      name="keyword"
                      form={formik}
                      value={formik.values.keyword}
                      onChange={formik.handleChange}
                    />
                    {formik.values.keyword.length <= 0 ? (
                      ""
                    ) : (
                      <input
                        type="button"
                        value="Generate"
                        style={{ border: "none" }}
                        data-wait="Please wait..."
                        className="brix---btn-primary-inside-input w-button"
                        onClick={(e) => handlekeywordsSubmit(e, formik.values)}
                      />
                    )}
                  </div>
                  {/* <GoogleTrendsComponent focusword={focusword} /> */}
                  {/* {focusword.length === 0 ? null : (
                  reRendering()
                )} */}
                  {/* <Modals show={show} handleClose={handleClose} /> */}
                  <DrawerPage
                    selectedss={selectedss}
                    locate2={locate2}
                    visibled={visibled}
                    handleKeyDown={handleKeyDown}
                    current={isCheck}
                    longTail={longTail}
                    savedTail={savedTail}
                    keywordTail={keywordTail}
                    removeTag={removeTag}
                    show={show}
                    isCheck={isCheck}
                    handleClose={handleClose}
                  />

                  <div className="row">
                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                      <div
                        className="uui-navbar01_blog-item-2"
                        style={{ maxWidth: "100%" }}
                      >
                        <div className="video-thumbnail-container">
                          <img
                            className="home-image"
                            src={
                              getYtubeData === null || undefined
                                ? ""
                                : getYtubeData.video_thumbnail_url
                            }
                            // src="/images/nav-image-06.jpg"
                            alt="home-page"
                          />
                        </div>
                        <div className="uui-navbar01_large-item-content-2">
                          <div className="uui-navbar01_item-heading-2">
                            {/* How to get started with YTubeBooster */}
                            {getYtubeData.video_title}
                          </div>
                          <div className="uui-text-size-small-5">
                            Jump right in — get an overview of the basics and
                            get started on building.
                            {/* {getYtubeData.video_description} */}
                          </div>
                          <div className="uui-navbar01_item-button-wrapper-2">
                            <div className="uui-button-row-3">
                              <div className="uui-button-link-3 is-button-xsmall">
                                <a
                                  href={getYtubeData.video_link}
                                  style={{ color: "#6941c6" }}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  <div className="div-block-20">
                                    <div className="uui-button-icon-3 w-embed">
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10.0003 18.3334C14.6027 18.3334 18.3337 14.6024 18.3337 10C18.3337 5.39765 14.6027 1.66669 10.0003 1.66669C5.39795 1.66669 1.66699 5.39765 1.66699 10C1.66699 14.6024 5.39795 18.3334 10.0003 18.3334Z"
                                          stroke="currentColor"
                                          strokeWidth="1.66667"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                        <path
                                          d="M7.91699 7.47113C7.91699 7.07339 7.91699 6.87452 8.00011 6.7635C8.07254 6.66675 8.18342 6.60622 8.30397 6.59761C8.44231 6.58773 8.6096 6.69527 8.94416 6.91035L12.878 9.43923C13.1683 9.62587 13.3135 9.71919 13.3636 9.83785C13.4074 9.94152 13.4074 10.0585 13.3636 10.1622C13.3135 10.2809 13.1683 10.3742 12.878 10.5608L8.94416 13.0897C8.6096 13.3048 8.44231 13.4123 8.30397 13.4024C8.18342 13.3938 8.07254 13.3333 8.00011 13.2365C7.91699 13.1255 7.91699 12.9266 7.91699 12.5289V7.47113Z"
                                          stroke="currentColor"
                                          strokeWidth="1.66667"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                      </svg>
                                    </div>
                                    <div className="text-block-12">
                                      Watch video
                                    </div>
                                  </div>
                                </a>
                                <CopyToClipboard text={getYtubeData.video_link}>
                                  <Whisper
                                    placement="top"
                                    controlId="control-id-click"
                                    trigger="click"
                                    speaker={<Tooltip>Copied</Tooltip>}
                                  >
                                    {/* <CopyToClipboard text={getYtubeData.video_link}> */}
                                    <div
                                      className="div-block-20"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <div className="uui-button-icon-3 w-embed">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="feather feather-copy"
                                        >
                                          <rect
                                            x="9"
                                            y="9"
                                            width="13"
                                            height="13"
                                            rx="2"
                                            ry="2"
                                          ></rect>
                                          <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                        </svg>
                                      </div>
                                      <div className="text-block-13">
                                        Copy Video Url
                                      </div>
                                    </div>
                                    {/* </CopyToClipboard> */}
                                  </Whisper>
                                </CopyToClipboard>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="uui-contact05_form-wrapper w-form mt-3">
                        {/* <form
                id="wf-form-Contact-05-form"
                name="wf-form-Contact-05-form"
                data-name="Contact 05 form"
                method="get"
                className="uui-contact05_form"
              > */}
                        <div className="uui-form-field-wrapper">
                          <label
                            htmlFor="Contact-05-email"
                            className="uui-field-label"
                          >
                            Title for the video
                          </label>
                          <input
                            type="text"
                            className="uui-form_input w-input"
                            maxLength="256"
                            name="video_title"
                            value={formik.values.video_title}
                            onChange={formik.handleChange}
                            data-name="Contact 05 email"
                            placeholder="Enter the title of your video"
                            id="Contact-05-email"
                            required=""
                          />
                          <div className="character-count uk-label">
                            {formik.values.video_title.length}/100{" "}
                          </div>
                        </div>
                        <div className="uui-form-field-wrapper">
                          <label
                            htmlFor="Contact-05-message"
                            className="uui-field-label uk-margin-top"
                          >
                            Description for the video
                          </label>
                          <textarea
                            id="Contact-05-message"
                            maxLength="5000"
                            data-name="Contact 05 message"
                            placeholder="Type your description text here..."
                            className="uui-form_input text-area w-input"
                            value={formik.values.video_description}
                            onChange={formik.handleChange}
                            name="video_description"
                          ></textarea>
                          <div className="character-count uk-label">
                            {formik.values.video_description.length}/5000{" "}
                          </div>
                        </div>
                        {/* <div className="row mt-3">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"> */}
                        <div className="uk-margin-top">
                          <div
                            ref={ref}
                            className="original-video-tags background-color-indigo600"
                          >
                            <div className="current-head">
                              Current Video Tags
                            </div>
                            <div style={{ opacity: "0.6", fontSize: "11px" }}>
                              Press "Enter" key in your window or press "Return"
                              key in your mac
                            </div>
                            <div className="uui-testimonial18_content-wrapper">
                              <div className="uui-text-size-medium original-video-tag-color">
                                {isCheck.map((tag, index) => (
                                  <div
                                    key={index}
                                    className="original-video-tag-badge"
                                  >
                                    <span>{tag}</span>{" "}
                                    <span
                                      className="close"
                                      onClick={() => removeTag(index)}
                                    >
                                      ×
                                    </span>
                                  </div>
                                ))}
                                <input
                                  type="text"
                                  name="tagi"
                                  // value={isCheck}
                                  // onChange={handleKeyDown}
                                  onKeyDown={(e) => handleKeyDown(e)}
                                  className="tags-inputs"
                                  placeholder="Type a tag"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* </div>
                </div> */}
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                      <div>
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="recommand"
                        >
                          <Nav
                            variant="pills"
                            style={{
                              paddingTop: "12px",
                              paddingBottom: "12px",
                              paddingLeft: "12px",
                              borderRadius: "10px",
                              fontSize: "12px",
                            }}
                            className="d-flex gap-2 pd-5"
                          >
                            <Nav.Item
                              style={{
                                borderBottomColor: "#642eff",
                                borderRadius: "50px",
                              }}
                            >
                              <Nav.Link
                                onClick={(e) => selectedss(e, "recommand")}
                                style={
                                  locate === "recommand"
                                    ? {
                                        borderBottomColor: "#642eff",
                                        backgroundColor: "#642eff",
                                        color: "#fff",
                                        fontWeight: "500",
                                        padding: "7px 8px",
                                      }
                                    : {
                                        backgroundColor: "#eff0f6",
                                        color: "#160042",
                                        padding: "7px 8px",
                                      }
                                }
                                eventKey="recommand"
                              >
                                Suggestions
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item
                              style={{
                                borderBottomColor: "#642eff",
                                borderRadius: "50px",
                              }}
                            >
                              <Nav.Link
                                onClick={(e) => selectedss(e, "videoLink")}
                                style={
                                  locate === "videoLink"
                                    ? {
                                        borderBottomColor: "#642eff",
                                        backgroundColor: "#642eff",
                                        color: "#fff",
                                        fontWeight: "500",
                                        padding: "7px 8px",
                                      }
                                    : {
                                        backgroundColor: "#eff0f6",
                                        color: "#160042",
                                        padding: "7px 8px",
                                      }
                                }
                                eventKey="videoLink"
                              >
                                Video Links
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item
                              style={{
                                borderBottomColor: "#642eff",
                                borderRadius: "50px",
                              }}
                            >
                              <Nav.Link
                                onClick={(e) => selectedss(e, "social")}
                                style={
                                  locate === "social"
                                    ? {
                                        borderBottomColor: "#642eff",
                                        backgroundColor: "#642eff",
                                        color: "#fff",
                                        fontWeight: "500",
                                        padding: "7px 8px",
                                      }
                                    : {
                                        backgroundColor: "#eff0f6",
                                        color: "#160042",
                                        padding: "7px 8px",
                                      }
                                }
                                eventKey="social"
                              >
                                Social
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item
                              style={{
                                borderBottomColor: "#642eff",
                                borderRadius: "50px",
                              }}
                            >
                              <Nav.Link
                                onClick={(e) => selectedss(e, "affliate")}
                                style={
                                  locate === "affliate"
                                    ? {
                                        borderBottomColor: "#642eff",
                                        backgroundColor: "#642eff",
                                        color: "#fff",
                                        fontWeight: "500",
                                        padding: "7px 8px",
                                      }
                                    : {
                                        backgroundColor: "#eff0f6",
                                        color: "#160042",
                                        padding: "7px 8px",
                                      }
                                }
                                eventKey="affliate"
                              >
                                Affiliate
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item
                              style={{
                                borderBottomColor: "#642eff",
                                borderRadius: "50px",
                              }}
                            >
                              <Nav.Link
                                onClick={(e) => selectedss(e, "thumbnail")}
                                style={
                                  locate === "thumbnail"
                                    ? {
                                        borderBottomColor: "#642eff",
                                        backgroundColor: "#642eff",
                                        color: "#fff",
                                        fontWeight: "500",
                                        padding: "7px 8px",
                                      }
                                    : {
                                        backgroundColor: "#eff0f6",
                                        color: "#160042",
                                        padding: "7px 8px",
                                      }
                                }
                                eventKey="thumbnail"
                              >
                                Thumbnail
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane eventKey="recommand">
                              <VideoRecommand
                                focusword={focusword}
                                isCheck={isCheck}
                                formik={formik}
                                reRendering={reRendering}
                              />
                            </Tab.Pane>
                          </Tab.Content>
                          <Tab.Content>
                            <Tab.Pane eventKey="videoLink">
                              <VideoAndPlaylist
                                addVideoandLinkToDescription={
                                  addVideoandLinkToDescription
                                }
                                addVideoPlaylist={addVideoPlaylist}
                                formik={formik}
                              />
                            </Tab.Pane>
                          </Tab.Content>
                          <Tab.Content>
                            <Tab.Pane eventKey="social">
                              <SocialMediaPage
                                SocialMedai={SocialMedai}
                                formik={formik}
                                applySocialLink={applySocialLink}
                                socialInputsFields={socialInputsFields}
                                increaseInput={increaseInput}
                                decreaseInputField={decreaseInputField}
                                handleDynamicChange={handleDynamicChange}
                                socialMediaApp={socialMediaApp}
                                getSocialLinks={getSocialLinks}
                              />
                            </Tab.Pane>
                          </Tab.Content>
                          <Tab.Content>
                            <Tab.Pane eventKey="affliate">
                              <AffliatePage
                                formik={formik}
                                affliateLink={affliateLink}
                                affliateDesclaimer={affliateDesclaimer}
                                getDisclaimer={getDisclaimer}
                                getAffliateLink={getAffliateLink}
                                disclaimers={disclaimers}
                                periferalEdit={periferalEdit}
                              />
                            </Tab.Pane>
                          </Tab.Content>
                          <Tab.Content>
                            <Tab.Pane eventKey="thumbnail">
                              <ThumbnailC
                                formik={formik}
                                orgnl={getYtubeData.video_thumbnail_url}
                                thumbnailref={thumbnailref}
                                handleImageChange={handleImageChange}
                                imageUplaodBtn={imageUplaodBtn}
                                uploading={uploading}
                              />
                              {/* <AffliatePage
                              formik={formik}
                              affliateLink={affliateLink}
                              affliateDesclaimer={affliateDesclaimer}
                            /> */}
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Optimize;
