import {takeEvery, all, put, call} from 'redux-saga/effects';

import * as actionTypes from '../redux/actionTypes';

// actions
import { fetchModule_access } from '../api/service/channelid';
import { fetchModuleFailed, fetchModuleSuccess } from '../redux/actions/moduleAccessAction';

// services

// import { fetchYoutube} from '../api/services/connect';



// Worker saga
// ***************************** Youtube START *****************************
function* loadAccessModule(action){
    try{
        // console.log('action', action.payload);
        const {data} = yield call(fetchModule_access, action.payload);
        // console.log('datafetchaccces', data[0].module_access)
        yield put(fetchModuleSuccess(data[0].module_access))
        // yield put(youtubeConnDataFetchSuccess(data['Please go to this URL: ']));
    }catch(error){
        // console.log("accessError",error)
        yield put(fetchModuleFailed(error));
    }
}

// watcher saga
function* watchAccessModule() {
    yield takeEvery(actionTypes.FETCH_MODULE_DATA_START, loadAccessModule);
}

export function* moduleAccessSaga(){
    yield all([watchAccessModule()])
}