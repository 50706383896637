import * as actionTypes from "../actionTypes";
const inititalState = {
    forgotpass_notify: "",
  };

  export const getNotificationReducer = (state = inititalState, action) => {
    switch (action.type) {
    case actionTypes.GET_NOTIFICATION_DATA:
        return {
          ...state,
          forgotpass_notify: action.payload,
        };
  
      default:
        return state;
    }
  }