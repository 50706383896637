import * as actionTypes from "../actionTypes";

export const fetchModuleStart = () => {
  // console.log("userDetails", userDetails);
  return {
    type: actionTypes.FETCH_MODULE_DATA_START,
    // payload: userDetails,
  };
};
export const fetchModuleSuccess = (data) => {
  // console.log("userDetails", data);
  return {
    type: actionTypes.FETCH_MODULE_DATA_SUCCESS,
    payload: data,
  };
};
export const fetchModuleFailed = (error) => {
  // console.log("userDetails", error);
  return {
    type: actionTypes.FETCH_MODULE_DATA_FAILED,
    payload: error,
  };
};