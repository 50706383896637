import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";

const GoogleYoutube = () => {
    const navigate = useNavigate()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Get specific query parameters
  const state = queryParams.get("state");
  const code = queryParams.get("code");
  // const scope = queryParams.get("scope");
//   console.log("kya hai", state);
//   console.log("code", code);
//   console.log("scope", scope);

  const lastHope = async () => {
    const payload = {
      state: `${state}`,
      code: `${code}`,
    };
    try {
      const data = await privateRequest.post(
        "/api/youtube/auth2callback/",
        payload
      );
      console.log("finaldata", data);
        navigate('/dashboard')
    } catch (err) {
      console.log("err", err);
      if(err.response.data.response==="The YouTube Channel you tried to connect with YTubeBooster is already in use with this account."){
        navigate('/same-channel')
      }
      else if(err.response.data.response==="The YouTube Channel you tried to connect with YTubeBooster is already in use with another account"){
        navigate('/another-account')
      }
      else if(err.response.data.response==="No Youtube data Found"){
        navigate('/no-ytube-data')
      }
      else if(err.response.data.response==="Provide all youtube permissions"){
        navigate('/provide-permission')
      }
      else if(err.response.data.response==="Can't add more channel. Your limit is over"){
        navigate('/limit-over')
      }
    }
  };
  useEffect(() => {
    lastHope();
  },[])
  return <div className="mt-3" style={{textAlign:"center"}}>Please wait...</div>;
};

export default GoogleYoutube;
