import React from "react";

const AffliatePage = ({
  formik,
  affliateLink,
  affliateDesclaimer,
  getDisclaimer,
  disclaimers,
  getAffliateLink,
  periferalEdit
}) => {

  return (
    <>
      <div className="uk-card uk-card-default uk-card-body">
        <div className="uui-navbar01_item-heading-2">
          Add your Affiliate links in here
        </div>
        <div className="brix---mg-bottom-16px-4">
          <div>
          <div className="uk-margin-top uk-margin-bottom">
            <p>select your predefine Affliate Links</p>
            <select
              name="country_code"
              onChange={(e) => periferalEdit(e.target.value,formik)}
              style={{
                height: "49px",
                borderRadius: "10px",
                border: "1px solid #fff",
                width: "100%",
              }}
              className="form-select mt-2"
              aria-label="Default select example"
            >
              <option>---</option>
              {getAffliateLink.map((res) => (
                <option key={res.id} value={JSON.stringify(res)}>
                  {res.list_name}
                </option>
              ))}
            </select>
          </div>
            <div className="">
              <div className="brix---position-relative-2">
                <input
                  type="text"
                  className="button-affiliate-description w-input"
                  autoFocus={true}
                  maxLength="256"
                  value={formik.values.affititle}
                  onChange={formik.handleChange}
                  name="affititle"
                  data-name="BRIX - Footer Email - V16"
                  placeholder="Add your affiliate title"
                  id="BRIX-Footer-Email-V16"
                />
              </div>
              <div className="brix---position-relative-2">
                <input
                  type="text"
                  className="button-affiliate-description w-input"
                  autoFocus={true}
                  maxLength="256"
                  value={formik.values.affiLink}
                  onChange={formik.handleChange}
                  name="affiLink"
                  data-name="BRIX - Footer Email - V16"
                  placeholder="Add your affiliate URL"
                  id="BRIX-Footer-Email-V16"
                />
                {/* <input
                  type="button"
                  value="Add TITLE AND URL TO DESCRIPTION"
                  data-wait="Please wait..."
                  className="brix---btn-primary-inside-input w-button"
                  onClick={(e) => affliateLink(e, formik)}
                /> */}
              </div>
              <button
                type="button"
                onClick={(e) => affliateLink(e, formik)}
                className="add-descp-btn"
                style={{ float: "right", margin: "6px" }}
              >
                Add TITLE AND URL TO DESCRIPTION
              </button>
              <br />
            </div>
          </div>
        </div>
        <div className="affiliate-heading ">
          Add your Affiliate Disclaimer in here
        </div>
        <div className="containers-custom" style={{ padding: "0px 10px" }}>
          <div className="uk-margin-top uk-margin-bottom">
            <p>select your predefine desclaimer</p>
            <select
              name="country_code"
              onChange={(e) => disclaimers(e.target.value,formik)}
              style={{
                height: "49px",
                borderRadius: "10px",
                border: "1px solid #fff",
                width: "100%",
              }}
              className="form-select mt-2"
              aria-label="Default select example"
            >
              <option>---</option>
              {getDisclaimer.map((res) => (
                <option key={res.id} value={res.description}>
                  {res.title}
                </option>
              ))}
            </select>
          </div>
          <textarea
            id="Contact-05-message-2"
            name="affliateDisclaimer"
            value={formik.values.affliateDisclaimer}
            onChange={formik.handleChange}
            maxLength="5000"
            data-name="Contact 05 Message 2"
            placeholder="Type your description text here..."
            required=""
            className="uui-form_input text-area w-input uk-margin-bottom"
          ></textarea>
        </div>
        <button
          type="button"
          onClick={(e) => affliateDesclaimer(e, formik)}
          className="add-descp-btn"
          style={{ float: "right", margin: "6px" }}
        >
          Add Disclaimer to Description
        </button>
      </div>
    </>
  );
};

export default AffliatePage;
