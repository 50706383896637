import React from "react";

const TestLanding = () => {
  return (
    <div
      style={{ backgroundColor: "#e8ac28", height: "100vh" }}
      className="d-flex justify-content-center align-items-center"
    >
      <div
        style={{
          fontSize: "60px",
          color: "#fff",
          fontWeight: "500",
          flexDirection: "column",
        }}
        className="d-flex align-items-center"
      >
        {" "}
        <div>
          <img
            src="/images/YTBPRo.svg"
            loading="lazy"
            width="200px"
            alt="pro"
          />
        </div>
        <div>Coming Soon</div>
      </div>
    </div>
  );
};

export default TestLanding;
