import React, { useState } from "react";
import { Button, Modal, Pagination, SelectPicker, useToaster } from "rsuite";
import { adminPrivateRequest } from "../../../api/axiosConfig/adminPrivateRequest";
import { useEffect } from "react";
import Select from 'react-select';
import moment from "moment";
import { priceTag } from "../../Users/Pages/StringStore";
import { showSuccessNotification } from "../../../Notification";
import { ProgressBar } from "react-loader-spinner";

const AdminCoupons = () => {
  const [open, setOpen] = useState(false);
  const [delOpen,setDelOpen] = useState(false);
  const [genCode, setGenCode] = useState("");
  const [couponsDatas, setCouponsDatas] = useState([]);
  const [loading,setLoading] = useState(false)
  // const [pageNumber, setPageNumber] = useState(5);
  const [limit, setLimit] = useState(5);
  const [active, setActive] = useState(1);
  const [couponsName,setCouponsName] = useState("")
  const [couponsCode,setCouponCode] = useState("")
  const [discountType,setDiscountType] = useState("")
  const [validUpto,setValidUpto] = useState("")
  const [maxRedum,setMaxRedum] = useState("")
  const [redumTypes,setRedumTypes] = useState("")
  const [redumCycle,setRedumCycle] = useState("")
  const [planType,setPlanType] = useState("")
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [usersDel,setUsersDel] = useState("");

  const limitOptions = [5,10,20];
  const startIdx = (active - 1) * limit;
  const endIdx = startIdx + limit;

  const toaster = useToaster();

  const pageChanged = (data) => {
    setActive(1);
    setLimit(data);  
  }

  const generateCode = async () => {
    // setAssociatePlans((res)=>[selectedOptions,...res])
    setLoading(true)
    const abd = selectedOptions.map((res) => res.value)
    console.log("associatePlans",abd.toString());
    const accoc = abd.toString()
    console.log("planType",planType)
    console.log("redumCycle",redumCycle)
    console.log("redumTypes",redumTypes)
    console.log("maxRedum",maxRedum)
    console.log("validUpto",validUpto)
    console.log("discountType",discountType)
    console.log("couponsName",couponsName)
    console.log("couponsCode",couponsCode)
    console.log("discount",genCode)
    const cpons = {
      coupon_name:couponsName,
      coupon_code:couponsCode,
      discount:genCode,
      discount_type:discountType,
      valid_upto:validUpto,
      maximum_redemption:maxRedum,
      redemption_type:redumTypes,
      redemption_cycle:redumCycle,
      plan_type:planType,
      associate_plans: accoc
    }
    try{
      const abd = await adminPrivateRequest.post(`/api/admin_panel/coupon/`,cpons);
      console.log("abd",abd)
      toaster.push(showSuccessNotification("Coupon created successfull"))

    }catch(err){
      console.log("err",err)
    }
    setLoading(false)
    setGenCode("");
    setCouponsDatas([]);
    setCouponsName("");
    setCouponCode("");
    setDiscountType("");
    setValidUpto("");
    setMaxRedum("");
    setRedumTypes("");
    setRedumCycle("");
    setPlanType("");
    setSelectedOptions([]);
    handleClose();
    couponsData();
  }

  // console.log("selectedOptions",)

  const handleModalDelte = (data) => {
    setDelOpen(true);
    // console.log("modalData", data);
    setUsersDel(data);
  };

  const deleteCoupons = async (data) => {
    setLoading(true)
    try{
     const del = await adminPrivateRequest.delete(`/api/admin_panel/coupons/${data}/`);
     console.log("del",del)
     toaster.push(showSuccessNotification("Coupon deleted successfull"))
    }catch(err){
     console.log("deleteErr",err) 
    }
    setLoading(false)
    delHandleClose();
    couponsData();
  }

  const handleOpen = () => {
    setOpen(true);
  };

  // Handle the change event when the user selects or removes an option
  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const delHandleClose = () => {
    setDelOpen(false)
  }
  const inputHandler = (e) => {
    const { value, maxLength } = e.target;
    if (String(value).length >= maxLength) {
      e.preventDefault();
      return;
    }
  };

  // const pageInfo = (data) => {
  //   setPageNumber(data);
  //   setActive(1);
  // };

  const couponsData = async () => {
    setLoading(true)
    try {
      const coupons_data = await adminPrivateRequest.get(
        `/api/admin_panel/coupons/`
      );
      console.log("dataredss", coupons_data.data);
      setCouponsDatas(coupons_data.data);
    } catch (err) {
      console.log("err", err);
    }
    setLoading(false)
  };

   const options = [
    { value: priceTag.acornM, label: priceTag.acornM },
    { value: priceTag.palmM, label: priceTag.palmM },
    { value: priceTag.oackM, label: priceTag.oackM },
    { value: priceTag.acornY, label: priceTag.acornY },
    { value: priceTag.palmY, label: priceTag.palmY },
    { value: priceTag.oackY, label: priceTag.oackY },
    // Add more options as needed
  ];

  useEffect(() => {
    couponsData();
  }, []);
  return (
    <div>
      <h1>Coupons &nbsp;</h1>
      <div className="uk-child-width-1-1@m">
        <div className="uk-card uk-card-default uk-card-body">
          <div className="d-flex justify-content-between align-items-center mt-3">
            <div className="d-flex">
              <h4 className="heading-14" style={{ textAlign: "start" }}>
                Results:&nbsp;
              </h4>
              <SelectPicker
              value={limit}
              onChange={pageChanged}
              cleanable={false}
              searchable={false}
              data={limitOptions.map((key) => ({ value: key, label: key }))}
            />{" "}
            </div>

            <button
              className="f-button-neutral-2 w-button"
              onClick={handleOpen}
              style={{
                backgroundColor: "#5167f6",
                border: "1px solid",
                fontWeight: "600",
              }}
              type="submit"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-plus"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
            </button>
          </div>
          <div className="uk-margin-top">
            <table className="table table-striped">
              <thead className="table-header">
                <tr>
                  <th>Coupon Code </th>
                  <th>Coupon Name </th>
                  <th>Created Date</th>
                  <th>Discount</th>
                  <th>Discount Type</th>
                  <th>Redemption Cycle</th>
                  <th>Redemption Type</th>
                  <th>Maximum Redemption</th>
                  <th>Used Redemption</th>
                  <th>Valid Upto</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              {loading===true?
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              <td className="d-flex align-items-center justify-content-end">
                LOADING &nbsp;
                <ProgressBar
                  height="80"
                  width="80"
                  ariaLabel="progress-bar-loading"
                  // wrapperStyle={{}}
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#53389e"
                  barColor="#53389e"
                />
              </td>
              <td></td>
              <td></td>
              <td></td>
              </tr>
              :<tbody>
                {couponsDatas
                  .slice(startIdx, endIdx)
                  .map((res) => (
                    <tr style={{ height: "50px" }} key={res.id}>
                      <td>{res.coupon_code}</td>
                      <td>{res.coupon_name}</td>
                      <td>{moment(res.created_at).format(" D-MM-YY")}</td>
                      <td>{res.discount}</td>
                      <td>{res.discount_type}</td>
                      <td>{res.redemption_cycle}</td>
                      <td>{res.redemption_type}</td>
                      <td>{res.maximum_redemption}</td>
                      <td>{res.used_redemption}</td>
                      <td>{res.valid_upto}</td>
                      <td>{res.status}</td>
                      <td> <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-trash-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleModalDelte(res.id)}
                        >
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                          <line x1="10" y1="11" x2="10" y2="17"></line>
                          <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg></td>
                    </tr>
                  ))}
              </tbody>}
            </table>
            <div className="d-flex justify-content-between">
              <div>
                <div
                  style={{ padding: "20px" }}
                  className="uk-card uk-card-default"
                >
                  Showing page {active} of {couponsDatas.length}
                </div>
              </div>
              <div>
                <Pagination
                  prev
                  next
                  first
                  last
                  size="md"
                  maxButtons={10}
                  page={Math.ceil(couponsDatas.length / limit)}
                  limit={limit}
                  total={couponsDatas.length}
                  activePage={active}
                  onChangePage={setActive}
                  onChangeLimit={pageChanged}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal backdrop="static" size="sm" open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 14 14"
              fill="#2196f3"
              aria-hidden="true"
              focusable="false"
              class="rs-icon"
              aria-label="info"
              data-category="legacy"
            >
              <path d="M7 14A7 7 0 117 0a7 7 0 010 14zm0-9.333a1.167 1.167 0 100-2.334 1.167 1.167 0 000 2.334zm0 1.166c-.644 0-1.167.522-1.167 1.167v3.5a1.167 1.167 0 002.334 0V7c0-.644-.522-1.167-1.167-1.167z"></path>
            </svg>{" "}
            Generate Coupon Codes
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="container row gap-2">
              <input
                className="form-control col"
                type="text"
                name="coupon_name"
                placeholder="Enter Coupon Name"
                value={couponsName}
                onChange={(e) => setCouponsName(e.target.value)}
              />
              <input
                className="form-control col"
                type="text"
                name="coupon_code"
                placeholder="Enter Coupon Code"
                aria-label="default input example"
                value={couponsCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
            </div>
            <div className="container row gap-2 mt-1">
              <input
                className="form-control col"
                type="number"
                min="0"
                max="99"
                placeholder="Discount"
                aria-label="default input example"
                value={genCode}
                maxLength="2"
                onKeyPress={inputHandler}
                onChange={(e) => setGenCode(e.target.value)}
              />
              <select
                class="form-select col"
                aria-label="Default select example"
                value={discountType}
                onChange={(e) => setDiscountType(e.target.value)}
              >
                <option selected>Discount Type</option>
                <option value="flat">Flat</option>
                <option value="percent">Percentage</option>
              </select>
            </div>
            <div className="container row gap-2 mt-1">
              <input
                className="form-control col"
                type="date"
                placeholder="Enter events name"
                aria-label="default input example"
                value={validUpto}
                onChange={(e) => setValidUpto(e.target.value)}
              />
              <input
                className="form-control col"
                type="number"
                min="0"
                max="99"
                placeholder="Maxium Redemption"
                required
                aria-label="default input example"
                maxLength="2"
                onKeyPress={inputHandler}
                value={maxRedum}
                onChange={(e) => setMaxRedum(e.target.value)}
              />
            </div>
            <div className="container row gap-2 mt-1">
              <select
                class="form-select col"
                aria-label="Default select example"
                value={redumTypes}
                onChange={(e) => setRedumTypes(e.target.value)}
              >
                <option selected>Redemption Type</option>
                <option value="number_time">number time</option>
                <option value="onetime">onetime</option>
                <option value="forever">forever</option>
              </select>
              {redumTypes==="number_time"?<input
                className="form-control col"
                type="number"
                min="0"
                max="99"
                placeholder="Redemption Cycle"
                aria-label="default input example"
                maxLength="2"
                onKeyPress={inputHandler}
                value={redumCycle}
                onChange={(e) => setRedumCycle(e.target.value)}
              />:""}
            </div>
            <div className="container row gap-2 mt-1">
              <select
                class="form-select col"
                aria-label="Default select example"
                value={planType}
                onChange={(e) => setPlanType(e.target.value)}
              >
                <option selected>Plan Type</option>
                <option value="selected_plans">selected plans</option>
                <option value="all_plans">all plans</option>
              </select>
             {planType==="selected_plans"?
             <Select
             isMulti
             options={options}
             value={selectedOptions}
             onChange={handleSelectChange}
           />
          //  <select
          //       class="form-select col"
          //       aria-label="Default select example"
          //       value={associatePlans}
          //       onChange={(e) => setAssociatePlans(e.target.value)}
          //     >
          //       <option selected>associate plans</option>
          //       <option value="acorn-monthly-test">acorn-monthly-test</option>
          //       <option value="palm-monthly-test">palm-monthly-test</option>
          //       <option value="aoak-monthly-test">oak-monthly-test</option>
          //     </select>
              :
              ""}
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            cancel
          </Button>
          <Button onClick={generateCode} appearance="primary">Generate</Button>
        </Modal.Footer>
      </Modal>
      {/* modal for delete users */}
      <Modal size="xs" open={delOpen} onClose={delHandleClose}>
        <Modal.Header>
          <Modal.Title>
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 14 14"
              fill="#2196f3"
              aria-hidden="true"
              focusable="false"
              class="rs-icon"
              aria-label="info"
              data-category="legacy"
            >
              <path d="M7 14A7 7 0 117 0a7 7 0 010 14zm0-9.333a1.167 1.167 0 100-2.334 1.167 1.167 0 000 2.334zm0 1.166c-.644 0-1.167.522-1.167 1.167v3.5a1.167 1.167 0 002.334 0V7c0-.644-.522-1.167-1.167-1.167z"></path>
            </svg>{" "}
            Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to delete</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={delHandleClose} appearance="subtle">
            No
          </Button>
          <Button onClick={() => deleteCoupons(usersDel)} appearance="primary">
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminCoupons;
