import { takeEvery, all, put, call } from "redux-saga/effects";

import * as actionTypes from "../redux/actionTypes";

// actions
import { fetchChannelIdData } from "../api/service/channelid";
// import { fetchModuleFailed, fetchModuleSuccess } from '../redux/actions/moduleAccessAction';
import {
  fetchChannelIdDataFailed,
  fetchChannelIdDataSuccess,
} from "../redux/actions/channelIdDataAction";
// import { useNavigate } from 'react-router-dom';

// services

// import { fetchYoutube} from '../api/services/connect';

// const navigate = useNavigate()
// Worker saga
// ***************************** Youtube START *****************************
function* loadChannelIdDataModule(action) {
  // const navigate = useNavigate()
  try {
    // console.log('action', action.payload);
    const { data } = yield call(fetchChannelIdData, action.payload);
    // console.log('datafetchData', data)
    yield put(fetchChannelIdDataSuccess(data.multichannel_id));
    // yield put(youtubeConnDataFetchSuccess(data['Please go to this URL: ']));
  } catch (error) {
    // console.log("accessError",error)
    if (error.response.data.error === "YouTube Channel is not Present") {
      yield put(fetchChannelIdDataFailed(error.response.data.error));
      // console.log("1")
      // navigate('/connect')
    } else {
      yield put(fetchChannelIdDataFailed(error));
      // console.log("errorr",error)
    }
  }
}

// watcher saga
function* watchChannelIdDataModule() {
  yield takeEvery(
    actionTypes.FETCH_CHANNEL_ID_DATA_START,
    loadChannelIdDataModule
  );
}

export function* channelIdDataSaga() {
  yield all([watchChannelIdDataModule()]);
}
