import React, { useEffect, useState } from "react";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";
import { useSelector } from "react-redux";
import { Button, Modal, useToaster } from "rsuite";
import { showSuccessNotification } from "../../../Notification";

const GlobalAffliateDisclamer = () => {
  const [affliateDisclaimerTitle, setAffliateDisclaimerTitle] = useState("");
  const [affliateDisclaimer, setAffliateDisclaimer] = useState("");
  const [open, setOpen] = useState(false);
  const [editid,setEditId] = useState(null);
  const [switcher,setSwticher] = useState(false);
  const [getDisclaimer,setGetDisclaimer] = useState([])
  const { channelid } = useSelector((state) => state.channels);
  const { fetchId } = useSelector((state) => state.channelIdData);

  const handleOpen = (e, data) => {
    setOpen(true);
    console.log("modalData", data);
    setEditId(data);
    // setMData(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toaster = useToaster()

  const clearall = () => {
    setAffliateDisclaimerTitle("")
    setAffliateDisclaimer("")
    setSwticher(false)
  }

  const saveDisclaimer = async () => {
    // const
    const payloads = {
      multichannel: channelid === null ? fetchId : channelid,
      title:affliateDisclaimerTitle,
      description:affliateDisclaimer
    }
    try {
      // eslint-disable-next-line
      const disclaimerData = await privateRequest.post(
        `/api/youtube/disclaimer-manager/`,payloads
      );
      // console.log("disclaimer", disclaimerData);
      toaster.push(
        showSuccessNotification("Successfully added the disclaimer")
      );
      clearall();
    } catch (err) {
      console.log("disclaimerError", err);
    }
    getSaveDisclaimer();
  };

  const getSaveDisclaimer = async () => {
    try{
      const disclaimer = await privateRequest.get(`/api/youtube/disclaimer-manager/${channelid === null ? fetchId : channelid}`);
      // console.log("disclaimer",disclaimer)
      setGetDisclaimer(disclaimer.data)
    }catch(err){
      console.log("errordata",err)
    }
  }

  const editDisclaimer = (data) => {
    setSwticher(true)
    console.log("disclaimerData",data)
    setEditId(data.id)
    setAffliateDisclaimer(data.description)
    setAffliateDisclaimerTitle(data.title)

  }

  const updateDisclaimer = async () => {
    const payloads = {
      multichannel: channelid === null ? fetchId : channelid,
      title:affliateDisclaimerTitle,
      description:affliateDisclaimer
    }
    try{
      const data = await privateRequest.put(`/api/youtube/disclaimer-manager/update-delete/${editid}/`,payloads)
      console.log("dataEdits",data)
      toaster.push(
        showSuccessNotification("Changes saved successfully")
      );
      clearall();

    }catch(err){
      console.log(err)
    }
    getSaveDisclaimer()
  }

  const deleteAffliateDisclaimer = async () => {
    setOpen(false);
    try{
      const deleting = await privateRequest.delete(`/api/youtube/disclaimer-manager/update-delete/${editid}/`)
      console.log("deleting",deleting)
      clearall();
      toaster.push(
        showSuccessNotification("You have successfully deleted the item")
      );
    }catch(err){
      console.log(err)
    }
    getSaveDisclaimer()
  }

  useEffect(() => {getSaveDisclaimer()},[fetchId])

  return (
    <div className="uk-card uk-card-default uk-card-hover uk-card-body uk-width-2-2@m uk-margin-top">
      <h3 className="heading-14">AFFILIATE DISCLAIMER MANAGER</h3>
      <div className="row">
        <div className="global-affliat-disclaimer-left col-lg-6">
          <div className="d-flex justify-content-between">
            {/* <div> */}
            <input
              className="form-control"
              type="text"
              value={affliateDisclaimerTitle}
              onChange={(e) => setAffliateDisclaimerTitle(e.target.value)}
              placeholder="Add the Affliate Disclaimer Group name here"
              style={{ width: "83%" }}
            />
            {/* </div> */}
            <div className="d-flex gap-2">
              {getDisclaimer.length>=6?null:<svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer" }}
                onClick={clearall}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-plus"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>}
             {switcher===false?getDisclaimer.length>=6?null:<svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer" }}
                onClick={saveDisclaimer}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-clipboard"
              >
                <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
              </svg>:
             <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer" }}
                onClick={updateDisclaimer}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-clipboard"
              >
                <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
              </svg>}
            </div>
          </div>
          <div className="uk-margin-top">
            <p className="global-sub-text">
              Enter your Affiliate Disclaimer below
            </p>
            <textarea
              className="form-control mt-1"
              value={affliateDisclaimer}
              onChange={(e) => setAffliateDisclaimer(e.target.value)}
              style={{ height: "217px" }}
            ></textarea>
          </div>
        </div>
        <div className="global-affliat-disclaimer-right col-lg-6">
          <p className="global-sub-text uk-margin-bottom">
            Affiliate Disclaimer Description List (save upto 6 descriptions)
          </p>
          <ul className="list-group">
            {getDisclaimer.map((res) => (<li className="list-group-item" key={res.id}>
              <div className="d-flex justify-content-between">
                <p className="global-sub-text">{res.title}</p>
                <div className="d-flex gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ cursor: "pointer" }}
                    onClick={() => editDisclaimer(res)}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-edit-2 global-icon-setting"
                  >
                    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleOpen(e,res.id)}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-trash-2 global-icon-setting"
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                </div>
              </div>
            </li>))}
          </ul>
        </div>
      </div>
      <Modal backdrop="static" size="sm" open={open} onClose={handleClose}>
        <Modal.Header>
          {/* <Modal.Title>
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 14 14"
              fill="#2196f3"
              aria-hidden="true"
              focusable="false"
              className="rs-icon"
              aria-label="info"
              data-category="legacy"
            >
              <path d="M7 14A7 7 0 117 0a7 7 0 010 14zm0-9.333a1.167 1.167 0 100-2.334 1.167 1.167 0 000 2.334zm0 1.166c-.644 0-1.167.522-1.167 1.167v3.5a1.167 1.167 0 002.334 0V7c0-.644-.522-1.167-1.167-1.167z"></path>
            </svg>{" "}
            Information
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to delete this item?</h5>
          <p>This action cannot be undone.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="primary">
            No, I changed my mind
          </Button>
          <Button onClick={deleteAffliateDisclaimer} color="red" appearance="primary">
            Yes, I want to delete anyway
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GlobalAffliateDisclamer;
