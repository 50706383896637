import React from "react";
import Thumbnail from "./Thumbnail";

const ThumbnailC = ({ formik, thumbnailref,imageUplaodBtn, handleImageChange, uploading,orgnl}) => {

  return (
    <div className="mt-1 mb-5x">
      <div style={{ padding: "13px" }}>
        <p className="uui-field-label">Upload or change your thumbnail</p>
        <div className="d-flex justify-content-between">
          <p
            className="f-button-neutral-2 w-button"
            style={{cursor:"pointer"}}
            onClick={() => thumbnailref.current.click()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-image"
              style={{paddingRight:"5px"}}
            >
              <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
              <circle cx="8.5" cy="8.5" r="1.5"></circle>
              <polyline points="21 15 16 10 5 21"></polyline>
            </svg>
            &nbsp;Select your image
          </p>
          <input
            ref={thumbnailref}
            hidden
            type="file"
            onChange={(e) => handleImageChange(e, formik)}
            className="mb-2"
          />
          {imageUplaodBtn===true?(<button
            className="f-button-neutral-7 w-button"
            onClick={(e) => uploading(e, formik)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-upload"
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline points="17 8 12 3 7 8"></polyline>
              <line x1="12" y1="3" x2="12" y2="15"></line>
            </svg>&nbsp;
            Update Thumbnail
          </button>):""}
        </div>
      </div>
      <div>
        <div>
          <div>
            <div>
              {formik.values.file !== null ? (
                <Thumbnail
                  file={formik.values.file}
                  className={"image-6"}
                  width={960}
                  height={540}
                  borderRadius={"0px"}
                />
              ) : (
                <img
                  src={orgnl}
                  alt="circular"
                  width="960"
                  height="540"
                  className="image-6"
                />
              )}
            </div>
          </div>
        </div>
        {/* <div>
          <h5
            style={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#333",
            }}
          >
            Check how your thumbnail looks with other videos for the same
            keyword
          </h5>
          <div
            className="uui-navbar01_blog-item-10"
            style={{ maxWidth: "100%" }}
          >
            <div>
              <img
                className="thumb-nail"
                alt="thumbnail"
                src="/images/nav-image-06.jpg"
              />
            </div>
            <div className="uui-navbar01_large-item-content-2">
              <div
                className="uui-navbar01_item-heading-2"
                style={{
                  fontSize: "14px",
                  marginTop: "0px",
                  marginBottom: "0.1rem",
                }}
              >
                How to get started with YTubeBooster
              </div>
              <div
                className="uui-text-size-small-5"
                style={{ fontSize: "12px" }}
              >
                Oggy Hindi - हिन्दी
              </div>
              <div>
                <div className="uui-button-row-3">
                  <div
                    className="uui-button-link-3 is-button-xsmall"
                    style={{ gridColumnGap: "0.3rem" }}
                  >
                    <div className="div-block-20">
                      <div
                        className="text-block-12"
                        style={{
                          fontSize: "12px",
                          color: "#606060",
                          fontWeight: "400",
                          fontFamily: "'Roboto', sans-serif",
                        }}
                      >
                        35M Views
                      </div>
                    </div>
                    <div
                      className="div-block-20"
                      style={{
                        fontSize: "12px",
                        color: "#606060",
                        fontWeight: "400",
                        fontFamily: "'Roboto', sans-serif",
                      }}
                    >
                      <div className="text-block-13">&#x2022; 1 year ago</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="uui-navbar01_blog-item-10"
            style={{ maxWidth: "100%" }}
          >
            <div>
              {formik.values.file !== null ? (
                <Thumbnail
                  file={formik.values.file}
                  width={168}
                  height={94}
                  className={"thumb-nail"}
                  borderRadius={"10px"}
                />
              ) : (
                <img
                  className="thumb-nail"
                  alt="thumbnail"
                  src="/images/nav-image-06.jpg"
                />
              )}
            </div>
            <div className="uui-navbar01_large-item-content-2">
              <div
                className="uui-navbar01_item-heading-2"
                style={{
                  fontSize: "14px",
                  marginTop: "0px",
                  marginBottom: "0.1rem",
                }}
              >
                How to get started with YTubeBooster
              </div>
              <div
                className="uui-text-size-small-5"
                style={{ fontSize: "12px" }}
              >
                Oggy Hindi - हिन्दी
              </div>
              <div>
                <div className="uui-button-row-3">
                  <div
                    className="uui-button-link-3 is-button-xsmall"
                    style={{ gridColumnGap: "0.3rem" }}
                  >
                    <div className="div-block-20">
                      <div
                        className="text-block-12"
                        style={{
                          fontSize: "12px",
                          color: "#606060",
                          fontWeight: "400",
                          fontFamily: "'Roboto', sans-serif",
                        }}
                      >
                        34M views
                      </div>
                    </div>
                    <div
                      className="div-block-20"
                      style={{
                        fontSize: "12px",
                        color: "#606060",
                        fontWeight: "400",
                        fontFamily: "'Roboto', sans-serif",
                      }}
                    >
                      <div className="text-block-13">&#x2022; 2 year ago</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="uui-navbar01_blog-item-10"
            style={{ maxWidth: "100%" }}
          >
            <div>
              <img
                className="thumb-nail"
                alt="thumbnail"
                src="/images/nav-image-06.jpg"
              />
            </div>
            <div className="uui-navbar01_large-item-content-2">
              <div
                className="uui-navbar01_item-heading-2"
                style={{
                  fontSize: "14px",
                  marginTop: "0px",
                  marginBottom: "0.1rem",
                }}
              >
                How to get started with YTubeBooster
              </div>
              <div
                className="uui-text-size-small-5"
                style={{ fontSize: "12px" }}
              >
                Oggy Hindi - हिन्दी
              </div>
              <div>
                <div className="uui-button-row-3">
                  <div
                    className="uui-button-link-3 is-button-xsmall"
                    style={{ gridColumnGap: "0.3rem" }}
                  >
                    <div className="div-block-20">
                      <div
                        className="text-block-12"
                        style={{
                          fontSize: "12px",
                          color: "#606060",
                          fontWeight: "400",
                          fontFamily: "'Roboto', sans-serif",
                        }}
                      >
                        70M views
                      </div>
                    </div>
                    <div
                      className="div-block-20"
                      style={{
                        fontSize: "12px",
                        color: "#606060",
                        fontWeight: "400",
                        fontFamily: "'Roboto', sans-serif",
                      }}
                    >
                      <div className="text-block-13">&#x2022; 4 year ago</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ThumbnailC;
