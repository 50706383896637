import React from 'react'

const ChannelDelete = () => {

  const dta = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  return (
    <div style={{ backgroundColor: "blue", height: "100vh" }}>
      <div style={{ paddingTop: "100px" }} className='d-flex justify-content-center align-items-center'>
        <div className="uk-card uk-card-default uk-width-1-2@m" style={{ maxHeight: "500px", overflowY: "scroll" }}>
          <div className="uk-card-body">
            {dta.map((res, index) => (<div key={index} className='d-flex align-items-center justify-content-between mt-2'>
              <div><img alt="data" style={{ width: "100px", height: "100px" }} src="/images/loginscreengraphic.png" /></div>
              <div>This is channel</div>
              <div><button className='btn btn-danger'>Delete</button></div>
            </div>))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChannelDelete