import React, { useEffect, useState } from "react";
import { Button, useToaster } from "rsuite";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../Notification";
import { priceTag } from "../Pages/StringStore";
import { Modal } from "rsuite";
import { fetchModuleStart } from "../../../redux/actions/moduleAccessAction";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubscriptionDetailsStart } from "../../../redux/actions/subscriptionDetailsActions";
import PlaneDetails from "../Pages/PlaneDetails";

const PlanInfo = () => {
  const [togged, setTogged] = useState(true);
  // const [slideSide, setSlideSide] = useState();
  const toaster = useToaster();
  const [open, setOpen] = useState(false);
  const [mdata, setMData] = useState("");
  const { subscribeData } = useSelector(
    (state) => state.subscribe
  );

  const dispatch = useDispatch();
  const handleOpen = (data) => {
    setOpen(true);
    // console.log("modalData", data);
    setMData(data);
  };
  const handleClose = () => setOpen(false);

  const takeToggle = (data) => {
    if (togged === false) {
      setTogged(true);
    } else {
      setTogged(false);
    }
  };

  const handlePricing = async (e, data) => {
    e.preventDefault();
    // toaster.push(showSuccessNotification("mai hu na"),{placement:"topCenter"})
    handleClose();
    const upgrade = {
      billing_period: data,
      activated_at: "eot",
    };
    // immediately or eot
    // console.log("pricingData", data);
    try {
      const Postpayment = await privateRequest.post(
        `/api/payments/upgrade-downgrade/`,
        upgrade
      );
      // console.log("payment", Postpayment.data.message);
      toaster.push(showSuccessNotification(Postpayment.data.message));
    } catch (err) {
      console.log(err);
      if (err.response.data.error) {
        toaster.push(showErrorNotification(err.response.data.error), {
          duration: "off",
        });
      } else {
        toaster.push(showErrorNotification(err.response.data.message), {
          duration: "off",
        });
      }
    }
    dispatch(fetchSubscriptionDetailsStart());
    dispatch(fetchModuleStart());
    // toaster.remove()
    // dispatch(getPricing(data));
    // navigate("/register");
  };

  const SliderQuote = async () => {
    try {
      const data = await privateRequest.get(`/api/payments/user/subscription/`);
      // console.log("subscribeDetalis", data.data[0].plan_name);
      // setSlideSide(data.data[0].plan_name);
      toggleAuto(data.data[0].plan_name);
    } catch (err) {
      console.log("err", err);
    }
  };

  const toggleAuto = (sls) => {
    // console.log("autoTogged", sls);
    if (
      sls === priceTag.acornM ||
      sls === priceTag.palmM ||
      sls === priceTag.oackM
    ) {
      setTogged(false);
    } else {
      setTogged(true);
    }
  };
  useEffect(() => {
    dispatch(fetchSubscriptionDetailsStart());
    SliderQuote();
    toggleAuto();
    // palne();
  }, []);
  return (
    <>
      <div className="cf-pricing-hero-section" style={{ borderRadius: "10px" }}>
        <div className="cf-wrapper-1200px-2">
          <div className="cf-pricing-hero-wrapper">
            <div className="cf-pricing-hero-head-container">
              <div className="cf-white-top-heading">Pricing plans</div>
              <h1 className="cf-pricing-hero-heading">
                Get more value from your <br />
                <span className="cf-colourful-text-2">
                  YTubeBooster Subscription
                </span>
              </h1>
              <p className="cf-pricing-hero-para">
                Our curated and custom pricing plans are have been designed with
                you in mind. Check our various plans and select the one which
                suits your needs.
              </p>
            </div>
          </div>
          <div
            className="cf-pricing-toggle-header-wrapper-2 pricing-header-toggle"
            id="pricings"
          >
            <div className="cf-toggle-row-2">
              <div className="cf-toggle-text-2">Monthly</div>
              {togged === false ? (
                <div
                  data-w-id="36d9533a-055b-4e2a-3186-0d13e50c9fe3"
                  className="cf-toggle-contain-2 white-toggle"
                  onClick={takeToggle}
                >
                  <div
                    style={{
                      WebkitTransform:
                        "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    }}
                    className="cf-toggle-dot-2 green-toggle-dot"
                  />
                </div>
              ) : (
                <div
                  data-w-id="36d9533a-055b-4e2a-3186-0d13e50c9fe3"
                  className="cf-toggle-contain-2 white-toggle"
                  onClick={takeToggle}
                >
                  <div
                    style={{
                      WebkitTransform:
                        "translate3d(30px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(30px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(30px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(30px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    }}
                    className="cf-toggle-dot-2 green-toggle-dot"
                  />
                </div>
              )}
              <div className="cf-toggle-text-2">Yearly</div>
              <div className="cf-toggle-save-container-2">
                <div className="cf-toggle-save-text-2">Save Up To 17%</div>
                <img
                  src="images/Arrow-6.svg"
                  loading="lazy"
                  alt=""
                  className="cf-toggle-arrow-icon-2"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cf-pricing-box-section">
          <div className="cf-wrapper-1200px-2">
            <div className="cf-pricing-box-wrapper-2 pricing-1-box">
              {togged === false ? (
                <div
                  data-w-id="36d9533a-055b-4e2a-3186-0d13e50c9fee"
                  style={{
                    WebkitTransform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 1,
                    display: "display",
                  }}
                  className="cf-pricing-box-row-2 pricing-box-1"
                >
                  <div
                    style={{ marginTop: "67px" }}
                    className={
                      subscribeData.plan_name === priceTag.acornM
                        ? `cf-pricing-box-2 green-pricing-box`
                        : `cf-pricing-box-2 pricing-1-box`
                    }
                  >
                    <div style={{ padding: "27px" }}>
                      <div className="cf-pricing-box-plan-wrapper-2">
                        <div className="cf-pricing-plan-text-2 pricing-1-plan-text">
                          Acorn
                        </div>
                        <p style={{ marginBottom: "32px" }}>
                          For new YouTube Creators who want access to
                          accelerated growth-oriented tools.
                        </p>
                        <div className="cf-pricing-plan-price-2">
                          $9.99
                          <span className="cf-pricing-plan-small-text-2">
                            /month
                          </span>
                        </div>
                      </div>
                      <div className="cf-pricing-plan-content-wrapper-2">
                        <div className="cf-pricing-plan-content-wrapper-2">
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src={
                                subscribeData.plan_name === priceTag.acornM
                                  ? "images/2.svg"
                                  : "images/1.svg"
                              }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Free Trial not available. 07 days full refund
                              policy. No-quibble Cancellation policy.
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src={
                                subscribeData.plan_name === priceTag.acornM
                                  ? "images/2.svg"
                                  : "images/1.svg"
                              }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              1 YouTube channel
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src={
                                subscribeData.plan_name === priceTag.acornM
                                  ? "images/2.svg"
                                  : "images/1.svg"
                              }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Standard Channel analytics dashboard
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src={
                                subscribeData.plan_name === priceTag.acornM
                                  ? "images/2.svg"
                                  : "images/1.svg"
                              }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Credits can be purchased to access AI powered
                              features
                            </div>
                          </div>
                        </div>
                      </div>
                      {subscribeData.plan_name === priceTag.acornM ? (
                        <p className="choosen-btun">Current Plan</p>
                      ) : (
                        <p
                          className="cf-secondary-button-2 pricing-button w-buttons"
                          onClick={(e) => handleOpen(priceTag.acornM)}
                        >
                          Downgrade to Acorn
                        </p>
                      )}
                    </div>
                  </div>
                  <div
                    style={{ marginTop: "67px" }}
                    className={
                      subscribeData.plan_name === priceTag.palmM
                        ? `cf-pricing-box-2 green-pricing-box`
                        : `cf-pricing-box-2 pricing-1-box`
                    }
                  >
                    <div style={{ padding: "27px" }}>
                      <div
                        className={`cf-pricing-box-plan-wrapper-2 ${
                          subscribeData.plan_name === priceTag.palmM
                            ? `white-border`
                            : `black-border`
                        }`}
                      >
                        <div className="cf-pricing-plan-text-2">Palm</div>
                        <p style={{ marginBottom: "32px" }}>
                          For budding and growing YouTube Creators seeking to
                          make informed data-driven decisions with rapid channel
                          growth potential
                        </p>
                        <div className="cf-pricing-plan-price-2">
                          $29.99
                          <span className="cf-pricing-plan-small-text-2">
                            /month
                          </span>
                        </div>
                      </div>
                      <div className="cf-pricing-plan-content-wrapper-2">
                        <div className="cf-pricing-plan-content-wrapper-2">
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src={
                                subscribeData.plan_name === priceTag.palmM
                                  ? "images/2.svg"
                                  : "images/1.svg"
                              }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              All of Acorn features, plus Advanced Channel
                              Analytics, AI features with subscribed credits etc
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src={
                                subscribeData.plan_name === priceTag.palmM
                                  ? "images/2.svg"
                                  : "images/1.svg"
                              }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Free 7-day Trial available with no-quibble
                              cancellation policy.
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src={
                                subscribeData.plan_name === priceTag.palmM
                                  ? "images/2.svg"
                                  : "images/1.svg"
                              }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Add up to 3 YouTube channels you own or manage.
                            </div>
                          </div>
                        </div>
                      </div>
                      {subscribeData.plan_name === priceTag.palmM ? (
                        <p className="choosen-btun">Current Plan</p>
                      ) : (
                        <p
                          className="cf-secondary-button-2 pricing-button w-buttons"
                          onClick={(e) => handleOpen(priceTag.palmM)}
                        >
                          Upgrade to Palm
                        </p>
                      )}
                    </div>
                  </div>
                  <div
                    className="last-cfprice"
                    style={{ backgroundColor: "#ceedfa" }}
                  >
                    <div className="upper-top-cards-recom">
                      <div
                        style={{
                          fontSize: "24px",
                          fontWeight: "700",
                          textAlign: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          loading="lazy"
                          alt="recommanded"
                          src="https://assets-global.website-files.com/60e5f2de011b86acebc30db7/6447dd0049378e8f47155cdf_Stars.svg"
                        />{" "}
                        &nbsp; Recommended
                      </div>
                      <div
                        className={
                          subscribeData.plan_name === priceTag.oackM
                            ? `under-sections-2`
                            : `under-sections`
                        }
                      >
                        <div className="cf-pricing-box-plan-wrapper-2">
                          <div className="cf-pricing-plan-text-2">Oak</div>
                          <p style={{ marginBottom: "32px" }}>
                            For growing and established YouTube Creators and
                            Brands, who want to boost their channel with AI
                          </p>
                          <div className="cf-pricing-plan-price-2">
                            $69.99
                            <span className="cf-pricing-plan-small-text-2">
                              /month
                            </span>
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-wrapper-2">
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src={
                                subscribeData.plan_name === priceTag.oackM
                                  ? "images/2.svg"
                                  : "images/1.svg"
                              }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              All of Palm features, plus Social Media Post
                              generator, Blog Writer incl. more subscribed
                              credits for all AI powered features.
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src={
                                subscribeData.plan_name === priceTag.oackM
                                  ? "images/2.svg"
                                  : "images/1.svg"
                              }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Free 7-day Trial available with no-quibble
                              cancellation policy.
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src={
                                subscribeData.plan_name === priceTag.oackM
                                  ? "images/2.svg"
                                  : "images/1.svg"
                              }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Add up to 10 YouTube channels you own or manage.
                            </div>
                          </div>
                        </div>
                        {/* <p className="cf-secondary-button-2 pricing-button w-buttons">
                        Start Free Trial
                      </p> */}
                        {subscribeData.plan_name === priceTag.oackM ? (
                          <p className="choosen-btun">Current Plan</p>
                        ) : (
                          <p
                            // className="cf-secondary-button-2 pricing-button w-buttons"
                            onClick={(e) => handleOpen(priceTag.oackM)}
                            className="cf-secondary-button-2 pricing-button"
                          >
                            Upgrade to Oak
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div
                    className={
                      subscribeData.plan_name === priceTag.oackM
                        ? `cf-pricing-box-2 green-pricing-box`
                        : `cf-pricing-box-2 pricing-1-box`
                    }
                  >
                    <div className="cf-pricing-box-plan-wrapper-2">
                      <div className="cf-pricing-plan-text-2">Oak</div>
                      <div className="cf-pricing-plan-note-text-2">
                        Free 7-day trial.Cancel anytime.
                      </div>
                      <div className="cf-pricing-plan-price-2">
                        $69.99
                        <span className="cf-pricing-plan-small-text-2">
                          /month
                        </span>
                      </div>
                    </div>
                    {subscribeData.plan_name === priceTag.oackM ? (
                      <p className="choosen-btun">Current Plan</p>
                    ) : (
                      <p
                        className="cf-secondary-button-2 pricing-button w-buttons"
                        onClick={(e) => handleOpen(priceTag.oackM)}
                      >
                        Start Free Trial
                      </p>
                    )}
                  </div> */}
                </div>
              ) : (
                <div
                  data-w-id="36d9533a-055b-4e2a-3186-0d13e50ca046"
                  style={{
                    WebkitTransform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 1,
                    display: "flex",
                  }}
                  className="cf-pricing-box-row-2 pricing-box-2"
                >
                  <div
                    style={{ marginTop: "67px" }}
                    className={
                      subscribeData.plan_name === priceTag.acornY
                        ? `cf-pricing-box-2 green-pricing-box`
                        : `cf-pricing-box-2 pricing-1-box`
                    }
                  >
                    <div style={{ padding: "48px" }}>
                      <div className="cf-pricing-box-plan-wrapper-2">
                        <div className="cf-pricing-plan-text-2 pricing-1-plan-text">
                          Acorn
                        </div>
                        <p style={{ marginBottom: "32px" }}>
                          For new YouTube Creators who want access to
                          accelerated growth-oriented tools.
                        </p>
                        <div className="lower-price-opt">
                          <strike className="strick-opts">$9.99/month</strike>
                          <div
                            className="cf-pricing-plan-price-2"
                            style={{ marginBottom: "0px" }}
                          >
                            $8.33
                            <span className="cf-pricing-plan-small-text-2">
                              /month
                            </span>
                          </div>
                        </div>
                        <p className="warns">
                          save 17% with annual billing incl. 2 months free
                          access
                        </p>
                      </div>
                      <div className="cf-pricing-plan-content-wrapper-2">
                        <div className="cf-pricing-plan-content-wrapper-2">
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src={
                                subscribeData.plan_name === priceTag.acornY
                                  ? "images/2.svg"
                                  : "images/1.svg"
                              }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Free Trial not available. 07 days full refund
                              policy. No-quibble Cancellation policy.
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src={
                                subscribeData.plan_name === priceTag.acornY
                                  ? "images/2.svg"
                                  : "images/1.svg"
                              }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              1 YouTube channel
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src={
                                subscribeData.plan_name === priceTag.acornY
                                  ? "images/2.svg"
                                  : "images/1.svg"
                              }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Standard Channel analytics dashboard
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src={
                                subscribeData.plan_name === priceTag.acornY
                                  ? "images/2.svg"
                                  : "images/1.svg"
                              }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Credits can be purchased to access AI powered
                              features
                            </div>
                          </div>
                        </div>
                      </div>
                      {subscribeData.plan_name === priceTag.acornY ? (
                        <p className="choosen-btun">Current Plan</p>
                      ) : (
                        <p
                          className="cf-secondary-button-2 pricing-button w-buttons"
                          onClick={(e) => handleOpen(priceTag.acornY)}
                        >
                          Downgrade to Acorn
                        </p>
                      )}
                    </div>
                  </div>
                  <div
                    style={{ marginTop: "67px" }}
                    className={
                      subscribeData.plan_name === priceTag.palmY
                        ? `cf-pricing-box-2 green-pricing-box`
                        : `cf-pricing-box-2 pricing-1-box`
                    }
                  >
                    <div style={{ padding: "27px" }}>
                      <div
                        className={`cf-pricing-box-plan-wrapper-2 ${
                          subscribeData.plan_name === priceTag.palmM
                            ? `white-border`
                            : `black-border`
                        }`}
                      >
                        <div className="cf-pricing-plan-text-2">Palm</div>
                        <p style={{ marginBottom: "32px" }}>
                          For budding and growing YouTube Creators seeking to
                          make informed data-driven decisions with rapid channel
                          growth potential
                        </p>
                        <div className="lower-price-opt">
                          <strike className="strick-opts">$29.99/month</strike>
                          <div
                            className="cf-pricing-plan-price-2"
                            style={{ marginBottom: "0px" }}
                          >
                            $24.99
                            <span className="cf-pricing-plan-small-text-2">
                              /month
                            </span>
                          </div>
                        </div>
                        <p className="warns">
                          save over 17% with annual billing incl. 2 months free
                          access
                        </p>
                      </div>
                      <div className="cf-pricing-plan-content-wrapper-2">
                        <div className="cf-pricing-plan-content-wrapper-2">
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src={
                                subscribeData.plan_name === priceTag.palmY
                                  ? "images/2.svg"
                                  : "images/1.svg"
                              }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              All of Acorn features, plus Advanced Channel
                              Analytics, AI features with subscribed credits etc
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src={
                                subscribeData.plan_name === priceTag.palmY
                                  ? "images/2.svg"
                                  : "images/1.svg"
                              }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Free 7-day Trial available with no-quibble
                              cancellation policy.
                            </div>
                          </div>
                          <div className="cf-pricing-plan-content-item-2">
                            <img
                              src={
                                subscribeData.plan_name === priceTag.palmY
                                  ? "images/2.svg"
                                  : "images/1.svg"
                              }
                              loading="lazy"
                              width={17}
                              alt=""
                              className="cf-pricing-plan-tick-icon-2"
                            />
                            <div className="cf-pricing-plan-content-text-2">
                              Add up to 3 YouTube channels you own or manage.
                            </div>
                          </div>
                        </div>
                      </div>
                      {subscribeData.plan_name === priceTag.palmY ? (
                        <p className="choosen-btun">Current Plan</p>
                      ) : (
                        <p
                          className="cf-secondary-button-2 pricing-button w-buttons"
                          onClick={(e) => handleOpen(priceTag.palmY)}
                        >
                          Upgrade to Palm
                        </p>
                      )}
                    </div>
                  </div>
                  <div
                    className="last-cfprice"
                    style={{ backgroundColor: "#ceedfa" }}
                  >
                    <div className="upper-top-cards-recom">
                      <div
                        style={{
                          fontSize: "24px",
                          fontWeight: "700",
                          textAlign: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          loading="lazy"
                          alt="recommanded"
                          src="https://assets-global.website-files.com/60e5f2de011b86acebc30db7/6447dd0049378e8f47155cdf_Stars.svg"
                        />{" "}
                        &nbsp; Recommended
                      </div>
                      <div
                        className={
                          subscribeData.plan_name === priceTag.oackY
                            ? `under-sections-2`
                            : `under-sections`
                        }
                      >
                        <div className="cf-pricing-plan-content-wrapper-2">
                          <div className="cf-pricing-box-plan-wrapper-2">
                            <div className="cf-pricing-plan-text-2">Oak</div>
                            <p style={{ marginBottom: "32px" }}>
                              For growing and established YouTube Creators and
                              Brands, who want to boost their channel with AI
                            </p>
                            <div className="lower-price-opt">
                              <strike className="strick-opts">
                                $69.99/month
                              </strike>
                              <div
                                className="cf-pricing-plan-price-2"
                                style={{ marginBottom: "0px" }}
                              >
                                $58.33
                                <span className="cf-pricing-plan-small-text-2">
                                  /month
                                </span>
                              </div>
                            </div>
                            <p className="warns">
                              save over 17% with annual billing incl. 2 months
                              free access
                            </p>
                          </div>
                          <div className="cf-pricing-plan-content-wrapper-2">
                            <div className="cf-pricing-plan-content-item-2">
                              <img
                                src={
                                  subscribeData.plan_name === priceTag.oackY
                                    ? "images/2.svg"
                                    : "images/1.svg"
                                }
                                loading="lazy"
                                width={17}
                                alt=""
                                className="cf-pricing-plan-tick-icon-2"
                              />
                              <div className="cf-pricing-plan-content-text-2">
                                All of Palm features, plus Social Media Post
                                generator, Blog Writer incl. more subscribed
                                credits for all AI powered features.
                              </div>
                            </div>
                            <div className="cf-pricing-plan-content-item-2">
                              <img
                                src={
                                  subscribeData.plan_name === priceTag.oackY
                                    ? "images/2.svg"
                                    : "images/1.svg"
                                }
                                loading="lazy"
                                width={17}
                                alt=""
                                className="cf-pricing-plan-tick-icon-2"
                              />
                              <div className="cf-pricing-plan-content-text-2">
                                Free 7-day Trial available with no-quibble
                                cancellation policy.
                              </div>
                            </div>
                            <div className="cf-pricing-plan-content-item-2">
                              <img
                                src={
                                  subscribeData.plan_name === priceTag.oackY
                                    ? "images/2.svg"
                                    : "images/1.svg"
                                }
                                loading="lazy"
                                width={17}
                                alt=""
                                className="cf-pricing-plan-tick-icon-2"
                              />
                              <div className="cf-pricing-plan-content-text-2">
                                Add up to 10 YouTube channels you own or manage.
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <p className="cf-secondary-button-2 pricing-button w-buttons">
                        Start Free Trial
                      </p> */}
                        {subscribeData.plan_name === priceTag.oackY ? (
                          <p className="choosen-btun">Current Plan</p>
                        ) : (
                          <p
                            // className="cf-secondary-button-2 pricing-button w-buttons"
                            onClick={(e) => handleOpen(priceTag.oackY)}
                            className="cf-secondary-button-2 pricing-button"
                          >
                            Upgrade to Oak
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal size="xs" open={open} onClose={handleClose}>
          <Modal.Header>
            <Modal.Title>
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 14 14"
                fill="#2196f3"
                aria-hidden="true"
                focusable="false"
                className="rs-icon"
                aria-label="info"
                datacategory="legacy"
              >
                <path d="M7 14A7 7 0 117 0a7 7 0 010 14zm0-9.333a1.167 1.167 0 100-2.334 1.167 1.167 0 000 2.334zm0 1.166c-.644 0-1.167.522-1.167 1.167v3.5a1.167 1.167 0 002.334 0V7c0-.644-.522-1.167-1.167-1.167z"></path>
              </svg>{" "}
              Information
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Do you want to change the plan</h5>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              No
            </Button>
            <Button
              onClick={(e) => handlePricing(e, mdata)}
              appearance="primary"
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <PlaneDetails />
    </>
  );
};

export default PlanInfo;
