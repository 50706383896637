import { all } from "redux-saga/effects";
import { additionalCreditsDetailsSaga } from "./additionalCreditsSaga";
import { channelidSaga } from "./channelidSaga";
import { loginSaga } from "./loginSaga";
import { moduleAccessSaga } from "./moduleAccessSaga";
import { subscriptionDetailsSaga } from "./subscriptionDetailsSaga";
import { TotalCreditsDetailsSaga } from "./totalCreditsSaga";
import { youtubeSaga } from "./youtubeSaga";
import { channelIdDataSaga } from "./channelIdDataSaga";

function* rootSaga() {
  yield all([
    loginSaga(),
    youtubeSaga(),
    channelidSaga(),
    moduleAccessSaga(),
    subscriptionDetailsSaga(),
    TotalCreditsDetailsSaga(),
    additionalCreditsDetailsSaga(),
    channelIdDataSaga(),
  ]);
}

export default rootSaga;
