import React from "react";
import { publicRequest } from "../../../api/axiosConfig/publicRequest";

const VerifyEmail = () => {
  const resends = async () => {
    const entrData = {
      email: localStorage.getItem("emails"),
    };
    console.log("data", entrData);
    try {
      publicRequest.post(`/api/accounts/resend-email/`, entrData);
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <div
      className="container-fluid cf-pricing-hero-section"
      style={{ height: "100vh" }}
    >
      <div className="d-flex justify-content-center">
        <div
          className="cf-pricing-box-cards green-pricing-box"
          style={{ minHeight: "0px" }}
        >
          <div className="cf-pricing-box-plan-wrapper-2 white-border">
            <div className="cf-pricing-plan-text-2">Verify Your Email</div>
          </div>
          <div
            className="cf-pricing-plan-content-wrapper-2"
            style={{ marginBottom: "0px" }}
          >
            <div className="cf-pricing-plan-content-item-2">
              <div className="cf-pricing-plan-content-text-2">
              An email has been sent to your registered email address for account verification. 
              </div>
            </div>
            <div className="cf-pricing-plan-content-item-2">
              <div
                className="cf-pricing-plan-content-text-2"
                style={{ fontSize: "14px", color: "#5b5b5b" }}
              >
                If you do not receive it within 2-3 minutes, please check your Junk or Spam folder.
              </div>
            </div>
            <div className="cf-pricing-plan-content-item-2">
              <div
                className="cf-pricing-plan-content-text-2"
                disabled
                style={{
                  fontSize: "14px",
                  color: "#9186e7",
                  cursor: "pointer",
                }}
                onClick={resends}
              >
                Resend Email
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
