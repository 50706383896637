import * as actionTypes from "../actionTypes";

export const fetchTotalCreditsStart = () => {
  // console.log("userDetails", userDetails);
  return {
    type: actionTypes.FETCH_TOTAL_CREDITS_DETAILS_START,
    // payload: userDetails,
  };
};
export const fetchTotalCreditsSuccess = (data) => {
  // console.log("userDetails", data);
  return {
    type: actionTypes.FETCH_TOTAL_CREDITS_DETAILS_SUCCESS,
    payload: data,
  };
};
export const fetchTotalCreditsFailed = (error) => {
  // console.log("userDetails", error);
  return {
    type: actionTypes.FETCH_TOTAL_CREDITS_DETAILS_FAILED,
    payload: error,
  };
};

// additional credits

export const fetchAdditionalCreditsStart = () => {
    // console.log("userDetails", userDetails);
    return {
      type: actionTypes.FETCH_ADDITONAL_CREDITS_DETAILS_START,
      // payload: userDetails,
    };
  };
  export const fetchAdditionalCreditsSuccess = (data) => {
    // console.log("userDetails", data);
    return {
      type: actionTypes.FETCH_ADDITONAL_CREDITS_DETAILS_SUCCESS,
      payload: data,
    };
  };
  export const fetchAdditionalCreditsFailed = (error) => {
    // console.log("userDetails", error);
    return {
      type: actionTypes.FETCH_ADDITONAL_CREDITS_DETAILS_FAILED,
      payload: error,
    };
  };