import React, { useEffect, useState } from "react";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";
import { useNavigate } from "react-router-dom";
import { Button, Modal, useToaster } from "rsuite";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../Notification";

const ChannelDetailsData = () => {
  const [channelDetails, setChannelDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [mdata, setMData] = useState("");
  const [channelLtm, setChannelLtm] = useState([]);
  const [enableDisable, setEnableDisable] = useState("");

  const navigate = useNavigate();
  const toaster = useToaster();

  const handleOpen = (data) => {
    setOpen(true);
    // console.log("modalData", data);
    setMData(data);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const mapChannels = async () => {
    try {
      const { data } = await privateRequest.get(
        "/api/youtube/channel-details/"
      );
      setChannelDetails(data);
      // console.log("dataYoutube", data);
    } catch (err) {
      console.log(err);
    }
  };

  const restriction = async () => {
    try {
      const data = await privateRequest.get(
        `/api/youtube/restrict-channel-access/`
      );
      // console.log("restirction", data);
      setChannelLtm(data.data);
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleDelete = async (e, data) => {
    // console.log("deleteData",mdata);
    setOpen(false);
    try {
      await privateRequest.delete(`/api/youtube/channel-details/${mdata}/`);
      toaster.push(
        showSuccessNotification("You have successfully deleted the channel")
      );
      // console.log("deleted successfully",deleted)
    } catch (err) {
      toaster.push(showErrorNotification("Something went wrong"));
      // console.log("eoeroero",err)
    }
    setMData("");
    mapChannels();
  };

  const addChannel = () => {
    navigate("/connect");
  };

  const enaDis = async () => {
    const data = await privateRequest.get(
      `/api/youtube/enable-disable-delete-button/`
    );
    // console.log("enablesss", data.data["delete button"]);
    setEnableDisable(data.data["delete button"]);
  };

  useEffect(() => {
    enaDis();
    restriction();
    // profileData();
    mapChannels();
  }, []);
  return (
    <div>
      <div className="col-lg-12">
        <h2 className="f-tab-heading">Channel(s) Details</h2>
      </div>
      <div className="card-tools card">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Sr.no</th>
                  <th scope="col">Channel Name</th>
                  <th scope="col">Date Added</th>
                  <th scope="col">Linked Youtube Channel</th>
                  <th scope="col">Delete Youtube Channel</th>
                </tr>
              </thead>
              <tbody>
                {channelDetails.map(
                  ({ channel_logo, channel_name, created_at, id }, index) => (
                    <tr key={id}>
                      <th scope="row">{index + 1}</th>
                      <td>{channel_name}</td>
                      <td>{created_at}</td>
                      {/* <td>25th Dec 22,12:36pm</td> */}
                      <td>
                        <div className="prof-imgs">
                          <img src={channel_logo} alt="table1" height="50px" />
                        </div>
                      </td>
                      <td>
                        {enableDisable === "Disable" ? (
                          <button
                            disable
                            // onClick={() => handleOpen(id)}
                            className="btn btn-danger"
                          >
                            Delete
                          </button>
                        ) : (
                          <button
                            onClick={() => handleOpen(id)}
                            className="btn btn-danger"
                          >
                            Delete
                          </button>
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          {channelLtm === undefined ? (
            ""
          ) : channelLtm["Total channel Limit"] <=
            channelLtm["Number of Channel Present"] ? (
            ""
          ) : (
            <button onClick={addChannel} className="f-button-neutral-2">
              Add Channels
            </button>
          )}
        </div>
      </div>
      {/* modal to delete the channels */}
      <Modal backdrop="static" size="sm" open={open} onClose={handleClose}>
        <Modal.Header>
          {/* <Modal.Title>
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 14 14"
              fill="#2196f3"
              aria-hidden="true"
              focusable="false"
              className="rs-icon"
              aria-label="info"
              data-category="legacy"
            >
              <path d="M7 14A7 7 0 117 0a7 7 0 010 14zm0-9.333a1.167 1.167 0 100-2.334 1.167 1.167 0 000 2.334zm0 1.166c-.644 0-1.167.522-1.167 1.167v3.5a1.167 1.167 0 002.334 0V7c0-.644-.522-1.167-1.167-1.167z"></path>
            </svg>{" "}
            Information
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <h5>
            Are you sure you want to delete this channel? You will not be able
            to re-add it to your account for the next 90 days, subject to
            availability based on your subscribed plan. For any queries, please
            contact our Customer Support team via your dashboard
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="primary">
            No, I changed my mind
          </Button>
          <Button
            onClick={(e) => handleDelete(e, mdata)}
            color="red"
            appearance="primary"
          >
            Yes, I want to delete anyway
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ChannelDetailsData;
