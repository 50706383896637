import {takeEvery, all, put, call} from 'redux-saga/effects';

import * as actionTypes from '../redux/actionTypes';

// actions
import { fetchSubscription_details } from '../api/service/channelid';

import { fetchSubscriptionDetailsFailed, fetchSubscriptionDetailsSuccess } from '../redux/actions/subscriptionDetailsActions';

// services

// import { fetchYoutube} from '../api/services/connect';



// Worker saga
// ***************************** Youtube START *****************************
function* loadSuubscriptionDetails(action){
    try{
        // console.log('action', action.payload);
        const {data} = yield call(fetchSubscription_details, action.payload);
        // console.log('poper', data[0])
        yield put(fetchSubscriptionDetailsSuccess(data[0]))
        // yield put(youtubeConnDataFetchSuccess(data['Please go to this URL: ']));
    }catch(error){
        // console.log("accessError",error)
        yield put(fetchSubscriptionDetailsFailed(error));
    }
}

// watcher saga
function* watchSubscriptionDetails() {
    yield takeEvery(actionTypes.FETCH_SUBSCRIPTION_DETAILS_START, loadSuubscriptionDetails);
}

export function* subscriptionDetailsSaga(){
    yield all([watchSubscriptionDetails()])
}