import React from "react";
import GlobalSocialMedailink from "../Pages/GlobalSocialMedailink";
import GlobalVideoHashTag from "../Pages/GlobalVideoHashTag";
import GlobalAffliateLink from "../Pages/GlobalAffliateLink";
import GlobalAffliateDisclamer from "../Pages/GlobalAffliateDisclamer";

const GlobalSetting = () => {
  return (
    <div className="row uk-margin-bottom">
      <div className="col-lg-6 col-12">
        <GlobalSocialMedailink />
        <GlobalAffliateDisclamer />
      </div>
      <div className="col-lg-6 col-12">
        <GlobalVideoHashTag />
        <GlobalAffliateLink />
      </div>
    </div>
  );
};

export default GlobalSetting;
