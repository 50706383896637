import React from "react";
import Script from "react-load-script";

export default function GoogleTrend({ type, keyword, url,countrys }) {
  const handleScriptLoad = _ => {
    window.trends.embed.renderExploreWidgetTo(
      document.getElementById("widget"),
      type,
      {
        comparisonItem: [{ keyword, geo: countrys, time: "today 12-m" }],
        category: 0,
        property: ""
      },
      {
        exploreQuery: `q=${encodeURI(keyword)}&geo=US&date=today 12-m`,
        guestPath: "https://trends.google.com:443/trends/embed/"
      }
    );
  };


  const renderGoogleTrend = _ => {
    return <Script url={url} onLoad={handleScriptLoad} />;
  };

//   useEffect(() => {
//     renderGoogleTrend()
//   },[countrys])

  return <div className="googleTrend">{renderGoogleTrend()}</div>;
}