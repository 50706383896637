import * as actionTypes from "../actionTypes";
const inititalState = {
  isDataFetch: false,
  youtubeData: {},
  isError: false,
  error: {},
};

export const youtubeReducer = (state = inititalState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_YOUTUBE_START:
      return {
        ...state,
        isDataFetch: true,
        isError: false,
        error: {},
      };

    case actionTypes.FETCH_YOUTUBE_SUCCESS:
      return {
        ...state,
        isDataFetch: false,
        youtubeData: action.payload,
        isError: false,
        error: {},
      };
    case actionTypes.FETCH_YOUTUBE_FAILD:
      return {
        ...state,
        isError: true,
        isDataFetch: false,
        projectTarget: {},
        error: { ...action.payload },
      };
    default:
      return state;
  }
};
