import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Modal, Pagination, SelectPicker, useToaster } from "rsuite";
import { adminPrivateRequest } from "../../../api/axiosConfig/adminPrivateRequest";
import { showErrorNotification, showSuccessNotification } from "../../../Notification";
import { ProgressBar } from "react-loader-spinner";

const Appsumo = () => {
  const [limit, setLimit] = useState(5);
  const [active, setActive] = useState(1);
  const [appsumo, setAppsumo] = useState([]);
  const [filing, setFiling] = useState(null);
  const [pageTotal,setPageTotal] = useState(null)
  const [open, setOpen] = useState(false);
  const [loading,setLoading] = useState(false)
  // const startIdx = (active - 1) * limit;
  // const endIdx = startIdx + limit;
  const limitOptions = [5, 10, 20];

  const toaster = useToaster()

  const uploading = async () => {
    console.log("filing", filing);
    handleClose();
    setLoading(true)
    if (filing) {
      const formData = new FormData();
      formData.append("file", filing);
      try {
        const uploads = await adminPrivateRequest.post(
          `/api/admin_panel/add-appsumo-data/`,
          formData
        );
        console.log("uploads", uploads);
        toaster.push(showSuccessNotification("Successfully Uploaded the file"));
      } catch (err) {
        console.log("err", err);
        toaster.push(showErrorNotification("Something went wrong"))
      }
    }
    setLoading(false)
    getAppsumoUser()
  };

  const pageChanged = (data) => {
    setActive(1);
    setLimit(data);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getAppsumoUser = async (page) => {
    setActive(page);
    setLoading(true)
    try {
      const user = await adminPrivateRequest.get(
        `/api/admin_panel/appsumo-data/?page=${page}&page_size=${limit}`
      );
      console.log("user", user);
      setPageTotal(user.data.count)
      setAppsumo(user.data.results);
    } catch (err) {
      console.log("err", err);
    }
    setLoading(false)
  };

  useEffect(() => {
    getAppsumoUser(1);
  }, [limit]);

  return (
    <div>
      <h1>Appsumo Users &nbsp;</h1>
      <div className="uk-child-width-1-1@m">
        <div className="uk-card uk-card-default uk-card-body">
          <div className="d-flex justify-content-between align-items-center mt-3">
            <div className="d-flex">
              <h4 className="heading-14" style={{ textAlign: "start" }}>
                Results:&nbsp;
              </h4>
              <SelectPicker
                value={limit}
                onChange={pageChanged}
                cleanable={false}
                searchable={false}
                data={limitOptions.map((key) => ({ value: key, label: key }))}
              />{" "}
            </div>

            <button
              className="f-button-neutral-2 w-button"
              onClick={handleOpen}
              style={{
                backgroundColor: "#5167f6",
                border: "1px solid",
                fontWeight: "600",
              }}
              type="submit"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-plus"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
            </button>
          </div>
          <div className="uk-margin-top">
            <table className="table table-striped">
              <thead className="table-header">
                <tr>
                  <th>Email</th>
                  <th>Full Name </th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {loading===true?
                <div className="d-flex align-items-center justify-content-end">
                LOADING &nbsp;
                <ProgressBar
                  height="80"
                  width="80"
                  ariaLabel="progress-bar-loading"
                  // wrapperStyle={{}}
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#53389e"
                  barColor="#53389e"
                />
                </div>
                :appsumo.length === 0
                  ? null
                  : appsumo.map((res) => (
                      <tr style={{ height: "50px" }} key={res.id}>
                        <td>{res.appsumo_email}</td>
                        <td>{res.full_name}</td>
                        <td>{res.status}</td>
                      </tr>
                    ))}
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
              <div>
                <div
                  style={{ padding: "20px" }}
                  className="uk-card uk-card-default"
                >
                  Showing page {active} of {pageTotal}
                </div>
              </div>
              <div>
                <Pagination
                  prev
                  next
                  first
                  last
                  size="md"
                  maxButtons={10}
                  //   page={Math.ceil(couponsDatas.length / limit)}
                  // page={10}
                  limit={limit}
                  //   total={couponsDatas.length}
                  total={pageTotal}
                  activePage={active}
                  onChangePage={getAppsumoUser}
                  // onChangeLimit={pageChanged}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal backdrop="static" size="sm" open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 14 14"
              fill="#2196f3"
              aria-hidden="true"
              focusable="false"
              class="rs-icon"
              aria-label="info"
              data-category="legacy"
            >
              <path d="M7 14A7 7 0 117 0a7 7 0 010 14zm0-9.333a1.167 1.167 0 100-2.334 1.167 1.167 0 000 2.334zm0 1.166c-.644 0-1.167.522-1.167 1.167v3.5a1.167 1.167 0 002.334 0V7c0-.644-.522-1.167-1.167-1.167z"></path>
            </svg>{" "}
            Generate Coupon Codes
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container row gap-2">
            <input
              className="form-control col"
              type="file"
              name="coupon_name"
              placeholder="Enter Coupon Name"
              // value={filing}
              onChange={(e) => setFiling(e.target.files[0])}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            cancel
          </Button>
          <Button onClick={uploading} appearance="primary">
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Appsumo;
