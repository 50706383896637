import * as actionTypes from "../actionTypes";

export const channelid = (userDetails) => {
  // console.log("userDetails", userDetails);
  return {
    type: actionTypes.Fetch_Channel_id,
    payload: userDetails,
  };
};
export const fetchChannelStart = () => {
  // console.log("userDetails", userDetails);
  return {
    type: actionTypes.FETCH_CHANNEL_START,
    // payload: userDetails,
  };
};
export const fetchChennalFirst = (data) => {
  // console.log("Firsst", data);
  return {
    type: actionTypes.FETCH_CHANNEL_FIRST,
    payload: data,
  };
};
export const fetchChennalSuccess = (data) => {
  // console.log("userDetails", data);
  return {
    type: actionTypes.FETCH_CHANNEL_SUCCESS,
    payload: data,
  };
};
export const fetchChennalFailed = (error) => {
  // console.log("userDetails", error);
  return {
    type: actionTypes.FETCH_CHANNEL_FAILD,
    payload: error,
  };
};