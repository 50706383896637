import React, { Suspense, lazy } from "react";
import { Routes, Route, useLocation,Navigate } from "react-router-dom";
import Navbars from "./Navbars";
import AiGenerator from "./Components/Users/Private/AiGenerator";
import AskYtubeBooster from "./Components/Users/Private/AskYtubeBooster";
import CreditsSummery from "./Components/Users/Private/CreditsSummery";
import KeywordResearch from "./Components/Users/Private/KeywordResearch";
import Optimize from "./Components/Users/Private/Optimize";
import Pricing from "./Components/Users/Private/Pricing";
import Profile from "./Components/Users/Private/Profile";
import TagExtractor from "./Components/Users/Private/TagExtractor";
import LandingPage from "./Components/Users/Public/LandingPage";
import Register from "./Components/Users/Public/Register";
import PlanInfo from "./Components/Users/Private/PlanInfo";
import ChannelConnect from "./Components/Users/Private/ChannelConnect";
import TransitionInvoice from "./Components/Users/Private/TransitionInvoice";
import SubscriptionCard from "./Components/Users/Private/SubscriptionCard";
// import ForgotPassword from "./Components/Users/Public/ForgotPassword";
import ForgotPasswordEnter from "./Components/Users/Public/ForgotPasswordEnter";
import ForgotPassword from "./Components/Users/Public/ForgotPassword";
import Notes from "./Components/Users/Private/Notes";
import VerifyEmail from "./Components/Users/Public/VerifyEmail";
import Congrate from "./Components/Users/Public/Congrate";
import ActivationExpire from "./Components/Users/Public/ActivationExpire";
import InvalidToken from "./Components/Users/Public/InvalidToken";
import NoytData from "./Components/Users/Private/NoytData";
import ProvidePermi from "./Components/Users/Private/ProvidePermi";
import LimitOver from "./Components/Users/Private/LimitOver";
import PrivacyPolicyPage from "./Components/Users/Public/PrivacyPolicyPage";
import TermsofServices from "./Components/Users/Public/TermsofServices";
import SameUser from "./Components/Users/Private/SameUser";
import OtherAccount from "./Components/Users/Private/OtherAccount";
import Sidebar from "./Sidebar";
import AdminUsers from "./Components/Admin/AdminPrivate/AdminUsers";
import AdminCoupons from "./Components/Admin/AdminPrivate/AdminCoupons";
import AdminCreditsCoupons from "./Components/Admin/AdminPrivate/AdminCreditsCoupons";
import AdminRevokeCoupon from "./Components/Admin/AdminPrivate/AdminRevokeCoupon";
import AdminLoginRecord from "./Components/Admin/AdminPrivate/AdminLoginRecord";
import GoogleYoutube from "./Components/Users/Pages/GoogleYoutube";
import AdminAi from "./Components/Admin/AdminPrivate/AdminAi";
import LimitedUse from "./Components/Users/Public/LimitedUse";
import ChannelAnalyse from "./Components/Users/Private/ChannelAnalyse";
import VerificationEmail from "./Components/Users/Private/VerificationEmail";
import ChannelDetailsData from "./Components/Users/Private/ChannelDetailsData";
import GlobalSetting from "./Components/Users/Private/GlobalSetting";
import Appsumo from "./Components/Admin/AdminPrivate/Appsumo";
import AppSumoSubscription from "./Components/Users/Private/AppSumoSubscription";
import AdminCancelledSubscriber from "./Components/Admin/AdminPrivate/AdminCancelledSubscriber";
import ErrorBoundary from "./ErrorBoundary";
import ChannelDelete from "./Components/Users/Private/ChannelDelete";
import TestLanding from "./Components/Users/Public/TestLanding";

const AdminDashboard = lazy(() =>
  import("./Components/Admin/AdminPrivate/AdminDashboard")
);
const Dashboard = lazy(() => import("./Components/Users/Private/Dashboard"));
const Admin = lazy(() => import("./Components/Admin/AdminPublic/Admin"));
const Login = lazy(() => import("./Components/Users/Public/Login"));
const AdminPrivateRoute = lazy(() => import("./Routers/AdminPrivateRoute"));
const AdminPublicRoute = lazy(() => import("./Routers/AdminPublicRoute"));
const PrivateRoute = lazy(() => import("./Routers/PrivateRoute"));
const PublicRoute = lazy(() => import("./Routers/PublicRoutes"));

const Home = () => {
  if (useLocation().pathname.includes("/admin")) {
    localStorage.removeItem("authUser");
    return (
      <Suspense fallback={<div>...Loading</div>}>
        <Routes>
          <Route path="/" element={<AdminPrivateRoute />}>
            <Route path="/" element={<Sidebar />}>
            <Route path="/" element={<Navigate replace to="admin_dashboard" />} />
            <Route path="admin_dashboard" element={<AdminDashboard />} />
            <Route path="admin-users" element={<AdminUsers />} />
            <Route path="admin-coupons" element={<AdminCoupons />} />
            <Route path="admin-credits-coupons" element={<AdminCreditsCoupons />} />
            <Route path="admin-revoked" element={<AdminRevokeCoupon/>} />
            <Route path="admin-login-record" element={<AdminLoginRecord/>} />
            <Route path="admin-ai" element={<AdminAi/>} />
            <Route path="admin-appsumo-users" element={<Appsumo/>} />
            <Route path="admin-cancel-subscription" element={<AdminCancelledSubscriber/>} />
            </Route>
            {/* <Route path="register" element={<Registers/>}/> */}
          </Route>
          <Route path="/" element={<AdminPublicRoute />}>
            <Route path="admin" element={<Admin />} />
            {/* <Route path="register" element={<Registers/>}/> */}
          </Route>
        </Routes>
      </Suspense>
    );
  } else {
    localStorage.removeItem("adminUser");
    // const {accessData} = useSelector((state) => state.accessModule)
    // const navigate = useNavigate()
    // console.log("for home",accessData)
    return (
      <div>
        <Suspense fallback={<div>...Loading</div>}>
          <Routes>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/" element={<Navbars />}>
              <Route path="/" element={<Navigate replace to="dashboard" />} />
              <Route path="dashboard" element={<ErrorBoundary><Dashboard /></ErrorBoundary>} />
              <Route path="pricing" element={<Pricing />} />
              <Route path="optimize/:id" element={<Optimize />} />
              <Route path="keyword-research" element={<KeywordResearch />} />
              <Route path="aigenerator" element={<AiGenerator />} />
             <Route path="ask-ytubebooster" element={<AskYtubeBooster />} />
              <Route path="tag-extractor" element={<TagExtractor />} />
              <Route path="profile" element={<Profile />} />
              <Route path="credits-history" element={<CreditsSummery />} />
              <Route path="plan-info" element={<PlanInfo />} />
              <Route path="transition-invoice" element={<TransitionInvoice />} />
              <Route path="notes" element={<Notes />} />
              <Route path="channel-analyse" element={<ChannelAnalyse />} />
              <Route path="channel-details" element={<ChannelDetailsData />} />
              <Route path="global-setting" element={<GlobalSetting />} />
              </Route>
            </Route>
            
            <Route path="/" element={<PrivateRoute />}>
            <Route path="connect" element={<ChannelConnect/>} />
            <Route path="subscribe" element={<SubscriptionCard />} />
            <Route path="appsumo" element={<AppSumoSubscription/>} />
            <Route path="/no-ytube-data" element={<NoytData />} />
            <Route path="/same-channel" element={<SameUser />} />
            <Route path="/another-account" element={<OtherAccount />} />
            <Route path="/provide-permission" element={<ProvidePermi />} />
            <Route path="limit-over" element={<LimitOver />} />
            <Route path="/channel-revoke" element={<ChannelDelete />} />
            <Route path="api/youtube/auth2callback/" element={<GoogleYoutube />} />
            </Route>

            {/* </Route> */}
            <Route path="/" element={<PublicRoute />}>
            <Route path="/" element={<Navigate replace to="login" />} />
            <Route path="landing" element={<LandingPage/>} />
            <Route path="test-landing" element={<TestLanding/> } />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/terms-services" element={<TermsofServices />} />
              <Route path="login" element={<Login />} />
              <Route path="verify-email" element={<VerifyEmail />} />
              <Route path="congrats" element={<Congrate />} />
              <Route path="register" element={<Register />} />
              <Route path="forgot" element={<ForgotPassword />} />
              <Route path="expire" element={<ActivationExpire />} />
              <Route path="invalid-token" element={<InvalidToken />} />        
              <Route path="password-reset-confirm/:uid/:token" element={<ForgotPasswordEnter/>} />
              <Route path="/limited-use-disclosure" element={<LimitedUse />} />
              <Route path="/api/accounts/email-verify" element={<VerificationEmail />} />
            </Route>
          </Routes>
        </Suspense>
      </div>
    );
  }
};

export default Home;
