import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextError from "../../../FormikControl/TextError";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
// import { useSelector } from "react-redux";
import { publicRequest } from "../../../api/axiosConfig/publicRequest";
import { useToaster } from "rsuite";
import { showErrorNotification } from "../../../Notification";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import TermsService from "../Pages/TermsService";

const Register = () => {
  const navigate = useNavigate();
  // const { pricing } = useSelector((state) => state.price);
  const [disable, setDisable] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [termsHidden, setTermsHidden] = useState(false);

  const toaster = useToaster();

  // console.log("price", pricing);

  const handleViewClose = () => {
    setHidden(false);
  };

  const handleTermsViewClose = () => {
    setTermsHidden(false);
  };

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    billing_email: "",
    plan_info: localStorage.getItem("prices"),
    password: "",
    confirm_password: "",
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("Required*"),
    last_name: Yup.string().required("Required*"),
    email: Yup.string().required("Required*"),
    password: Yup.string().required("Required*"),
    billing_email: Yup.string().required("Required*"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required*"),
  });

  const handleCheck = (e) => {
    if (disable === false) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };

  const handleSubmit = async (values) => {
    // console.log("values", values);
    try {
      await publicRequest.post(`/api/accounts/register/`, values);
      // console.log("register", data);
      localStorage.setItem("emails",values.email)
      navigate("/verify-email");
    } catch (err) {
      if (err.response.data.email) {
        toaster.push(
          showErrorNotification("User with this email address already exists."),
          {
            duration: 0,
          }
        );
      } else if (err.response.data.error) {
        // console.log("err",err.response.data.error)
        toaster.push(showErrorNotification(err.response.data.error[0]), {
          duration: 0,
        });
      } else if (err.response.data.message) {
        toaster.push(showErrorNotification(err.response.data.message), {
          duration: 0,
        });
      }
      // console.log("errror", err);
    }
  };

  const login = () => {
    navigate("/login");
  };
  return (
    <div className="container-fluide">
      <div className="d-flex">
        <div
          className="col-lg-5 col-lg-5 d-lg-block d-xl-block d-none d-sm-none"
          style={{ height: "100vh" }}
        >
          <div className="f-account-image-wrapper">
            <div className="div-block-25">
              <div style={{ textAlign: "start" }}>
                <p className="paragraph-10">
                  The ultimate <span className="text-span-6">YouTube</span> SEO
                  platform that will help you grow your channel and increase
                  your views and{" "}
                  <span className="text-span-7">subscribers. </span>
                  Our platform is designed to help you optimize your videos and
                  channel for YouTube search engines, so you can rank higher and
                  get more visibility. <br />
                </p>
              </div>
              <div>
                <p className="paragraph-9">
                  With YTubeBooster, you can easily track your rankings, analyze
                  your competitors, and get actionable insights to improve your
                  performance. Whether you’re a beginner or an experienced
                  YouTuber, YTubeBooster has everything you need to succeed.
                  Sign up today and start boosting your YouTube presence!
                </p>
                <h1 className="heading-12">
                  YTubeBooster <span className="text-span-8">PRO</span>
                </h1>
                <p className="paragraph-9">www.ytubeboosterpro.app</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container for_background col-xl-7 col-lg-7 col-12 pt-4">
          <div className="container-fluid ">
            <div
              className="f-account-container-l row"
              style={{ textAlign: "start" }}
            >
              <div className="col-12">
                <img alt="login" src="/images/YTBPRo.svg" width="178" />
                <br />
                <h5 className="d-header-welcome-username">Register</h5>
              </div>
              <div className="f-margin-bottom-25 col-12">
                <p className="enter-color">
                  Enter your name email and password to register
                </p>
              </div>
              <div className="col">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {(formik) => {
                    return (
                      <Form>
                        <div className="mb-3 row">
                          <div className="col">
                            <label
                              htmlFor="exampleInput1"
                              className="form-label"
                              style={{ float: "left" }}
                            >
                              First Name
                            </label>
                            <Field
                              type="text"
                              name="first_name"
                              className="form-control"
                              id="exampleInput1"
                              aria-describedby="emailHelp"
                            />
                            <ErrorMessage
                              name="first_name"
                              component={TextError}
                            />
                          </div>
                          <div className="col">
                            <label
                              htmlFor="exampleInput2"
                              className="form-label"
                              style={{ float: "left" }}
                            >
                              Last Name
                            </label>
                            <Field
                              type="text"
                              name="last_name"
                              className="form-control"
                              id="exampleInput2"
                              aria-describedby="emailHelp"
                            />
                            <ErrorMessage
                              name="last_name"
                              component={TextError}
                            />
                          </div>

                          {/* <input
                      type="text"
                      className="form-control"
                      id="exampleInput1"
                      aria-describedby="emailHelp"
                    /> */}
                        </div>
                        <div className="mb-3 row">
                          <div className="col">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                              style={{ float: "left" }}
                            >
                              Email
                            </label>
                            <Field
                              type="email"
                              name="email"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                            />
                            <ErrorMessage name="email" component={TextError} />
                          </div>
                          <div className="col">
                            <label
                              htmlFor="exampleInputEmail2"
                              className="form-label"
                              style={{ float: "left" }}
                            >
                              Billing Email
                            </label>
                            <Field
                              type="email"
                              name="billing_email"
                              className="form-control"
                              id="exampleInputEmail2"
                              aria-describedby="emailHelp"
                            />
                            <ErrorMessage
                              name="billing_email"
                              component={TextError}
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputPassword1"
                            className="form-label"
                            style={{ float: "left" }}
                          >
                            Password
                          </label>
                          <Field
                            name="password"
                            type="password"
                            className="form-control"
                            id="exampleInputPassword1"
                          />
                          <ErrorMessage name="password" component={TextError} />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputPassword2"
                            className="form-label"
                            style={{ float: "left" }}
                          >
                            Confirm Password
                          </label>
                          <Field
                            name="confirm_password"
                            type="password"
                            className="form-control"
                            id="exampleInputPassword2"
                          />
                          <ErrorMessage
                            name="confirm_password"
                            component={TextError}
                          />
                        </div>

                        <div className="mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="yes"
                            id="flexCheckDefault"
                            onChange={handleCheck}
                          />
                          <label
                            className="form-check-label"
                            // for="flexCheckDefault"
                          >
                            <span
                              className="f-checkbox-label w-form-label ms-2"
                              htmlFor="Privacy-Checkbox-01"
                            >
                              I agree to the{" "}
                              <span
                                onClick={() => setTermsHidden(true)}
                                className="text-span-5"
                              >
                                Terms of Service
                              </span>{" "}
                              and
                              <span
                                onClick={() => setHidden(true)}
                                className="text-span-5"
                              >
                                &nbsp;Privacy Policy
                              </span>
                            </span>
                          </label>
                          {/* <label
                            id="Privacy-Checkbox-01"
                            className="w-checkbox f-checkbox-field"
                          >
                            <div className="w-checkbox-input w-checkbox-input--inputType-custom f-checkbox"></div>
                            <input
                              type="checkbox"
                              id="Privacy-Checkbox-01"
                              name="Privacy-Checkbox-01"
                              data-name="Privacy Checkbox 01"
                              required=""
                              style={{
                                opacity: "0",
                                position: "absolute",
                                zIndex: "-1",
                              }}
                            />
                            <span
                              className="f-checkbox-label w-form-label"
                              htmlFor="Privacy-Checkbox-01"
                            >
                              I agree to the{" "}
                              <span className="text-span-5">
                                Terms and Privacy Policy
                              </span>
                            </span>
                          </label> */}
                        </div>

                        <div className="f-account-form-button-2">
                          {disable === true ? (
                            <button
                              type="submit"
                              style={{ color: "#fff" }}
                              className="colors"
                            >
                              Register
                            </button>
                          ) : (
                            <button
                              style={{ color: "black", fontWeight: "500" }}
                              className="colors2"
                              disabled
                            >
                              Register
                            </button>
                          )}
                        </div>
                        <p
                          style={{ marginTop: "20px" }}
                          className="f-paragraph-small-2"
                        >
                          I have an account?{" "}
                          <span
                            style={{ cursor: "pointer" }}
                            className="f-account-link-2"
                            onClick={login}
                          >
                            Login
                          </span>
                        </p>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PrivacyPolicy handleViewClose={handleViewClose} hidden={hidden} />
      <TermsService
        handleTermsViewClose={handleTermsViewClose}
        setHidden={setHidden}
        termsHidden={termsHidden}
      />
    </div>
  );
};

export default Register;
