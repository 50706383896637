import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { ProgressBar } from "react-loader-spinner";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";
import { useDispatch } from "react-redux";
import {
  fetchAdditionalCreditsStart,
  fetchTotalCreditsStart,
} from "../../../redux/actions/creditsActions";
import { Tooltip, Whisper, useToaster } from "rsuite";
import { showErrorNotification } from "../../../Notification";
import ReactMarkdown from "react-markdown";
import CopyToClipboard from "react-copy-to-clipboard";
import AIheader from "./AIheader";
import { AiHeaders } from "./StringStore";
// import MarkdownIt from 'markdown-it';
// import AutoAdjustIframe from "./AutoAdjustIframe";
// import SunEditor from "suneditor-react";

const BlogPost = () => {
  const [selectTitle, setSelectTitle] = useState("list-item-6");
  const [searchTitles, setSearchTitles] = useState([]);
  // const [outlineCard1, setOutlineCard1] = useState("");
  // const [outlineCard2, setOutlineCard2] = useState("");
  // const [outlineCard3, setOutlineCard3] = useState("");
  const [blogAticle, setBlogAticle] = useState("");
  const [meta, setMeta] = useState("");
  const [metaKey, setMetaKey] = useState("");
  const [blogSearchLoading, setBlogSearchLoading] = useState(false);
  // const [outlineLoading, setOutlineLoading] = useState(false);
  const [articleLoading, setArticleLoading] = useState(false);
  const toaster = useToaster();
  const dispatch = useDispatch();

  const listSelect = async (out) => {
    // try {
    //   setSelectTitle(data);
    //   console.log("data", data);
    //   setBlogAticle("");
    //   const outlineTitle = {
    //     user_input: data,
    //   };
    //   setOutlineLoading(true);
    //   const outlines = await privateRequest.post(
    //     `/api/youtube/article/outline/`,
    //     outlineTitle
    //   );
    //   setOutlineLoading(false);
    //   console.log("titles?.data?.titles", outlines.data);
    //   setOutlineCard1(outlines.data.article_outline1);
    //   setOutlineCard2(outlines.data.article_outline2);
    //   setOutlineCard3(outlines.data.article_outline3);
    // } catch (err) {
    //   console.log("err", err);
    //   toaster.push(showErrorNotification(err.response.data.message), {
    //     duration: 0,
    //   });
    //   setOutlineLoading(false);
    // }
    // dispatch(fetchAdditionalCreditsStart());
    // dispatch(fetchTotalCreditsStart());
    // setSearchTitles(titles.data.titles)
    try {
      const outLineArticle = {
        user_input: out,
      };
      setSelectTitle(out);
      setArticleLoading(true);
      const articles = await privateRequest.post(
        `/api/youtube/blog/post/`,
        outLineArticle
      );
      const meta = await privateRequest.post(
        `/api/youtube/metadata/keywords/`,
        outLineArticle
      );
      setArticleLoading(false);
      // console.log("meta", meta.data.meta_data);
      // console.log("articles", articles.data.Blog_Post);
      setBlogAticle(articles.data.Blog_Post);
      setMeta(meta.data.meta_data);
      setMetaKey(meta.data.keywords);
    } catch (err) {
      console.log("err", err);
      toaster.push(showErrorNotification(err.response.data.message), {
        duration: 0,
      });
      setArticleLoading(false);
    }
    dispatch(fetchAdditionalCreditsStart());
    dispatch(fetchTotalCreditsStart());
  };
  // const editor = useRef();

  // const getSunEditorInstance = (sunEditor) => {
  //   editor.current = sunEditor;
  //   console.log("kfofk", sunEditor);
  // };

  // const md = new MarkdownIt();

  const markdown = (markdownContent) => {
    // Replace headers (e.g., # Header) with their text content
    markdownContent = markdownContent.replace(/^# (.+)$/gm, "$1");

    // Replace italic text (e.g., *italic*) with its content
    markdownContent = markdownContent.replace(/\*([^*]+)\*/g, "$1");

    // Replace bold text (e.g., **bold**) with its content
    markdownContent = markdownContent.replace(/\*\*([^*]+)\*\*/g, "$1");

    // Replace unordered lists with plain text
    markdownContent = markdownContent.replace(/^\s*-\s+/gm, "");

    // Replace hyperlinks (e.g., [text](url)) with their text content
    markdownContent = markdownContent.replace(/\[([^\]]+)\]\([^)]+\)/g, "$1");

    downloadTxtFile(markdownContent);
  };

  const downloadTxtFile = (fac) => {
    // console.log("facebookss",fac)
    // text content
    // const texts = ["line 1", "line 2", "line 3"];

    // file object
    const file = new Blob([fac], { type: "text/plain" });

    // anchor link
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    // eslint-disable-next-line
    element.download = "100ideas-" + ".txt";

    // simulate link click
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  // const outlineSelect = async (out) => {
  //   try {
  //     const outLineArticle = {
  //       user_input: out,
  //     };
  //     setArticleLoading(true);
  //     const articles = await privateRequest.post(
  //       `/api/youtube/blog/post/`,
  //       outLineArticle
  //     );
  //     const meta = await privateRequest.post(
  //       `/api/youtube/metadata/keywords/`,
  //       outLineArticle
  //     );
  //     setArticleLoading(false);
  //     console.log("meta", meta.data.meta_data);
  //     console.log("articles", articles.data.Blog_Post);
  //     setBlogAticle(articles.data.Blog_Post);
  //     setMeta(meta.data.meta_data);
  //     setMetaKey(meta.data.keywords);
  //   } catch (err) {
  //     console.log("err", err);
  //     toaster.push(showErrorNotification(err.response.data.message), {
  //       duration: 0,
  //     });
  //     setArticleLoading(false);
  //   }
  //   dispatch(fetchAdditionalCreditsStart());
  //   dispatch(fetchTotalCreditsStart());
  // };

  const initialValues = {
    user_input: "",
  };
  const addblogs = async (values) => {
    try {
      // setOutlineCard1("");
      // setOutlineCard2("");
      // setOutlineCard3("");
      setBlogAticle("");
      setMeta("");
      setMetaKey("");
      setBlogSearchLoading(true);
      const titles = await privateRequest.post(
        `/api/youtube/blog/titles/`,
        values
      );
      setBlogSearchLoading(false);
      // console.log("titles?.data?.titles", titles.data.titles);
      setSearchTitles(titles.data.titles);
    } catch (err) {
      console.log("err", err);
      toaster.push(showErrorNotification(err.response.data.message), {
        duration: 0,
      });
      setBlogSearchLoading(false);
    }
    dispatch(fetchAdditionalCreditsStart());
    dispatch(fetchTotalCreditsStart());
  };
  return (
    <div className="container-fluid pt-3">
      <div className="mb-4">
        <AIheader
          title={AiHeaders.blogtitle}
          description={AiHeaders.blogdescription}
          lasttext={AiHeaders.bloglast}
        />
      </div>
      <div className="containers-custom p-4 mb-4">
        <div className="f-heading-detail-small-2 uk-margin-bottom">
          Generate Blog Posts for your video
        </div>
        <div className="brix---position-relative-2">
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              await addblogs(values);
              resetForm();
            }}
          >
            {(formik) => {
              return (
                <Form>
                  <Field
                    type="text"
                    className="brix---input-large-button-inside w-input"
                    autoFocus={true}
                    style={{ border: "none" }}
                    maxLength="256"
                    name="user_input"
                    data-name="BRIX - Footer Email - V16"
                    placeholder="Enter your keywords to generate social media post"
                    id="BRIX-Footer-Email-V16"
                  />
                  {formik.values.user_input.length === 0 ||
                  blogSearchLoading === true ? (
                    <input
                      type="button"
                      style={{ border: "none", backgroundColor: "grey" }}
                      data-wait="Please wait..."
                      className="brix---btn-primary-inside-input w-button"
                      value="Generate"
                    />
                  ) : (
                    <input
                      type="submit"
                      data-wait="Please wait..."
                      value="Generate"
                      style={{ border: "none" }}
                      className="brix---btn-primary-inside-input w-button"
                    />
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      {blogSearchLoading === true ? (
        <div className="d-flex justify-content-center align-items-center mt-3">
          LOADING &nbsp;
          <ProgressBar
            height="80"
            width="80"
            ariaLabel="progress-bar-loading"
            // wrapperStyle={{}}
            wrapperClass="progress-bar-wrapper"
            borderColor="#53389e"
            barColor="#53389e"
          />
        </div>
      ) : (
        <>
          {searchTitles.length === 0 ? (
            ""
          ) : (
            <div className="row justify-content-center gap-1">
              {articleLoading === true ? (
                <div className="title-container custom1-background col-lg-5">
                  <h4 className="heading-14">Titles</h4>
                  {/* <h1 className="f-h2-heading">Titles</h1> */}
                  <div className="text-block-22" style={{ textAlign: "start" }}>
                    Choose a title from the list of 20 suggestions for your
                    blog. The title sets the tone and topic of your article.
                  </div>
                  <div className="div-block-23">
                    {
                      // eslint-disable-next-line
                    }{" "}
                    <ul className="list-5">
                      {searchTitles.map((res, index) => (
                        <li
                          key={index + 1}
                          // onClick={() => listSelect(res)}
                          style={{ cursor: "default" }}
                          className={selectTitle === res ? "selected" : ""}
                        >
                          <div className="text-block-16">{res}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : (
                <div className="title-container custom1-background col-lg-5">
                  <h4 className="heading-14">Titles</h4>
                  {/* <h1 className="f-h2-heading">Titles</h1> */}
                  <div className="text-block-22" style={{ textAlign: "start" }}>
                    Choose a title from the list of 20 suggestions for your
                    blog. The title sets the tone and topic of your article.
                  </div>
                  <div className="div-block-23">
                    {
                      // eslint-disable-next-line
                    }{" "}
                    <ul className="list-5">
                      {searchTitles.map((res, index) => (
                        <li
                          key={index + 1}
                          onClick={() => listSelect(res)}
                          className={
                            selectTitle === res ? "selected" : "list-item-6"
                          }
                        >
                          <div className="text-block-16">{res}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              {articleLoading === true ? (
                <div className="col-lg-6 d-flex justify-content-center align-items-center mt-3">
                  LOADING &nbsp;
                  <ProgressBar
                    height="80"
                    width="80"
                    ariaLabel="progress-bar-loading"
                    // wrapperStyle={{}}
                    wrapperClass="progress-bar-wrapper"
                    borderColor="#53389e"
                    barColor="#53389e"
                  />
                </div>
              ) : blogAticle.length === 0 ? (
                ""
              ) : (
                <div className="col-lg-6">
                  <div
                    className="title-container custom-background"
                    style={{ paddingBottom: "18px" }}
                  >
                    <div className="d-flex justify-content-end ">
                      <p
                        className="f-smpost-gen-copy w-button me-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => markdown(blogAticle)}
                      >
                        Download
                      </p>
                      <CopyToClipboard text={blogAticle}>
                        <Whisper
                          placement="top"
                          controlId="control-id-click"
                          trigger="click"
                          speaker={<Tooltip>Copied</Tooltip>}
                        >
                          <p
                            className="f-smpost-gen-copy w-button mt-0"
                            style={{ cursor: "pointer" }}
                          >
                            Copy
                          </p>
                        </Whisper>
                      </CopyToClipboard>
                    </div>
                    {/* <h1 className="f-h2-heading">Article</h1> */}
                    <h4 className="heading-14">AI Generated Blog Article</h4>
                    <div
                      className="text-block-22"
                      style={{ textAlign: "start" }}
                    >
                      Here, our AI has generated the blog article for you based
                      on the title you've selected. While the AI provides the
                      foundation, don't forget to leverage your creativity and
                      expertise to refine and personalize the content. Use your
                      skills to make this blog truly your own.
                    </div>
                    <div className="div-block1-23" style={{ fontSize: "13px" }}>
                      {/* <div>{blogAticle}</div> */}
                      <ReactMarkdown className="markdown">
                        {blogAticle}
                      </ReactMarkdown>
                    </div>
                  </div>
                  <div className="meta-container mt-3">
                    <div className="meta-containers">
                      {meta.length === 0 ? (
                        ""
                      ) : (
                        <div className="custom-background">
                          {/* <h1 className="f-h2-headings mb-2">MetaData</h1> */}
                          <h4 className="heading-14">MetaData</h4>
                          <div
                            className="text-block-22"
                            style={{ textAlign: "start" }}
                          >
                            Write a brief synopsis of your entire blog in 164
                            characters or less. This meta description will
                            appear in search results and social media previews,
                            so make it engaging.
                          </div>
                          <div className="div-block1-23">
                            <ReactMarkdown className="markdown">
                              {meta}
                            </ReactMarkdown>
                          </div>
                        </div>
                      )}
                      {metaKey.length === 0 ? (
                        ""
                      ) : (
                        <div className="custom-background">
                          <h4 className="heading-14">Keywords</h4>
                          <div
                            className="text-block-22"
                            style={{ textAlign: "start" }}
                          >
                            Select keywords that are relevant to your blog.
                            These keywords will help your blog rank higher in
                            search engines and attract the right audience.
                          </div>
                          <div className="div-block1-23">
                            <ReactMarkdown className="markdown">
                              {metaKey}
                            </ReactMarkdown>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
      {
        // eslint-disable-next-line
      }
      {/* {articleLoading === true ? (
        <div className="d-flex justify-content-center align-items-center mt-3">
          LOADING &nbsp;
          <ProgressBar
            height="80"
            width="80"
            ariaLabel="progress-bar-loading"
            wrapperClass="progress-bar-wrapper"
            borderColor="#53389e"
            barColor="#53389e"
          />
        </div>
      ) : (
        <>
          <div className="meta-container mt-3">
            <div className="meta-containers row justify-content-center">
              {meta.length === 0 ? (
                ""
              ) : (
                <div className="custom-background col-lg-5">
                  <h4 className="heading-14">MetaData</h4>
                  <div className="text-block-22" style={{ textAlign: "start" }}>
                    Write a brief synopsis of your entire blog in 164 characters
                    or less. This meta description will appear in search results
                    and social media previews, so make it engaging.
                  </div>
                  <div className="div-block1-23">
                    <ReactMarkdown className="markdown">{meta}</ReactMarkdown>
                  </div>
                </div>
              )}
              {metaKey.length === 0 ? (
                ""
              ) : (
                <div className="custom-background col-lg-6">
                  <h4 className="heading-14">Keywords</h4>
                  <div className="text-block-22" style={{ textAlign: "start" }}>
                    Select keywords that are relevant to your blog. These
                    keywords will help your blog rank higher in search engines
                    and attract the right audience.
                  </div>
                  <div className="div-block1-23">
                    <ReactMarkdown className="markdown">
                      {metaKey}
                    </ReactMarkdown>
                  </div>
                </div>
              )}
            </div>
          </div>
          
        </>
      )} */}

      {/* <div className="title-container">
        {outlineLoading === true ? (
          <Comment
            visible={true}
            height="80"
            width="80"
            ariaLabel="comment-loading"
            wrapperStyle={{}}
            wrapperClass="comment-wrapper"
            color="#fff"
            backgroundColor="#1a75ff"
          />
        ) : (
          <>
            {outlineCard1.length === 0 ? (
              ""
            ) : (
              <h1 className="f-h2-heading">Outlines</h1>
            )}
            <div className="outine-container">
              {outlineCard1.length === 0 ? (
                ""
              ) : (
                <div className="div-block-23">
                  <iframe
                    id="myIframe"
                    style={{
                      width: "100%",
                      height: "555px",
                      border: "1px solid #e9ecef",
                      borderRadius: "5px",
                      fontFamily: "sans-serif",
                    }}
                    srcDoc={outlineCard1}
                  ></iframe>
                  <pre
                    style={{
                      whiteSpace: "pre-line",
                      backgroundColor: "transparent",
                      color: "#000000",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    {outlineCard1}
                  </pre>
                  <button
                    onClick={() => outlineSelect(outlineCard1)}
                    className="btn btn-sm btn-success"
                  >
                    Generate Article
                  </button>

                 
                </div>
              )}
              {outlineCard2.length === 0 ? (
                ""
              ) : (
                <div className="div-block-23">
                  <iframe
                    id="myIframe"
                    style={{
                      width: "100%",
                      height: "555px",
                      border: "1px solid #e9ecef",
                      borderRadius: "5px",
                      fontFamily: "sans-serif",
                    }}
                    srcDoc={outlineCard2}
                  ></iframe>
                  
                  <button
                    onClick={() => outlineSelect(outlineCard2)}
                    className="btn btn-sm btn-success"
                  >
                    Generate Article
                  </button>
                  
                </div>
              )}
              {outlineCard3.length === 0 ? (
                ""
              ) : (
                <div className="div-block-23">
                  <iframe
                    id="myIframe"
                    style={{
                      width: "100%",
                      height: "555px",
                      border: "1px solid #e9ecef",
                      borderRadius: "5px",
                      fontFamily: "sans-serif",
                    }}
                    srcDoc={outlineCard3}
                  ></iframe>
                  <button
                    onClick={() => outlineSelect(outlineCard3)}
                    className="btn btn-sm btn-success"
                  >
                    Generate Article
                  </button>
                
                </div>
              )}
            </div>
          </>
        )}
      </div> */}
    </div>
  );
};

export default BlogPost;
