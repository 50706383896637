import React, { useEffect, useState } from "react";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";

const SupportSystem = () => {
  const [wigd, setWidg] = useState("");

  useEffect(() => {
    // Check if wigd is empty before fetching data
    if (!wigd) {
      const fetchUserData = async () => {
        try {
          const response = await privateRequest.get(
            `api/accounts/userprofile/username/`
          );
          // console.log('find', response.data[0]);
          setWidg(response.data[0]);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchUserData();
    }

    const script = document.createElement("script");
    script.src = "https://cdn.helpspace.com/widget/widget-v1.js";
    script.setAttribute("data-auto-init", "");
    script.setAttribute("id", "helpspace-widget-script");
    script.async = true;
    script.setAttribute(
      "data-token",
      "smAAkBqjZtxa5Z6K9e6W6gF0WzV5A6qT0n2Aw3sN"
    );
    script.setAttribute("data-client-id", "cccbf521730848179e12eb0b07a64ccd");
    script.setAttribute(
      "data-widget-id",
      "693fee10-ae9f-4870-9788-3923d7e688ca"
    );

    script.onload = function () {
      // Initialize HelpWidget after the script has loaded and when wigd.email is available
      if (wigd && wigd.email) {
        window.HelpWidget("user", {
          name: `${wigd.first_name} ${wigd.last_name}`,
          email: wigd.email,
          show: false,
        });
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [wigd]);
  return <div>
     
  </div>;
};

export default SupportSystem;
