// import { useSelector } from "react-redux";
import * as actionTypes from "../actionTypes";

// const {fetchId} = useSelector((state) => state.channelIdData)
const inititalState = {
  ischannelDataFetch: false,
  channelidData: [],
  isError: false,
  error: {},
  firstChannel:"",
  // channelid:localStorage.getItem("fav")=== null ?1:localStorage.getItem("fav"),
  channelid: localStorage.getItem("fav"),
};

export const channelidReducer = (state = inititalState, action) => {
    switch (action.type) {
        case actionTypes.Fetch_Channel_id:
          return {
            ...state,
            channelid: action.payload,
          };
          case actionTypes.FETCH_CHANNEL_START:
            return {
              ...state,
              ischannelDataFetch: true,
              isError: false,
              error: {},
            };
      
          case actionTypes.FETCH_CHANNEL_FIRST:
            return {
              ...state,
              ischannelDataFetch: false,
              firstChannel: action.payload,
              isError: false,
              error: {},
            };
          case actionTypes.FETCH_CHANNEL_SUCCESS:
            return {
              ...state,
              ischannelDataFetch: false,
              channelidData: action.payload,
              isError: false,
              error: {},
            };
          case actionTypes.FETCH_CHANNEL_FAILD:
            return {
              ...state,
              isError: true,
              ischannelDataFetch: false,
              error: { ...action.payload },
            };
          default:
      return state;
        }
}