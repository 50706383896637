import React from "react";
import { adminPrivateRequest } from "../../../api/axiosConfig/adminPrivateRequest";
import { useEffect } from "react";
import { useState } from "react";
import { useToaster } from "rsuite";
import { showErrorNotification, showSuccessNotification } from "../../../Notification";

const AdminAi = () => {
  const [dataPrompt, setDataPrompt] = useState([]);
  const [descriptionPrompts, setDescriptionPrompts] = useState([]);
  const [blogTitle, setBlogTitle] = useState([]);
  const [blogPost, setBlogPost] = useState([]);
  const [blogMetaData, setBlogMetaData] = useState([]);
  const [blogKeyword, setBlogKeywords] = useState([]);
  const [facebooks, setfacebooks] = useState([]);
  const [instagrams, setInstagrams] = useState([]);
  const [twitters, setTwitters] = useState([]);
  const toaster = useToaster()
  const promptDashboard = async () => {
    const prompt_data = await adminPrivateRequest.get(
      `/api/admin_panel/save-prompt/`
    );
    console.log("prompt_data", prompt_data.data);
    
    
    
    
    
    
    
    
  };

  // get data for all prompt

  const getTitleData = async () => {
    try{
      const refd = await adminPrivateRequest.get(`/api/admin_panel/get-prompt/?prompt_type=title_creator`)
      // console.log("refd",refd.data[0].prompt)
      setDataPrompt(refd.data[0].prompt);
    }catch(err){
      console.log("err",err)
    }
  }
  const getDescriptionData = async () => {
    try{
      const refd = await adminPrivateRequest.get(`/api/admin_panel/get-prompt/?prompt_type=description_creator`)
      // console.log("refd",refd.data[0].prompt)
      setDescriptionPrompts(refd.data[0].prompt);
    }catch(err){
      console.log("err",err)
    }
  }

  const getTitleBlog = async () => {
    try{
      const refd = await adminPrivateRequest.get(`/api/admin_panel/get-prompt/?prompt_type=blog_title`)
      // console.log("refd",refd.data[0])
      setBlogTitle(refd.data[0].prompt);
    }catch(err){
      console.log("err",err)
    }
  }
  const getBlogPost = async () => {
    try{
      const refd = await adminPrivateRequest.get(`/api/admin_panel/get-prompt/?prompt_type=blog_post`)
      // console.log("refd",refd.data[0].prompt)
      setBlogPost(refd.data[0].prompt);
    }catch(err){
      console.log("err",err)
    }
  }
  const getMetaData = async () => {
    try{
      const refd = await adminPrivateRequest.get(`/api/admin_panel/get-prompt/?prompt_type=meta_data`)
      // console.log("refd",refd.data[0].prompt)
      setBlogMetaData(refd.data[0].prompt);
    }catch(err){
      console.log("err",err)
    }
  }
  const getKeywordsData = async () => {
    try{
      const refd = await adminPrivateRequest.get(`/api/admin_panel/get-prompt/?prompt_type=keywords`)
      // console.log("refd",refd.data[0].prompt)
      setBlogKeywords(refd.data[0].prompt);
    }catch(err){
      console.log("err",err)
    }
  }
  const getfacebookData = async () => {
    try{
      const refd = await adminPrivateRequest.get(`/api/admin_panel/get-prompt/?prompt_type=facebook`)
      // console.log("refd",refd.data[0].prompt)
    setfacebooks(refd.data[0].prompt);
    }catch(err){
      console.log("err",err)
    }
  }
  const getinstagramData = async () => {
    try{
      const refd = await adminPrivateRequest.get(`/api/admin_panel/get-prompt/?prompt_type=instagram`)
      // console.log("refd",refd.data[0].prompt)
      setInstagrams(refd.data[0].prompt);
    }catch(err){
      console.log("err",err)
    }
  }
  const getTwitterData = async () => {
    try{
      const refd = await adminPrivateRequest.get(`/api/admin_panel/get-prompt/?prompt_type=twitter`)
      // console.log("refd",refd.data[0].prompt)
      setTwitters(refd.data[0].prompt);
    }catch(err){
      console.log("err",err)
    }
  }
 

  // end of get data prompt


  const updateTitle = async () => {
    const patchs_title = {
      prompt_type: "title_creator",
      prompt: dataPrompt,
    };
    console.log("titledata", patchs_title);
    try {
      const patching = await adminPrivateRequest.patch(
        `/api/admin_panel/update-prompt`,
        patchs_title
      );
      console.log("added", patching);
     toaster.push(showSuccessNotification("Successfully Updated the title creater"))
    } catch (err) {
      console.log("titleErr", err);
      toaster.push(showErrorNotification("Something went wrong"))
    }
    getTitleData()
  };

  const updateDescription = async () => {
    const patchs_description = {
      prompt_type: "description_creator",
      prompt: descriptionPrompts,
    };
    console.log("descriptionPrompt", patchs_description);
    try {
      const patching = await adminPrivateRequest.patch(
        `/api/admin_panel/update-prompt`,
        patchs_description
      );
      console.log("added", patching);
      toaster.push(showSuccessNotification("Successfully Updated the description creater"))
    } catch (err) {
      console.log("titleErr", err);
      toaster.push(showErrorNotification("Something went wrong"))
    }
    promptDashboard()
  };

  const updateBlogTitle = async () => {
    const patch_blogTitle = {
      prompt_type: "blog_title",
      prompt: blogTitle,
    };
    console.log("patch_blogtitle", patch_blogTitle);
    try {
      const patching = await adminPrivateRequest.patch(
        `/api/admin_panel/update-prompt`,
        patch_blogTitle
      );
      console.log("added", patching);
      toaster.push(showSuccessNotification("Successfully Updated the Blog Title"))
    } catch (err) {
      console.log("titleErr", err);
      toaster.push(showErrorNotification("Something went wrong"))
    }
    getTitleBlog()
  };
  const updateBlogPost = async () => {
    const patch_blogPost = {
      prompt_type: "blog_post",
      prompt: blogPost,
    };
    console.log("patch_blogPost", patch_blogPost);
    try {
      const patching = await adminPrivateRequest.patch(
        `/api/admin_panel/update-prompt`,
        patch_blogPost
      );
      console.log("added", patching);
      toaster.push(showSuccessNotification("Successfully Updated the Blog Post"))
    } catch (err) {
      console.log("titleErr", err);
      toaster.push(showErrorNotification("Something went wrong"))
    }
    getBlogPost()
  };
  const updateMetaData = async () => {
    const patch_blogMeta = {
      prompt_type: "meta_data",
      prompt: blogMetaData,
    };
    console.log("patch_blogMeta", patch_blogMeta);
    try {
      const patching = await adminPrivateRequest.patch(
        `/api/admin_panel/update-prompt`,
        patch_blogMeta
      );
      console.log("added", patching);
      toaster.push(showSuccessNotification("Successfully Updated the MetaData"))
    } catch (err) {
      console.log("titleErr", err);
      toaster.push(showErrorNotification("Something went wrong"))
    }
    getMetaData()
  };

  const updateKeywords = async () => {
    const patch_blogKey = {
      prompt_type: "keywords",
      prompt: blogKeyword,
    };
    console.log("patch_blogKeywords", patch_blogKey);
    try {
      const patching = await adminPrivateRequest.patch(
        `/api/admin_panel/update-prompt`,
        patch_blogKey
      );
      console.log("added", patching);
      toaster.push(showSuccessNotification("Successfully Updated the Keyword"))
    } catch (err) {
      console.log("titleErr", err);
      toaster.push(showErrorNotification("Something went wrong"))
    }
    getKeywordsData()
  };
  const updateFaceBook = async () => {
    const patch_blogKey = {
      prompt_type: "facebook",
      prompt: facebooks,
    };
    console.log("patch_facebook", patch_blogKey);
    try {
      const patching = await adminPrivateRequest.patch(
        `/api/admin_panel/update-prompt`,
        patch_blogKey
      );
      console.log("added", patching);
      toaster.push(showSuccessNotification("Successfully Updated the Facebook"))
    } catch (err) {
      console.log("titleErr", err);
      toaster.push(showErrorNotification("Something went wrong"))
    }
    getfacebookData()
  };
  const updateInstagram = async () => {
    const patch_blogKey = {
      prompt_type: "instagram",
      prompt: instagrams,
    };
    console.log("patch_Insta", patch_blogKey);
    try {
      const patching = await adminPrivateRequest.patch(
        `/api/admin_panel/update-prompt`,
        patch_blogKey
      );
      console.log("added", patching);
      toaster.push(showSuccessNotification("Successfully Updated the Instagram"))
    } catch (err) {
      console.log("titleErr", err);
      toaster.push(showErrorNotification("Something went wrong"))
    }
    getinstagramData()
  };
  const updateTwitter = async () => {
    const patch_blogKey = {
      prompt_type: "twitter",
      prompt: twitters,
    };
    console.log("patch_twitter", patch_blogKey);
    try {
      const patching = await adminPrivateRequest.patch(
        `/api/admin_panel/update-prompt`,
        patch_blogKey
      );
      console.log("added", patching);
      toaster.push(showSuccessNotification("Successfully Updated the Twitter"))
    } catch (err) {
      console.log("titleErr", err);
      toaster.push(showErrorNotification("Something went wrong"))
    }
    getTwitterData()
  };

  useEffect(() => {
    promptDashboard();
    getTitleData();
    getDescriptionData();
    getTitleBlog();
    getBlogPost();
    getMetaData();
    getKeywordsData();
    getfacebookData();
    getinstagramData();
    getTwitterData();
  }, []);

  return (
    <div className="uk-margin-top row">
      <div className="uk-card uk-card-body uk-card-default col-11">
        <p
          className="uk-margin-bottom"
          style={{ fontSize: "15px", fontWeight: "600" }}
        >
          Title Generator Prompt
        </p>
        <textarea
          className="form-control"
          type="text"
          value={dataPrompt === undefined ? "" : dataPrompt}
          onChange={(e) => setDataPrompt(e.target.value)}
          placeholder="Enter Your Prompt here"
        />
        <button
          onClick={updateTitle}
          className="mt-1 f-button-neutral-2 w-button"
        >
          Update Title Prompt
        </button>
      </div>
      <div className="uk-margin-top uk-card uk-card-body uk-card-default col-11">
        <p
          className="uk-margin-bottom"
          style={{ fontSize: "15px", fontWeight: "600" }}
        >
          Description Generator Prompt
        </p>
        <textarea
          className="form-control"
          type="text"
          value={descriptionPrompts === undefined ? "" : descriptionPrompts}
          onChange={(e) => setDescriptionPrompts(e.target.value)}
          placeholder="Enter Your Prompt fro description here"
        />
        <button
          onClick={updateDescription}
          className="mt-1 f-button-neutral-2 w-button"
        >
          Update Description Prompt
        </button>
      </div>
      <div className="uk-margin-top uk-card uk-card-body uk-card-default col-11">
        <h3
          className="uk-margin-bottom"
          // style={{ fontSize: "15px", fontWeight: "600" }}
        >
          Blog Generator Prompt
        </h3>
        <div>
          <p
            className="uk-margin-top uk-margin-bottom"
            style={{ fontSize: "15px", fontWeight: "600" }}
          >
            Blog Title Prompt
          </p>
          <textarea
            className=" mt-1 form-control"
            type="text"
            value={blogTitle}
            onChange={(e) => setBlogTitle(e.target.value)}
            placeholder="Enter Your Prompt for blog here"
          />
          <button
            onClick={updateBlogTitle}
            className="mt-1 f-button-neutral-2 w-button"
          >
            Update Blog Title Prompt
          </button>
        </div>

        <div>
          <p
            className="uk-margin-top uk-margin-bottom"
            style={{ fontSize: "15px", fontWeight: "600" }}
          >
            Blog Post Prompt
          </p>
          <textarea
            className=" mt-1 form-control"
            type="text"
            value={blogPost}
            onChange={(e) => setBlogPost(e.target.value)}
            placeholder="Enter Your Prompt for blog here"
          />
          <button
            onClick={updateBlogPost}
            className="mt-1 f-button-neutral-2 w-button"
          >
            Update Blog Post Prompt
          </button>
        </div>
        <div>
          <p
            className="uk-margin-top uk-margin-bottom"
            style={{ fontSize: "15px", fontWeight: "600" }}
          >
            Blog Meta Data Prompt
          </p>
          <textarea
            className=" mt-1 form-control"
            type="text"
            value={blogMetaData}
            onChange={(e) => setBlogMetaData(e.target.value)}
            placeholder="Enter Your Prompt for blog here"
          />
          <button
            onClick={updateMetaData}
            className="mt-1 f-button-neutral-2 w-button"
          >
            Update Meta Data Prompt
          </button>
        </div>
        <div>
          <p
            className="uk-margin-top uk-margin-bottom"
            style={{ fontSize: "15px", fontWeight: "600" }}
          >
            Blog Keywords Prompt
          </p>
          <textarea
            className=" mt-1 form-control"
            type="text"
            value={blogKeyword}
            onChange={(e) => setBlogKeywords(e.target.value)}
            placeholder="Enter Your Prompt for blog here"
          />
          <button
            onClick={updateKeywords}
            className="mt-1 f-button-neutral-2 w-button"
          >
            Update Blog Keyword Prompt
          </button>
        </div>
      </div>
      <div className="uk-margin-top uk-card uk-card-body uk-card-default col-11">
        <h3
          className="uk-margin-bottom"
          // style={{ fontSize: "15px", fontWeight: "600" }}
        >
          Social Media Generator Prompt
        </h3>

        <div>
          <p
            className="uk-margin-top uk-margin-bottom"
            style={{ fontSize: "15px", fontWeight: "600" }}
          >
            Facebook Prompt
          </p>
          <textarea
            className="form-control"
            value={facebooks}
            onChange={(e) => setfacebooks(e.target.value)}
            type="text"
            placeholder="Enter Your Prompt for social media here"
          />
          <button
            onClick={updateFaceBook}
            className="mt-1 f-button-neutral-2 w-button"
          >
            Update Facebook Prompt
          </button>
        </div>
        <div>
          <p
            className="uk-margin-top uk-margin-bottom"
            style={{ fontSize: "15px", fontWeight: "600" }}
          >
            Instagram Prompt
          </p>
          <textarea
            className="form-control"
            value={instagrams}
            onChange={(e) => setInstagrams(e.target.value)}
            type="text"
            placeholder="Enter Your Prompt for social media here"
          />
          <button
            onClick={updateInstagram}
            className="mt-1 f-button-neutral-2 w-button"
          >
            Update Instagram Prompt
          </button>
        </div>
        <div>
          <p
            className="uk-margin-top uk-margin-bottom"
            style={{ fontSize: "15px", fontWeight: "600" }}
          >
            Twitter Prompt
          </p>
          <textarea
            className="form-control"
            value={twitters}
            onChange={(e) => setTwitters(e.target.value)}
            type="text"
            placeholder="Enter Your Prompt for social media here"
          />
          <button
            onClick={updateTwitter}
            className="mt-1 f-button-neutral-2 w-button"
          >
            Update Twitter Prompt
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminAi;
