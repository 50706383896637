import React from "react";
import ChartModal from "./ChartModal";
import CopyToClipboard from "react-copy-to-clipboard";
import { Tooltip, Whisper } from "rsuite";
import { useState } from "react";

const Question = ({ questions, chartCompare }) => {
  const [locate, setLocate] = useState("card1");
  const [open, setOpen] = useState(false);
  // console.log("chartCompare", chartCompare);
  const handleOpen = () => {
    setOpen(true);
    setLocate("chart2");
  };
  const handleClose = () => {
    setOpen(false);
    setLocate("card2");
  };
  return (
    <div>
      {questions.length !== 0 ? (
        <div className="row section-3-view">
          <h2
            className="card-title"
            style={{
              marginLeft: "12px",
              marginTop: "12px",
              marginBottom: "25px",
            }}
          >
            Questions
          </h2>
          <div
            className="d-flex gap-5 uk-margin-bottom"
            style={{
              width: "auto",
              backgroundColor: "#eff0f6",
              paddingTop: "12px",
              paddingBottom: "12px",
              paddingLeft: "12px",
              borderRadius: "10px",
              fontSize: "14px",
            }}
          >
            <span>Cards</span>
            <span style={{ cursor: "pointer" }} onClick={handleOpen}>
              Chart
            </span>
          </div>
          <div
            // className="containers-custom"
            className="row section-3-view"
            style={{ marginBottom: "10px" }}
          >
            {questions.map((res, index) =>
              res.rating.length === 0 ? (
                <div className="col-md-3 mt-2 mb-2" key={index}>
                  <div className="card">
                    <div className="card-body" style={{ padding: "35px" }}>
                      <div className="unlisting">
                        <h3 className="card-title">{res.name}</h3>
                        <div className="diveders"></div>
                        <ul className="list-unstyled">
                          <div className="row row-one vertical-align">
                            <li className="col-10 li_name forop">
                              No Results found for your query
                            </li>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-md-3 mt-2 mb-2" key={index}>
                  <div className="card">
                    <div className="card-body" style={{ padding: "35px" }}>
                      <div className="unlisting">
                        <h3 className="card-title">{res.name}</h3>
                        <div className="diveders"></div>
                        <ul className="list-unstyled">
                          <div
                            className="row row-one vertical-align"
                            style={{
                              fontSize: "10px",
                              marginBottom: "8px",
                            }}
                          >
                            <li className="col-10 li_name4">Key</li>
                            <div className="col-2 text-end">copy</div>
                          </div>
                          {res.rating.map((result, index) => (
                            <div
                              key={index}
                              className="row row-one vertical-align mb-2"
                            >
                              <li className="col-10 li_name">{result}</li>
                              <div className="col-2 text-end">
                                <CopyToClipboard text={result}>
                                  <Whisper
                                    placement="top"
                                    controlId="control-id-click"
                                    trigger="click"
                                    speaker={<Tooltip>Copied</Tooltip>}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="black"
                                      strokeWidth="2.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-copy"
                                    >
                                      <rect
                                        x="9"
                                        y="9"
                                        width="13"
                                        height="13"
                                        rx="2"
                                        ry="2"
                                      ></rect>
                                      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                    </svg>
                                  </Whisper>
                                </CopyToClipboard>
                              </div>
                            </div>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          <ChartModal
            headers="QUESTIONS"
            open={open}
            chartPrepo={chartCompare}
            locate2={locate}
            handleClose={handleClose}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Question;
