import * as actionTypes from "../actionTypes";

export const fetchSubscriptionDetailsStart = () => {
  // console.log("userDetails", userDetails);
  return {
    type: actionTypes.FETCH_SUBSCRIPTION_DETAILS_START,
    // payload: userDetails,
  };
};
export const fetchSubscriptionDetailsSuccess = (data) => {
  // console.log("userDetails", data);
  return {
    type: actionTypes.FETCH_SUBSCRIPTION_DETAILS_SUCCESS,
    payload: data,
  };
};
export const fetchSubscriptionDetailsFailed = (error) => {
  // console.log("userDetails", error);
  return {
    type: actionTypes.FETCH_SUBSCRIPTION_DETAILS_FAILED,
    payload: error,
  };
};