import * as actionTypes from "../actionTypes";
const inititalState = {
  isDataFetch: false,
  fetchId: {},
  isError: false,
  error: {},
};

export const channelIdDataReducer = (state = inititalState, action) => {
    switch(action.type){
        case actionTypes.FETCH_CHANNEL_ID_DATA_START:
      return {
        ...state,
        isDataFetch: true,
        isError: false,
        error: {},
      };
      case actionTypes.FETCH_CHANNEL_ID_DATA_SUCCESS:
          return{
            ...state,
            isDataFetch: false,
            fetchId: action.payload,
            isError: false,
            error: {},
          }
          case actionTypes.FETCH_CHANNEL_ID_DATA_FAILED:
            return {
              ...state,
              isError: true,
              isDataFetch: false,
              error: action.payload,
            };
          default:
            return state;

    }

}