import { Field, Form, Formik } from "formik";
import React from "react";
import { useState } from "react";
import { country } from "./Country";
import style from "./VideoContentGenerator.module.css";
import AIheader from "./AIheader";
import { AiHeaders } from "./StringStore";

const VideoContentGenerator = () => {
  // eslint-disable-next-line
  const [tone, setTone] = useState("Formal");

  const languages = [
    { id: "1", name: "English", code: "en" },
    { id: "2", name: "Français", code: "fr" },
    { id: "2", name: "Español", code: "es" },
    { id: "3", name: "Deutsch", code: "de" },
    { id: "4", name: "Português", code: "pt" },
    { id: "5", name: "Italiano", code: "it" },
    { id: "6", name: "Nederlands", code: "nl" },
    { id: "7", name: "Polski", code: "pl" },
    { id: "8", name: "Dansk", code: "da" },
    { id: "9", name: "Svenska", code: "sv" },
    { id: "10", name: "Norsk", code: "no" },
    { id: "11", name: "Pусский", code: "ru" },
    { id: "12", name: "Slovenčina", code: "el" },
    { id: "13", name: "Suomi", code: "fi" },
    { id: "14", name: "Čeština", code: "cs" },
    { id: "15", name: "Hindi", code: "hi" },
  ];

  const initialValues = {
    user_input: "",
  };

  return (
    <>
      <div className={style["div-block-10"]}>
        <AIheader
          title={AiHeaders.vititle}
          description={AiHeaders.viblueDesc}
          lasttext={AiHeaders.vilast}
        />
        <div className={style["brix---mg-bottom-16px-4"]}>
          <div className={style["form-10"]}>
            <div>
              <select
                name="country_code"
                className={`${style["select-input"]} form-select`}
                // value={formik.values.country_code}
                // onChange={formik.handleChange}
                onChange={(e) => setTone(e.target.value)}
                style={{ width: "100%", padding: "12px 16px" }}
                //   style={{
                //     height: "49px",
                //     borderRadius: "10px",
                //     border: "1px solid #fff",
                //     // width: "auto",
                //     boxShadow: "0 2px 7px 0 rgba(20, 20, 43, 0.08)",
                //     transition:
                //       "box-shadow 300ms ease, color 300ms ease, border-color 300ms ease",
                //   }}
                //   className="form-select"
                aria-label="Default select example"
              >
                {country.map((res, index) => (
                  <option key={index} value={res.code}>
                    {res.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <select
                name="country_code"
                // value={formik.values.country_code}
                // onChange={formik.handleChange}
                onChange={(e) => setTone(e.target.value)}
                style={{ width: "100%", padding: "12px 16px" }}
                //   style={{
                //     height: "49px",
                //     borderRadius: "10px",
                //     border: "1px solid #fff",
                //     // width: "auto",
                //     boxShadow: "0 2px 7px 0 rgba(20, 20, 43, 0.08)",
                //     transition:
                //       "box-shadow 300ms ease, color 300ms ease, border-color 300ms ease",
                //   }}
                className={`${style["select-input"]} form-select`}
                aria-label="Default select example"
              >
                {languages.map((res, index) => (
                  <option key={index} value={res.code}>
                    {res.name}
                  </option>
                ))}
              </select>
            </div>
            <div className={`${style["brix---position-relative-2"]}`}>
              <Formik
                initialValues={initialValues}
                // validationSchema={validationSchema}
                onSubmit={async (values, { resetForm }) => {
                  //   await addtext(values);

                  resetForm();
                }}
              >
                {(formik) => {
                  return (
                    <Form>
                      {/* <div className={style['brix---position-relative-2']}> */}
                      <Field
                        type="text"
                        // className="brix---input-large-button-inside w-input"
                        className={`${style["brix---input-large-button-inside"]} ${style["w-input"]}`}
                        autoFocus={true}
                        maxLength="256"
                        name="user_input"
                        style={{ border: "none" }}
                        data-name="BRIX - Footer Email - V16"
                        placeholder="Let us know what is your video about"
                        id="BRIX-Footer-Email-V16"
                      />
                      {formik.values.user_input.length !== 0 ? (
                        <input
                          type="submit"
                          //   style={{ border: "none" }}
                          data-wait="Please wait..."
                          className={`${style["brix---btn-primary-inside-input"]} ${style["w-button"]}`}
                          style={{
                            backgroundColor: "#4a3aff",
                            color: "#ffffff",
                          }}
                          value="Generate"
                        />
                      ) : (
                        <input
                          type="button"
                          style={{
                            border: "none",
                            backgroundColor: "grey",
                            color: "#ffffff",
                          }}
                          data-wait="Please wait..."
                          className={`${style["brix---btn-primary-inside-input"]} ${style["w-button"]}`}
                          value="Generate"
                        />
                      )}
                      {/* </div> */}
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row gap-lg-4 gap-2"
        style={{ maxWidth: "1150px", marginLeft: "auto", marginRight: "auto" }}
      >
        <div className="row gap-4 col-lg-6 col-12">
          <div className="col-12 custom-background" style={{height:"30vh"}}>
            <h5 style={{marginBottom:"1rem"}}>Select Your Title</h5>
            <div className="div-block-23" style={{padding:"15px 20px"}}>
              {
                // eslint-disable-next-line
              }{" "}
              <ul className="list-5">
                <li>
                  <div className="text-block-16">Title1</div>
                </li>
                <li>
                  <div className="text-block-16">Title2</div>
                </li>
                <li>
                  <div className="text-block-16">Title3</div>
                </li>
                <li>
                  <div className="text-block-16">Title4</div>
                </li>
                <li>
                  <div className="text-block-16">Title5</div>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="col-12 custom-background"
            // style={{ height:"50%" }}
            style={{ maxHeight: "50vh", overflow: "scroll" }}
          >
            <div>
              <h5 className="m-1">Description</h5>
              <div className="markdown">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum. It is a long established fact that a reader will be
                distracted by the readable content of a page when looking at its
                layout. The point of using Lorem Ipsum is that it has a
                more-or-less normal distribution of letters, as opposed to using
                'Content here, content here', making it look like readable
                English. Many desktop publishing packages and web page editors
                now use Lorem Ipsum as their default model text, and a search
                for 'lorem ipsum' will uncover many web sites still in their
                infancy. Various versions have evolved over the years, sometimes
                by accident, sometimes on purpose (injected humour and the
                like).
              </div>
            </div>
          </div>
          <div
            className="col-12 custom-background"
            // style={{ height: "20%" }}
            style={{ maxHeight: "20vh", overflow: "scroll" }}
          >
            <h5>Keywords</h5>
            <div className="markdown">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </div>
          </div>
        </div>
        <div className="row gap-4 col-lg-6 col-12">
          <div
            className="col-12 custom-background"
            style={{ height:"100vh", overflowY: "scroll" }}
          >
            <h5>Video Narration</h5>
            <div className="markdown">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Contrary to popular belief, Lorem Ipsum is not simply
              random text. It has roots in a piece of classical Latin literature
              from 45 BC, making it over 2000 years old. Richard McClintock, a
              Latin professor at Hampden-Sydney College in Virginia, looked up
              one of the more obscure Latin words, consectetur, from a Lorem
              Ipsum passage, and going through the cites of the word in
              classical literature, discovered the undoubtable source. Lorem
              Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
              Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero,
              written in 45 BC. This book is a treatise on the theory of ethics,
              very popular during the Renaissance. The first line of Lorem
              Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in
              section 1.10.32. The standard chunk of Lorem Ipsum used since the
              1500s is reproduced below for those interested. Sections 1.10.32
              and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are
              also reproduced in their exact original form, accompanied by
              English versions from the 1914 translation by H. Rackham. There
              are many variations of passages of Lorem Ipsum available, but the
              majority have suffered alteration in some form, by injected
              humour, or randomised words which don't look even slightly
              believable. If you are going to use a passage of Lorem Ipsum, you
              need to be sure there isn't anything embarrassing hidden in the
              middle of text. All the Lorem Ipsum generators on the Internet
              tend to repeat predefined chunks as necessary, making this the
              first true generator on the Internet. It uses a dictionary of over
              200 Latin words, combined with a handful of model sentence
              structures, to generate Lorem Ipsum which looks reasonable. The
              generated Lorem Ipsum is therefore always free from repetition,
              injected humour, or non-characteristic words etc. There are many
              variations of passages of Lorem Ipsum available, but the majority
              have suffered alteration in some form, by injected humour, or
              randomised words which don't look even slightly believable. If you
              are going to use a passage of Lorem Ipsum, you need to be sure
              there isn't anything embarrassing hidden in the middle of text.
              All the Lorem Ipsum generators on the Internet tend to repeat
              predefined chunks as necessary, making this the first true
              generator on the Internet. It uses a dictionary of over 200 Latin
              words, combined with a handful of model sentence structures, to
              generate Lorem Ipsum which looks reasonable. The generated Lorem
              Ipsum is therefore always free from repetition, injected humour,
              or non-characteristic words etc.
              Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
            </div>
          </div>
          
        </div>
        <div className="row gap-4 col-lg-12 col-12">
        <div
            className="col-12 custom-background"
            // style={{ height: "30vh" }}
          >
            <h5>Audio</h5>
            <div className="markdown">
              <audio controls>
                <source
                  src="http://streaming.tdiradio.com:8000/house.mp3"
                  type="audio/mpeg"
                />
                Your browser does not support the audio element.
              </audio>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoContentGenerator;
