import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { NavDropdown,Dropdown } from "react-bootstrap";
import { Navbar, Container } from "react-bootstrap";
import {
  NavLink,
  Outlet,
  useNavigate,
  Link,
  useLocation,
} from "react-router-dom";
import { Dropdown, Tooltip, Whisper } from "rsuite";
// import AdminIcon from "@rsuite/icons/Admin";
import "./App.css";
import Footer from "./Components/Users/Pages/Footer";
import { priceTag } from "./Components/Users/Pages/StringStore";
import CreditsModal from "./Components/Users/Private/CreditsModal";
import { channelid, fetchChannelStart } from "./redux/actions/channelAction";
import {
  fetchAdditionalCreditsStart,
  fetchTotalCreditsStart,
} from "./redux/actions/creditsActions";
import { logoutAction } from "./redux/actions/loginActions";
import { fetchModuleStart } from "./redux/actions/moduleAccessAction";
import { fetchSubscriptionDetailsStart } from "./redux/actions/subscriptionDetailsActions";
import { fetchChannelIdDataStart } from "./redux/actions/channelIdDataAction";
import SupportSystem from "./Components/Users/Pages/SupportSystem";
// import styles from "./Components/Users/Private/my-style.module.css";

const Navbars = () => {
  // const [hovers, setHovers] = useState(false);
  // const [rotates, setRoatets] = useState("");
  const [inner, setInner] = useState(window.innerWidth > 980);
  const [tryOpen, setTryOpen] = useState(false);
  const { channelidData } = useSelector((state) => state.channels);
  const { accessData } = useSelector((state) => state.accessModule);
  const { totalCreditsData, additionalCreditsData } = useSelector(
    (state) => state.credits
  );
  const { fetchId } = useSelector((state) => state.channelIdData);
  const { subscribeData } = useSelector((state) => state.subscribe);
  // console.log("subss",subscribeData)
  // const [droped, setDroped] = useState("down");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logedout = () => {
    dispatch(logoutAction());
    navigate("/login");
  };

  const handleOpenTry = () => {
    setTryOpen(true);
  };

  const handleCloseTry = () => {
    setTryOpen(false);
  };
  // console.log('hover', hovers,droped)
  // const showdrop = () => {
  //   // setHovers(true);
  //   setRoatets("svgRotate");
  //   // setDroped("up");
  // };
  // const closedrop = () => {
  //   // setHovers(false);
  //   setRoatets("svgRotates");
  //   // setDroped("down");
  // };

  const switching = (result) => {
    dispatch(channelid(result));
    localStorage.setItem("fav", result);
    // localStorage.setItem("secfav",result)
  };

  // const accessing = async () => {
  //   const goku = await privateRequest.get(`/api/payments/module-access/`);
  //   console.log("goku",goku)
  // }
  const updateMedia = () => {
    setInner(window.innerWidth > 980);
  };

  // const handleHover = (e) => {
  //   // console.log("clickking",e)
  //   setToggle(!toggle);
  // };

  useEffect(() => {
    dispatch(fetchChannelIdDataStart());
    dispatch(fetchSubscriptionDetailsStart());
    dispatch(fetchChannelStart());
    dispatch(fetchModuleStart());
    dispatch(fetchAdditionalCreditsStart());
    dispatch(fetchTotalCreditsStart());
  }, [fetchId]);
  // console.log("fetchid", fetchId);

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  // useEffect(() => {
  // // localStorage.setItem("fav", firstChannel);
  // dispatch(channelid(firstChannel));
  // localStorage.setItem("secfav",firstChannel)
  // },[firstChannel])

  // console.log("channeldata", channelidData)
  // console.log("accessData",accessData)

  const triggerImage = (props, ref) => {
    return (
      <img
        {...props}
        ref={ref}
        alt="logo"
        src="/images/YTubeBooster_instagramLogo_12x.png"
        className="image-yt me-5"
      />
    );
  };

  const renderDrop = (props, ref) => {
    return (
      <div className="nav-link" {...props} ref={ref}>
        <span style={{ fontWeight: "700", color: "#475467" }}>
          Creator Toolkit
        </span>
        <svg
          width="20px"
          height="20px"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0" fill="none" width="24" height="24" />

          <g>
            <path d="M20 9l-8 8-8-8 1.414-1.414L12 14.172l6.586-6.586" />
          </g>
        </svg>
      </div>
    );
  };

  return (
    <div>
      <SupportSystem />
      {/* <nav className="navbar navbar-expand-lg navbar-light bg-light"> */}
      <Navbar expand="lg" className="bg-body-tertiary">
        {/* <div className="container-fluid uui-navbar08_container"> */}
        <Container fluid>
          <div className="uui-navbar08_logo-link w-nav-brand">
            <div className="uui-logo_component-4 ms-2" style={{height:"5rem"}}>
              <img
                src="/images/ytb_beta_logo.svg"
                loading="lazy"
                alt="Untitled UI logotext"
                className="uui-logo_logotype-4"
              />
              {/* <img
                src="/images/untitled-ui-logo.png"
                loading="lazy"
                alt="Logo"
                className="uui-logo_image-4"
              /> */}
            </div>
          </div>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            {/* <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}
            {/* <Nav
            className="me-auto my-2 my-lg-0"
            // style={{ maxHeight: '500px' }}
            navbarScroll
          > */}
            {/* <div className="collapse navbar-collapse" id="navbarSupportedContent"> */}
            <ul
              className="navbar-nav mb-2 mb-lg-0"
              // style={{ marginLeft: "50px" }}
              style={{
                fontFamily: "Exo, sans-serif",
                fontSize: "0.9rem",
                color: "#475467",
                marginRight: "auto",
                marginLeft: "auto",
              }}
            >
              <li className="nav-item ms-4">
                <NavLink
                  className="nav-link"
                  to="/dashboard"
                  style={({ isActive }) => ({
                    color: isActive ? "#ffae14" : "#475467",
                    fontWeight: "700",
                    // background: isActive ? '#7600dc' : undefined,
                  })}
                >
                  Channel Overview
                </NavLink>
              </li>
              {accessData === null ? (
                ""
              ) : accessData.tag_research === false ? (
                ""
              ) : (
                <li className="nav-item ms-4">
                  <NavLink
                    className="nav-link"
                    to="/keyword-research"
                    style={({ isActive }) => ({
                      color: isActive ? "#ffae14" : "#475467",
                      fontWeight: "700",
                      // background: isActive ? '#7600dc' : undefined,
                    })}
                  >
                    Keywords Scout
                  </NavLink>
                </li>
              )}

              <li className="nav-item ms-4">
                <Dropdown
                  style={{ backgroundColor: "#fff" }}
                  renderToggle={renderDrop}
                >
                  <Link
                    className="profile-links uui-navbar08_dropdown-link-list"
                    to="/aigenerator"
                  >
                    <Dropdown.Item className="d-flex gap-2">
                      <div className="uui-navbar08_icon-wrapper">
                        <div className="uui-icon-1x1-xsmall-3 w-embed">
                          <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20 19.5V16.5H7C5.34315 16.5 4 17.8431 4 19.5M8.8 22.5H16.8C17.9201 22.5 18.4802 22.5 18.908 22.282C19.2843 22.0903 19.5903 21.7843 19.782 21.408C20 20.9802 20 20.4201 20 19.3V5.7C20 4.57989 20 4.01984 19.782 3.59202C19.5903 3.21569 19.2843 2.90973 18.908 2.71799C18.4802 2.5 17.9201 2.5 16.8 2.5H8.8C7.11984 2.5 6.27976 2.5 5.63803 2.82698C5.07354 3.1146 4.6146 3.57354 4.32698 4.13803C4 4.77976 4 5.61984 4 7.3V17.7C4 19.3802 4 20.2202 4.32698 20.862C4.6146 21.4265 5.07354 21.8854 5.63803 22.173C6.27976 22.5 7.11984 22.5 8.8 22.5Z"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div className="uui-navbar08_item-right">
                        <div className="uui-navbar08_item-heading">
                          AI Generator
                        </div>
                        <div className="uui-text-size-small-4 hide-mobile-landscape">
                          The latest industry news, updates and info.
                        </div>
                      </div>
                    </Dropdown.Item>
                  </Link>
                  <Link
                    to="/channel-analyse"
                    className="profile-links uui-navbar08_dropdown-link-list"
                  >
                    <Dropdown.Item className="d-flex gap-2">
                      <div className="uui-navbar08_icon-wrapper">
                        <div className="uui-icon-1x1-xsmall-3 w-embed">
                          <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.5 22.5V17.5M4.5 7.5V2.5M2 5H7M2 20H7M13 3.5L11.2658 8.00886C10.9838 8.74209 10.8428 9.10871 10.6235 9.41709C10.4292 9.6904 10.1904 9.92919 9.91709 10.1235C9.60871 10.3428 9.24209 10.4838 8.50886 10.7658L4 12.5L8.50886 14.2342C9.24209 14.5162 9.60871 14.6572 9.91709 14.8765C10.1904 15.0708 10.4292 15.3096 10.6235 15.5829C10.8428 15.8913 10.9838 16.2579 11.2658 16.9911L13 21.5L14.7342 16.9911C15.0162 16.2579 15.1572 15.8913 15.3765 15.5829C15.5708 15.3096 15.8096 15.0708 16.0829 14.8765C16.3913 14.6572 16.7579 14.5162 17.4911 14.2342L22 12.5L17.4911 10.7658C16.7579 10.4838 16.3913 10.3428 16.0829 10.1235C15.8096 9.92919 15.5708 9.6904 15.3765 9.41709C15.1572 9.10871 15.0162 8.74209 14.7342 8.00886L13 3.5Z"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div className="uui-navbar08_item-right">
                        <div className="uui-navbar08_item-heading">
                          Channel Review
                        </div>
                        <div className="uui-text-size-small-4 hide-mobile-landscape">
                          Learn how our customers are making big changes.
                        </div>
                      </div>
                    </Dropdown.Item>
                  </Link>

                  <Link
                    to="/ask-ytubebooster"
                    className="profile-links uui-navbar08_dropdown-link-list"
                  >
                    <Dropdown.Item className="d-flex gap-2">
                      <div className="uui-navbar08_icon-wrapper">
                        <div className="uui-icon-1x1-xsmall-3 w-embed">
                          <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M9.5 9.46533C9.5 8.98805 9.5 8.74941 9.59974 8.61618C9.68666 8.50007 9.81971 8.42744 9.96438 8.4171C10.1304 8.40525 10.3311 8.53429 10.7326 8.79239L15.4532 11.8271C15.8016 12.051 15.9758 12.163 16.0359 12.3054C16.0885 12.4298 16.0885 12.5702 16.0359 12.6946C15.9758 12.837 15.8016 12.949 15.4532 13.1729L10.7326 16.2076C10.3311 16.4657 10.1304 16.5948 9.96438 16.5829C9.81971 16.5726 9.68666 16.4999 9.59974 16.3838C9.5 16.2506 9.5 16.012 9.5 15.5347V9.46533Z"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div className="uui-navbar08_item-right">
                        <div className="uui-navbar08_item-heading">
                          AskYtubeBooster
                        </div>
                        <div className="uui-text-size-small-4 hide-mobile-landscape">
                          Get up and running on new features and techniques.
                        </div>
                      </div>
                    </Dropdown.Item>
                  </Link>
                </Dropdown>

                {/* <div
                  data-hover="true"
                  data-w-id="acd63ba1-fda5-2354-1c66-59b5a5785f94"
                  className="uui-navbar08_menu-dropdown w-dropdown"
                >
                  <div
                    style={{ paddingTop: "7px" }}
                    onClick={(e) => handleHover(e)}
                    className={`uui-navbar08_dropdown-toggle w-dropdown-toggle open ${
                      toggle === true ? "w--open" : ""
                    }`}
                  >
                    <div className="uui-dropdown-icon-4 w-embed">
                      <svg
                        width="20"
                        height="20"
                        viewbox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 7.5L10 12.5L15 7.5"
                          stroke="CurrentColor"
                          stroke-width="1.67"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </div>
                    <div
                      className="text-block-6"
                      style={{
                        fontWeight: "700",
                        color: "#475467",
                        fontSize: "14.5px",
                      }}
                    >
                      Features
                    </div>
                  </div>
                  <nav
                    data-w-id="acd63ba1-fda5-2354-1c66-59b5a5785f99"
                    className={`happed uui-navbar08_dropdown-list w-dropdown-list ${
                      toggle === true ? "w--open" : ""
                    }`}
                  >
                    <div className="uui-navbar08_dropdown-content">
                      <div className="uui-navbar08_dropdown-link-list">
                        <Link
                          to="/aigenerator"
                          className="uui-navbar08_dropdown-link w-inline-block"
                        >
                          <div className="uui-navbar08_icon-wrapper">
                            <div className="uui-icon-1x1-xsmall-3 w-embed">
                              <svg
                                width="24"
                                height="25"
                                viewbox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M20 19.5V16.5H7C5.34315 16.5 4 17.8431 4 19.5M8.8 22.5H16.8C17.9201 22.5 18.4802 22.5 18.908 22.282C19.2843 22.0903 19.5903 21.7843 19.782 21.408C20 20.9802 20 20.4201 20 19.3V5.7C20 4.57989 20 4.01984 19.782 3.59202C19.5903 3.21569 19.2843 2.90973 18.908 2.71799C18.4802 2.5 17.9201 2.5 16.8 2.5H8.8C7.11984 2.5 6.27976 2.5 5.63803 2.82698C5.07354 3.1146 4.6146 3.57354 4.32698 4.13803C4 4.77976 4 5.61984 4 7.3V17.7C4 19.3802 4 20.2202 4.32698 20.862C4.6146 21.4265 5.07354 21.8854 5.63803 22.173C6.27976 22.5 7.11984 22.5 8.8 22.5Z"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </div>
                          </div>
                          <div className="uui-navbar08_item-right">
                            <div className="uui-navbar08_item-heading">
                              AI Generator
                            </div>
                            <div className="uui-text-size-small-4 hide-mobile-landscape">
                              The latest industry news, updates and info.
                            </div>
                          </div>
                        </Link>
                        <Link
                        to="/channel-analyse"
                          className="uui-navbar08_dropdown-link w-inline-block"
                        >
                          <div className="uui-navbar08_icon-wrapper">
                            <div className="uui-icon-1x1-xsmall-3 w-embed">
                              <svg
                                width="24"
                                height="25"
                                viewbox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4.5 22.5V17.5M4.5 7.5V2.5M2 5H7M2 20H7M13 3.5L11.2658 8.00886C10.9838 8.74209 10.8428 9.10871 10.6235 9.41709C10.4292 9.6904 10.1904 9.92919 9.91709 10.1235C9.60871 10.3428 9.24209 10.4838 8.50886 10.7658L4 12.5L8.50886 14.2342C9.24209 14.5162 9.60871 14.6572 9.91709 14.8765C10.1904 15.0708 10.4292 15.3096 10.6235 15.5829C10.8428 15.8913 10.9838 16.2579 11.2658 16.9911L13 21.5L14.7342 16.9911C15.0162 16.2579 15.1572 15.8913 15.3765 15.5829C15.5708 15.3096 15.8096 15.0708 16.0829 14.8765C16.3913 14.6572 16.7579 14.5162 17.4911 14.2342L22 12.5L17.4911 10.7658C16.7579 10.4838 16.3913 10.3428 16.0829 10.1235C15.8096 9.92919 15.5708 9.6904 15.3765 9.41709C15.1572 9.10871 15.0162 8.74209 14.7342 8.00886L13 3.5Z"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </div>
                          </div>
                          <div className="uui-navbar08_item-right">
                            <div className="uui-navbar08_item-heading">
                            Channel Review
                            </div>
                            <div className="uui-text-size-small-4 hide-mobile-landscape">
                              Learn how our customers are making big changes.
                            </div>
                          </div>
                        </Link>
                        <Link
                        to="/ask-ytubebooster"
                          className="uui-navbar08_dropdown-link w-inline-block"
                        >
                          <div className="uui-navbar08_icon-wrapper">
                            <div className="uui-icon-1x1-xsmall-3 w-embed">
                              <svg
                                width="24"
                                height="25"
                                viewbox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M9.5 9.46533C9.5 8.98805 9.5 8.74941 9.59974 8.61618C9.68666 8.50007 9.81971 8.42744 9.96438 8.4171C10.1304 8.40525 10.3311 8.53429 10.7326 8.79239L15.4532 11.8271C15.8016 12.051 15.9758 12.163 16.0359 12.3054C16.0885 12.4298 16.0885 12.5702 16.0359 12.6946C15.9758 12.837 15.8016 12.949 15.4532 13.1729L10.7326 16.2076C10.3311 16.4657 10.1304 16.5948 9.96438 16.5829C9.81971 16.5726 9.68666 16.4999 9.59974 16.3838C9.5 16.2506 9.5 16.012 9.5 15.5347V9.46533Z"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </div>
                          </div>
                          <div className="uui-navbar08_item-right">
                            <div className="uui-navbar08_item-heading">
                            AskYtubeBooster
                            </div>
                            <div className="uui-text-size-small-4 hide-mobile-landscape">
                              Get up and running on new features and techniques.
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </nav>
                </div> */}
              </li>
              {accessData === null ? (
                ""
              ) : accessData.tag_extractor === false ? (
                ""
              ) : (
                <li className="nav-item ms-4">
                  <NavLink
                    className="nav-link"
                    to="/tag-extractor"
                    style={({ isActive }) => ({
                      color: isActive ? "#ffae14" : "#475467",
                      fontWeight: "700",
                      // background: isActive ? '#7600dc' : undefined,
                    })}
                  >
                    Tag Grabber
                  </NavLink>
                </li>
              )}

              <li className="nav-item ms-4">
                <NavLink
                  className="nav-link"
                  to="/notes"
                  style={({ isActive }) => ({
                    color: isActive ? "#ffae14" : "#475467",
                    fontWeight: "700",
                    // background: isActive ? '#7600dc' : undefined,
                  })}
                >
                  Creator Journal
                </NavLink>
              </li>
              {/* <li className="nav-item ms-4">
                <NavLink
                  className="nav-link"
                  to="/channel-analyse"
                  style={({ isActive }) => ({
                    color: isActive ? "#ffae14" : "#475467",
                    fontWeight: "700",
                  })}
                >
                  Review
                </NavLink>
              </li> */}
              {/* <li className="nav-item ms-4">
                <NavLink
                  className="nav-link"
                  to="/notes"
                  style={({ isActive }) => ({
                    color: isActive ? "#ffae14" : "#475467",
                    fontWeight: "700",
                  })}
                >
                  Notes
                </NavLink>
              </li> */}
              {/* <li className="nav-item ms-4">
                <p
                  className="nav-link"
                  onClick={logedout}
                  style={{
                    color: "#475467",
                    fontWeight: "700",
                    cursor: "pointer",
                    // background: isActive ? '#7600dc' : undefined,
                  }}
                >
                  Logout
                </p>
              </li> */}
            </ul>
            {/* </Nav> */}
            <div className="d-flex">
              {subscribeData === undefined ? (
                ""
              ) : subscribeData.plan_name === undefined ? (
                ""
              ) : subscribeData.plan_name === priceTag.acornM ? (
                ""
              ) : subscribeData === undefined ? (
                ""
              ) : subscribeData.plan_name === undefined ? (
                ""
              ) : subscribeData.plan_name === priceTag.acornY ? (
                ""
              ) : (
                <div className="uk-margin-right">
                  <div className="credits">
                    <div className="text-block-39 d-flex gap-1">
                      <span>Credits</span>
                      <span>
                        <Whisper
                          placement="bottomEnd"
                          controlId="control-id-click"
                          trigger="hover"
                          speaker={<Tooltip>credits</Tooltip>}
                        >
                          <svg
                            style={{
                              marginBottom: "5px",
                              color: "#fff",
                              cursor: "pointer",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            fill="#fff"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-info"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="12" y1="16" x2="12" y2="12"></line>
                            <line x1="12" y1="8" x2="12.01" y2="8"></line>
                          </svg>
                        </Whisper>
                      </span>
                    </div>
                    <div className="text-block-38">
                      {totalCreditsData === undefined
                        ? ""
                        : totalCreditsData.credits === undefined
                        ? ""
                        : totalCreditsData.credits}
                    </div>
                  </div>
                </div>
              )}
              <div className="">
                <div
                  className="additional_credits"
                  onClick={handleOpenTry}
                  style={{ cursor: "pointer" }}
                >
                  <div className="text-block-39 d-flex gap-1">
                    Additional Credits
                    <span>
                      <Whisper
                        placement="bottomEnd"
                        controlId="control-id-click"
                        trigger="hover"
                        speaker={<Tooltip>buy credits</Tooltip>}
                      >
                        <svg
                          style={{
                            marginBottom: "5px",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                          fill="#fff"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-info"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="12" y1="16" x2="12" y2="12"></line>
                          <line x1="12" y1="8" x2="12.01" y2="8"></line>
                        </svg>
                      </Whisper>
                    </span>
                  </div>
                  <div className="text-block-38">
                    {additionalCreditsData === undefined
                      ? ""
                      : additionalCreditsData.credits === undefined
                      ? ""
                      : additionalCreditsData.credits}
                  </div>
                </div>
              </div>
            </div>

            {/* </div> */}

            <div style={{ marginLeft: "auto" }}>
              <div>
                {inner ? (
                  <Dropdown
                    style={{ marginLeft: "auto" }}
                    placement="leftStart"
                    renderToggle={triggerImage}
                  >
                    {useLocation().pathname.includes("/optimize")
                      ? ""
                      : channelidData.map(
                          ({
                            channel_logo,
                            channel_name,
                            id,
                            multichannel,
                          }) => (
                            <Dropdown.Item
                              key={id}
                              onClick={() => switching(multichannel)}
                            >
                              <img
                                alt="header"
                                style={{
                                  borderRadius: "40%",
                                  width: "25px",
                                  height: "23px",
                                }}
                                src={channel_logo}
                              />
                              &nbsp; {channel_name}
                            </Dropdown.Item>
                          )
                        )}
                    {/* <Dropdown.Item>
                    <img
                    alt="header"
                      style={{
                        borderRadius: "40%",
                        width: "25px",
                        height: "23px",
                      }}
                      src="/images/YTubeBooster_instagramLogo_12x.png"
                    />
                    &nbsp; Channel Two
                  </Dropdown.Item> */}
                    <Dropdown.Separator />
                    <Link className="profile-links" to="/profile">
                      <Dropdown.Item>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="19"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-user profile-svg"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                          <circle cx="12" cy="7" r="4"></circle>
                        </svg>
                        Profile
                      </Dropdown.Item>
                    </Link>
                    <Link className="profile-links" to="/channel-details">
                      <Dropdown.Item>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="19"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-user profile-svg"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                          <circle cx="12" cy="7" r="4"></circle>
                        </svg>
                        Channel Details
                      </Dropdown.Item>
                    </Link>
                    <Link className="profile-links" to="/plan-info">
                      <Dropdown.Item>
                        <i
                          className="fa-solid fa-receipt"
                          style={{
                            // color: "blueviolet",
                            marginLeft: "3px",
                            marginRight: "8px",
                            width: "17px",
                            height: "17px",
                          }}
                        ></i>
                        Upgrade/Downgrade Plan
                      </Dropdown.Item>
                    </Link>
                    <Link to="/transition-invoice">
                      <Dropdown.Item>
                        <i
                          className="fa-solid fa-receipt"
                          style={{
                            // color: "blueviolet",
                            marginLeft: "3px",
                            marginRight: "8px",
                            width: "17px",
                            height: "17px",
                          }}
                        ></i>
                        Transactions & Invoices
                      </Dropdown.Item>
                    </Link>
                    <Link to="/global-setting">
                      <Dropdown.Item>
                        <i
                          className="fa-solid fa-receipt"
                          style={{
                            // color: "blueviolet",
                            marginLeft: "3px",
                            marginRight: "8px",
                            width: "17px",
                            height: "17px",
                          }}
                        ></i>
                        Global Setting
                      </Dropdown.Item>
                    </Link>
                    <Dropdown.Item onClick={() => window.HelpWidget("open")}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-inbox profile-svg"
                      >
                        <polyline points="22 12 16 12 14 15 10 15 8 12 2 12"></polyline>
                        <path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"></path>
                      </svg>
                      Contact Support
                    </Dropdown.Item>
                    {/* <Dropdown.Item>
                    <i
                      className="fa-solid fa-gear"
                      style={{
                        // color: "blueviolet",
                        marginLeft: "3px",
                        marginRight: "8px",
                        width: "17px",
                        height: "17px",
                      }}
                    ></i>
                    Global Setting
                  </Dropdown.Item> */}
                    {/* <Dropdown.Item>
                    <Link className="profile-links"  to="/credits-history">
                    <i
                      className="fa-sharp fa-solid fa-dollar-sign"
                      style={{
                        color: "white",
                        borderRadius: "8px",
                        backgroundColor: "#575757",
                        textAlign: "center",
                        marginLeft: "3px",
                        marginRight: "8px",
                        // padding:"1px",
                        paddingTop: "2px",
                        width: "19px",
                        height: "18px",
                      }}
                    ></i>
                    Credits Summery
                    </Link>
                  </Dropdown.Item> */}
                    <Dropdown.Separator />
                    <Dropdown.Item onClick={logedout}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-log-out profile-svg"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                        <polyline points="16 17 21 12 16 7"></polyline>
                        <line x1="21" y1="12" x2="9" y2="12"></line>
                      </svg>
                      Logout
                    </Dropdown.Item>
                  </Dropdown>
                ) : (
                  <Dropdown
                    style={{ marginLeft: "auto", marginTop: "15px" }}
                    placement="startLeft"
                    renderToggle={triggerImage}
                  >
                    {useLocation().pathname.includes("/optimize")
                      ? ""
                      : channelidData.map(
                          ({
                            channel_logo,
                            channel_name,
                            id,
                            multichannel,
                          }) => (
                            <Dropdown.Item
                              key={id}
                              onClick={() => switching(multichannel)}
                            >
                              <img
                                alt="header"
                                style={{
                                  borderRadius: "40%",
                                  width: "25px",
                                  height: "23px",
                                }}
                                src={channel_logo}
                              />
                              &nbsp; {channel_name}
                            </Dropdown.Item>
                          )
                        )}
                    {/* <Dropdown.Item>
                  <img
                  alt="header"
                    style={{
                      borderRadius: "40%",
                      width: "25px",
                      height: "23px",
                    }}
                    src="/images/YTubeBooster_instagramLogo_12x.png"
                  />
                  &nbsp; Channel Two
                </Dropdown.Item> */}
                    <Dropdown.Separator />
                    <Link className="profile-links" to="/profile">
                      <Dropdown.Item>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="19"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-user profile-svg"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                          <circle cx="12" cy="7" r="4"></circle>
                        </svg>
                        Profile
                      </Dropdown.Item>
                    </Link>
                    <Link className="profile-links" to="/plan-info">
                      <Dropdown.Item>
                        <i
                          className="fa-solid fa-receipt"
                          style={{
                            // color: "blueviolet",
                            marginLeft: "3px",
                            marginRight: "8px",
                            width: "17px",
                            height: "17px",
                          }}
                        ></i>
                        Upgrade/Downgrade Plan
                      </Dropdown.Item>
                    </Link>
                    <Link to="/transition-invoice">
                      <Dropdown.Item>
                        <i
                          className="fa-solid fa-receipt"
                          style={{
                            // color: "blueviolet",
                            marginLeft: "3px",
                            marginRight: "8px",
                            width: "17px",
                            height: "17px",
                          }}
                        ></i>
                        Transactions & Invoices
                      </Dropdown.Item>
                    </Link>
                    <Dropdown.Item>
                      <div data-widget-open data-widget-contact>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="19"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-inbox profile-svg"
                        >
                          <polyline points="22 12 16 12 14 15 10 15 8 12 2 12"></polyline>
                          <path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"></path>
                        </svg>
                        Contact Support
                      </div>
                    </Dropdown.Item>
                    {/* <Dropdown.Item>
                  <i
                    className="fa-solid fa-gear"
                    style={{
                      // color: "blueviolet",
                      marginLeft: "3px",
                      marginRight: "8px",
                      width: "17px",
                      height: "17px",
                    }}
                  ></i>
                  Global Setting
                </Dropdown.Item> */}
                    {/* <Dropdown.Item>
                  <Link className="profile-links"  to="/credits-history">
                  <i
                    className="fa-sharp fa-solid fa-dollar-sign"
                    style={{
                      color: "white",
                      borderRadius: "8px",
                      backgroundColor: "#575757",
                      textAlign: "center",
                      marginLeft: "3px",
                      marginRight: "8px",
                      // padding:"1px",
                      paddingTop: "2px",
                      width: "19px",
                      height: "18px",
                    }}
                  ></i>
                  Credits Summery
                  </Link>
                </Dropdown.Item> */}
                    <Dropdown.Separator />
                    <Dropdown.Item onClick={logedout}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-log-out profile-svg"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                        <polyline points="16 17 21 12 16 7"></polyline>
                        <line x1="21" y1="12" x2="9" y2="12"></line>
                      </svg>
                      Logout
                    </Dropdown.Item>
                  </Dropdown>
                )}
              </div>
            </div>
          </Navbar.Collapse>
        </Container>
        {/* </div> */}
        {/* </nav> */}
      </Navbar>
      <div className="container-fluid">
        <Outlet />
      </div>
      <div className="fotter">
        <Footer />
      </div>
      <CreditsModal tryOpen={tryOpen} handleCloseTry={handleCloseTry} />
    </div>
  );
};

export default Navbars;
