import React, { useState } from "react";
import { Button, Modal, Pagination, SelectPicker, useToaster } from "rsuite";
import { adminPrivateRequest } from "../../../api/axiosConfig/adminPrivateRequest";
import { useEffect } from "react";
import moment from "moment";
import { showSuccessNotification } from "../../../Notification";
import { ProgressBar } from "react-loader-spinner";

const AdminCoupons = () => {
  const [delOpen,setDelOpen] = useState(false);
  const [couponsDatas, setCouponsDatas] = useState([]);
  const [loading,setLoading] = useState(false)
  // const [pageNumber, setPageNumber] = useState(5);
  const [limit, setLimit] = useState(5);
  const [active, setActive] = useState(1);
  const [usersDel,setUsersDel] = useState("");

  const limitOptions = [5,10,20];
  const startIdx = (active - 1) * limit;
  const endIdx = startIdx + limit;

  const toaster = useToaster();

  const pageChanged = (data) => {
    setActive(1);
    setLimit(data);  
  }


  // console.log("selectedOptions",)

  const handleModalDelte = (data) => {
    setDelOpen(true);
    console.log("modalData", data);
    setUsersDel(data);
  };

  const deleteCoupons = async (data) => {
    setLoading(true)
    try{
     const del = await adminPrivateRequest.post(`/api/admin_panel/subscription-cancel/`,{user_id:data});
     console.log("del",del)
     toaster.push(showSuccessNotification("User has unsubscribed"))
    }catch(err){
     console.log("deleteErr",err) 
    }
    setLoading(false)
    delHandleClose();
    couponsData();
  }


  const delHandleClose = () => {
    setDelOpen(false)
    setUsersDel("")
  }

  // const pageInfo = (data) => {
  //   setPageNumber(data);
  //   setActive(1);
  // };

  const couponsData = async () => {
    setLoading(true)
    try {
      const coupons_data = await adminPrivateRequest.get(
        `/api/admin_panel/list-cancel-subscription/`
      );
    //   console.log("dataredss", coupons_data.data);
      setCouponsDatas(coupons_data.data);
    } catch (err) {
      console.log("err", err);
    }
    setLoading(false)
  };

  useEffect(() => {
    couponsData();
  }, []);
  return (
    <div>
      <h1>Cancel Subscription&nbsp;</h1>
      <div className="uk-child-width-1-1@m">
        <div className="uk-card uk-card-default uk-card-body">
          <div className="d-flex justify-content-between align-items-center mt-3">
            <div className="d-flex">
              <h4 className="heading-14" style={{ textAlign: "start" }}>
                Results:&nbsp;
              </h4>
              <SelectPicker
              value={limit}
              onChange={pageChanged}
              cleanable={false}
              searchable={false}
              data={limitOptions.map((key) => ({ value: key, label: key }))}
            />{" "}
            </div>
          </div>
          <div className="uk-margin-top">
            <table className="table table-striped">
              <thead className="table-header">
                <tr>
                  <th>User Id </th>
                  <th>Email</th>
                  <th>Start Date</th>
                  <th>Cancellation Date</th>
                  <th>Cancellation Reason</th>
                  <th>Status</th>
                  <th>Users Requested Cancellation Date </th>
                  <th>Canceled Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              {loading===true?
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              <td className="d-flex align-items-center justify-content-end">
                LOADING &nbsp;
                <ProgressBar
                  height="80"
                  width="80"
                  ariaLabel="progress-bar-loading"
                  // wrapperStyle={{}}
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#53389e"
                  barColor="#53389e"
                />
              </td>
              <td></td>
              <td></td>
              <td></td>
              </tr>
              :<tbody>
                {couponsDatas
                  .slice(startIdx, endIdx)
                  .map((res) => (
                    <tr style={{ height: "50px" }} key={res.id}>
                      <td>{res.user_id}</td>
                      <td>{res.email}</td>
                      <td>{moment(res.starts_at).format(" D-MM-YY")}</td>
                      <td>{moment(res.cancellation_date).format(" D-MM-YY")}</td>
                      <td>{res.cancellation_reason}</td>
                      <td>{res.status}</td>
                      <td>{moment(res.user_requested_cancellation_date).format(" D-MM-YY")}</td>
                      <td>{res.canceled_date===null?"-":moment(res.canceled_date).format(" D-MM-YY")}</td>
                      <td> <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-trash-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleModalDelte(res.user_id)}
                        >
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                          <line x1="10" y1="11" x2="10" y2="17"></line>
                          <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg></td>
                    </tr>
                  ))}
              </tbody>}
            </table>
            <div className="d-flex justify-content-between">
              <div>
                <div
                  style={{ padding: "20px" }}
                  className="uk-card uk-card-default"
                >
                  Showing page {active} of {couponsDatas.length}
                </div>
              </div>
              <div>
                <Pagination
                  prev
                  next
                  first
                  last
                  size="md"
                  maxButtons={10}
                  page={Math.ceil(couponsDatas.length / limit)}
                  limit={limit}
                  total={couponsDatas.length}
                  activePage={active}
                  onChangePage={setActive}
                  onChangeLimit={pageChanged}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal for delete users */}
      <Modal size="xs" open={delOpen} onClose={delHandleClose}>
        <Modal.Header>
          <Modal.Title>
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 14 14"
              fill="#2196f3"
              aria-hidden="true"
              focusable="false"
              class="rs-icon"
              aria-label="info"
              data-category="legacy"
            >
              <path d="M7 14A7 7 0 117 0a7 7 0 010 14zm0-9.333a1.167 1.167 0 100-2.334 1.167 1.167 0 000 2.334zm0 1.166c-.644 0-1.167.522-1.167 1.167v3.5a1.167 1.167 0 002.334 0V7c0-.644-.522-1.167-1.167-1.167z"></path>
            </svg>{" "}
            Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to unSubscribe</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={delHandleClose} appearance="subtle">
            No
          </Button>
          <Button onClick={() => deleteCoupons(usersDel)} appearance="primary">
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminCoupons;
