import React, { useState } from "react";
// import '../Public/ytubebooster.webflow.css'

const Pricing = () => {
  const [togged, setTogged] = useState(false);
  const takeToggle = (data) => {
    if (togged === false) {
      setTogged(true);
    } else {
      setTogged(false);
    }
  };
  return (
    <>
      <div className="cf-pricing-hero-section">
        <div className="cf-wrapper-1200px-2">
          <div className="cf-pricing-hero-wrapper">
            <div className="cf-pricing-hero-head-container">
              <div className="cf-white-top-heading">Pricing plans</div>
              <h1 className="cf-pricing-hero-heading">
                Get more value from your <br />
                <span className="cf-colourful-text-2">
                  YTubeBooster Subscription
                </span>
              </h1>
              <p className="cf-pricing-hero-para">
                Our curated and custom pricing plans are have been designed with
                you in mind. Check our various plans and select the one which
                suits your needs.
              </p>
            </div>
          </div>
          <div className="cf-pricing-toggle-header-wrapper-2 pricing-header-toggle">
            <div className="cf-toggle-row-2">
              <div className="cf-toggle-text-2">Monthly</div>
              {togged === false ? (
                <div
                  data-w-id="36d9533a-055b-4e2a-3186-0d13e50c9fe3"
                  className="cf-toggle-contain-2 white-toggle"
                  onClick={takeToggle}
                >
                  <div
                    style={{
                      WebkitTransform:
                        "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    }}
                    className="cf-toggle-dot-2 green-toggle-dot"
                  />
                </div>
              ) : (
                <div
                  data-w-id="36d9533a-055b-4e2a-3186-0d13e50c9fe3"
                  className="cf-toggle-contain-2 white-toggle"
                  onClick={takeToggle}
                >
                  <div
                    style={{
                      WebkitTransform:
                        "translate3d(30px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(30px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(30px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(30px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    }}
                    className="cf-toggle-dot-2 green-toggle-dot"
                  />
                </div>
              )}
              <div className="cf-toggle-text-2">Yearly</div>
              <div className="cf-toggle-save-container-2">
                <div className="cf-toggle-save-text-2">Save Up To 58%</div>
                <img
                  src="images/Arrow-6.svg"
                  loading="lazy"
                  alt=""
                  className="cf-toggle-arrow-icon-2"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cf-pricing-box-section">
          <div className="cf-wrapper-1200px-2">
            <div className="cf-pricing-box-wrapper-2 pricing-1-box">
              {togged === false ? (
                <div
                  data-w-id="36d9533a-055b-4e2a-3186-0d13e50c9fee"
                  style={{
                    WebkitTransform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 1,
                    display: "display",
                  }}
                  className="cf-pricing-box-row-2 pricing-box-1"
                >
                  <div className="cf-pricing-box-2 pricing-1-box">
                  <div className="cf-pricing-box-plan-wrapper-2">
                        <div className="cf-pricing-plan-text-2 pricing-1-plan-text">
                          Acorn
                        </div>
                        <p style={{ marginBottom: "32px" }}>
                          For new YouTube Creators who want access to
                          accelerated growth-oriented tools.
                        </p>
                        <div className="cf-pricing-plan-price-2">
                          $9.99
                          <span className="cf-pricing-plan-small-text-2">
                            /month
                          </span>
                        </div>
                      </div>
                    <p
                      className="cf-secondary-button-2 pricing-button w-button"
                      // onClick={(e) => handlePricing(e,"t")}
                    >
                      Start Free Trial
                    </p>
                  </div>
                  <div className="cf-pricing-box-2 green-pricing-box">
                  <div className="cf-pricing-box-plan-wrapper-2 white-border">
                        <div className="cf-pricing-plan-text-2">Palm</div>
                        <p style={{ marginBottom: "32px" }}>
                          For budding and growing YouTube Creators seeking to
                          make informed data-driven decisions with rapid channel
                          growth potential
                        </p>
                        <div className="cf-pricing-plan-price-2">
                          $29.99
                          <span className="cf-pricing-plan-small-text-2">
                            /month
                          </span>
                        </div>
                      </div>
                    <p
                      className="cf-primary-button-2 pricing-button w-button"
                      // onClick={(e) => handlePricing(e,"m")}
                    >
                      Start Free Trial
                    </p>
                  </div>
                  <div className="cf-pricing-box-2 pricing-1-box">
                  <div className="cf-pricing-box-plan-wrapper-2">
                          <div className="cf-pricing-plan-text-2">Oak</div>
                          <p style={{ marginBottom: "32px" }}>
                            For growing and established YouTube Creators and
                            Brands, who want to boost their channel with AI
                          </p>
                          <div className="cf-pricing-plan-price-2">
                            $69.99
                            <span className="cf-pricing-plan-small-text-2">
                              /month
                            </span>
                          </div>
                        </div>
                    <p
                      className="cf-secondary-button-2 pricing-button w-button"
                      // onClick={(e) => handlePricing(e,"y")}
                    >
                      Start Free Trial
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  data-w-id="36d9533a-055b-4e2a-3186-0d13e50ca046"
                  style={{
                    WebkitTransform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 1,
                    display: "flex",
                  }}
                  className="cf-pricing-box-row-2 pricing-box-2"
                >
                  <div className="cf-pricing-box-2 pricing-1-box">
                  <div className="cf-pricing-box-plan-wrapper-2">
                        <div className="cf-pricing-plan-text-2 pricing-1-plan-text">
                          Acorn
                        </div>
                        <p style={{ marginBottom: "32px" }}>
                          For new YouTube Creators who want access to
                          accelerated growth-oriented tools.
                        </p>
                        <div className="lower-price-opt">
                          <strike className="strick-opts">$99.99/month</strike>
                          <div
                            className="cf-pricing-plan-price-2"
                            style={{ marginBottom: "0px" }}
                          >
                            $8.33
                            <span className="cf-pricing-plan-small-text-2">
                              /month
                            </span>
                          </div>
                        </div>
                        <p className="warns">
                          save 17% with annual billing incl. 2 months free
                          access
                        </p>
                      </div>
                    <p className="cf-secondary-button-2 pricing-button w-button">
                      Start Free Trial
                    </p>
                  </div>
                  <div className="cf-pricing-box-2 green-pricing-box">
                    <div className="cf-pricing-box-plan-wrapper-2 white-border">
                      <div className="cf-pricing-plan-text-2">Palm</div>
                      <div className="cf-pricing-plan-note-text-2">
                        Free 14-day trial.Cancel anytime.
                      </div>
                      <div className="cf-pricing-plan-price-2">
                        $1490
                        <span className="cf-pricing-plan-small-text-2">
                          /month
                        </span>
                      </div>
                    </div>
                    {/* <div className="cf-pricing-plan-content-wrapper-2">
                    <div className="cf-pricing-plan-content-item-2">
                      <img
                        src="images/2.svg"
                        loading="lazy"
                        alt=""
                        className="cf-pricing-plan-tick-icon-2"
                      />
                      <div className="cf-pricing-plan-content-text-2">
                        One User
                      </div>
                    </div>
                    <div className="cf-pricing-plan-content-item-2">
                      <img
                        src="images/2.svg"
                        loading="lazy"
                        alt=""
                        className="cf-pricing-plan-tick-icon-2"
                      />
                      <div className="cf-pricing-plan-content-text-2">
                        Unlimited Viewers
                      </div>
                    </div>
                    <div className="cf-pricing-plan-content-item-2">
                      <img
                        src="images/2.svg"
                        loading="lazy"
                        alt=""
                        className="cf-pricing-plan-tick-icon-2"
                      />
                      <div className="cf-pricing-plan-content-text-2">
                        Basic Insights
                      </div>
                    </div>
                    <div className="cf-pricing-plan-content-item-2">
                      <img
                        src="images/2.svg"
                        loading="lazy"
                        alt=""
                        className="cf-pricing-plan-tick-icon-2"
                      />
                      <div className="cf-pricing-plan-content-text-2">
                        Integrations with Figma, Jira, Dropbox
                      </div>
                    </div>
                  </div> */}
                    <p className="cf-primary-button-2 pricing-button w-button">
                      Start Free Trial
                    </p>
                  </div>
                  <div className="cf-pricing-box-2 pricing-1-box">
                    <div className="cf-pricing-box-plan-wrapper-2">
                      <div className="cf-pricing-plan-text-2">Oak</div>
                      <div className="cf-pricing-plan-note-text-2">
                        Free 14-day trial.Cancel anytime.
                      </div>
                      <div className="cf-pricing-plan-price-2">
                        $2000
                        <span className="cf-pricing-plan-small-text-2">
                          /month
                        </span>
                      </div>
                    </div>
                    {/* <div className="cf-pricing-plan-content-wrapper-2">
                    <div className="cf-pricing-plan-content-item-2">
                      <img
                        src="images/1.svg"
                        loading="lazy"
                        width={17}
                        alt=""
                        className="cf-pricing-plan-tick-icon-2"
                      />
                      <div className="cf-pricing-plan-content-text-2">
                        One User
                      </div>
                    </div>
                    <div className="cf-pricing-plan-content-item-2">
                      <img
                        src="images/1.svg"
                        loading="lazy"
                        width={17}
                        alt=""
                        className="cf-pricing-plan-tick-icon-2"
                      />
                      <div className="cf-pricing-plan-content-text-2">
                        Unlimited Viewers
                      </div>
                    </div>
                    <div className="cf-pricing-plan-content-item-2">
                      <img
                        src="images/1.svg"
                        loading="lazy"
                        width={17}
                        alt=""
                        className="cf-pricing-plan-tick-icon-2"
                      />
                      <div className="cf-pricing-plan-content-text-2">
                        Basic Insights
                      </div>
                    </div>
                    <div className="cf-pricing-plan-content-item-2">
                      <img
                        src="images/1.svg"
                        loading="lazy"
                        width={17}
                        alt=""
                        className="cf-pricing-plan-tick-icon-2"
                      />
                      <div className="cf-pricing-plan-content-text-2">
                        Integrations with Figma, Jira, Dropbox
                      </div>
                    </div>
                    <div className="cf-pricing-plan-content-item-2">
                      <img
                        src="images/1.svg"
                        loading="lazy"
                        width={17}
                        alt=""
                        className="cf-pricing-plan-tick-icon-2"
                      />
                      <div className="cf-pricing-plan-content-text-2">
                        Whitelabelling
                      </div>
                    </div>
                  </div> */}
                    <p className="cf-secondary-button-2 pricing-button w-button">
                      Start Free Trial
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
