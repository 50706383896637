import React from "react";

const VideoRecommand = ({ focusword, formik, isCheck, reRendering }) => {
  //  const regexExp = /^#[^ !@#$%^&*(),.?":{}|<>]*$/gi;
  const regexExp = /(?<!#)#[\w]+/g;
  const search = focusword;
  console.log("search", search);

  const sentence = formik.values.video_description;
  const wordToFind = search;

  function containsLink(inputString) {
    // Regular expression to match URLs
    const urlPattern = /(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]/i;

    // Test if the inputString contains a link
    return urlPattern.test(inputString);
  }

  const countOccurrences = (string, word) => {
    const regex = new RegExp("\\b" + word + "\\b", "gi"); // Create a regular expression with the word and global case-insensitive flag
    const matches = string.toLowerCase().match(regex); // Get an array of all matched occurrences

    // if (matches && matches.length === 3) {
    if (matches && matches.length >= 3) {
      return true; // The word appears three times
    } else {
      return false; // The word does not appear three times
    }
  };

  const result = countOccurrences(sentence, wordToFind);
  // let wordsArray = search.split(" ");

  // Filter the array to include only words that occur three or more times
  // let duplicatesArray = wordsArray.filter((word, index) => {
  //   return (
  //     wordsArray.indexOf(word) !== index &&
  //     wordsArray.lastIndexOf(word) === index &&
  //     wordsArray.indexOf(word) === 0
  //   );
  // });
  // console.log("fgfg",focusword.length)
  return (
    <>
      <div className="div-block-21">
        <div className="uui-navbar03_dropdown-link-list">
          <div
            className="uui-navbar03_dropdown-link w-inline-block"
            style={
              regexExp.test(formik.values.video_description)
                ? { opacity: "0.3", textDecoration: "none" }
                : { textDecoration: "none" }
            }
          >
            <div className="uui-navbar03_icon-wrapper">
              <div className="icon-featured-large">
                <div className="uui-icon-1x1-xsmall-4 w-embed">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-hash"
                  >
                    <line x1="4" y1="9" x2="20" y2="9"></line>
                    <line x1="4" y1="15" x2="20" y2="15"></line>
                    <line x1="10" y1="3" x2="8" y2="21"></line>
                    <line x1="16" y1="3" x2="14" y2="21"></line>
                  </svg>
                </div>
              </div>
            </div>
            <div className="uui-navbar03_item-right">
              <div className="uui-navbar03_item-heading">Hashtags</div>
              <div className="uui-text-size-small-6">
                Add Hashtags in the description
              </div>
            </div>
          </div>
          <div
            // href="#"
            className="uui-navbar03_dropdown-link w-inline-block"
            style={
              formik.values.video_description.length >= 500
                ? { opacity: "0.3", textDecoration: "none" }
                : { textDecoration: "none" }
            }
          >
            <div className="uui-navbar03_icon-wrapper">
              <div className="icon-featured-large">
                <div className="uui-icon-1x1-xsmall-4 w-embed">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 17V21H9V17M5.2 17H18.8C19.9201 17 20.4802 17 20.908 16.782C21.2843 16.5903 21.5903 16.2843 21.782 15.908C22 15.4802 22 14.9201 22 13.8V6.2C22 5.0799 22 4.51984 21.782 4.09202C21.5903 3.71569 21.2843 3.40973 20.908 3.21799C20.4802 3 19.9201 3 18.8 3H5.2C4.07989 3 3.51984 3 3.09202 3.21799C2.71569 3.40973 2.40973 3.71569 2.21799 4.09202C2 4.51984 2 5.07989 2 6.2V13.8C2 14.9201 2 15.4802 2.21799 15.908C2.40973 16.2843 2.71569 16.5903 3.09202 16.782C3.51984 17 4.0799 17 5.2 17Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <div className="uui-navbar03_item-right">
              <div className="uui-navbar03_item-heading">
                Description Characters
              </div>
              <div className="uui-text-size-small-6">
                Add over 500 characters in the description
              </div>
            </div>
          </div>
          <div
            // href="#"
            className="uui-navbar03_dropdown-link w-inline-block"
            style={
              focusword.length === 0
                ? { textDecoration: "none" }
                : result === true
                ? { opacity: "0.3", textDecoration: "none" }
                : { textDecoration: "none" }
            }
          >
            <div className="uui-navbar03_icon-wrapper">
              <div className="icon-featured-large">
                <div className="uui-icon-1x1-xsmall-4 w-embed">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-key"
                  >
                    <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                  </svg>
                </div>
              </div>
            </div>
            <div className="uui-navbar03_item-right">
              <div className="uui-navbar03_item-heading">
                Keyword in Description
              </div>
              <div className="uui-text-size-small-6">
                Video Description should have atleast 3-4 instance of your focus
                keyword in it
              </div>
            </div>
          </div>
          <div
            // href="#"
            className="uui-navbar03_dropdown-link w-inline-block"
            style={
              formik.values.file !== null
                ? { opacity: "0.3", textDecoration: "none" }
                : { textDecoration: "none" }
            }
          >
            <div className="uui-navbar03_icon-wrapper">
              <div className="icon-featured-large">
                <div className="uui-icon-1x1-xsmall-4 w-embed">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-image"
                  >
                    <rect
                      x="3"
                      y="3"
                      width="18"
                      height="18"
                      rx="2"
                      ry="2"
                    ></rect>
                    <circle cx="8.5" cy="8.5" r="1.5"></circle>
                    <polyline points="21 15 16 10 5 21"></polyline>
                  </svg>
                </div>
              </div>
            </div>
            <div className="uui-navbar03_item-right">
              <div className="uui-navbar03_item-heading">Thumbnail</div>
              <div className="uui-text-size-small-6">
                Add a thumbnail to your video. Do not make it a clickbait.
              </div>
            </div>
          </div>
        </div>
        <div className="uui-navbar03_dropdown-link-list">
          <div
            // href="#"
            className="uui-navbar03_dropdown-link w-inline-block"
            style={
              formik.values.video_title.length >= 1
                ? { opacity: "0.3", textDecoration: "none" }
                : { textDecoration: "none" }
            }
          >
            <div className="uui-navbar03_icon-wrapper">
              <div className="icon-featured-large">
                <div className="uui-icon-1x1-xsmall-4 w-embed">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-youtube"
                  >
                    <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path>
                    <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon>
                  </svg>
                </div>
              </div>
            </div>
            <div className="uui-navbar03_item-right">
              <div className="uui-navbar03_item-heading">Video Title</div>
              <div className="uui-text-size-small-6">
                Add a video title which relates to the video
              </div>
            </div>
          </div>
          <div
            // href="#"
            className="uui-navbar03_dropdown-link w-inline-block"
            style={
              formik.values.video_title.length >= 70
                ? { opacity: "0.3", textDecoration: "none" }
                : { textDecoration: "none" }
            }
          >
            <div className="uui-navbar03_icon-wrapper">
              <div className="icon-featured-large">
                <div className="uui-icon-1x1-xsmall-4 w-embed">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-edit-3"
                  >
                    <path d="M12 20h9"></path>
                    <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                  </svg>
                </div>
              </div>
            </div>
            <div className="uui-navbar03_item-right">
              <div className="uui-navbar03_item-heading">Title characters</div>
              <div className="uui-text-size-small-6">
                Add not more than 70-80 characters in the title
              </div>
            </div>
          </div>
          <div
            // href="#"
            className="uui-navbar03_dropdown-link w-inline-block"
            style={
              containsLink(formik.values.video_description.toString()) === true
                ? { opacity: "0.3", textDecoration: "none" }
                : { textDecoration: "none" }
            }
            // style={
            //   formik.values.video_description.includes("Social Link:") ||
            //   formik.values.video_description.includes("Video:") ||
            //   formik.values.video_description.includes("PlayList:")
            //     ? { opacity: "0.3", textDecoration: "none" }
            //     : { textDecoration: "none" }
            // }
          >
            <div className="uui-navbar03_icon-wrapper">
              <div className="icon-featured-large">
                <div className="uui-icon-1x1-xsmall-4 w-embed">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-link"
                  >
                    <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                    <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                  </svg>
                </div>
              </div>
            </div>
            <div className="uui-navbar03_item-right">
              <div className="uui-navbar03_item-heading">Links</div>
              <div className="uui-text-size-small-6">
                Add links to your other videos and social media
              </div>
            </div>
          </div>
          <div
            // href="#"
            className="uui-navbar03_dropdown-link w-inline-block"
            style={
              focusword.length === 0
                ? { textDecoration: "none" }
                : formik.values.video_title
                    .toLowerCase()
                    .toString()
                    .includes(search.toLowerCase())
                ? { opacity: "0.3", textDecoration: "none" }
                : { textDecoration: "none" }
            }
          >
            <div className="uui-navbar03_icon-wrapper">
              <div className="icon-featured-large">
                <div className="uui-icon-1x1-xsmall-4 w-embed">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2C13.3132 2 14.6136 2.25866 15.8268 2.76121C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7363 6.95991 21.2388 8.17317C21.7413 9.38643 22 10.6868 22 12M12 2V12M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5229 22 12M12 2C17.5228 2 22 6.47716 22 12M22 12L12 12M22 12C22 13.5781 21.6265 15.1338 20.9101 16.5399C20.1936 17.946 19.1546 19.1626 17.8779 20.0902L12 12"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <div className="uui-navbar03_item-right">
              <div className="uui-navbar03_item-heading">Focus keyword</div>
              <div className="uui-text-size-small-6">
                Video title should have your focus keyword in it
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-margin-top">
        <h6 className="uk-margin-left">Google Trends</h6>
        <div>{focusword.length === 0 ? null : reRendering()}</div>
      </div>
    </>
  );
};

export default VideoRecommand;
