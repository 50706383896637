import {takeEvery, all, put, call} from 'redux-saga/effects';

import * as actionTypes from '../redux/actionTypes';

// actions
import{
    youtubeConnDataFetchSuccess,youtubeConnDataFetchFailed
} from '../redux/actions/youtubeAction';
import { fetchYoutube } from '../api/service/connect';

// services

// import { fetchYoutube} from '../api/services/connect';



// Worker saga
// ***************************** Youtube START *****************************
function* loadYoutube(action){
    try{
        // console.log('action', action.payload);
        const {data} = yield call(fetchYoutube, action.payload);
        console.log('datafetch', data)
        yield put(youtubeConnDataFetchSuccess(data['Please go to this URL: ']));
    }catch(error){
        console.log("error",error.response.data['Please go to this URL: '])
        if(error.response.data['Please go to this URL: ']){
            yield put(youtubeConnDataFetchSuccess(error.response.data['Please go to this URL: ']))   
        }
        yield put(youtubeConnDataFetchFailed(error.response));
    }
}

// watcher saga
function* watchYoutube() {
    yield takeEvery(actionTypes.FETCH_YOUTUBE_START, loadYoutube);
}

export function* youtubeSaga(){
    yield all([watchYoutube()])
}
