import React, { useEffect, useRef } from "react";
import Script from "react-load-script";

export default function GoogleTrends2({ type, keyword, url, countrys }) {
  const mapContainerRef = useRef(null);

  const handleScriptLoad = () => {
    // Ensure window.trends is defined before accessing it
    if (window.trends) {
      window.trends.embed.renderExploreWidgetTo(
        mapContainerRef.current,
        type,
        {
          comparisonItem: [{ keyword, geo: countrys, time: "today 12-m" }],
          category: 0,
          property: ""
        },
        {
          exploreQuery: `q=${encodeURI(keyword)}&geo=US&date=today 12-m`,
          guestPath: "https://trends.google.com:443/trends/embed/"
        }
      );
    }
  };

  useEffect(() => {
    // Ensure the map container is cleared before rendering a new map
    if (mapContainerRef.current) {
      mapContainerRef.current.innerHTML = "";
    }

    // Call handleScriptLoad when the script is loaded and countrys prop changes
    if (window.trends) {
      handleScriptLoad();
    }
  }, [countrys]);

  return (
    <div className="googleTrend">
      <div ref={mapContainerRef}></div>
      <Script url={url} onLoad={handleScriptLoad} />
    </div>
  );
}
