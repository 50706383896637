import React from "react";
import { Modal } from "rsuite";
// import styles from '../Private/my-style.module.css'
import styles from "./PrivacyPolicy.module.css";

const PrivacyPolicy = ({ hidden, handleViewClose }) => {
  return (
    <div>
      {/* modal for more youtube analitic */}
      <Modal
        className={styles.modBoad}
        overflow
        size="lg"
        backdrop="static"
        open={hidden}
        onClose={() => handleViewClose()}
      >
        <Modal.Header>
          {/* <Modal.Title>Privacy Policy</Modal.Title> */}
        </Modal.Header>

        <Modal.Body className={styles.scrollbars}>
          <p className={`${styles.c23}`}>
            <span className={`${styles.c10} ${styles.c22}`}>
              Privacy Policy
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              YTubeBooster (&ldquo;YTubeBooster&rdquo;, &ldquo;we&rdquo;,
              &ldquo;us&rdquo;, or &ldquo;our&rdquo;) values your privacy. In
              this privacy policy (&ldquo;Policy&rdquo;), we describe how we
              collect, use, and disclose information that we obtain about
              visitors to our website YtubeBoosterpro.app (the
              &ldquo;Site&rdquo;) and the services available through our Site
              including our free product that has many features to allow users
              to gain access to optimize and get insights on YouTube
              (collectively, the &ldquo;Services&rdquo;).
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              By visiting our Site or using any of our Services, you acknowledge
              that your personal information will be handled as described in
              this Policy. Visiting our Site, use of our Services, and any
              dispute over privacy, is subject to our Terms of Use, including
              its applicable limitations on damages and the resolution of
              disputes.
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              The Information We Collect About You How We Use Your Information
              How We Share Your Information Our Use of Cookies and Other
              Tracking Mechanisms Third-Party Links Security of Your Personal
              Information Direct Marketing Children Under 13 Information for EU
              Individuals Contact Us Changes to this Policy
            </span>
          </p>
          <p className={styles.c0}>
            <span className={styles.c2}>
              <b>Google Privacy Policy</b> By using the YtubeBooster software,
              you acknowledge and agree to be bound by the Google Privacy Policy
              available at &nbsp;
              <a target="_blank" rel="noopener noreferrer" href="http://www.google.com/policies/privacy">
                http://www.google.com/policies/privacy
              </a>
              . We encourage you to review this policy to understand how Google
              handles your personal information. <br />
              <b>YouTube Terms of Service</b> By using the YtubeBooster
              software, you agree to be bound by the YouTube Terms of Service,
              available at &nbsp;
              <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/t/terms">
                https://www.youtube.com/t/terms
              </a>
              . Please review these terms to ensure compliance with YouTube's
              guidelines and regulations.
              <br />
              <b>Revoking API Client's Access</b> YtubeBooster utilizes certain
              YouTube API clients to enhance its functionality. If you wish to
              revoke YtubeBooster's access to your data, please refer to
              Google's security settings page at &nbsp;
              <a
                target="_blank" rel="noopener noreferrer"
                href="https://myaccount.google.com/connections?filters=3,4&hl=en"
              >
                https://myaccount.google.com/connections?filters=3,4&hl=en{" "}
              </a>
              for instructions on how to manage and revoke access.
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}></span>
          </p>
          <p class={styles.c0}>
            <span class={`${styles.c3} ${styles.c1}`}>
              1. Information We Collect About You
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              We collect information about you directly through your use of our
              Site and Services. All visitors to our Site may browse without
              using our Services, however, to make most use of our Services you
              must provide certain information which includes the following:
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}></span>
          </p>
          <p class={styles.c0}>
            <span class={`${styles.c3} ${styles.c1}`}>
              Information Provided by You Directly
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              Common Identifiers, being your first and/or last name and
              initials; Login information, in particular your email address;
              Contact information, including email address and phone number;
              Financial Information, including your credit card details, but not
              your CVV number; and details of your YouTube channel.
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}></span>
          </p>
          <p class={styles.c0}>
            <span class={`${styles.c3} ${styles.c1}`}>
              Information We Collect Automatically
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              We automatically collect information about your use of our Site
              and Services through cookies or other tracking technologies: your
              domain name; your device ID; your browser type and operating
              system; web pages you view; links you click; your IP address; the
              length of time you visit our Site and/or use our Services; and the
              referring URL, or the webpage that led you to our Site. We may
              combine information automatically collected with other information
              that we have collected about you. Please see the section
              &ldquo;Cookies and Other Tracking Mechanisms&rdquo; below for more
              information.
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}></span>
          </p>
          <p class={styles.c0}>
            <span class={`${styles.c3} ${styles.c1}`}>
              Information Provided Voluntarily
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              We invite you to post content to our Services, including your
              comments, pictures, and any other information that you would like
              to be available on our Services. If you post content to our
              Services, all of the information that you post and your web alias
              will be available to all visitors to our Site and users of our
              Services. If you post your own content on our Site or Services,
              your posting may become public and we cannot prevent such
              information from being used in a manner that may violate this
              Policy, the law or your personal privacy.
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}></span>
          </p>
          <p class={styles.c0}>
            <span class="c5 c1">
              Information Provided by Third-Party Services
            </span>
          </p>
          <p class={styles.c0}>
            <span class="c5">
              YTubeBooster may collect some data about your YouTube channel as
              permitted by YouTube&#39;s Terms of Service for use by our tools.
              By using YouTube Content within YTubeBooster&rsquo;s Sites and
              Services you are agreeing to be bound to YouTube&rsquo;s Terms of
              Service and Google&rsquo;s Privacy Policy. Additionally, when you
              sign-up for a YTubeBooster account, you may choose to authenticate
              to our Services through a method provided by Google. In those
              cases, you authorize us to access Personal Information and other
              data held by Google on an ongoing basis in order to provide the
              Services. By authenticating using a third-party authentication
              mechanism you acknowledge that you are authorized to perform this
              authentication and to grant YTubeBooster any associated privileges
              to that data. Users can revoke access to data collected via
              YouTube API Services via the steps outlined in Google security
              settings.
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}></span>
          </p>
          <p class={styles.c0}>
            <span class={`${styles.c3} ${styles.c1}`}>
              2. How We Use Your Information
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              We use your information, including your personal information, for
              the following purposes:
            </span>
          </p>
          <p class={styles.c0}>
            <span className={`${styles.c1}`}>
              Providing and Improving Our Services.
            </span>
            <span class={styles.c2}>
              &nbsp;To provide our Services to you; to communicate with you
              about your use of our Services; to respond to your inquiries; to
              perform technical operations, such as updating our Site; and for
              other customer service and support purposes.
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              To tailor the content and information that we may send or display
              to you; to offer personalized help and instructions; and to
              otherwise personalize your experiences while using our Services.
              Marketing. To provide you with news and newsletters, special
              offers, and promotions; to contact you about products or
              information we think may interest you; and for other marketing,
              advertising, and promotional purposes. We may provide your with
              such updates and promotional materials via email.
            </span>
          </p>
          <p class={styles.c0}>
            <span className={`${styles.c1}`}>Research and Analytics.</span>
            <span class={styles.c2}>
              &nbsp;To analyze how you interact with our Services; to monitor
              and analyze usage and activity trends; and for other research,
              analytical, and statistical purposes both on an aggregated and
              individualized basis including to respond to user desires and
              preferences.
            </span>
          </p>
          <p class={styles.c0}>
            <span className={`${styles.c1}`}>
              Protecting Rights and Interests.
            </span>
            <span class={styles.c2}>
              &nbsp;To protect the safety, rights, property, or security of
              YTubeBooster, the Services; to detect, prevent, or otherwise
              address fraud, security, or technical issues; to prevent or stop
              activity which YTubeBooster, in its sole discretion, may consider
              to be, or to pose a risk of being, an illegal, unethical, or
              legally actionable activity; to use as evidence in litigation; and
              to enforce this Policy or our Terms of Use.
            </span>
          </p>
          <p class={styles.c0}>
            <span className={`${styles.c1}`}>Legal Compliance.</span>
            <span class={styles.c2}>
              &nbsp;To comply with applicable legal or regulatory obligations,
              including as part of a judicial proceeding; to respond to a
              subpoena, warrant, court order, or other legal process; or as part
              of an investigation or request, whether formal or informal, from
              law enforcement or a governmental authority.
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}>
              <br />
            </span>
          </p>
          <p class={styles.c0}>
            <span class={`${styles.c3} ${styles.c1}`}>
              3. How We Share Your Information
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              We highly value your privacy, and we want to assure you that your
              personal data is not shared with any third-party applications we
              use for our service without your explicit consent. We adhere
              strictly to the General Data Protection Regulation (GDPR)
              guidelines, ensuring that your information remains confidential
              and is used solely for the purposes outlined in our Privacy
              Policy.
            </span>
            <span class={styles.c2}>
              We may share your information, including personal information, as
              follows:
            </span>
          </p>
          <p class={styles.c0}>
            <span className={`${styles.c1}`}>Affiliates.</span>
            <span class={styles.c2}>
              &nbsp;We may disclose the information we collect from you to our
              affiliates or subsidiaries; however, if we do so, their use and
              disclosure of your personally identifiable information will be
              subject to this Policy. Partners. We exchange data with YouTube to
              enable us to give you insights into your channel to help you to
              grow. Service Providers. We may disclose the information we
              collect from you to third party vendors, service providers,
              contractors or agents who perform functions on our behalf, in
              particular, Google, Facebook Advertising, Twitter and Intercom.
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              We also disclose information in the following circumstances:
            </span>
          </p>
          <p class={styles.c0}>
            <span className={`${styles.c1}`}>Business Transfers.</span>
            <span class={styles.c2}>
              &nbsp;If we are or may be acquired by or merged with another
              company, if any of our assets are transferred to another company,
              or as part of a bankruptcy or insolvency proceeding, we may
              transfer the information we have collected from you to the other
              company. In Response to Legal Process. We also may disclose the
              information we collect from you in order to comply with the law, a
              judicial proceeding, court order, or other legal process, such as
              in response to a court order or a subpoena.
            </span>
          </p>
          <p class={styles.c0}>
            <span className={`${styles.c1}`}>To Protect Us and Others.</span>
            <span class={styles.c2}>
              &nbsp;We also may disclose the information we collect from you
              where we believe it is necessary to investigate, prevent, or take
              action regarding illegal activities, suspected fraud, situations
              involving potential threats to the safety of any person,
              violations of our Terms of Use, or this Policy, or as evidence in
              litigation in which we are involved.
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              Aggregate and De-Identified Information. We may share aggregate or
              de-identified information about users with third parties for
              marketing, advertising, research or similar purposes.
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}></span>
          </p>
          <p class={styles.c0}>
            <span class={`${styles.c3} ${styles.c1}`}>
              4. Our Use of Cookies and Other Tracking Mechanisms
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              We and our third party service providers use cookies and other
              tracking mechanisms to track information about your use of our
              Site or Services. We may combine this information with other
              personal information we collect from you (and our third party
              service providers may do so on our behalf).
            </span>
          </p>
          <p class={styles.c0}>
            <span className={`${styles.c1}`}>Cookies.</span>
            <span class={styles.c2}>
              &nbsp;A cookie is a text file that a website transfers to your
              computer&rsquo;s hard drive for record-keeping purposes. Some
              cookies allow us to make it easier for you to navigate our Site,
              while others are used to allow us to track your activities while
              using our Site or our Services. There are two types of cookies:
              session and persistent cookies. Session cookies exist only during
              an online session. They disappear from your computer when you
              close your browser or turn off your computer. We use session
              cookies to allow our systems to uniquely identify you during a
              session or while you are browsing the Site. This allows us to
              verify your identity as you use our Services. Persistent cookies
              remain on your computer after you have closed your browser or
              turned off your computer. We use persistent cookies to track
              aggregate and statistical information about user activity, and to
              display advertising on our Site. To see a full list of our cookies
              and details of why we use them, please contact us.
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              Most web browsers automatically accept cookies, but if you prefer,
              you can edit your browser options to block them in the future. The
              Help portion of the toolbar on most browsers will tell you how to
              prevent your computer from accepting new cookies, how to have the
              browser notify you when you receive a new cookie, or how to
              disable cookies altogether. Visitors to our Site who disable
              cookies will be able to browse certain areas of the Site, but some
              features may not function (e.g., you may not be able to stay
              logged into your account).
            </span>
          </p>
          <p class={styles.c0}>
            <span className={`${styles.c1}`}>Local Storage Objects.</span>
            <span class={styles.c2}>
              &nbsp;We may use Flash Local Storage Objects (&ldquo;Flash
              LSOs&rdquo;) to store your Site preferences and to personalize
              your visit. Flash LSOs are different from browser cookies because
              of the amount and type of data stored. Typically, you cannot
              control, delete, or disable the acceptance of Flash LSOs through
              your web browser. For more information on Flash LSOs, or to learn
              how to manage your settings for Flash LSOs, go to the Adobe Flash
              Player Help Page, choose &ldquo;Global Storage Settings
              Panel&rdquo; and follow the instructions. To see the Flash LSOs
              currently on your computer, choose &ldquo;Website Storage Settings
              Panel&rdquo; and follow the instructions to review and, if you
              choose, to delete any specific Flash LSO.
            </span>
          </p>
          <p class={styles.c0}>
            <span className={`${styles.c1}`}>
              Clear GIFs, pixel tags and other technologies.
            </span>
            <span class={styles.c2}>
              &nbsp;Clear GIFs are tiny graphics with a unique identifier,
              similar in function to cookies. In contrast to cookies, which are
              stored on your computer&rsquo;s hard drive, clear GIFs are
              embedded invisibly on web or mobile application pages. We may use
              clear GIFs (a.k.a. web beacons, web bugs or pixel tags), in
              connection with our Site to, among other things, track the
              activities visitors to our Site, help us manage content, and
              compile statistics about usage of our Site. We and our third party
              service providers also use clear GIFs in HTML emails to our
              customers, to help us track email response rates, identify when
              our emails are viewed, and track whether our emails are forwarded.
            </span>
          </p>
          <p class={styles.c0}>
            <span className={`${styles.c1}`}>Third-Party Analytics.</span>
            <span class={styles.c2}>
              &nbsp;We use Google Analytics as a third-party analytics company,
              to evaluate use of our Site and Services. We use this tools to
              help us understand use of, and to improve, our Site and Services,
              performance, and user experiences. Google Analytics may use
              cookies and other tracking technologies, such as web beacons or
              local storage objects (LSOs), to perform their services. To learn
              more about Google&rsquo;s privacy practices, please review the
              Google privacy policy at &nbsp;
              <a
                target="_blank" rel="noopener noreferrer"
                href="https://www.google.com/policies/privacy/"
              >
                https://www.google.com/policies/privacy/
              </a>
              . You can also download the Google Analytics Opt-out Browser
              Add-on to prevent their data from being used by Google Analytics
              at &nbsp;
              <a
                target="_blank" rel="noopener noreferrer"
                href="https://tools.google.com/dlpage/gaoptout"
              >
                {" "}
                https://tools.google.com/dlpage/gaoptout
              </a>
              .
            </span>
          </p>
          <p class={styles.c0}>
            <span className={`${styles.c1}`}>Do Not Track.</span>
            <span class={styles.c2}>
              &nbsp;Currently, our systems do not recognize browser
              &ldquo;do-not-track&rdquo; requests, but we do not track your
              activities after you leave our Site. You may, however, disable
              certain tracking as discussed in this section (e.g. by disabling
              cookies).
            </span>
          </p>
          <p class={styles.c0}>
            <span className={`${styles.c1}`}>
              Users in the United States may opt out of many third-party ad
              networks.
            </span>
            <span class={styles.c2}>
              &nbsp;For example, you may go to the Digital Advertising Alliance
              (&ldquo;DAA&rdquo;) Consumer Choice Page for information about
              opting out of interest-based advertising and their choices
              regarding having information used by DAA companies. You may also
              go to the Network Advertising Initiative (&ldquo;NAI&rdquo;)
              Consumer Opt-Out Page for information about opting out of
              interest-based advertising and their choices regarding having
              information used by NAI members. If you are in the EU, you may
              opt-out of certain third party cookies that we and other websites
              may use for targeted advertising through the European Interactive
              Digital Advertising Alliance (EDAA) Your Online Choices Page or
              www.aboutads.info.
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              Opting out from one or more companies listed on the DAA Consumer
              Choice Page or the NAI Consumer Opt-Out Page will opt you out from
              those companies&rsquo; delivery of interest-based content or ads
              to you, but it does not mean you will no longer receive any
              advertising through our Site or on other websites.
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}></span>
          </p>
          <p class={styles.c0}>
            <span class={`${styles.c3} ${styles.c1}`}>
              5. Third-Party Links
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              Our Site and Services may contain links to third-party websites.
              Any access to and use of such linked websites is not governed by
              this Policy, but instead is governed by the privacy policies of
              those third party websites. We are not responsible for the
              information practices of such third party websites.
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}></span>
          </p>
          <p class={styles.c0}>
            <span class={`${styles.c3} ${styles.c1}`}>
              6. Security of Your Personal Information
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              We have implemented reasonable precautions to protect the
              information we collect from loss, misuse, and unauthorized access,
              disclosure, alteration, and destruction. Please be aware that
              despite our efforts, no data security measures can guarantee
              security. You should take steps to protect against unauthorized
              access to your password, phone, and computer by, among other
              things, signing off after using a shared computer, choosing a
              robust password that nobody else knows or can easily guess, and
              keeping your log-in and password private. We are not responsible
              for any lost, stolen, or compromised passwords or for any activity
              on your account via unauthorized password activity.
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}></span>
          </p>
          <p class={styles.c0}>
            <span class={`${styles.c3} ${styles.c1}`}>7. Direct Marketing</span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              We may send periodic promotional emails to you. You may opt-out of
              promotional communications by updating your marketing preferences
              in your profile or by emailing us at contact@YTubeBooster.com or
              calling us at 888-998-8434. Please note that it may take up to 10
              business days for us to process opt-out requests. If you opt-out
              of receiving promotional emails about recommendations or other
              information we think may interest you, we may still send you
              emails about your account or any services you have requested or
              received from us.
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}></span>
          </p>
          <p class={styles.c0}>
            <span class={`${styles.c3} ${styles.c1}`}>
              8. Your California Privacy Rights
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              If you are a California resident then under Cal. Civ. Code &sect;
              1798.83 you may request and obtain from us once a year, free of
              charge, information about the personal information (if any) we
              disclosed to third parties for direct marketing purposes in the
              preceding calendar year. If you are a California resident and
              would like to make such a request, please submit your request in
              writing to us at the contact information provided below. In your
              request, please attest to the fact that you are a California
              resident and provide a current California address for your
              response.
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}></span>
          </p>
          <p class={styles.c0}>
            <span class="c1 c3">9. Children Under 13</span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              Our Site and Services are not designed for children under 13. If
              we discover that a child under 13 has provided us with personal
              information, we will delete such information from our systems.
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}></span>
          </p>
          <p class={styles.c0}>
            <span class={`${styles.c3} ${styles.c1}`}>
              10. Information for EU Individulas
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              The following disclosures apply only to YTubeBooster&rsquo;s
              processing of personal data about EU residents.
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              The Data Controller of the information collect through the Site
              and Services is Simera Ltd, which is headquartered in the United
              Kingdom at 3rd Floor, 14 Hanover Street, Hanover Square, London
              W1S 1YH. To exercise any rights that you may have with regard to
              your personal data, you may contact us using the following details
              info@ytubeboosterpro.app.
            </span>
          </p>
          <p class={styles.c0}>
            <span class="c5">
              We used HelpSpace to manage our customer engagement platform which
              will manage the data by their privacy policy available{" "}
              <a href="https://legal.helpspace.com/privacy-policy">
                https://legal.helpspace.com/privacy-policy
              </a>
              , but they are contracted to only act on our instructions.
            </span>
            {/* <span class="c5">
                  We used Intercom to manage our customer engagement platform
                  who will manage the data in accordance with their privacy
                  policy available
                  &nbsp;<a target='_blank' href="https://www.intercom.com/terms-and-policies#privacy">https://www.intercom.com/terms-and-policies#privacy</a>, but they
                  are contracted to only act on our instructions.
                </span> */}
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              The legal bases for using your personal Information. We collect
              your information as a Data Controller when we have a legal basis
              to do so. The following legal bases pertain to our collection of
              data:
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              Our use of your personal data is necessary to perform a contract
              or take steps to enter into a contract with you. This applies to
              our processing activities described at 2A above.
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              Our use of your personal data is in our legitimate interest as a
              commercial organization to make improvements to our Site and to
              provide you with access to the Services as you request. This
              applies to our processing activities described at 2B, 2C (save
              where we need consent by local law) and 2D.
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              Our use of your personal information is necessary to comply with a
              relevant legal or regulatory obligation that we have in
              particular, where we are required to disclose personal information
              to a court or regulatory body in the event of an investigation.
              This applies to our processing activities described at 2E and 2F.
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              Our use of your personal information is in accordance with your
              consent. This applies to our processing activities described at 2C
              (where required by local law).
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}></span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              If you would like to find out more about the legal bases on which
              we process personal information, please contact us using the
              details below.
            </span>
          </p>
          <p class={styles.c0}>
            <span className={`${styles.c1}`}>Your Legal Rights.</span>
            <span class={styles.c2}>
              &nbsp;Subject to certain exemptions, and dependent upon the
              processing activity we are undertaking, European Union individuals
              have certain rights in relation to personal information:
              <br />
            </span>
          </p>
          <p class={styles.c0}>
            <span class={`${styles.c3} ${styles.c1}`}>
              Right to access, correct, and delete your personal information:
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              You have the right to request access to the personal information
              that we hold about you and: (a) the source of your personal
              information; (b) the purposes, legal basis and methods of
              processing; (c) the data controller&rsquo;s identity; and (d) the
              entities or categories of entities to whom your personal
              information may be transferred. You also have the right to request
              that we correct any inaccuracies or delete your information. We
              are not required to comply with your request to erase personal
              information if the processing of your personal information is
              necessary for compliance with a legal obligation or for the
              establishment, exercise, or defence of legal claims.
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}></span>
          </p>
          <p class={styles.c0}>
            <span className={`${styles.c1}`}>
              Right to restrict the processing of your personal information:
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              You have the right to restrict the use of your personal
              information when (i) you contest the accuracy of the data; (ii)
              the use is unlawful but you do not want us to erase the data;
              (iii) we no longer need the personal information for the relevant
              purposes, but we require it for the establishment, exercise, or
              defense of legal claims; or (iv) you have objected to our personal
              information use justified on our legitimate interests verification
              as to whether we have a compelling interest to continue to use
              your data. We can continue to use your personal information
              following a request for restriction, where: (a) we have your
              consent; or (b) to establish, exercise or defend legal claims; or
              (c) to protect the rights of another natural or legal person.
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}></span>
          </p>
          <p class={styles.c0}>
            <span className={`${styles.c1}`}>Right to data portability:</span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              To the extent that we process your information (i) based on your
              consent or under a contract; and (ii) through automated means, you
              have the right to receive such personal information in a
              structured, commonly used, machine-readable format, or you can ask
              to have it transferred directly to another data controller.
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}></span>
          </p>
          <p class={styles.c0}>
            <span class={`${styles.c3} ${styles.c1}`}>
              Right to object to the processing of your personal information:
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              You can object to any processing of your personal information
              which has our legitimate interests as its legal basis, if you
              believe your fundamental rights and freedoms outweigh our
              legitimate interests. If you raise an objection, we have an
              opportunity to demonstrate that we have compelling legitimate
              interests which override your rights and freedoms.
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}>
              <br />
            </span>
          </p>
          <p class={styles.c0}>
            <span className={`${styles.c1}`}>
              Right to lodge a complaint with your local supervisory authority:
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              You have a right to lodge a complaint with your local supervisory
              authority if you have concerns about how we are processing your
              personal information. We ask that you please attempt to resolve
              any issues with us first, although you have a right to contact
              your supervisory authority at any time.
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}></span>
          </p>
          <p class={styles.c0}>
            <span class="c5 c1">How to Exercise Your Rights:</span>
          </p>
          <p class={styles.c0}>
            <span>
              If you would like to exercise any of the rights described above,
              please send us a request
            </span>
            <span class="c1 c6">info@YtubeBoosterpro.app</span>
            <span class={styles.c2}>
              . In your message, please indicate the right you would like to
              exercise and the information to which it relates.
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              We may ask you for additional information to confirm your identity
              and for security purposes before disclosing the personal
              information requested to you. We reserve the right to charge a fee
              where permitted by law, for instance if your request is manifestly
              unfounded or excessive.
            </span>
          </p>
          <p class={styles.c0}>
            <span className={`${styles.c1}`}>
              Cross-border Transfer of Information.
            </span>
            <span class={styles.c2}>
              &nbsp;We maintain servers and systems in the United States hosted
              by Amazon Web Services. As a result, where the personal
              information that we collect through or in connection with the Site
              or the Services is processed in the United Kingdom, we will take
              steps to ensure that the information receives the same level of
              protection as if it remained within the European Union. You may
              have a right receive details of any safeguards that we have where
              your data is transferred outside the European Union, (e.g. to
              request a copy where the safeguard is documented, which may be
              redacted to ensure confidentiality).
            </span>
          </p>
          <p class={styles.c0}>
            <span className={`${styles.c1}`}>
              Retention of Personal Data and Access.
            </span>
            <span class={styles.c2}>
              &nbsp;We will keep your information accurate, complete and up to
              date. We will retain your personal data for the period necessary
              to fulfil the different purposes outlined in this Policy, and in
              particular we will keep data for as long as it takes to respond to
              a query. Where we are required to do so to meet legal, regulatory,
              tax or accounting requirements, we will retain your personal data
              for longer periods of time, but only where permitted to do so,
              including so that we have an accurate record of your dealings with
              us in the event of any complaints or challenges, or if we
              reasonably believe there is a possibility of legal action relating
              to your personal data or dealings.
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}></span>
          </p>
          <p class={styles.c0}>
            <span class={`${styles.c3} ${styles.c1}`}>11. Contact Us</span>
          </p>
          <p class={styles.c0} id="h.gjdgxs">
            <span class={styles.c2}>
              If you have questions about the privacy aspects of our Site or
              Services, or would like to make a complaint, please contact us at
              info@ytubeboosterpro.app.
            </span>
          </p>
          <p class={`${styles.c0} ${styles.c4}`}>
            <span class={styles.c2}></span>
          </p>
          <p class={styles.c0}>
            <span class={`${styles.c3} ${styles.c1}`}>
              12. Changes to this Policy
            </span>
          </p>
          <p class={styles.c0}>
            <span class={styles.c2}>
              This Policy is current as of the Effective Date set forth above.
              We may change this Policy from time to time, so please be sure to
              check back periodically. We will post any changes to this Policy
              on our App. If we make any changes to this Policy that materially
              affect our practices with regard to the personal information we
              have previously collected from you, we will endeavor to provide
              you with notice in advance of such change by highlighting the
              change on our App.
            </span>
          </p>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={handleClose} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PrivacyPolicy;
