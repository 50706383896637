import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { publicRequest } from "../../../api/axiosConfig/publicRequest";

const VerificationEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  // Get specific query parameters
  const tokens = queryParams.get("token");

  const verifying = async () => {
    const payload = {
      token: `${tokens}`,
    };
    try {
      await publicRequest.post("/api/accounts/email-verify/", payload);
      navigate("/congrats");
      // console.log("finaldata", data);
    } catch (err) {
      console.log("err", err);
      if (err.response.data.error === "invalid-token") {
        navigate("/invalid-token");
      } else if (err.response.data.error === "expire") {
        navigate("/expire");
      }
    }
  };

  useEffect(() => {
    verifying();
  }, []);

  return (
    <div className="mt-3" style={{ textAlign: "center" }}>
      Please wait...
    </div>
  );
};

export default VerificationEmail;
