import React from "react";
import { useState } from "react";
import { useToaster } from "rsuite";
import { showErrorNotification } from "../../../Notification";
import VideoDrawer from "./VideoDrawer";

const VideoAndPlaylist = ({
  formik,
  addVideoandLinkToDescription,
  addVideoPlaylist,
}) => {
  const [leftOpen, setLeftOpen] = useState(false);
  const [inputNumber, setInputNumber] = useState(1);
  const [videoOrPlay, setVideoOrPlay] = useState(false);
  const [videoLinkDesc, setVideoLinkDesc] = useState("");
  const [bestdata, setBestData] = useState("");
  const [videoLinkDesc3, setVideoLinkDesc3] = useState("");
  const [videoLinkDesc4, setVideoLinkDesc4] = useState("");
  const [playLinkDesc1, setPlayLinkDesc1] = useState("");
  const [playLinkDesc2, setPlayLinkDesc2] = useState("");
  const [playLinkDesc3, setPlayLinkDesc3] = useState("");
  const [playLinkDesc4, setPlayLinkDesc4] = useState("");
  const toaster = useToaster()
  const handleOpened = () => {
    setLeftOpen(true);
    setVideoOrPlay(false);
    setInputNumber(1);
  };
  const handleOpened2 = () => {
    setLeftOpen(true);
    setVideoOrPlay(false);
    setInputNumber(2);
  };
  const handleOpened3 = () => {
    setLeftOpen(true);
    setVideoOrPlay(false);
    setInputNumber(3);
  };
  const handleOpened4 = () => {
    setLeftOpen(true);
    setVideoOrPlay(false);
    setInputNumber(4);
  };
  const handlePlaylistOpened = () => {
    setLeftOpen(true);
    setVideoOrPlay(true);
    setInputNumber(5);
  };
  const handlePlaylistOpened2 = () => {
    setLeftOpen(true);
    setVideoOrPlay(true);
    setInputNumber(6);
  };
  const handlePlaylistOpened3 = () => {
    setLeftOpen(true);
    setVideoOrPlay(true);
    setInputNumber(7);
  };
  const handlePlaylistOpened4 = () => {
    setLeftOpen(true);
    setVideoOrPlay(true);
    setInputNumber(8);
  };

  const handleClosed = () => {
    setLeftOpen(false);
  };
  const handleFirst = (res, { setFieldValue, values }) => {
    // console.log("first", res);
    // console.log("res", res);
    if (
      res.video_link === bestdata.video_link ||
      res.video_link === videoLinkDesc3.video_link ||
      res.video_link === videoLinkDesc4.video_link
    ) {
      // console.log("This link is already been added");
      toaster.push(showErrorNotification("This link is already been added"))
    } else {
      setVideoLinkDesc(res);
      setFieldValue("videoTitle1", `${res.video_title}`);
      setFieldValue("videoLink1", `${res.video_link}`);
    }
    handleClosed();
  };
  const handleSecond = (res, { setFieldValue, values }) => {
    // console.log("second", res);
    // console.log("link", values.videoLink1);
    if (
      res.video_link === videoLinkDesc.video_link ||
      res.video_link === videoLinkDesc3.video_link ||
      res.video_link === videoLinkDesc4.video_link
    ) {
      // console.log("This link is already been added");
      toaster.push(showErrorNotification("This link is already been added"))
    } else {
      setBestData(res);
      setFieldValue("videoTitle2", `${res.video_title}`);
      setFieldValue("videoLink2", `${res.video_link}`);
    }
    handleClosed();
  };
  const handleThird = (res, { setFieldValue, values }) => {
    // console.log("Third", res);
    if (
      res.video_link === videoLinkDesc.video_link ||
      res.video_link === bestdata.video_link ||
      res.video_link === videoLinkDesc4.video_link
    ) {
      // console.log("This link is already been added");
      toaster.push(showErrorNotification("This link is already been added"))
    } else {
      setVideoLinkDesc3(res);
      setFieldValue("videoTitle3", `${res.video_title}`);
      setFieldValue("videoLink3", `${res.video_link}`);
    }
    handleClosed();
  };
  const handleForth = (res, { setFieldValue, values }) => {
    // console.log("Forth", res);
    if (
      res.video_link === videoLinkDesc.video_link ||
      res.video_link === bestdata.video_link ||
      res.video_link === videoLinkDesc3.video_link
    ) {
      // console.log("This link is already been added");
      toaster.push(showErrorNotification("This link is already been added"))
    } else {
      setVideoLinkDesc4(res);
      setFieldValue("videoTitle4", `${res.video_title}`);
      setFieldValue("videoLink4", `${res.video_link}`);
    }
    handleClosed();
  };

  // for playlist
  const handlePlaylistFirst = (res, { setFieldValue, values }) => {
    // console.log("Forth", res);
    if (
      res.link === playLinkDesc2.link ||
      res.link === playLinkDesc3.link ||
      res.link === playLinkDesc4.link
    ) {
      // console.log("This link is already been added");
      toaster.push(showErrorNotification("This link is already been added"))
    } else {
      setPlayLinkDesc1(res);
      setFieldValue("videoPlayListTitle1", `${res.title}`);
      setFieldValue("videoPlayListLink1", `${res.link}`);
    }
    handleClosed();
  };
  const handlePlaylistSecond = (res, { setFieldValue, values }) => {
    // console.log("Forth", res);
    if (
      res.link === playLinkDesc1.link ||
      res.link === playLinkDesc3.link ||
      res.link === playLinkDesc4.link
    ) {
      // console.log("This link is already been added");
      toaster.push(showErrorNotification("This link is already been added"))
    } else {
      setPlayLinkDesc2(res);
      setFieldValue("videoPlayListTitle2", `${res.title}`);
      setFieldValue("videoPlayListLink2", `${res.link}`);
    }
    handleClosed();
  };
  const handlePlaylistThird = (res, { setFieldValue, values }) => {
    // console.log("Forth", res);
    if (
      res.link === playLinkDesc1.link ||
      res.link === playLinkDesc2.link ||
      res.link === playLinkDesc4.link
    ) {
      // console.log("This link is already been added");
      toaster.push(showErrorNotification("This link is already been added"))
    } else {
      setPlayLinkDesc3(res);
      setFieldValue("videoPlayListTitle3", `${res.title}`);
      setFieldValue("videoPlayListLink3", `${res.link}`);
    }
    handleClosed();
  };
  const handlePlaylistForth = (res, { setFieldValue, values }) => {
    // console.log("Forth", res);
    if (
      res.link === playLinkDesc1.link ||
      res.link === playLinkDesc2.link ||
      res.link === playLinkDesc4.link
    ) {
      // console.log("This link is already been added");
      toaster.push(showErrorNotification("This link is already been added"))
    } else {
      setPlayLinkDesc4(res);
      setFieldValue("videoPlayListTitle4", `${res.title}`);
      setFieldValue("videoPlayListLink4", `${res.link}`);
    }
    handleClosed();
  };
  return (
    <>
      <div className="uk-card uk-card-default uk-card-body">
        <div className="f-tab-heading-4">Add your Videos and Playlist here</div>
        <div className="text-block-14">Videos: </div>
        <div className="div-block-22">
          <div className="icon-featured-large-social">
            <div className="uui-icon-1x1-xsmall-4 w-embed">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-video"
              >
                <polygon points="23 7 16 12 23 17 23 7"></polygon>
                <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
              </svg>
            </div>
          </div>
          <div className="form-block-2 w-form">
            <div id="email-form" className="form-5">
              <input
                disabled
                type="text"
                className="text-field-2 w-input"
                maxLength="256"
                name="videoTitle1"
                placeholder="Title"
                value={formik.values.videoTitle1}
                onChange={formik.handleChange}
              />
              <input
                disabled
                type="text"
                className="text-field-2 w-input"
                maxLength="256"
                name="videoLink1"
                placeholder="Link"
                value={formik.values.videoLink1}
                onChange={formik.handleChange}
              />
              <input
                type="button"
                value="+"
                data-wait="Please wait..."
                className="submit-button w-button"
                onClick={handleOpened}
              />
            </div>
          </div>
        </div>
        <div className="div-block-22">
          <div className="icon-featured-large-social">
            <div className="uui-icon-1x1-xsmall-4 w-embed">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-video"
              >
                <polygon points="23 7 16 12 23 17 23 7"></polygon>
                <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
              </svg>
            </div>
          </div>
          <div className="form-block-2 w-form">
            <div id="email-form" className="form-5">
              <input
                disabled
                type="text"
                className="text-field-2 w-input"
                maxLength="256"
                name="videoTitle2"
                placeholder="Title"
                value={formik.values.videoTitle2}
                onChange={formik.handleChange}
              />
              <input
                disabled
                type="text"
                className="text-field-2 w-input"
                maxLength="256"
                name="videoLink2"
                placeholder="Link"
                value={formik.values.videoLink2}
                onChange={formik.handleChange}
              />
              <input
                type="button"
                value="+"
                data-wait="Please wait..."
                className="submit-button w-button"
                onClick={handleOpened2}
              />
            </div>
          </div>
        </div>
        <div className="div-block-22">
          <div className="icon-featured-large-social">
            <div className="uui-icon-1x1-xsmall-4 w-embed">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-video"
              >
                <polygon points="23 7 16 12 23 17 23 7"></polygon>
                <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
              </svg>
            </div>
          </div>
          <div className="form-block-2 w-form">
            <div id="email-form" className="form-5">
              <input
                disabled
                type="text"
                className="text-field-2 w-input"
                maxLength="256"
                name="videoTitle3"
                id="name-2"
                placeholder="Title"
                value={formik.values.videoTitle3}
                onChange={formik.handleChange}
              />
              <input
                disabled
                type="text"
                className="text-field-2 w-input"
                maxLength="256"
                name="videoLink3"
                id="name-2"
                placeholder="Link"
                value={formik.values.videoLink3}
                onChange={formik.handleChange}
              />
              <input
                type="button"
                value="+"
                data-wait="Please wait..."
                className="submit-button w-button"
                onClick={handleOpened3}
              />
            </div>
          </div>
        </div>
        <div className="div-block-22">
          <div className="icon-featured-large-social">
            <div className="uui-icon-1x1-xsmall-4 w-embed">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-video"
              >
                <polygon points="23 7 16 12 23 17 23 7"></polygon>
                <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
              </svg>
            </div>
          </div>
          <div className="form-block-2 w-form">
            <div id="email-form" className="form-5">
              <input
                disabled
                type="text"
                className="text-field-2 w-input"
                maxLength="256"
                name="videoTitle4"
                id="name-2"
                placeholder="Title"
                value={formik.values.videoTitle4}
                onChange={formik.handleChange}
              />
              <input
                disabled
                type="text"
                className="text-field-2 w-input"
                maxLength="256"
                name="videoLink4"
                id="name-2"
                placeholder="Link"
                value={formik.values.videoLink4}
                onChange={formik.handleChange}
              />
              <input
                type="button"
                value="+"
                data-wait="Please wait..."
                className="submit-button w-button"
                onClick={handleOpened4}
              />
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            type="button"
            onClick={(e) => addVideoandLinkToDescription(e, formik)}
            className="add-descp-btn"
          >
            Add to Description
          </button>
          {/* <button
            type="submit"
            className="btn btn-primary mt-2"
            style={{
              display: "flex",
              fontSize: "14px",
            }}
            onClick={(e) => addVideoandLinkToDescription(e, formik,)}
          >
            Add to Description
          </button> */}
        </div>
        <div className="text-block-14">Playlists: </div>
        <div className="div-block-22">
          <div className="icon-featured-large-social">
            <div className="uui-icon-1x1-xsmall-4 w-embed">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-video"
              >
                <polygon points="23 7 16 12 23 17 23 7"></polygon>
                <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
              </svg>
            </div>
          </div>
          <div className="form-block-2 w-form">
            <div id="email-form" className="form-5">
              <input
                disabled
                type="text"
                className="text-field-2 w-input"
                maxLength="256"
                name="videoPlayListTitle1"
                id="name-2"
                placeholder="Title"
                value={formik.values.videoPlayListTitle1}
                onChange={formik.handleChange}
              />
              <input
                disabled
                type="text"
                className="text-field-2 w-input"
                maxLength="256"
                name="videoPlayListLink1"
                id="name-2"
                placeholder="Link"
                value={formik.values.videoPlayListLink1}
                onChange={formik.handleChange}
              />
              <input
                type="button"
                value="+"
                data-wait="Please wait..."
                className="submit-button w-button"
                onClick={handlePlaylistOpened}
              />
            </div>
          </div>
        </div>
        <div className="div-block-22">
          <div className="icon-featured-large-social">
            <div className="uui-icon-1x1-xsmall-4 w-embed">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-video"
              >
                <polygon points="23 7 16 12 23 17 23 7"></polygon>
                <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
              </svg>
            </div>
          </div>
          <div className="form-block-2 w-form">
            <div id="email-form" className="form-5">
              <input
                disabled
                type="text"
                className="text-field-2 w-input"
                maxLength="256"
                name="videoPlayListTitle2"
                id="name-2"
                placeholder="Title"
                value={formik.values.videoPlayListTitle2}
                onChange={formik.handleChange}
              />
              <input
                disabled
                type="text"
                className="text-field-2 w-input"
                maxLength="256"
                name="videoPlayListLink2"
                id="name-2"
                placeholder="Link"
                value={formik.values.videoPlayListLink2}
                onChange={formik.handleChange}
              />
              <input
                type="button"
                value="+"
                data-wait="Please wait..."
                className="submit-button w-button"
                onClick={handlePlaylistOpened2}
              />
            </div>
          </div>
        </div>
        <div className="div-block-22">
          <div className="icon-featured-large-social">
            <div className="uui-icon-1x1-xsmall-4 w-embed">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-video"
              >
                <polygon points="23 7 16 12 23 17 23 7"></polygon>
                <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
              </svg>
            </div>
          </div>
          <div className="form-block-2 w-form">
            <div id="email-form" className="form-5">
              <input
                disabled
                type="text"
                className="text-field-2 w-input"
                maxLength="256"
                name="videoPlayListTitle2"
                id="name-2"
                placeholder="Title"
                value={formik.values.videoPlayListTitle3}
                onChange={formik.handleChange}
              />
              <input
                disabled
                type="text"
                className="text-field-2 w-input"
                maxLength="256"
                name="videoPlayListLink3"
                id="name-2"
                placeholder="Link"
                value={formik.values.videoPlayListLink3}
                onChange={formik.handleChange}
              />
              <input
                type="button"
                value="+"
                data-wait="Please wait..."
                className="submit-button w-button"
                onClick={handlePlaylistOpened3}
              />
            </div>
          </div>
        </div>
        <div className="div-block-22">
          <div className="icon-featured-large-social">
            <div className="uui-icon-1x1-xsmall-4 w-embed">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-video"
              >
                <polygon points="23 7 16 12 23 17 23 7"></polygon>
                <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
              </svg>
            </div>
          </div>
          <div className="form-block-2 w-form">
            <div id="email-form" className="form-5">
              <input
                disabled
                type="text"
                className="text-field-2 w-input"
                maxLength="256"
                name="videoPlayListTitle4"
                id="name-2"
                placeholder="Title"
                value={formik.values.videoPlayListTitle4}
                onChange={formik.handleChange}
              />
              <input
                disabled
                type="text"
                className="text-field-2 w-input"
                maxLength="256"
                name="videoPlayListLink4"
                data-name="Name 2"
                id="name-2"
                placeholder="Link"
                value={formik.values.videoPlayListLink4}
                onChange={formik.handleChange}
              />
              <input
                type="button"
                value="+"
                data-wait="Please wait..."
                className="submit-button w-button"
                onClick={handlePlaylistOpened4}
              />
            </div>
          </div>
        </div>
        <button
          type="button"
          style={{ float: "right", margin: "6px" }}
          onClick={(e) => addVideoPlaylist(e, formik)}
          className="add-descp-btn"
        >
          Add to Description
        </button>
        {/* <button
            type="submit"
            className="btn btn-primary mt-2"
            style={{
              display: "flex",
              fontSize: "14px",
            }}
            onClick={(e) => addVideoPlaylist(e, formik,)}
          >
            Add to Description
          </button> */}
        <VideoDrawer
          formik={formik}
          handleFirst={handleFirst}
          handleSecond={handleSecond}
          handleThird={handleThird}
          handleForth={handleForth}
          handlePlaylistFirst={handlePlaylistFirst}
          handlePlaylistSecond={handlePlaylistSecond}
          handlePlaylistThird={handlePlaylistThird}
          handlePlaylistForth={handlePlaylistForth}
          inputNumber={inputNumber}
          videoOrPlay={videoOrPlay}
          leftOpen={leftOpen}
          handleClosed={handleClosed}
        />
      </div>
    </>
  );
};

export default VideoAndPlaylist;
