import React, { useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import HeatMaps from "../Pages/HeatMaps";
import Videos from "../Pages/Videos";
import Tags from "../Pages/Tags";
import { privateRequest } from "../../../api/axiosConfig/privateRequest";
import TopVideos from "../Pages/TopVideos";
import { ProgressBar } from "react-loader-spinner";
import { useToaster } from "rsuite";
import { showErrorNotification } from "../../../Notification";

const ChannelAnalyse = () => {
  const [channelName, setChannelName] = useState("");
  const [locate, setLocate] = useState("title");
  // const [searchButton,setSearchButton] = useState(false)
  const [channelDetail, setChannelDetail] = useState();
  const [channelTitle, setChannelTitle] = useState();
  const [channelVideoResponce, setChannelVideoResponce] = useState([]);
  const [videoPloting, setVideoPloting] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const [loaders, setLoaders] = useState(false);
  const toaster = useToaster();
  const selectedss = (e, data) => {
    // console.log("e", data);
    setLocate(data);
  };

  const handleChannelLink = async (e) => {
    e.preventDefault();
    // console.log("channelName",channelName)
    setLoaders(true);
    try {
      const allChannelData = await privateRequest.post(
        `/api/youtube/bulk-data/`,
        {
          channel_url: channelName,
        }
      );
      // console.log("allChannels",allChannelData.data)
      setChannelDetail(allChannelData.data.channel_details);
      setChannelTitle(
        allChannelData.data.channel_response.items[0].snippet.title
      );
      // setChannelVideoResponce(allChannelData.data.video_response)
      // console.log("checkRespomce",allChannelData.data.video_response)
      setChannelVideoResponce(allChannelData.data.video_response);
      setVideoPloting(allChannelData.data.plotting);
      setTagsData(allChannelData.data.tags_data);
    } catch (err) {
      // console.log("allChannelError",err)
      if (err.response.data.error === "'items'") {
        toaster.push(showErrorNotification("Please Enter a valid url"));
      }
    }
    setLoaders(false);
  };
  return (
    <>
      <div className="top-serach">
        <div className="top-2nsearch">
          <div className="search-main-conatainer">
            <div className="search-contain">
              <div className="searchmarg">
                <div className="search-heading">
                  Search and analyze YouTube channels worldwide.
                </div>
              </div>
              <div className="searchInput">
                <form
                  // id="wf-form-BRIX---Top-Bar-Form"
                  // name="wf-form-BRIX---Top-Bar-Form"
                  onSubmit={(e) => handleChannelLink(e)}
                >
                  <div className="input-section">
                    <input
                      type="text"
                      className="input-channel input-channel2"
                      maxLength="256"
                      // name="BRIX-Top-Bar-Email"
                      // data-name="BRIX Top Bar Email"
                      value={channelName}
                      placeholder="Enter The Channel Url Of Any Youtube Channel To Understand Its Analytics"
                      id="BRIX-Top-Bar-Email"
                      // required=""
                      onChange={(e) => setChannelName(e.target.value)}
                    />
                    {channelName.length === 0 || loaders === true ? (
                      <input
                        type="button"
                        value={
                          channelName.length === 0 ? "search" : "Please wait..."
                        }
                        data-wait="Please wait..."
                        className="input-submit input-submit-2"
                      />
                    ) : (
                      <input
                        type="submit"
                        value="Search"
                        data-wait="Please wait..."
                        className="input-submit input-submit-2"
                      />
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tabs-analysis">
        {loaders === true ? (
          <div className="d-flex justify-content-center align-items-center mt-3">
            LOADING &nbsp;
            <ProgressBar
              height="80"
              width="80"
              ariaLabel="progress-bar-loading"
              // wrapperStyle={{}}
              wrapperClass="progress-bar-wrapper"
              borderColor="#53389e"
              barColor="#53389e"
            />
          </div>
        ) : channelVideoResponce.length === 0 ? (
          ""
        ) : (
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav
              variant="pills"
              style={{
                backgroundColor: "#eff0f6",
                paddingTop: "12px",
                paddingBottom: "12px",
                paddingLeft: "12px",
                paddingRight: "12px",
                borderRadius: "10px",
                fontSize: "14px",
              }}
              className="d-flex gap-3 pd-5 justify-content-center"
            >
              <Nav.Item
                style={{
                  borderBottomColor: "#642eff",
                  borderRadius: "50px",
                }}
              >
                <Nav.Link
                  onClick={(e) => selectedss(e, "title")}
                  style={
                    locate === "title"
                      ? {
                          borderBottomColor: "#642eff",
                          backgroundColor: "#fff",
                          color: "#160042",
                          fontWeight: "500",
                          padding: "12px 44px",
                        }
                      : {
                          backgroundColor: "#eff0f6",
                          color: "#160042",
                          padding: "12px 44px",
                        }
                  }
                  eventKey="first"
                >
                  Summary
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="second"
                  onClick={(e) => selectedss(e, "descrip")}
                  style={
                    locate === "descrip"
                      ? {
                          borderBottomColor: "#642eff",
                          backgroundColor: "#fff",
                          color: "#160042",
                          padding: "12px 44px",
                        }
                      : {
                          backgroundColor: "#eff0f6",
                          color: "#160042",
                          padding: "12px 44px",
                        }
                  }
                >
                  Top 10 Videos
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="third"
                  onClick={(e) => selectedss(e, "blog")}
                  style={
                    locate === "blog"
                      ? {
                          borderBottomColor: "#642eff",
                          backgroundColor: "#fff",
                          color: "#160042",
                          padding: "12px 44px",
                        }
                      : {
                          backgroundColor: "#eff0f6",
                          color: "#160042",
                          padding: "12px 44px",
                        }
                  }
                >
                  Upload Timing
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="forth"
                  onClick={(e) => selectedss(e, "smpost")}
                  style={
                    locate === "smpost"
                      ? {
                          borderBottomColor: "#642eff",
                          backgroundColor: "#fff",
                          color: "#160042",
                          padding: "12px 44px",
                          // marginRight:"10px"
                        }
                      : {
                          backgroundColor: "#eff0f6",
                          color: "#160042",
                          padding: "12px 44px",
                        }
                  }
                >
                  Tags
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div style={{ width: "1000px" }}>
                  <Videos
                    channelTitle={channelTitle}
                    channelDetail={channelDetail}
                    channelVideoResponce={channelVideoResponce}
                  />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div style={{ width: "1000px" }}>
                  <TopVideos
                    channelTitle={channelTitle}
                    channelVideoResponce={channelVideoResponce}
                  />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <HeatMaps
                  channelTitle={channelTitle}
                  videoPloting={videoPloting}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="forth">
                <div style={{ width: "1000px" }}>
                  <Tags channelTitle={channelTitle} tagsData={tagsData} />
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}></div>
    </>
  );
};

export default ChannelAnalyse;
