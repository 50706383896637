import React, { useState } from "react";
import { Modal, Button } from "rsuite";

const SavedModal = ({ handleClose, open, addList }) => {
  const [saveInput, setSaveInput] = useState("");
  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        open={open}
        onClose={handleClose}
      >
        <Modal.Header>
          <Modal.Title>Create New List</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container">
            <div className="col-md-12">
              <label htmlFor="validationCustom01" className="form-label">
                Enter List Name
              </label>
              <input
                control="input"
                name="name"
                type="input"
                value={saveInput}
                className="form-control"
                id="exampleFormControlInput1"
                onChange={(e) => setSaveInput(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
          <Button
            onClick={(e) => addList(e, saveInput, setSaveInput(""))}
            appearance="primary"
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SavedModal;
