import { combineReducers } from "redux";
import { channelidReducer } from "./channelidReducer";
import { loginReducer } from "./loginReducer";
import { moduleAccessReducer } from "./moduleAccessReducer";
import { getNotificationReducer } from "./notoficationReducer";
import { pricingReducer } from "./pricingReducers";
import { youtubeReducer } from "./youtubeReducer";
import {subscriptionDetailsReducer} from "./subscriptionDetailsReducer"
import { totalCreditsDetailsReducer } from "./creditsReducers";
import { channelIdDataReducer } from "./channelIdDataReducer";

export const rootReducer = combineReducers({
    login: loginReducer,
    price: pricingReducer,
    youtube: youtubeReducer,
    channels: channelidReducer,
    notified: getNotificationReducer,
    accessModule: moduleAccessReducer,
    subscribe: subscriptionDetailsReducer,
    credits:totalCreditsDetailsReducer,
    channelIdData: channelIdDataReducer,
})