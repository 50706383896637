export const priceTag = {
  acornM: `acorn-monthly-${process.env.REACT_APP_SUBSCRIPTION}`,
  acornY: `acorn-yearly-${process.env.REACT_APP_SUBSCRIPTION}`,

  sumoAcornM: `acorn-monthly-${process.env.REACT_APP_SUBSCRIPTION}`,
  sumoAcornY: `acorn-yearly-${process.env.REACT_APP_SUBSCRIPTION}`,

  palmM: `palm-monthly-${process.env.REACT_APP_SUBSCRIPTION}`,
  palmY: `palm-yearly-${process.env.REACT_APP_SUBSCRIPTION}`,

  sumoPalmM: `appsumo-palm-monthly-${process.env.REACT_APP_SUBSCRIPTION}`,
  sumoPalmY: `appsumo-palm-yearly-${process.env.REACT_APP_SUBSCRIPTION}`,

  oackM: `oak-monthly-${process.env.REACT_APP_SUBSCRIPTION}`,
  oackY: `oak-yearly-${process.env.REACT_APP_SUBSCRIPTION}`,

  sumoOackM: `appsumo-oak-monthly-${process.env.REACT_APP_SUBSCRIPTION}`,
  sumoOackY: `appsumo-oak-yearly-${process.env.REACT_APP_SUBSCRIPTION}`,
};

export const creditsPrice = [
  {
    id: 1,
    spell: `credits-14000-${process.env.REACT_APP_SUBSCRIPTION}`,
    doller: "10",
    credits: "14000",
  },
  {
    id: 2,
    spell: `credits-20000-${process.env.REACT_APP_SUBSCRIPTION}`,
    doller: "20",
    credits: "20000",
  },
  {
    id: 3,
    spell: `credits-55000-${process.env.REACT_APP_SUBSCRIPTION}`,
    doller: "30",
    credits: "55000",
  },
  {
    id: 4,
    spell: `credits-110000-${process.env.REACT_APP_SUBSCRIPTION}`,
    doller: "50",
    credits: "110000",
  },
];

export const tooltips = {
  keywordresearch:
    "Discover the perfect keywords for your YouTube videos with our advanced Keyword Research Tool. Uncover high-performing, low-competition keywords that will boost your video's visibility and drive more organic traffic to your channel. Stay ahead of the competition and optimize your content effortlessly with our user-friendly interface and comprehensive keyword analytics",
  Aigenerator:
    "The AI generator tool within the YouTube SEO manager software empowers content creators by offering a diverse range of dynamically generated, attention-grabbing video descriptions and titles. With its ability to strike a balance between complexity and simplicity, the tool ensures that your video metadata is optimized for search engines while captivating your audience with engaging and informative text.",
  tagExtractor:
    "Introducing the revolutionary 'Youtube Video Tag Extractor,' a powerful tool designed to optimize your YouTube SEO game. This innovative software analyzes any YouTube video and effortlessly extracts relevant tags, providing a burst of insightful keywords that enhance discoverability and maximize your online presence. With its user-friendly interface and advanced algorithms, this tool combines the complexity of tag extraction with the simplicity you need for effective YouTube video optimization",
  askytb:
    "Stay ahead of the curve with our YouTube SEO manager's powerful 'trending topic research' tool. Discover the hottest and most engaging content ideas by analyzing trending topics, enabling you to create captivating videos that resonate with your audience and boost your channel's visibility.",
  notes:
    "The note-taking app feature in our YouTube SEO manager software enhances your content optimization process. With its dynamic interface, you can effortlessly jot down valuable insights, keywords, and strategies, while enjoying the flexibility of longer, detailed notes combined with concise snippets, empowering you to efficiently manage your YouTube channel's search engine optimization.",
};

export const plansTools = {
  keywordResearch:
    "Discover the most popular, relevant tags to target through intelligent keyword research and analysis.",
  tag_extract:
    "Automatically extract keyword tags from top-ranking YouTube videos in your niche to optimize your own content.",
  ask_ytb:
    "Get suggestions for trending search queries and video ideas that are gaining popularity on YouTube",
  ai_title:
    "Craft clickable, SEO-friendly video titles tailored to your niche with AI-powered generation.",
  ai_description:
    "Generate engaging video descriptions optimized for YouTube search and human readers.",
  ai_social:
    "Create shareable social media posts to promote your videos and channel using AI.",
  ai_blog:
    "Produce SEO-optimized blog posts related to your videos to improve discovery and viewership.",
  // advance_analys:"Track and analyze the performance of your YouTube channel in depth to inform video strategy.",
  subscription_credits: "Subuscription Credits",
  multi_channel: "Multichannels",
  dashboard_with_channel_analysis: "dashboard channel analisis",
  advance_channel_state: "advance channel state",
  chrome_extansion: "Chrome Extantion",
  mobile_app: "Mobile Application",
};

export const faqs = [
  {
    id: 1,
    title: "What is the subscription model of YTubeBooster PRO?",
    answer:
      "YTubeBooster Pro provides a subscription model that includes three plans, each with the option of monthly or yearly subscription. These plans are designed based on the growth stages of YouTube Creators and are named Acorn (previously Boost Silver), Palm, and Oak. The Palm and Oak plans come with pre-filled Credits, which can be primarily used for AI-powered features available for each respective plan. However, Acorn plan users must purchase Credits to access AI-powered features available in this plan.",
  },
  {
    id: 2,
    title: "Is YTubeBooster PRO for me?",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
  },
  {
    id: 3,
    title: " Is YTubeBooster PRO for me?",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
  },
  {
    id: 4,
    title: "What does YTubeBooster PRO do with my data?",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
  },
  {
    id: 5,
    title: "Can I add multiple YouTube channels and users to my account?",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
  },
  {
    id: 6,
    title:
      "Does YTubeBooster PRO publish all my optimisations direct onto my YouTube Channel?",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
  },
  {
    id: 7,
    title: "Will I get access to all future updates and features?",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
  },
  {
    id: 8,
    title: "What if I change my mind and want to cancel my subscription?",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
  },
  {
    id: 9,
    title: "What payment methods do you support?",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
  },
  {
    id: 10,
    title: "Can I upgrade or downgrade to a different plan at a later time?",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
  },
  {
    id: 11,
    title: "What’s the subscription commitment?",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
  },
  {
    id: 12,
    title: "Do you offer free trials?",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
  },
];

export const AiHeaders = {
  // Ai Video Content Generator String
  vititle: "AI Video Content Generator",
  viblueDesc: `Discover the power of Ytubebooster's AI-driven ingenuity, guiding
  you towards crafting captivating content that grabs viewers'
  attention.`,
  vilast: `Unlock your content creation potential with our innovative tool. While it's an invaluable companion in your creative journey, remember that true brilliance stems from your own unique ideas. Combine your creative genius with our AI assistance for a winning content formula. Rely on your creativity; our tool is just a helping hand in your content creation arsenal.`,
  // smPost Generator String
  smtitle: "Generate Social Media Posts For Your Video",
  smdescription: `Discover the power of Ytubebooster's AI-driven ingenuity, guiding you towards crafting captivating content that grabs viewers' attention.`,
  smlast: `Unlock your content creation potential with our innovative tool. While it's an invaluable companion in your creative journey, remember that true brilliance stems from your own unique ideas. Combine your creative genius with our AI assistance for a winning content formula. Rely on your creativity; our tool is just a helping hand in your content creation arsenal.`,

  // Blog Generator String
  blogtitle: `Generate Blog Post For Your Video`,
  blogdescription: `Discover the power of Ytubebooster's AI-driven ingenuity, guiding
you towards crafting captivating content that grabs viewers'
attention.`,
  bloglast: `Unlock your content creation potential with our innovative tool. While it's an invaluable companion in your creative journey, remember that true brilliance stems from your own unique ideas. Combine your creative genius with our AI assistance for a winning content formula. Rely on your creativity; our tool is just a helping hand in your content creation arsenal.`,

  // Description Generator String
  topTitle: `Generate Description For Your Video`,
  topdescription: `Discover the power of Ytubebooster's AI-driven ingenuity, guiding
  you towards crafting captivating content that grabs viewers'
  attention.`,
  toplast: `Unlock your content creation potential with our innovative tool. While it's an invaluable companion in your creative journey, remember that true brilliance stems from your own unique ideas. Combine your creative genius with our AI assistance for a winning content formula. Rely on your creativity; our tool is just a helping hand in your content creation arsenal.`,

  // Title Generator String
  titleGenTitle: `Generate Title For Your Video`,
  titleGendesctiption: `Discover the power of Ytubebooster's AI-driven ingenuity, guiding
  you towards crafting captivating content that grabs viewers'
  attention.`,
  titleGenlast: `Unlock your content creation potential with our innovative tool. While it's an invaluable companion in your creative journey, remember that true brilliance stems from your own unique ideas. Combine your creative genius with our AI assistance for a winning content formula. Rely on your creativity; our tool is just a helping hand in your content creation arsenal.`,
};
