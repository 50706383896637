import React from "react";
import classes from "./TermsService.module.css";
import { Modal } from "rsuite";

const TermsService = ({ termsHidden, handleTermsViewClose,setHidden }) => {
  return (
    <div>
      <Modal
      className={classes.modBoad}
        overflow
        size="lg"
        backdrop="static"
        open={termsHidden}
        onClose={() => handleTermsViewClose()}
      >
        <Modal.Header>
          {/* <Modal.Title>Terms of Service</Modal.Title> */}
        </Modal.Header>

        <Modal.Body className={`${classes.scrollbars}`}>
              <p className={`${classes.c23}`}>
                <span className={`${classes.c10} ${classes.c22}`}>
                  Terms of Service
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  Updated on: 15 June 2023
                </span>
              </p>
              <p className={`${classes.c16}`}>
                <span className={`${classes.c11} ${classes.c10}`}>
                  Welcome to the YTubeBooster (
                </span>
                <span className={`${classes.c11} ${classes.c10}`}>
                  a trading name of
                </span>
                &nbsp;
                <span className={`${classes.c11} ${classes.c10}`}>
                  Simera Ltd, UK)
                  <br />
                  (&quot;Us&quot; or &quot;Our&quot; or &quot;We&quot;) Service!
                </span>
              </p>
              <p className={`${classes.c15}`}>
                <span className={`${classes.c1}`}></span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  These Terms of Service (&ldquo;Terms&rdquo;) are a legal
                  contract between You and Us and govern Your use of this
                  website, the services and related technologies for optimizing
                  and managing your YouTube channel(s) that We may provide
                  through any of Our websites (&ldquo;Services&rdquo;), and all
                  text, data, information, software, graphics, photographs and
                  more that We and Our affiliates may make available to You (all
                  of which We refer to as &ldquo;Materials&rdquo;). Unless
                  otherwise stated in these Terms, references to the
                  &ldquo;Service&rdquo; include all of Our websites and the
                  Services.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING THE
                  SERVICE. USING THE SERVICE OR ANY PART THEREOF INDICATES THAT
                  YOU HAVE BOTH READ AND ACCEPTED THESE TERMS. YOU CANNOT USE
                  THE SERVICE OR ANY PART THEREOF IF YOU DO NOT ACCEPT THESE
                  TERMS. CHANGES.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  We may alter the Materials and services We offer You through
                  the Service and/or choose to modify, suspend or discontinue
                  the Service at any time. We may also change, update, add or
                  remove provisions (collectively, &ldquo;modifications&rdquo;)
                  of these Terms from time to time. Because everyone benefits
                  from clarity, We promise to inform You of any modifications to
                  these Terms by posting them on the Service and, if You have
                  registered with Us, by describing the modifications to these
                  Terms in an email that We will send to the address that You
                  provided when registering on the Service. To be sure We
                  properly reach Your email inbox, We just ask that You let Us
                  know if Your preferred email address changes at any time after
                  Your registration.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  If You object to any such modifications, Your sole recourse
                  shall be to cease using the Service. Continued use of the
                  Service following notice of any such modifications indicates
                  You acknowledge and agree to be bound by the modifications.
                  Also, please know that these Terms may be superseded by
                  expressly-designated legal notices or terms of individual
                  Services. These expressly-designated legal notices or terms
                  are incorporated into these Terms and supersede the
                  provision(s) of these Terms that are designated as being
                  superseded.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  In the event, during the initial term, that You cancel use of
                  the Service due to our modifications of the Service that
                  substantially or materially limit the value of the Service, or
                  if We discontinue the Service, We shall reimburse you for the
                  prorated amount of money already paid for the Service that
                  will be unused from the date of the termination through the
                  end of the initial term.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  After the initial term, any termination will take effect 30
                  days from Your notice to Us and you will be refunded for the
                  prorated amount of unused fees, if any (i.e., fees that are
                  intended to pay use of the Service beyond the effective date
                  of the termination), already paid to Us.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c8} ${classes.c10}`}>
                  GENERAL USE
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  We invite You to use the Service for individual, consumer
                  purposes (&quot;Permitted Purposes&quot;) &ndash; enjoy!
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  By using the Service, You promise that You are at least 18
                  years of age. If you are not 18 yet, you may not access or use
                  any part of the Service and you are authorized by Your
                  employer to enter into this agreement on behalf of said
                  employer. .
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  In these Terms we are granting You a limited, personal,
                  non-exclusive and non-transferable license to use and to
                  display the Materials and to access and use the Service for
                  individual, consumer purposes (&ldquo;Permitted
                  Purposes&rdquo;); Your right to use the Materials is
                  conditioned on Your compliance with these Terms. You have no
                  other rights in the Service or any Materials and You may not
                  modify, edit, copy, reproduce, create derivative works of,
                  reverse engineer, alter, enhance or in any way exploit any of
                  the Service or Materials in any manner. If You make copies of
                  any of the Service then We ask that You be sure to keep on the
                  copies all of Our copyright and other proprietary notices as
                  they appear on the Service.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  Unfortunately, if You breach any of these Terms the above
                  license will terminate automatically and You must immediately
                  destroy any downloaded or printed materials (and any copies
                  thereof).
                  <br />
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c8} ${classes.c10}`}>
                  USING THIS WEBSITE AND THE SERVICE
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  We appreciate You visiting this website and allow You to do
                  just that &ndash; stop by and check it out without even
                  registering with Us!
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  However, in order to access certain password-restricted areas
                  of this website and to use certain Services and Materials
                  offered on and through the Service, You must successfully
                  register an account with Us.
                  <br />
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c8} ${classes.c10}`}>
                  PASSWORD RESTRICTED AREAS OF THE SERVICE
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  If You want an account with Us, You must submit the following
                  information through the account registration area:
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  A working email address;
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>First and last name;</span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  Preferred username and password.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  You may also provide additional, optional information so that
                  We can provide You a more customized experience when using the
                  Service &ndash;but, We will leave that decision with You. Once
                  You submit the required registration information, We alone
                  will determine whether or not to approve Your proposed
                  account. If approved, You will be sent an email detailing how
                  to complete Your registration. For so long as You use the
                  account, You agree to provide true, accurate, current, and
                  complete information which can be accomplished by logging into
                  Your account and making the relevant changes. And, if You
                  forget Your password &ndash; no worries as We will happily
                  send a password update to Your provided email address.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  You are responsible for complying with these Terms when You
                  access any part of the Service. Because it is Your account, it
                  is Your job to obtain and maintain all equipment and services
                  needed for access to and use of This Service as well as paying
                  related charges. It is also Your responsibility to maintain
                  the confidentiality of Your password(s), including any
                  password of any third-party site that We may allow You to use
                  to access the Service. Should You believe Your password or
                  security for This Service has been breached in any way, You
                  must immediately notify Us.
                </span>
              </p>
              <p className={`${classes.c15}`}>
                <span className={`${classes.c1}`}></span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={classes.c0}>CONTENT FROM YOUTUBE</span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c12} ${classes.c10} ${classes.c6}`}>
                  YTubeBooster accesses public Content from third-party social
                  networking services (SNS), such as YouTube. YTubeBooster uses
                  YouTube APIs, and by using YouTube Content within
                  YTubeBooster&rsquo; Sites and Services you are agreeing to be
                  bound to YouTube&rsquo;s Terms of Service, which can be found
                  at{" "}
                </span>
                <span
                  className={`${classes.c12} ${classes.c10} ${classes.c6} ${classes.c21}`}
                >
                  <a
                    className={`${classes.c3}`}
                    rel="noopener noreferrer"
                    href="https://www.google.com/url?q=https://www.youtube.com/t/terms&amp;sa=D&amp;source=editors&amp;ust=1686658595381632&amp;usg=AOvVaw2gF8TWJInhT_zBDuov5IzZ"
                    target="_blank"
                  >
                    https://www.youtube.com/t/terms
                  </a>
                </span>
                <span className={`${classes.c12} ${classes.c10} ${classes.c6}`}>
                  &nbsp;and Google&rsquo;s Privacy Policy, which can be found at{" "}
                </span>
                <span
                  className={`${classes.c12} ${classes.c21} ${classes.c10} ${classes.c6}`}
                >
                  <a
                    className={`${classes.c3}`}
                    rel="noopener noreferrer"
                    href="https://www.google.com/url?q=http://www.google.com/policies/privacy&amp;sa=D&amp;source=editors&amp;ust=1686658595382045&amp;usg=AOvVaw0wAIoNFsZpkFMPzDRsRref"
                    target="_blank"
                  >
                    http://www.google.com/policies/privacy
                  </a>
                </span>
                <span className={`${classes.c12} ${classes.c10} ${classes.c6}`}>
                  . Third-party social networking services, such as Google and
                  YouTube, may update their Terms of Service and Privacy
                  Policies from time to time, and YTubeBooster is not
                  responsible for your review of any changes or updates to
                  these. We recommend that you review YouTube&rsquo;s Terms of
                  Service and Google&rsquo;s Privacy Policy regularly.
                </span>
              </p>
              <p className={`${classes.c15}`}>
                <span className={`${classes.c1}`}>
                  <br />
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={classes.c0}>SUBSCRIPTIONS</span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  By registering for an account with Us, You become a
                  &ldquo;Subscriber&rdquo; with access to certain
                  password-restricted areas of the Service and to use certain
                  Services and Materials offered on and through the Service (a
                  &ldquo;Subscription&rdquo;). Each Subscription and the rights
                  and privileges provided to each Subscriber are personal and
                  non-transferable. All payments of Subscription fees will be in
                </span>
                <span className={`${classes.c9}`}>US Dollars($)</span>
                <span className={`${classes.c2}`}>
                  &nbsp;and are non-refundable, except as otherwise explicitly
                  stated herein..
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  The fee that We will charge You for Your Subscription will be
                  the price stated in the attached Purchase Order. We reserve
                  the right to change prices for Subscriptions at any time, and
                  do not provide price protection or refunds in the event of
                  promotions or price decreases. If you upgrade your
                  Subscription level, we will provide a pro-rated fee for your
                  first subscription period based on the amount of unused fees
                  already paid.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  You may pay for Your Subscription fees only with credit and
                  debit card payments. We will charge Your credit or debit card
                  for Your first Subscription fee on the date that We process
                  Your order for Your Subscription. Once Your credit or debit
                  card is charged the first Subscription fee, You will receive a
                  confirmation email notifying You of Your ability to access
                  those Subscription-only portions of, and Materials on, the
                  Service.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span class="c0">IMPORTANT NOTICE:</span>
                <span className={`${classes.c12} ${classes.c10} ${classes.c6}`}>
                  &nbsp;DEPENDING ON THE BILLING OPTION YOU CHOOSE WHEN YOU
                  REGISTER FOR YOUR SUBSCRIPTION, WE WILL AUTOMATICALLY RENEW
                  YOUR SUBSCRIPTION ON EACH MONTHLY (OR YEARLY) ANNIVERSARY OF
                  THAT DATE THAT WE CHARGE YOUR CREDIT OR DEBIT CARD FOR THE
                  FIRST SUBSCRIPTION FEE AND, AS AUTHORIZED BY YOU DURING THE
                  MEMBERSHIP SIGN-UP PROCESS, WE WILL CHARGE YOUR CREDIT OR
                  DEBIT CARD WITH THE APPLICABLE SUBSCRIPTION FEE AND ANY SALES
                  OR SIMILAR TAXES THAT MAY BE IMPOSED ON YOUR SUBSCRIPTION FEE
                  PAYMENT (UNLESS YOU CANCEL PRIOR TO THE ANNIVERSARY DATE).
                  EACH SUBSCRIPTION RENEWAL PERIOD IS FOR ONE MONTH OR ONE YEAR,
                  DEPENDING ON THE BILLING OPTION YOU CHOOSE. YOU MAY CANCEL OR
                  DOWNGRADE YOUR SUBSCRIPTION AT ANY TIME FROM WITHIN THE
                  SERVICE OR BY CONTACTING US AT{" "}
                </span>
                <span style={{ color: "black" }}>info@ytubeboosterpro.app</span>
                <span className={`${classes.c10} ${classes.c6} ${classes.c12}`}>
                  . IF YOU DOWNGRADE OR CANCEL YOUR SUBSCRIPTION, YOU WILL ENJOY
                  YOUR CURRENT SUBSCRIPTION BENEFITS UNTIL THE EXPIRATION OF THE
                  THEN-CURRENT SUBSCRIPTION PERIOD FOR WHICH YOU HAVE PAID, AND
                  YOUR SUBSCRIPTION BENEFITS WILL DOWNGRADE OR EXPIRE AT THE END
                  OF THE THEN-CURRENT SUBSCRIPTION PERIOD.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  You are liable for paying any and all applicable country/
                </span>
                <span className={`${classes.c9}`}>l</span>
                <span className={`${classes.c2}`}>
                  ocal sales and use taxes for the purchase of Your Subscription
                  based on the mailing address that You provide when You
                  register as a Subscriber, and You authorize Us to charge Your
                  credit or debit card for any such applicable taxes.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c8}`}>
                  <br />
                </span>
                <span className={`${classes.c8} ${classes.c10}`}>PAYMENTS</span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  You agree to pay all applicable fees related to Your use of
                  the Service. We may suspend or terminate Your account and/or
                  access to the Service if Your payment is late and/or Your
                  offered payment method (e.g., credit card or debit card)
                  cannot be processed. By providing a payment method, You
                  expressly authorize Us to charge the applicable fees on said
                  payment method as well as taxes and other charges incurred
                  thereto at regular intervals, all of which depend on Your
                  particular Subscription and utilized services.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  We understand that You might cancel Your account, but please
                  know that We will not provide any refund(s) and You will be
                  responsible for paying any balance due on the account. To make
                  things less complicated, You agree that We may charge any
                  unpaid fees to Your provided payment method and/or send You a
                  bill for such unpaid fees.
                  <br />
                  <br />
                  <br />
                  <br />
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c8} ${classes.c10}`}>
                  ELECTRONIC COMMUNICATIONS
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  By using the Service, both parties consent to receiving
                  electronic communications from the other party. These
                  electronic communications may include notices about applicable
                  fees and charges, transactional information and other
                  information concerning or related to the Service. These
                  electronic communications are part of Your relationship with
                  Us. Both parties agree that any notices, agreements,
                  disclosures or other communications that We sent between
                  parties electronically will satisfy any legal communication
                  requirements, including that such communications be in
                  writing.
                  <br />
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c8} ${classes.c10}`}>
                  PRIVACY POLICY
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  We respect the information that You provide to Us, and want to
                  be sure You fully understand exactly how We use that
                  information. So, please review Our{" "}
                </span>
                {/* <Link style={{ color: "black" }} to="/privacy-policy"> */}
                  <span style={{cursor:"pointer"}} onClick={() => setHidden(true)} className={`${classes.c9}`}>Privacy Policy</span>
                {/* </Link> */}
                <span className={`${classes.c2}`}>
                  &nbsp;(&ldquo;
                  <span>Privacy Policy</span>
                  &rdquo;) which explains everything. THIRD-PARTY SITES AND
                  SERVICES.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  We think links are convenient, and We sometimes provide links
                  on the Service to third-party websites. If You use these
                  links, You will leave the Service. We are not obligated to
                  review any third-party websites that You link to from the
                  Service, We do not control any of the third-party websites,
                  and We are not responsible for any of the third-party websites
                  (or the products, services, or content available through any
                  of them). Thus, We do not endorse or make any representations
                  about such third-party websites, any information, software,
                  products, services, or materials found there or any results
                  that may be obtained from using them. If You decide to access
                  any of the third-party websites linked to from the Service,
                  You do this entirely at Your own risk and You must follow the
                  privacy policies and terms and conditions for those
                  third-party websites.
                </span>
              </p>
              <p className={`${classes.c4}`} id="h.gjdgxs">
                <span className={`${classes.c2}`}>
                  The Service also enables linking between various online
                  third-party services, including YouTube (&ldquo;Third-Party
                  Services&rdquo;). To take advantage of these feature and
                  capabilities, we may ask you to authenticate, register for or
                  log into Third-Party Services through the Service or on the
                  websites of their respective providers and, if applicable,
                  allow you to configure your privacy settings in that
                  third-party website account to permit Your activities on the
                  Service to be shared with Your contacts in your third-party
                  site account. For more information about the implications of
                  activating these Third-Party Services and Our use, storage and
                  disclosure of information related to you and your use of
                  Third-Party Services within the Service, please see the{" "}
                </span>
                {/* <Link style={{ color: "black" }} to="/privacy-policy"> */}
                  {" "}
                  <span style={{cursor:"pointer"}} onClick={() => setHidden(true)} className={`${classes.c2} ${classes.c21}`}>
                    Privacy Policy
                  </span>
                {/* </Link> */}
                <span className={`${classes.c2}`}>
                  . However, please remember that the manner in which You may
                  use such Third-Party Services and the manner in which they
                  will use, store and disclose your information is governed
                  solely by the policies of such third parties.
                  <br />
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c8} ${classes.c10}`}>
                  UNAUTHORIZED ACTIVITIES
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  To be clear, We authorize Your use of the Service only for
                  Permitted Purposes. Any other use of the Service beyond the
                  Permitted Purposes is prohibited and, therefore, constitutes
                  unauthorized use of the Service. This is because, as between
                  You and Us, all rights in the Service remain Our property.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  Unauthorized use of the Service may result in violation of
                  various United Kingdom and international copyright laws. We
                  prefer keeping this relationship drama-free, so When using the
                  Service, you agree to abide by common standards of etiquette
                  and act in accordance with the law. For example, you agree not
                  to not to use the Service:
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  In a manner that modifies, publicly displays, publicly
                  performs, reproduces or distributes any of the Service;
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  In a manner that violates any local, state, national, foreign,
                  or international statute, regulation, rule, order, treaty, or
                  other law;
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  To stalk, harass, or harm another individual;
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  To impersonate any person or entity or otherwise misrepresent
                  Your affiliation with a person or entity;
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  To interfere with or disrupt the Service or servers or
                  networks connected to the Service;
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  To use any data mining, robots, or similar data gathering or
                  extraction methods in connection with the Service
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  Through any means other than through the interface that is
                  provided by YTubeBooster for use in accessing the Service; or
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  To attempt to gain unauthorized access to any portion of the
                  Service or any other accounts, computer systems, or networks
                  connected to the Service, whether through hacking, password
                  mining, or any other means.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  To use the YTubeBooster Services, or allow access to it, in a
                  manner that circumvents contractual usage restrictions or that
                  exceeds Customer&rsquo;s authorized use or usage metrics set
                  forth in this Agreement, including the applicable Order Form;
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  To license, sub-license, sell, re-sell, rent, lease, transfer,
                  distribute, timeshare or otherwise make any portion of the
                  YTubeBooster Services or Documentation available for access by
                  third parties except as otherwise expressly provided in this
                  Agreement;
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  To access or use the YTubeBooster Services or Documentation
                  for the purpose of developing or operating products or
                  services intended to be offered to third parties in
                  competition with the YTubeBooster Services or allow access by
                  a direct competitor of YTubeBooster;
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  To reverse engineer, decompile, disassemble, copy, or
                  otherwise attempt to derive source code or other trade secrets
                  from or about any of the YTubeBooster Services or
                  technologies, unless and then only to the extent expressly
                  permitted by applicable law, without consent;&nbsp;
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  To use the YTubeBooster Services or Documentation in a way
                  that (i) violates or infringes upon the rights of a third
                  party, including those pertaining to: contract, intellectual
                  property, privacy, or publicity; or (ii) effects or
                  facilitates the storage or transmission of libelous, tortious,
                  or otherwise unlawful material including, but not limited to,
                  material that is harassing, threatening, or obscene;
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  Remember, these are examples only and the list above is not a
                  complete list of everything that You are not permitted to do.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  You agree to hire attorneys to defend Us if You violate these
                  Terms and that violation results in a problem for Us. You also
                  agree to pay any damages that We may end up having to pay as a
                  result of Your violation. You alone are responsible for any
                  violation of these Terms by You. We reserve the right to
                  assume the exclusive defense and control of any matter
                  otherwise subject to indemnification by You and, in such case,
                  You agree to cooperate with Our defense of such claim.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c12} ${classes.c10} ${classes.c6}`}>
                  You also release, waive, discharge and promise not to sue or
                  bring any claim of any type against Us for any loss, damage or
                  injury relating in any way to the Service or any part thereof.
                  IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL
                  CODE SECTION 1542, WHICH SAYS: &ldquo;A GENERAL RELEASE DOES
                  NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR
                  SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE
                  RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED
                  HIS SETTLEMENT WITH THE DEBTOR.&rdquo; IF YOU ARE A RESIDENT
                  OF ANOTHER JURISDICTION, YOU WAIVE ANY COMPARABLE STATUTE OR
                  DOCTRINE.
                  <br />
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c8} ${classes.c10}`}>
                  YTUBEBOOSTER REPRESENTATIONS AND WARRANTIES
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  We represent and warrant that: (i) We have full right, power,
                  and authority to enter into and perform Our obligations under
                  these Terms; (ii) the Service will be provided in a
                  professional and workmanlike manner; and (iii) we have the
                  right, title, and interest to the Materials sufficient to
                  grant the rights granted under these Terms.
                  <br />
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c8} ${classes.c10}`}>
                  INDEMNIFICATION
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  Each party agrees to defend the other party, its corporate
                  affiliates, and their respective agents, officers, directors,
                  shareholders, partners, employees and licensees, and each of
                  their successors and permitted assigns (collectively, the
                  &ldquo;Indemnified Parties&rdquo;) and hold each of them
                  harmless from and against any and all claims and demands
                  (collectively, &ldquo;Claims&rdquo;), brought by a third party
                  based upon or arising in any manner, directly or indirectly,
                  out of or in connection with such party&rsquo;s breach of its
                  representations, warranties or obligations as provided in
                  these Terms. The indemnifying party shall pay all damages
                  finally awarded or paid in settlement of any such Claims. The
                  Indemnified Parties must notify the indemnifying party
                  promptly in writing of any claim for indemnification
                  hereunder, and provide, at the indemnifying party&rsquo;s
                  expense (to the extent of out-of-pocket expenses only), all
                  reasonably necessary assistance, information and authority to
                  allow the indemnifying party to control the defense and
                  settlement of such claim; provided that the failure of the
                  Indemnified Parties to promptly inform the indemnifying party
                  of any Claim shall not excuse the indemnifying party of its
                  obligations hereunder except to the extent such failure
                  materially prejudices the indemnifying party. Notwithstanding
                  the foregoing, the indemnifying party shall not enter into any
                  settlement of the defense of such action, without the
                  Indemnified Party&rsquo;s prior written consent, which consent
                  shall not be unreasonably withheld or delayed. The Indemnified
                  Party may participate at its expense in the defense and/or
                  settlement of any such action with counsel of its choosing and
                  at its sole expense.
                  <br />
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c8} ${classes.c10}`}>
                  PROPRIETARY RIGHTS
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  &quot;YTubeBooster&quot; is a trademark that belongs to Us.
                  Other trademarks, names and logos on the Service are the
                  property of their respective owners.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  Unless otherwise specified in these Terms, all Materials,
                  including the arrangement of them on the Service are Our sole
                  property. All rights not expressly granted herein are
                  reserved. Except as otherwise required or limited by
                  applicable law, any reproduction, distribution, modification,
                  retransmission, or publication of any copyrighted material is
                  strictly prohibited without the express written consent of the
                  copyright owner or license.
                  <br />
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c8} ${classes.c10}`}>
                  INTELLECTUAL PROPERTY INFRINGEMENT
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  We respect the intellectual property rights of others and
                  encourage You to do the same. Accordingly, We have a policy of
                  removing User Content that violates intellectual property
                  rights of others, suspending access to the Service (or any
                  portion thereof) to any user who uses the Service in violation
                  of someone&rsquo;s intellectual property rights, and/or
                  terminating in appropriate circumstances the account of any
                  user who uses the Service in violation of someone&rsquo;s
                  intellectual property rights.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c12} ${classes.c10} ${classes.c6}`}>
                  Pursuant to You also release, waive, discharge and promise not
                  to sue or bring any claim of any type against Us for any loss,
                  damage or injury relating in any way to the Site or any part
                  thereof. IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE
                  CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS: &ldquo;A
                  GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR
                  DOES NOT KNOW OR SPECT TO EXIST IN HIS FAVOR AT THE TIME OF
                  EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MT HAVE
                  MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.&rdquo; IF
                  YOU ARE A RESIDENT OF ANOTHER JURISDICTION, YOU WAIVE ANY
                  COMPARABLE STATUTE OR DOCTRINE., We have implemented
                  procedures for receiving written notification of claimed
                  copyright infringement and for processing such claims in
                  accordance with such law. If You believe Your copyright or
                  other intellectual property right is being infringed by a user
                  of the Service, please provide written notice to Our Agent for
                  notice of claims of infringement:
                </span>
              </p>
              <p className={classes.c7}>
                <span className={`${classes.c12} ${classes.c10} ${classes.c6}`}>
                  Attn: DMCA Agent
                </span>
              </p>
              <p className={classes.c7}>
                <span className={`${classes.c2}`}>CC:</span>
              </p>
              <p className={classes.c7}>
                <span className={`${classes.c2}`}>
                  Email: info@ytubeboosterpro.app
                </span>
              </p>
              <p className={classes.c7}>
                <span className={`${classes.c2}`}>
                  To be sure the matter is handled immediately, Your written
                  notice must:
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  Contain Your physical or electronic signature;
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  Identify the copyrighted work or other intellectual property
                  alleged to have been infringed;
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  Identify the allegedly infringing material in a sufficiently
                  precise manner to allow Us to locate that material;
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  Contain adequate information by which We can contact You
                  (including postal address, telephone number, and email
                  address);
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  Contain a statement that You have a good faith belief that use
                  of the copyrighted material or other intellectual property is
                  not authorized by the owner, the owner&#39;s agent or the law;
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  Contain a statement that the information in the written notice
                  is accurate; and
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  Contain statement, under penalty of perjury, that You are
                  authorized to act on behalf of the copyright or other
                  intellectual property right owner.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  Unless the notice pertains to copyright or other intellectual
                  property infringement, the Agent will be unable to address the
                  listed concern.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  Please also note that for copyright infringements under
                  Section 512(f) of the Copyright Act, any person who knowingly
                  materially misrepresents that material or activity is
                  infringing may be subject to liability.
                </span>
              </p>
              <p className={classes.c7}>
                <span className={`${classes.c2}`}>
                  Submitting a Digital Millennium Copyright Act
                  (&ldquo;DMCA&rdquo;) Counter-Notification
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  We will notify You that We have removed or disabled access to
                  copyright-protected material that You provided, if such
                  removal is pursuant to a validly received DMCA takedown
                  notice. In response, You may provide Our Agent with a written
                  counter-notification that includes the following information:
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  Your physical or electronic signature;
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  Identification of the material that has been removed or to
                  which access has been disabled, and the location at which the
                  material appeared before it was removed or access to it was
                  disabled;
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  A statement from You under the penalty of perjury, that You
                  have a good faith belief that the material was removed or
                  disabled as a result of a mistake or misidentification of the
                  material to be removed or disabled; and
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  Your name, physical address and telephone number, and a
                  statement that You consent to the jurisdiction of a court for
                  the judicial district in which Your physical address is
                  located, or if Your physical address is outside of the United
                  Kingdom, for any&nbsp;
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  judicial district in which We may be located, and that You
                  will accept service of process from the person who provided
                  notification of allegedly infringing material or an agent of
                  such person.
                </span>
              </p>
              <p className={classes.c7}>
                <span className={`${classes.c2}`}>
                  Termination of Repeat Infringers
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  We reserve the right, in Our sole discretion, to terminate the
                  account or access of any user of the Service who is the
                  subject of repeated DMCA or other infringement notifications.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c9}`}>
                  <br />
                </span>
                <span className={`${classes.c8} ${classes.c10}`}>
                  DISCLAIMER OF WARRANTIES
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  THIS SERVICE AND ALL MATERIALS ARE PROVIDED &quot;AS IS&quot;
                  AND &quot;WITH ALL FAULTS&quot;. THE ENTIRE RISK AS TO THEIR
                  QUALITY AND PERFORMANCE IS WITH YOU.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND (EXPRESS,
                  IMPLIED OR STATUTORY) WITH RESPECT TO THE SERVICE AND
                  MATERIALS, WHICH INCLUDES BUT IS NOT LIMITED TO, ANY IMPLIED
                  OR STATUTORY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                  PARTICULAR USE OR PURPOSE, TITLE, AND NON-INFRINGEMENT OF
                  INTELLECTUAL PROPERTY RIGHTS.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  THIS MEANS THAT WE DO NOT PROMISE YOU THAT THE SERVICE IS FREE
                  OF PROBLEMS. Without limiting the generality of the foregoing,
                  We make no warranty that the Service will meet Your
                  requirements or that the Service will be uninterrupted,
                  timely, secure, or error free or that defects in the Service
                  will be corrected. We make no warranty as to the results that
                  may be obtained from the use of the Service or as to the
                  accuracy or reliability of any information obtained through
                  the Service. No advice or information, whether oral or
                  written, obtained by You through the Service or from Us or Our
                  subsidiaries/other affiliated companies shall create any
                  warranty. We disclaim all equitable indemnities.
                  <br />
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c8} ${classes.c10}`}>
                  LIMITATION OF LIABILITY
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  EXCEPT WITH RESPECT TO WILLFUL MISCONDUCT, THE INDEMNIFICATION
                  OBLIGATIONS AND CONFIDENTIALITY OBLIGATIONS HEREUNDER, NEITHER
                  PARTY SHALL BE LIABLE TO THE OTHER FOR ANY DAMAGES RESULTING
                  FROM YOUR USE OF THE SERVICE OR ANY PART THEREOF, OR FROM YOUR
                  DISPLAYING, COPYING, UPLOADING, LINKING OR DOWNLOADING ANY
                  MATERIALS OR CONTENT TO OR FROM THE SERVICE. EXCEPT WITH
                  RESPECT TO WILLFUL MISCONDUCT, THE INDEMNIFICATION OBLIGATIONS
                  AND CONFIDENTIALITY OBLIGATIONS HEREUNDER, IN NO EVENT SHALL
                  EITHER PARTY BE LIABLE TO THE OTHER FOR ANY INDIRECT,
                  EXTRAORDINARY, EXEMPLARY, PUNITIVE, SPECIAL, INCIDENTAL, OR
                  CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF DATA, REVENUE,
                  PROFITS, USE OR OTHER ECONOMIC ADVANTAGE) HOWEVER ARISING,
                  EVEN IF WE KNOW THERE IS A POSSIBILITY OF SUCH DAMAGE.
                  <br />
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c8} ${classes.c10}`}>
                  LOCAL LAWS; EXPORT CONTROL
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  We control and operate the Service from Our headquarters in
                  United Kingdom and the entirety of the Service may not be
                  appropriate or available for use in other locations. If You
                  use the Service outside United Kingdom, You are solely
                  responsible for following applicable local laws, including but
                  not limited to local laws concerning online conduct and
                  acceptable content.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c9}`}>
                  <br />
                </span>
                <span className={`${classes.c8} ${classes.c10}`}>FEEDBACK</span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  Any feedback that You provide to Us about the Service (e.g.,
                  comments, questions, suggestions, materials &ndash;
                  collectively, &ldquo;Feedback&rdquo;) through any
                  communication whatsoever (e.g., call, fax, email) will be
                  treated as both non-confidential and non-proprietary. You
                  hereby assign all right, title, and interest in, and We are
                  free to use, without any attribution or compensation to You,
                  any ideas, know-how, concepts, techniques, or other
                  intellectual property and proprietary rights contained in the
                  Feedback, whether or not patentable, for any purpose
                  whatsoever, including but not limited to, developing,
                  manufacturing, having manufactured, licensing, marketing, and
                  selling, directly or indirectly, products and services using
                  such Feedback. You understand and agree that We are not
                  obligated to use, display, reproduce, or distribute any such
                  ideas, know-how, concepts, or techniques contained in the
                  Feedback, and You have no right to compel such use, display,
                  reproduction, or distribution.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c9}`}>
                  <br />
                </span>
                <span className={`${classes.c8} ${classes.c10}`}>
                  ARBITRATION
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  At Our or Your election, all disputes, claims, or
                  controversies arising out of or relating to these Terms or the
                  Service that are not resolved by mutual agreement may be
                  resolved in United Kingdom courts.&nbsp;
                </span>
              </p>
              <p className={`${classes.c15}`}>
                <span className={`${classes.c1}`}></span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c8} ${classes.c10}`}>GENERAL</span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  We think direct communication resolves most issues &ndash; if
                  We feel that You are not complying with these Terms, We will
                  tell You. We will even provide You with recommended necessary
                  corrective action(s) because We value this relationship.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  However, certain violations of these Terms, as determined by
                  Us, may require immediate termination of Your access to the
                  Service without prior notice to You. The United Kingdom law
                  will govern these Terms. Foreign laws do not apply. Except for
                  disputes subject to arbitration as described above, any
                  disputes relating to these Terms or the Site will be heard in
                  the United Kingdom. If any of these Terms are deemed
                  inconsistent with applicable law, then such term(s) shall be
                  interpreted to reflect the intentions of the parties, and no
                  other terms will be modified. By choosing not to enforced any
                  of these Terms, We are not waiving Our rights. These Terms are
                  the entire agreement between You and Us and, therefore,
                  supersede all prior or contemporaneous negotiations,
                  discussions or agreements between Everyone about the Service.
                  The proprietary rights, disclaimer of warranties,
                  representations made by You, indemnities, limitations of
                  liability and general provisions shall survive any termination
                  of these Terms.
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c8}`}>
                  <br />
                </span>
                <span className={`${classes.c8} ${classes.c10}`}>
                  CONTACT US
                </span>
              </p>
              <p className={`${classes.c4}`}>
                <span className={`${classes.c2}`}>
                  If You have any questions about these Terms or otherwise need
                  to contact Us for any reason, You can reach Us at
                  &nbsp;info@ytubeboosterpro.app&nbsp;{" "}
                </span>
                <span className={`${classes.c8} ${classes.c10}`}>or</span>
                <span className={`${classes.c2}`}>
                  &nbsp; Simera Ltd (YTubeBooster)&nbsp; |&nbsp; 3rd Floor, 14
                  Hanover Street, Hanover Square, London W1S 1YH&nbsp; |&nbsp;
                  United Kingdom.
                </span>
              </p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TermsService;
